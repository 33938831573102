import React from "react"
import DashSection from "../../components/DashSection"
import { useEffect } from "react"
import { useState } from "react"
import { useLazyQueryHook, useQueryHook } from "graphql/hooks"
import { STORAGE_KEYS, getItem } from "utils/local-storage"
import { ME } from "graphql/queries/auth"
// import { GET_TRACKS_BY_TAGS } from "graphql/queries/track"
import { RowSlider } from "components/RowSlider"
import { GET_TRACKS } from "graphql/queries/categories"

const NewReleased = () => {
  const accessToken = getItem(STORAGE_KEYS.ACCESS_TOKEN)
  const [currentData, setData] = useState([])
  const { func: meFunc, data: meData } = useLazyQueryHook({ query: ME })

  const {
    // func: tracksFunc,
    loading: trackLoading,
    data: trackData,
    error: trackError
  } = useQueryHook({
    query: GET_TRACKS,
    variables: {
      publishedStatus: "true"
    },

    fetchPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (trackData && trackData?.getTracks?.tracks?.length) {
      setData(trackData?.getTracks?.tracks)
    }
  }, [trackData])

  useEffect(() => {
    if (accessToken && accessToken !== "") {
      meFunc()
    }
  }, [accessToken])

  return (
    <>
      {!trackLoading ? (
        <DashSection title="New Release">
          <RowSlider items={currentData ? currentData.slice(0, 7) : []} />
        </DashSection>
      ) : (
        ""
      )}
    </>
  )
}

export default NewReleased
