import { LockOutlined } from "@ant-design/icons"
import moment from "moment"
import React, { useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { getItem, STORAGE_KEYS } from "utils/local-storage"

import cimg from "../../assets/images/sleep-dummy.svg"
import microphone from "../../assets/images/microphone.svg"
import { UserContext } from "contexts/userContext"

const CategoryTrackCard = ({
  img: Imagesrc,
  id: id,
  title: Title,
  duration,
  layout,
  link: Cardlink,
  subtitle: Subtitle,
  onPlusClick,
  pathname,
  onPlayClick,
  existsInTheQueue,
  premium,
  subpath
}) => {
  let history = useHistory()

  const user = useContext(UserContext)

  const today = Math.floor(new Date())

  const subscriptionLink = "/subscription"

  const toInnerPage = (id) => {
    history.push({
      pathname: getTrackRoute(),
      state: { id: id, pathname: pathname }
    })
  }

  const getTrackRoute = () => {
    if (premium) {
      if (
        user.subscriptionIos === "paid" ||
        (user?.subscriptionIos === "trial" &&
          moment(today) <
            moment(
              user.subscriptionGraceDate
                ? user.subscriptionGraceDate
                : new Date()
            ))
      ) {
        return Cardlink
      } else {
        return subscriptionLink
      }
    } else {
      return Cardlink
    }
  }

  if (layout === "horizontal") {
    return (
      <div className="card card--horizontal">
        <Link
          to={{
            pathname: { Cardlink },
            state: {
              id: id,
              pathname: pathname
            }
          }}
          className="card-link"
        />
        <div className="card-img">
          <img src={Imagesrc || cimg} alt="" />
          <span
            class="iconlyBulk-Play"
            onClick={() => {
              onPlayClick()
            }}
          >
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
        </div>
        <div className="card__body">
          <h3>{Title}</h3>
          {existsInTheQueue ? (
            <></>
          ) : (
            <div
              className="card__add-btn"
              onClick={() => {
                onPlusClick()
              }}
            >
              <i class="iconly-Plus icli"></i>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="category-card">
        <div className="category-card-regular" onClick={() => toInnerPage(id)}>
          {premium && user?.subscriptionIos === "free" ? (
            <div className="card-premium">
              <LockOutlined /> Premium
            </div>
          ) : (
            <>{premium ? <div className="card-premium">Premium</div> : ""}</>
          )}

          <div className="category-card-regular-header">
            <img
              src={Imagesrc || cimg}
              alt=""
              className="category-card-regular-header-img"
            />
            <div className="category-card-regular-header-duration">
              {duration}
            </div>
          </div>
          <div className="category-card-regular-body">
            {/* <div className="category-card-regular-body-writer">
              <img src={microphone} alt="microphone" />
              <span>Devanshu Bajracharya</span>
            </div> */}
            <h3 title={Title}>
              {Title?.length > 35 ? Title.substring(0, 35) + "..." : Title}
            </h3>
          </div>
        </div>
      </div>
    )
  }
}

export default CategoryTrackCard
