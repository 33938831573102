import { createSlice } from "@reduxjs/toolkit"
import { loadMoreHandler } from "../../utils/helper"

const initialState = {
  playlists: undefined,
  loadingPlaylist: false,
  metaPlaylist: undefined,
  errorPlaylist: undefined,

  playlistTracks: undefined,
  loadingPlaylistTracks: false,
  metaPlaylistTracks: undefined,
  errorPlaylistTracks: undefined,

  showCreatePlaylistModal: false,

  userPlaylists: undefined,
  loadingUserPLaylists: false,
  metaUserPlaylist: undefined,
  errorUserPlaylist: undefined,

  userPlaylistTracks: undefined,
  loadingUserPlaylistTracks: false,
  errorUserPlaylistTracks: undefined
}

const PlaylistSlice = createSlice({
  name: "playlists",
  initialState: initialState,
  reducers: {
    fetchPlaylistRequest(state) {
      state.loadingPlaylist = true
    },

    fetchPlaylistSuccess(state, actions) {
      state.playlists = loadMoreHandler(
        state.playlists,
        actions.payload.meta.current_page,
        actions.payload.playlists
      )
      state.metaPlaylist = actions.payload.meta
      state.loadingPlaylist = false
    },

    fetchPlaylistFail(state, actions) {
      state.loadingPlaylist = false
      state.errorPlaylist = actions.payload
    },

    fetchPlaylistTracksRequest(state) {
      state.loadingPlaylistTracks = true
    },

    fetchPlaylistTracksSuccess(state, actions) {
      state.loadingPlaylistTracks = false
      state.playlistTracks = loadMoreHandler(
        state.playlistTracks,
        actions.payload.meta.current_page,
        actions.payload.tracks
      )
      state.metaPlaylistTracks = actions.payload.meta
    },

    fetchPlaylistTrackFail(state, actions) {
      state.loadingPlaylistTracks = false
      state.errorPlaylist = actions.payload
    },

    // create playlist modal
    togglePlaylistModal(state, actions) {
      state.showCreatePlaylistModal = actions.payload
    },

    // user's playlist
    fetchUserPlaylistRequest(state) {
      state.loadingUserPLaylists = true
    },

    fetchUserPlaylistSuccess(state, actions) {
      state.loadingUserPLaylists = false
      state.userPlaylists = loadMoreHandler(
        state.userPlaylists,
        actions.payload.meta.current_page,
        actions.payload.playlists
      )
      state.metaUserPlaylist = actions.payload.meta
    },

    fetchUserPlaylistFail(state, actions) {
      state.loadingUserPLaylists = false
      state.errorUserPlaylist = actions.payload
    },

    createUserPlaylistSuccess(state, actions) {
      state.userPlaylists = [actions.payload].concat(state.userPlaylists)
    },

    // user playlist tracks
    fetchUserPlaylistTracksRequest(state) {
      state.loadingUserPlaylistTracks = true
    },

    fetchUserPlaylistTracksSuccess(state, actions) {
      state.loadingUserPlaylistTracks = false
      state.userPlaylistTracks = actions.payload
    },

    fetchUserPlaylistTracksFail(state, actions) {
      state.loadingUserPlaylistTracks = false
      state.errorUserPlaylistTracks = actions.payload
    },

    removeFromUserPlaylist(state, action) {
      const { listId } = action?.payload
      state.userPlaylistTracks = state?.userPlaylistTracks?.filter(
        (item) => item?.id !== listId
      )
    }

    // end user playlist track
  }
})

export const {
  fetchPlaylistFail,
  fetchPlaylistRequest,
  fetchPlaylistSuccess,

  fetchPlaylistTracksRequest,
  fetchPlaylistTracksSuccess,
  fetchPlaylistTrackFail,

  togglePlaylistModal,

  fetchUserPlaylistFail,
  fetchUserPlaylistRequest,
  fetchUserPlaylistSuccess,

  createUserPlaylistSuccess,

  fetchUserPlaylistTracksFail,
  fetchUserPlaylistTracksRequest,
  fetchUserPlaylistTracksSuccess,
  removeFromUserPlaylist
} = PlaylistSlice.actions

export default PlaylistSlice.reducer
