import { Modal, Form, Col, Row, Button, Input, message } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  createPlaylistService,
  togglePlaylistModalService
} from "../../services/playlist.service"
import PlaylistImage from "../../assets/images/favorite-tracks.png"
import { getErrors, setFormData } from "../../utils/helper"
import { useMutationHook } from "graphql/hooks"
import {
  CREATE_PLAYLIST,
  GET_PLAYLISTS,
  UPDATE_PLAYLIST_BY_ID
} from "graphql/queries/playlist"
import { set } from "lodash"

const EditPlaylist = (props) => {
  const {
    editPlaylistModal,
    setEditPlaylistModal,
    setPlaylist,
    playlist,
    playlistData
  } = props
  const {
    func: createPlaylistFunc,
    loading: createPlaylistLoading,
    error: createPlaylistError,
    data: createPlaylistData
  } = useMutationHook({
    query: CREATE_PLAYLIST,
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: {
          page: 1,
          limit: 20
        }
      }
    ]
  })

  // console.log("playlistData", playlistData)

  const [form] = Form.useForm()

  // console.log("playlist", playlist)

  //state
  const [creating, setCreating] = useState(false)
  const [formValues, setFormValues] = useState({
    playlistName: "",
    description: ""
  })
  const [errors, setErrors] = useState({})

  const {
    func: updatePlaylistFunc,
    loading: updatePlaylistLoading,
    error: updatePlaylistError,
    data: updatePlaylistData
  } = useMutationHook({
    query: UPDATE_PLAYLIST_BY_ID,
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: {
          page: 1,
          limit: 20
        }
      }
    ]
  })

  useEffect(() => {
    setFormValues({ ...formValues, ...playlist })

    return () => {
      form.resetFields()
    }
  }, [playlist])

  const onSubmit = async (values) => {
    // let form = setFormData(values)
    setCreating(true)
    if (
      playlistData?.getPlaylists?.playlist?.some(
        (item) => formValues.playlistName === item.playlistName
      )
    ) {
      message.error(`Playlist name ${formValues.playlistName} already exists`)
    } else {
      const variables = {
        id: playlist?.id,
        description: formValues.description,
        name: formValues.playlistName
      }
      if (!Object.keys(errors).length) {
        await updatePlaylistFunc({ variables }).then(() => {
          setEditPlaylistModal(false)
          message.success("Playlist updated successfully")
        })
      }
    }
    setFormValues({ ...formValues, ...playlist })

    setCreating(false)
  }

  // modal close
  const handleModalClose = () => {
    setPlaylist({})
    form.resetFields()
    setEditPlaylistModal(false)
  }

  //   onChange form Values

  const onChange = (e) => {
    const { name, value } = e.target

    if (!value) {
      setErrors({ ...errors, playlistName: "Name is required!!" })
    } else {
      setErrors({})
    }
    setFormValues({ ...formValues, [name]: value })
  }

  useEffect(() => {
    if (!updatePlaylistLoading) {
      if (updatePlaylistError) {
        message.error(
          getErrors(updatePlaylistError.graphQLErrors) ||
            updatePlaylistError.message
        )
      }
    }
  }, [updatePlaylistLoading])

  return (
    <Modal
      visible={editPlaylistModal}
      title={"Edit Playlist"}
      footer={[
        <div key={1}>
          <Button
            className="btn btn-primary"
            loading={creating}
            style={{ padding: "0.5em" }}
            onClick={onSubmit}
          >
            Save Changes
          </Button>
        </div>
      ]}
      onCancel={handleModalClose}
    >
      <div className="modal-body">
        <Row gutter={[12, 12]}>
          <Col xl={8} sm={24}>
            <div className="avatar-img">
              <img src={PlaylistImage} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col xl={16} sm={24}>
            <div className="modal-form">
              <Row>
                <Col span={24}>
                  <label for="playlistName" className="edit-form-label">
                    <span>*</span> Name
                  </label>
                  <Input
                    name="playlistName"
                    type="text"
                    value={formValues.playlistName}
                    onChange={onChange}
                    className="edit-form-input"
                  />
                  <div className="edit-form-error">
                    {errors && errors.playlistName}
                  </div>
                </Col>
                <Col span={24} style={{ marginTop: "10px" }}>
                  <label for="description" className="edit-form-label">
                    Description
                  </label>
                  <Input
                    name="description"
                    type="text"
                    value={formValues.description}
                    onChange={onChange}
                    className="edit-form-input"
                  />
                </Col>
              </Row>
              {/* <Form
                onFinish={onSubmit}
                form={form}
                initialValues={{
                  name: playlist?.playlistName,
                  description: playlist?.description
                }}
                id="gv-edit-playlist-form"
                layout="vertical"
                size="large"
                className="auth-form-wrapper"
                key={playlist}
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Name is required !!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Playlist's name"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Description is required !!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Playlist's description"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form> */}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default EditPlaylist
