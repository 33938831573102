import React from "react"
import "./index.scss"

const AuthReqWrapper = () => {
  return (
    <>
      <div className="auth-required-wrapper"></div>
    </>
  )
}

export default AuthReqWrapper
