import React from "react"
import ReactDOM from "react-dom"
import { initializeApp } from "firebase/app"

import App from "./App"
import "./assets/less/app.less"
import "./assets/scss/app.scss"

import { Router } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"
import { Provider } from "react-redux"
import history from "./utils/history"
import store from "./store/store"
import { firebaseConfig } from "./config/firebase.config"

import { ApolloProvider } from "@apollo/client"
import { client } from "./config/graphql.config"

import { CookiesProvider } from "react-cookie"

export const app = initializeApp(firebaseConfig)

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <ApolloProvider client={client}>
        <ConnectedRouter history={history}>
          <Router history={history}>
            <App />
          </Router>
        </ConnectedRouter>
      </ApolloProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
)
