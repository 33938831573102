import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  genres: undefined,
  loadingGenres: false,

  loadingSingleGenre: false,
  singleGenre: undefined
}

const genreSlice = createSlice({
  name: "genreSlice",
  initialState,
  reducers: {
    fetchAllGenresRequest(state) {
      state.loadingGenres = true
    },
    fetchAllGenresSuccess(state, actions) {
      const { genres } = actions?.payload
      state.loadingGenres = false
      state.genres = genres
    },
    fetchAllGenresFail(state, actions) {
      state.loadingGenres = false
    },

    fetchSingleGenreRequest(state, action) {
      state.loadingSingleGenre = true
    },
    fetchSingleGenreSuccess(state, action) {
      state.loadingSingleGenre = false
      state.singleGenre = action?.payload?.data
    },
    fetchSingleGenreFail(state, action) {
      state.loadingSingleGenre = false
    }
  }
})

export const {
  fetchAllGenresRequest,
  fetchAllGenresSuccess,
  fetchAllGenresFail,

  fetchSingleGenreRequest,
  fetchSingleGenreSuccess,
  fetchSingleGenreFail
} = genreSlice.actions

export default genreSlice.reducer
