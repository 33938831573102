
import React from 'react'

import { Button } from 'antd'

import history from 'utils/history'


const Subscribed = () => {

    const RedirectHome = () => {
        history.push("/")
    }

    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__success">
                <div className="heading" style={{ marginTop: "15px" }}>
                    <h2 style={{ fontSize: "26px" }}>Trying to access subscription page?</h2>
                </div>
                <div className="text-small" >
                    <p style={{ marginTop: "40px" }}>
                        You are already subscribed to our premium plan. We hope you are enjoying the Good Vibes Experience.
                    </p>
                </div>
                <Button
                    className="btn btn-primary btn-large"
                    htmlType="submit"
                    style={{ width: "85%", marginTop: "25px" }}
                    onClick={RedirectHome}
                >
                    Go to Home Page
                </Button>
            </div>
        </div>
    )
}

export default Subscribed