import React from "react"
const CardCategories = ({ title, imgsrc, size, totalTracks, description }) => {
  return (
    <div className="card-banner">
      {/* <div className="card-banner__img">
        <img src={imgsrc || Cimg1} alt="" />
      </div> */}

      <div className="card-banner__content">
        <div className="card-content-text">
          <h2
            className={`header-primary ${
              size === "large" ? "header-primary--large" : ""
            } `}
          >
            {title}
          </h2>

          {totalTracks ? (
            <p className="banner-tracks">
              {totalTracks}
              {totalTracks && totalTracks > 1 ? " tracks" : " track"}
            </p>
          ) : (
            ""
          )}

          <p className="banner-tracks">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default CardCategories
