import React from 'react';

const ForgotPasswordLayout = (props) => {
    return (
        <div className="forgot-layout">
            <div className="forgot-layout__img">
                {/* <img src={BrandLogo} alt="Logo" className="brand-logo" /> */}
                <img src={props.image} alt="" className="layout-img" height="100%" width="100%" />
            </div>
            <div className="forgot-layout__form">{props.children}</div>
        </div>
    );
};

export default ForgotPasswordLayout;