import React from "react"

import cimg from "../../assets/images/Thumbnail.png"

const CardRectangle = ({
  title: Title,
  time: Time,
  layout: Layout,
  selected
}) => {
  return (
    <>
      <div
        className={`card-rectangle ${
          Layout === "title-center" ? "card-round--title-center" : ""
        }`}
      >
        <div className="card-rectangle-option">
          {!selected ? (
            <div className="card-rectangle-option-selected" />
          ) : (
            <div className="card-rectangle-option-not-selected" />
          )}
        </div>

        <div className="card-rectangle-content">
          <h4>{Title}</h4>
          <h6>{Time}</h6>
        </div>
      </div>
    </>
  )
}

export default CardRectangle
