export const GET_RECOMMENDATION_BY_TAGS = `
  query ($getTracksDto: GetTracksDto!) {
    getRecommendationByTags(
      getTracksDto: $getTracksDto
    ){
      newTracks {
        id
        title
        slug
        trackType
        storageKeys{azure{image,menifestFileUrl}}
      }
      oldTracks {
        id
        title
        slug
        trackType
        storageKeys{azure{image,menifestFileUrl}}
      }
    }
  }
`
export const GET_TRACK = `
  query ($id: String!) {
    getTrack(
      id: $id
    ){
      id
      title
      description
      slug
      duration
      trackType
      storageKeys{azure{image,menifestFileUrl}}
      category {
        id
        categoryName
      }
    }
  }
`

export const GET_TRACK_BY_SLUG = `
  query ($slug: String!) {
    getTrackBySlug(
      slug: $slug
      ){
      id
      title
      description
      slug
      duration
      trackType
      storageKeys{azure{image,menifestFileUrl}}
      category {
        id
        categoryName
      }
    }
  }
`

export const GET_PREMIUM_TRACKS_BY_TAGS = `
  query ($getTracksDto: GetTracksDto!) {
    getPremiumTracksByTags(
      getTracksDto: $getTracksDto
    ){
        id
        title
        slug
        storageKeys{azure{image,menifestFileUrl}}
        trackType
    }
  }
`

export const GET_POPULAR_TRACKS_BY_TAGS = `
  query ($getTracksDto: GetTracksDto!) {
    getPopularTracksByTags(
      getTracksDto: $getTracksDto
    ){
        id
        title
        slug
        trackType
        storageKeys{azure{image,menifestFileUrl}}
    }
  }
`

export const GET_RECENTLY_PLAYED_TRACKS = `
  query ($getTracksDto: GetTracksDto!) {
    getRecentlyPlayedTracks(
      getTracksDto: $getTracksDto
    ){
        id
        title
        slug
        trackType
        storageKeys{azure{image,menifestFileUrl}}
    }
  }
`

export const GET_TRACKS_BY_TAGS = `
  query ($tracksByTagsArgs: GetTracksByTagsArgs!) {
    getTracksByTags(
      tracksByTagsArgs: $tracksByTagsArgs
    ){
      tracks{
        id
        title
        slug
        duration
        trackType
        category{
          categoryName
        }
        createdDTM
        storageKeys{azure{image,menifestFileUrl}}

    }
    meta{
      page
      total_items
      total_pages
    }
    }
  }
`

export const ADD_TRACK_TO_FAVOURITE = `
  mutation ($trackId: String!) {
    addTrackToFavorite(
      trackId: $trackId
    )
  }
`

export const REMOVE_TRACK_TO_FAVOURITE = `
  mutation ($trackId: String!) {
    removeTrackFromFavorite(
      trackId: $trackId
    )

    
  }
`

export const GET_FAVOURITES_TRACKS = `
  query ($options: PaginationInput!) {
    getFavoriteTracks(
      options: $options
    ){
      tracks{
        id
        slug
        trackType
        storageKeys{azure{image,menifestFileUrl}}
        title
        category{
          categoryName
          id
        }
        duration
      }

      meta {
        limit
        page
        total
        total_items
        total_pages
        }
    }
  }
`
export const IS_USER_FAVOURITE_TRACK = `
query($trackId: String!){
  isUserFavoriteTrack(trackId:$trackId)
}
`

export const RECENTLY_PLAYED_TRACK_MUSIC = `
  mutation($userPlayHistoryInput: UserPlayHistoryInput!){
    storeUserPlayHistory(userPlayHistoryInput:$userPlayHistoryInput){
      id
      duration
    }
  }
`

export const UPDATE_TRACK_COUNT = `
  mutation($trackId: String!){
    updateTrackPlayCount(trackId:$trackId)
  }
`
export const STORE_PLAY_HISTORY_INPUT = `
mutation ($trackId: String!,$duration:String!){
  storeUserPlayHistory(userPlayHistoryInput:{trackId:$trackId,duration:$duration}){
    duration
    id
  }
}
`

// export const GET_TRACKS = `query($tagId:String){
//   getTracks(searchAndFilterArgs:{
//       tagId:$tagId

//   }){
//       meta{
//              limit
//                page
//                total
//            }

//            tracks{
//                 title
//                 id
//                 storageKeys{
//                   freeTrackUrl
//                   image
//                   menifestFileUrl
//                   paidTrackUrl
//               }
//               }
//   }
// }`
