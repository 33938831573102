import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  choosedPackage: undefined,
  loadingProducts: false,
  products: [],
  errorProducts: undefined,
  showConfirmationModal: false,
  activeSubscription: undefined,
  productPaymentResponse: undefined
  // clientSecret: undefined
}

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    setChoosedPackage(state, actions) {
      state.choosedPackage = actions.payload
    },

    fetchProductListingRequest: (state, actions) => {
      state.loadingProducts = true
    },
    fetchProductListingSuccess: (state, actions) => {
      const { products } = actions?.payload
      state.products = products
      state.loadingProducts = false
    },
    fetchProductListingFail: (state, actions) => {
      state.loadingProducts = false
      state.errorProducts = actions?.payload
    },

    // toggleConfirmationModal: (state, actions) => {
    //   state.showConfirmationModal = !state.showConfirmationModal
    // },

    subscribeToProductSuccess: (state, actions) => {
      const { response } = actions?.payload
      state.clientSecret = response?.clientSecret
    },
    fetchAllSubscriptionSuccess: (state, actions) => {
      const { data } = actions?.payload
      state.activeSubscription = data
    },

    productPaymentSuccess: (state, actions) => {
      const { data } = actions?.payload
      state.productPaymentResponse = data
    }
  }
})

export const {
  setChoosedPackage,
  fetchProductListingRequest,
  fetchProductListingSuccess,
  fetchProductListingFail,
  // toggleConfirmationModal,
  subscribeToProductSuccess,
  fetchAllSubscriptionSuccess,
  productPaymentSuccess
} = subscriptionSlice.actions

export default subscriptionSlice.reducer
