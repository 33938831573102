import React, { Fragment, useState } from "react"
import {
  PlayCircleFilled,
  PauseCircleFilled,
  LoadingOutlined
} from "@ant-design/icons"
import { playSong, setPlaying } from "../../services/player.service"
import { useDispatch, useSelector } from "react-redux"
import FavouriteButton from "../UI/Button/favourite-button"

import DropdownSong from "../songlist/Dropdown"

import Cimg1 from "../../assets/images/Thumbnail.png"
import { setPlayingSong } from "../../store/slice/player.slice"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useLazyQueryHook, useQueryHook } from "graphql/hooks"
import { GET_PLAYLISTS } from "graphql/queries/playlist"
import { Spin } from "antd"

import useSendEvents from "functions/useSendEvents"
import userMixPanelEvents from "functions/mixPanelEvents"
import { useEffect } from "react"

const Cardbanner = ({
  img: Imgsrc,
  title: Title,
  text: Text,
  subtitle: Subtitle,
  titleSize: Size,
  totalTracks: TotalTracks,
  createdby: Createdby,
  duration,
  height,
  favourite,
  fromFav,
  track,
  isPlaylist,
  fromPlaylist,
  fromTag,
  loadingFav,
  routerData
}) => {
  const { id: playlistId } = useParams()

  let { state: routeState } = useLocation()

  // console.log("state data", routeState.sessionDetails)
  // console.log("playlistId playlistIdm from inner page", playlistId)

  // console.log("fromTrack", fromTrack)
  // console.log("sessionDetail sessionDetail", sessionDetails)

  const [sendEvents] = useSendEvents()
  const [heartClick, setHeartClick] = useState(false)
  const dispatch = useDispatch()
  const state = useSelector((state) => state.player)
  const { isPlaying, playingSong, activeSongId } = state
  const [playCount, setPlayCount] = useState(0)

  useEffect(() => {
    if (activeSongId === undefined) {
      setPlayCount(0)
    }
  }, [activeSongId])

  const playTrack = () => {
    if (track) {
      setPlayCount(playCount + 1)
      dispatch(playSong(track))
      dispatch(setPlaying(true))
      dispatch(
        setPlayingSong({
          id: track[0]?.id,
          index: 0
        })
      )
      // sending mix panel evenst
      const properties = {
        source: "track single page",
        track_name: Title,
        track_id: playlistId
      }
      if (playCount === 0) {
        if (routeState.sessionDetails) {
          let newProperty = {
            ...properties,
            "session name": routeState.sessionDetails.name
              ? routeState.sessionDetails.name
              : "",
            "session id": routeState.sessionDetails.id,
            type: "session"
          }
          userMixPanelEvents("click_play_rate", newProperty)
        } else {
          let newProperty = {
            ...properties,
            type: "single"
          }
          userMixPanelEvents("click_play_rate", newProperty)
        }
      }

      // send algolia evenst
      routeState = {
        ...routeState,
        eventComponent: "engagement"
      }
      sendEvents(routeState)
    }
  }

  const pauseTrack = () => {
    dispatch(setPlaying(false))
  }

  const onHeartClick = () => {
    setHeartClick(!heartClick)
  }

  return (
    <div className="card-banner">
      {Imgsrc ? (
        <div className="card-banner__img">
          <img src={Imgsrc || Cimg1} alt="" />
        </div>
      ) : (
        ""
      )}
      <div className="card-banner__content">
        <div className="card-content-text">
          {Subtitle ? <h5>{Subtitle}</h5> : ""}

          <h2
            className={`header-primary ${
              Size === "large" ? "header-primary--large" : ""
            } `}
          >
            {Title ?? "Playlist name"}
          </h2>
          {duration ? <p>Duration: {duration}</p> : ""}
          {/* {Text ? (
            <p
              className="banner-subtext"
              dangerouslySetInnerHTML={{ __html: Text }}
            ></p>
          ) : (
            ""
          )} */}
          {Createdby ? (
            <p className="banner-playlist-creator">By {Createdby}</p>
          ) : (
            ""
          )}
          {TotalTracks ? (
            <p className="banner-tracks">
              {TotalTracks}{" "}
              {TotalTracks && TotalTracks > 1 ? "tracks" : "track"}
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          className="btn-grp d-flex-ac"
          style={{
            marginTop: "0.7em",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          {track?.length !== 0 ? (
            <div>
              <Fragment>
                {/* <button className="btn btn-outline btn-large"> */}
                {track?.length === 1 ? (
                  //for single track details
                  <>
                    {isPlaying &&
                    track?.length &&
                    activeSongId === (track[0]?.track?.id ?? track[0]?.id) ? (
                      <button
                        className="btn btn-outline btn-large"
                        onClick={pauseTrack}
                      >
                        <span className="d-flex-ac">
                          <PauseCircleFilled /> Pause
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline btn-large"
                        onClick={playTrack}
                      >
                        <span className="d-flex-ac">
                          <PlayCircleFilled />
                          Play Now
                        </span>
                      </button>
                    )}
                  </>
                ) : (
                  //for list of songs
                  <>
                    {isPlaying &&
                    track &&
                    (playingSong === track ||
                      track.find((t) => t.id == activeSongId)) ? (
                      <button
                        className="btn btn-outline btn-large"
                        onClick={pauseTrack}
                      >
                        <span className="d-flex-ac">
                          <PauseCircleFilled /> Pause
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline btn-large"
                        onClick={playTrack}
                      >
                        <span className="d-flex-ac">
                          <PlayCircleFilled />
                          Play Now
                        </span>
                      </button>
                    )}
                  </>
                )}
                {/* </button> */}
              </Fragment>
            </div>
          ) : (
            <></>
          )}
          <div className="single-track-icons">
            <div className="single-track-icons-favourite">
              {!fromFav && !fromTag ? (
                <>
                  {loadingFav ? (
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        color: "#9797DE"
                      }}
                      spin
                    />
                  ) : (
                    <a type="button" className="">
                      <FavouriteButton track={track} favourite={favourite} />
                    </a>
                  )}
                </>
              ) : (
                ""
              )}
            </div>

            {!fromPlaylist && !fromTag ? (
              <div>
                <DropdownSong
                  track={track}
                  isPlaylist={isPlaylist}
                  playlistId={playlistId}
                  // listId={id}
                  page="track"
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cardbanner
