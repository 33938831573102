import React from "react"
import "./preloader.scss"
import { createPortal } from "react-dom"

const Preloader = () => {
  return createPortal(
    <div className="gv-preloader">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>,
    document.body
  )
}

export default Preloader
