export const SEARCH_TRACKS = `
query SearchTracks($searchTracksArgs: SearchTracksArgs!){
    searchTracks(searchTracksArgs:$searchTracksArgs){
      tracks{
        id
        title
        slug
        trackType
        category{
          categoryName
        }
        duration
        storageKeys{azure{image,menifestFileUrl}}
        
      }
    }
  }`

export const SEND_USER_EVENTS = `
  query($eventName: AlgoliaEventNames! $filter:[String!], $objectId:[String!], $position: Int, $query: String, $queryId: String ){
    sendUserEvents(sendEventInput: {
      eventName: $eventName, filter: $filter, objectId: $objectId, position: $position, query: $query, queryId: $queryId
    } )
    }`

export const GET_CUSTOM_CATEGORIES = `
      query($categoryNames: [String!]!){
        getCustomCategories(categoryNames: $categoryNames) {
          id
          storageKey
          categoryName
          publishStatus
        }
      }
    
    `
