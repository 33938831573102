// import from react
import React, { useEffect, useState } from "react"
// import form react router
import { useHistory } from "react-router-dom"
// import form antd
import { Button, Input, message } from "antd"
// import icons and image
import { ArrowRightOutlined } from "@ant-design/icons"
import { IoCheckmarkSharp } from "react-icons/io5"
import gimg1 from "../../assets/images/genre/abundance.png"
// import component
import CardRound from "../../components/Cards/CardRound"
import Preloader from "../../components/UI/Preloader"
// import form graphql funtion
import { useLazyQueryHook, useMutationHook } from "graphql/hooks"
import { UPDATE_USER_TAGS } from "graphql/queries/tags"
import { GET_TAGS } from "../../query/tagsQuery"
// import function
import { setItem, STORAGE_KEYS } from "utils/local-storage"
import { ME } from "graphql/queries/auth"
import userMixPanelEvents from "functions/mixPanelEvents"
import { getCurrentSession } from "../../utils/helper"


const ChooseGenre = () => {
  // states
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchTerm, setSearchTerm] = useState("")
  const [otherTagsValue, setOtherTagsValue] = useState("")
  const [OnOtherTagSelect, setOnOtherTagSelect] = useState(false)

  // current session data
  const userData = getCurrentSession().user

  const {
    func: getTags,
    loading: getLoading,
    error: getError,
    data
  } = useLazyQueryHook({ query: GET_TAGS, fetchPolicy: "network-only" })

  const {
    func: updateUserTagsFunc,
    loading: updateUserTagsLoading,
    error: updateUserTagsError,
    data: updateUserTagsData
  } = useMutationHook({
    fetchPolicy: "network-only",
    query: UPDATE_USER_TAGS
  })

  const {
    func: meFunc,
    data: meData,
    loading: meLoading,
    error: meError
  } = useLazyQueryHook({ query: ME })

  useEffect(() => {
    getTags({
      variables: {
        limit: rowsPerPage,
        page: currentPage,
        publishStatus:true

      }
    })
  }, [rowsPerPage, currentPage, searchTerm])

  // const dispatch = useDispatch()
  const history = useHistory()
  const [selectedTagGroups, setSelectedTagGroups] = useState(undefined)
  useEffect(() => {
    if (userData && userData?.tags && userData?.tags?.length) {
    }
  }, [])

  // is tag selected
  const tagSelected = (id) => {
    if (selectedTagGroups && selectedTagGroups.length) {
      if (selectedTagGroups.indexOf(id) !== -1) {
        return true
      }
    }
    return false
  }

  // toggle tag selection
  const toggletagSelection = (i) => {
    if (selectedTagGroups && selectedTagGroups.length) {
      if (selectedTagGroups.indexOf(i) === -1) {
        if (selectedTagGroups.length < 3) {
          let newTags = [...selectedTagGroups].concat([i])
          setSelectedTagGroups(newTags)
        }
      } else {
        let newTags = [...selectedTagGroups].filter((item) => item !== i)
        setSelectedTagGroups(newTags)
      }
    } else {
      setSelectedTagGroups([i])
    }
    // form.resetFields()
  }

  //update user's tags
  const [updatingTags, setUT] = useState(false)
  const updateTags = async () => {
    if (selectedTagGroups && selectedTagGroups.length) {
      setUT(true)

      const tagsSuggestion =
        otherTagsValue !== ""
          ? { tagSuggestionDto: { suggestion: otherTagsValue } }
          : ""

      if (!updateUserTagsLoading) {
        await updateUserTagsFunc({
          variables: {
            tags: selectedTagGroups,
            ...tagsSuggestion
          }
        }).then((data) => {
          // console.log({ data })
        })
      }

      setUT(false)
    }
  }

  useEffect(() => {
    if (!updateUserTagsLoading) {
      if (updateUserTagsError) {
        message.error(updateUserTagsError.message || updateUserTagsError)
      }

      if (
        updateUserTagsData &&
        updateUserTagsData.storeUserTag &&
        updateUserTagsData.storeUserTag.length
      ) {
        meFunc()
        message.success("Your tags updated successfully")
      }
    }
  }, [updateUserTagsLoading, updateUserTagsError, updateUserTagsData])

  useEffect(() => {
    if (!meLoading) {
      if (meData) {
        const {
          id,
          firstName,
          lastName,
          email,
          profilePicStorageKey,
          tags,
          emailConfirmed,
          subscriptionIos,
          subscriptionIosExpiryDate
        } = meData.me

        const userDetails = {
          id,
          firstName,
          lastName,
          email,
          image: profilePicStorageKey,
          emailConfirmed,
          tags: tags,
          subscriptionIos,
          subscriptionIosExpiryDate
        }
        let properties = {
          distinctId: id,
          source: "choose-tags"
        }
        let i = 0
        while (i < selectedTagGroups.length) {
          const filterTagItms = data.getTags.tags.filter(
            (tags) => tags.id == selectedTagGroups[i]
          )
          const filterTagName = filterTagItms.map((item) => {
            return item.tagName
          })
          // send mix panel events
          let neproperties = {
            ...properties,
            "tag name": filterTagName.toString()
          }
          i++
          userMixPanelEvents("user_tags", neproperties)
        }
        // close
        setItem(STORAGE_KEYS.USER, userDetails)
        history.push("/choose-commitments")
      }
    }
  }, [meData, meLoading])

  return (
    <section className="section-genre-list">
      {getLoading ? (
        <Preloader />
      ) : (
        <>
          <div className="row">
            <h2 className="header-primary">
              What Bring You To <span>Good Vibes? </span>
            </h2>

            <h4>
              Choose any 3 goals of interest for personalized suggestions.
            </h4>
            <ul className="genre-list">
              {data && data.getTags && data.getTags.tags ? (
                <>
                  {data.getTags.tags?.map((tag) => (
                    <li
                      className="genre-list__item"
                      key={tag.id}
                      onClick={() => toggletagSelection(tag.id)}
                    >
                      <CardRound
                        layout="title-center"
                        img={
                          tag?.storageKey.indexOf("https") !== -1
                            ? tag.storageKey
                            : gimg1
                        }
                        selectedTagGroups={selectedTagGroups}
                        title={tag.tagName}
                        selected={tagSelected(tag.id)}
                      />
                      {tagSelected(tag.id) ? (
                        <IoCheckmarkSharp className="card--check-icon" />
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                  <li
                    className="genre-list__item"
                    key={"other-tag"}
                    onClick={() => setOnOtherTagSelect(true)}
                  ></li>
                </>
              ) : (
                <p className="text-center">Sorry no Tags found!</p>
              )}
            </ul>
          </div>
          <div className="row row-btn">
            <div
              title={
                selectedTagGroups && selectedTagGroups.length
                  ? false
                  : "Please select atleast one item!!"
              }
            >
              <Button
                loading={updatingTags}
                className="btn btn-primary btn-large"
                onClick={updateTags}
                disabled={
                  selectedTagGroups && selectedTagGroups.length ? false : true
                }
              >
                NEXT
                <ArrowRightOutlined style={{ marginLeft: 7 }} />{" "}
              </Button>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default ChooseGenre
