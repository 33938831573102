import { Button, Modal } from "antd"
import React from "react"
import history from "utils/history"

const PremiumMessageModal = ({ isPremiumModalOpen, setIsPremiumModalOpen }) => {
    
  const handleOk = () => {
    history.push("/subscription")
  }
  const handleCancel = () => {
    setIsPremiumModalOpen(false)
  }
  return (
    <div className="premium-message-modal">
      <Modal
        title={null}
        visible={isPremiumModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <h2 className="premium-message-modal-header">
          Get access to premium features
        </h2>
        <p className="premium-message-modal-text">
          This is a premium track. Please upgrade your plan to premium to get
          access the track and unlock full Good Vibes experience.
        </p>
        <div className="premium-message-modal-btn">
          <Button className="btn btn-outline" onClick={handleCancel}>
            Go back
          </Button>
          <Button className="btn btn-primary" onClick={handleOk}>
            Upgrade
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default PremiumMessageModal
