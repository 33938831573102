import React, { useRef, useCallback, useState } from "react"
import { Row } from "antd"
import Preloader from "./UI/Preloader"
const ScrollPagination = (props) => {
  const {
    current_page,
    seTcurrentPage,
    loadingMore,
    data,
    total,
    limit,
    setLimit,
    total_pages
  } = props

  const observer = useRef()
  const lastElement = useCallback(
    (node) => {
      if (loadingMore) return
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        // if (entries[0].intersectionRatio) {
        const first = entries[0]
        if (first.isIntersecting) {
          if (current_page < total_pages) {
            // setLimit(limit + 5)
            seTcurrentPage(current_page + 1)
          }
        }
        // }
      })

      if (node) {
        observer.current.observe(node)
      }
    },
    [data]
  ) //eslint-disable-line

  return (
    <>
      <Row className="justify-content-center">
        {loadingMore ? (
          <Preloader />
        ) : (
          <>
            {/* changes coz if triggered while loading same page will be called */}
            <div ref={lastElement}></div>
          </>
        )}
      </Row>
    </>
  )
}

export default ScrollPagination
