import React, { useState, useEffect } from "react"
import { Modal, Button, message, Descriptions } from "antd"
import { useSelector, useDispatch } from "react-redux"
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement
} from "@stripe/react-stripe-js"
import { useHistory } from "react-router-dom"
import { productPaymentSuccess } from "../../../store/slice/subscription.slice"
import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "../../../index"

import { useLazyQueryHook, useMutationHook } from "graphql/hooks"
import { VERIFY_PAYMENT } from "graphql/queries/payment"
import { ME } from "graphql/queries/auth"
import { setItem, STORAGE_KEYS } from "utils/local-storage"

const ConfirmationModal = (props) => {
  const analytics = getAnalytics(app)

  const {
    showConfirmationModal,
    closeConfirmationModal,
    clientSecret,
    userInputValues,
    packageDetail
  } = props
  const state = useSelector((state) => state)
  const {} = state.subscription
  const stripe = useStripe()
  const elements = useElements()
  const [confirming, setConfirming] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const {
    func: verifyPaymentIntentFunction,
    loading: verifyPaymentIntentLoading,
    data: verifyPaymentIntentData,
    error: verifyPaymentIntentError
  } = useMutationHook({ query: VERIFY_PAYMENT })

  const {
    func: meFunc,
    data: meData,
    loading: meLoading,
    error: meError
  } = useLazyQueryHook({ query: ME })

  useEffect(() => {
    if (!meLoading) {
      if (meData) {
        const {
          firstName,
          lastName,
          email,
          profilePicStorageKey,
          tags,
          emailConfirmed,
          subscriptionIos,
          subscriptionIosExpiryDate
        } = meData.me

        const userDetails = {
          firstName,
          lastName,
          email,
          image: profilePicStorageKey,
          emailConfirmed,
          tags: tags,
          subscriptionIos,
          subscriptionIosExpiryDate
        }
        setItem(STORAGE_KEYS.USER, userDetails)
        history.push("/subscription-success")
      }
    }
  }, [meData, meLoading])

  const handleConfirmPayment = () => {
    setConfirming(true)

    if (clientSecret && clientSecret !== "") {
      // Create payment method and confirm payment intent.
      const cardElement = elements.getElement(CardNumberElement)
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: userInputValues.name,
              email: userInputValues.email
            }
          }
        })
        .then((result) => {
          if (result.error) {
            message.error(result?.error?.message ?? "Something went wrong")
            setConfirming(false)
          } else {
            if (result?.paymentIntent?.status === "succeeded") {
              // dispatch(productPaymentSuccess({ data: result?.paymentIntent }))
              logEvent(analytics, "plan_payment_success")
              closeConfirmationModal()
              setConfirming(false)
              history.replace("/profile")
            } else {
              logEvent(analytics, "plan_payment_failure")
              message.error("Something went wrong")
              setConfirming(false)
            }
          }
        })
        .catch((err) => {
          logEvent(analytics, "plan_payment_failure", {
            message: err?.message
          })
          message.error(err?.message ?? "Something went wrong")
          setConfirming(false)
        })
    } else {
      setTimeout(() => {
        logEvent(analytics, "plan_payment_success")
        closeConfirmationModal()
        setConfirming(false)
        meFunc()
        history.replace("/profile")
      }, 2000)

      if (verifyPaymentIntentError) {
        logEvent(analytics, "plan_payment_failure", {
          message: verifyPaymentIntentError.message || verifyPaymentIntentError
        })
        message.error(
          verifyPaymentIntentError.message ||
            verifyPaymentIntentError ||
            "Something went wrong"
        )
        setConfirming(false)
      }
    }
  }

  return (
    <Modal
      visible={showConfirmationModal}
      title={"Payment Confirmation"}
      centered
      // closable={false}
      maskClosable={false}
      footer={[
        <div key={1}>
          <Button
            className="btn btn-primary "
            htmlType="submit"
            form={"gv-create-playlist-form"}
            onClick={() => handleConfirmPayment()}
            loading={confirming}
          >
            Confirm and Pay
          </Button>
        </div>
      ]}
      onCancel={() => {
        message.warning("Payment Incomplete.")
        closeConfirmationModal()
      }}
    >
      <div>
        <Descriptions bordered>
          <Descriptions.Item label="Plan" span={3}>
            Good Vibes Premium - {packageDetail?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Price" span={3}>
            ${packageDetail?.price_detail?.unit_amount / 100}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
