import React from "react"
import { RowSessions } from "./rowSession"
import { useQueryHook } from "../../graphql/hooks"
import { GET_SESSIONS } from "graphql/queries/playlist"
import { useEffect } from "react"
import { useState } from "react"

const SessionComponent = () => {
  const [localSessionData, setlocalSessionData] = useState([])

  const {
    loading: sessionLoading,
    data: sessionData,
    error: sessionError
  } = useQueryHook({
    query: GET_SESSIONS,
    fetchPolicy: "cache-and-network"
  })
  useEffect(() => {
    if (
      sessionData &&
      sessionData?.getSessions &&
      sessionData?.getSessions?.session &&
      sessionData?.getSessions?.session?.length
    ) {
      setlocalSessionData(sessionData?.getSessions?.session)
    }
  }, [sessionData])
  return (
    <>
    {!sessionLoading?
    <div>
      {localSessionData &&
        localSessionData.length &&
        localSessionData?.map((sessions) => (
          <>
            <RowSessions
              session={sessions}
              noItemsText={`Sorry no ${sessions.name} not found !`}
              type="track"
            />
          </>
        ))}
        </div>:""}
    </>
  )
}

export default SessionComponent
