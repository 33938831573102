import { Collapse } from "antd"
import { useState } from "react"
import HeartIcon from "../../../assets/images/offer/heartIcon.svg"

import "./styles.scss"
const { Panel } = Collapse
const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`
const FAQ = () => {
  const [expandIconPosition, setExpandIconPosition] = useState("end")
  const onChange = (key) => {
    console.log(key)
  }
  return (
    <div className="faq-wrapper">
      <div className="heading">
        <h2>Frequently Asked Questions</h2>
      </div>
      <div className="collaspe-wrapper">
        <Collapse
          onChange={onChange}
          expandIconPosition={expandIconPosition}
          // defaultActiveKey={["1"]}
        >
          <Panel
            header="How can I gift-Card a Good Vibes Gift Card to someone?"
            key="1"
          >
            <div className="faq-wrapper-item">
              <ul>
                <li>
                  <img src={HeartIcon} alt="heart" /> Go to
                  https://goodvibesofficial.com/gift.
                </li>
                <li>
                  <img src={HeartIcon} alt="heart" /> Enter the recipient's
                  email address and any personalized message you would like to
                  include.
                </li>
                <li>
                  <img src={HeartIcon} alt="heart" /> Complete the purchase
                  process and send the gift card to the recipient. If the
                  recipient is not yet signed up for Good Vibes, they will
                  receive an email with a username and password generated by us.
                  The recipient can then change their password once they have
                  logged in. If you have any issues or questions regarding
                  gifting a Good Vibes Gift Card, please don't hesitate to reach
                  out to our support team at
                  <a href="support@goodvibesofficial.com." target="_black">
                    &nbsp; support@goodvibesofficial.com.
                  </a>
                </li>
              </ul>
            </div>
          </Panel>
          <Panel
            header="My recipient hasn't received their gift! What happened?"
            key="2"
          >
            <div className="faq-wrapper-item">
              <ul>
                <li>
                  <img src={HeartIcon} alt="heart" />
                  &nbsp; If the gift subscription hasn't been received by the
                  intended recipient on the specified date, ask them to check
                  their email inbox's "Promotions," "Junk," or "Spam" folder. If
                  the gift email is still not found, please reach out to us with
                  the recipient's email address and we'll investigate the issue
                  for you.
                </li>
                <li>
                  <img src={HeartIcon} alt="heart" />
                  &nbsp; If you require further assistance, send an email to
                  <a href="support@goodvibesofficial.com.">
                    &nbsp; support@goodvibesofficial.com.
                  </a>
                </li>
              </ul>
            </div>
          </Panel>
          <Panel header="Are gift cards refundable?" key="3">
            <div className="faq-wrapper-item">
              <ul>
                <li>
                  <img src={HeartIcon} alt="heart" />
                  &nbsp; Good Vibes Gift Cards are refundable only if the
                  request is made within 7 days of the purchase date. If you
                  would like to request a refund for a Good Vibes Gift Card,
                  please contact our support team at
                  <a href="support@goodvibesofficial.com.">
                    &nbsp; support@goodvibesofficial.com.
                  </a>{" "}
                  within 7 days of the purchase date. Our team will assist you
                  with the refund process.
                </li>
              </ul>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}
export default FAQ
