import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory } from "react-router-dom"

import { Layout, Input, Form } from "antd"
import { playstoreLink, appStoreLink } from "../../constants/urls"
import { getCurrentSession } from "../../utils/helper"

import {
  AndroidFilled,
  AppleFilled,
  AlignLeftOutlined
} from "@ant-design/icons"

import UserMenu from "./user-menu"
import { useSelector, useDispatch } from "react-redux"

import _ from "lodash"

import { push } from "connected-react-router"

import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "../../index"

import { useContext } from "react"
import { UserContext } from "contexts/userContext"

import arrowUpLeft from "../../assets/images/search/ArrowUpLeft.svg"
import removeIcon from "../../assets/images/search/remove.svg"

import algoliasearch from "algoliasearch"

import Cookies from "js-cookie"

const { Header } = Layout

const Headerlayout = ({ sidebarStatus: SidebarStatus }) => {
  const analytics = getAnalytics(app)

  const searchBox = useRef()

  const searchRef = useRef()

  const user = useContext(UserContext)

  let appId = process.env.REACT_APP_ALGOLIA_APP_ID

  let appKey = process.env.REACT_APP_ALGOLIA_KEY

  let appIndexName = process.env.REACT_APP_ALGOLIA_SUGGESTION_INDEX_NAME

  // algolia
  const client = algoliasearch(appId, appKey)

  const index = client.initIndex(appIndexName)

  // const params = useParams()

  const params = new URLSearchParams(window.location.search)

  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const [showSuggestion, setShowSuggestion] = useState(false)
  const [suggestionList, setSuggestionList] = useState([])
  const [suggestedList, setSuggestedList] = useState([])
  const [finalList, setFinalList] = useState([])

  const [searchValue, setSearchValue] = useState(
    params.get("keyword") ? params.get("keyword") : ""
  )

  let recentSearchData = Cookies.get("search")
    ? JSON.parse(Cookies.get("search"))
    : []

  const [searchCookies, setSearchCookies] = useState(
    recentSearchData.map((sc, i) => {
      return {
        title: `${sc}`,
        type: "recent"
      }
    })
  )

  useEffect(async () => {
    let query = searchValue ? searchValue : ""
    index
      .search(query, {
        userToken: user.id,
        enablePersonalization: true,
        hitsPerPage: 20
      })
      .then(async (res) => {
        const objectIDs = res.hits.map((hit) => {
          return {
            title: hit.objectID,
            type: "suggested"
          }
        })

        setSuggestionList([...objectIDs, ...suggestionList])
      })
  }, [])

  useEffect(() => {
    let data = [...searchCookies, ...suggestionList]

    data = [...new Map(data.map((item) => [item.title, item])).values()] //removing duplicates

    setFinalList(data)
  }, [searchCookies, suggestionList])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBox.current && !searchBox.current.contains(event.target)) {
        setShowSuggestion(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [searchBox])

  const handleInputChange = (e) => {
    e.preventDefault()
    setSearchValue(e.target.value) // Update the local state with the new search box value
    setShowSuggestion(true)
  }

  useEffect(async () => {
    if (searchValue === "") {
      let data = [...searchCookies, ...suggestionList]

      data = [...new Map(data.map((item) => [item.title, item])).values()] //removing duplicates

      setFinalList(data)
    }
    if (searchValue) {
      let indexName = process.env.REACT_APP_ALGOLIA_TRACK_INDEX_NAME
      const serchIndex = client.initIndex(indexName)

      await serchIndex
        .search(searchValue, {
          userToken: user.id,
          enablePersonalization: true,
          page: 0,
          hitsPerPage: 20
        })
        .then(async (res) => {
          if (res.hits.length) {
            const title = await res.hits.map((hit) => {
              return {
                title: hit.title,
                type: "suggested"
              }
            })

            await setFinalList([...title, ...suggestionList])
          }
        })
    }
  }, [searchValue])

  const handleSearch = async (item) => {
    await setSearchValue(item)

    let data = {
      title: item,
      type: "recent"
    }

    setSearchCookies([data, ...searchCookies])

    await dispatch(push(`/search?keyword=${item}`))
    setShowSuggestion(false)
    logEvent(analytics, "search", {
      keyword: item
    })
  }

  const handleClick = (title, type) => {
    if (type === "suggested") {
      setSearchValue(title)
    } else {
      let filterData = searchCookies.filter((sc) => sc.title !== title)
      let filterDataMap = filterData.map((sc) => sc.title)
      setSearchCookies(filterData)
      Cookies.set("search", JSON.stringify(filterDataMap))
    }
  }

  return (
    <Header className="header">
      <div className="sidebarmenu-icon">
        <AlignLeftOutlined
          onClick={() => {
            SidebarStatus()
          }}
        />
      </div>
      <div className="search-bar">
        <Input
          placeholder="Search For Meditation, Tracks & More..."
          onChange={handleInputChange}
          prefix={<i class="iconly-Search icli"></i>}
          allowClear
          value={searchValue}
          onClick={() => setShowSuggestion(true)}
          ref={searchRef}
          onPressEnter={(e) => handleSearch(e.target.value)}
        />
        {showSuggestion && finalList.length ? (
          <div className="search-bar__suggestion-container" ref={searchBox}>
            <ul>
              {finalList.map((item) => {
                return (
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: item.title }}
                      onClick={() => handleSearch(item.title)}
                    />
                    <img
                      alt="upp arrow"
                      src={item.type === "recent" ? removeIcon : arrowUpLeft}
                      height={12}
                      onClick={() => handleClick(item.title, item.type)}
                    />
                  </li>
                )
              })}
            </ul>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="header-menu">
        <a
          href={appStoreLink}
          target="_blank"
          onClick={() => {
            logEvent(analytics, "app_link_clicked_apple")
          }}
        >
          <AppleFilled size="large" className="header-menu__item" />
        </a>
        <a
          href={playstoreLink}
          target="_blank"
          onClick={() => {
            logEvent(analytics, "app_link_clicked_android")
          }}
        >
          <AndroidFilled size="large" className="header-menu__item" />
        </a>
        {getCurrentSession().authenticated ? (
          <>
            {/* <Link to="/settings">
                <SettingFilled className="header-menu__item" />
            //   </Link> */}

            {user && user.subscriptionIos === "free" ? (
              <Link to="/subscription" className="btn btn-outline btn-large">
                Upgrade
              </Link>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            <Link to="/login" className="btn btn-outline btn-large auth-btns">
              Login
            </Link>
            <Link to="/signup" className="btn btn-outline btn-large auth-btns">
              Sign up
            </Link>
          </>
        )}
        <UserMenu />
      </div>
    </Header>
  )
}

export default Headerlayout
