import { Col, Row, Skeleton } from "antd"
import React, { useEffect, useState } from "react"

const CardSkeleton = () => {
  const [screen, setScreen] = useState(undefined)
  const [items, setItems] = useState(undefined)
  const [width, setWidth] = useState(undefined)

  useEffect(() => {
    setScreen(window.innerWidth)
  }, [window.innerWidth]) //eslint-disable-line

  const getWidthItems = () => {
    if (screen < 500) {
      setItems(1)
      setWidth(24)
    } else if (screen < 992 && screen > 500) {
      setItems(3)
      setWidth(8)
    } else if (screen > 992 && screen < 1500) {
      setItems(3)
      setWidth(8)
    } else if (screen > 1500) {
      setItems(4)
      setWidth(6)
    }
  }

  useEffect(() => {
    if (screen) {
      getWidthItems()
    }
  }, [screen]) //eslint-disable-line

  return (
    <Row gutter={[20, 20]}>
      {items && width
        ? Array.from(Array(items), (e, i) => (
            <Col span={width} key={i} style={{ marginBottom: "10px" }}>
              <div className="card card--regular">
                <div className="card__header">
                  <Skeleton.Image className="card-img" active={true} />
                </div>
                <div className="card__body">
                  <h3>
                    <Skeleton paragraph={false} active={true} />
                  </h3>
                </div>
              </div>
            </Col>
          ))
        : ""}
    </Row>
  )
}

export default CardSkeleton
