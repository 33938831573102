import React, { useEffect, useState } from "react"
import {
  addTrackToFavourite,
  removeTrackToFavourite
} from "../../../services/track.service"
import { HeartOutlined, HeartFilled } from "@ant-design/icons"
import { useLazyQueryHook, useMutationHook } from "graphql/hooks"
import {
  ADD_TRACK_TO_FAVOURITE,
  GET_FAVOURITES_TRACKS,
  REMOVE_TRACK_TO_FAVOURITE
} from "graphql/queries/track"
import { message } from "antd"

import { ReactComponent as LoveIcon } from "../../../assets/images/Love-Svg.svg"
import { ReactComponent as LoveLikedIcon } from "../../../assets/images/Love-liked-Svg.svg"
import moment from "moment"
import PremiumMessageModal from "components/PremiumMessageModal"

import { useLocation } from "react-router-dom"
import useSendEvents from "functions/useSendEvents"
import userMixPanelEvents from "functions/mixPanelEvents"

const FavouriteButton = (props) => {
  const { track, favourite, page, premium, user, query } = props

  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false)

  let { state: routeState } = useLocation()

  const [sendEvents] = useSendEvents()

  // console.log("routeState routeState from banner without search", routeState)
  // console.log("track details", track)

  const {
    func: setFavourite,
    loading: favouriteLoading,
    data: favouriteData,
    error: favouriteError
  } = useMutationHook({
    query: ADD_TRACK_TO_FAVOURITE
  })

  const {
    func: removeFavourite,
    loading: unFavouriteLoading,
    data: unFavouriteData,
    error: unFavouriteError
  } = useMutationHook({
    query: REMOVE_TRACK_TO_FAVOURITE,
    refetchQueries: [
      {
        query: GET_FAVOURITES_TRACKS,
        variables: { options: { page: 1, limit: 10 } }
      }
    ]
  })

  const [favourited, setFavourited] = useState(false)

  const today = Math.floor(new Date())

  const trackid = track && track?.map(({ id }) => id)
  const trackTitle = track && track?.map(({ title }) => title)

  const trackId = trackid?.toString()
  

  useEffect(() => {
    if (favourite) {
      setFavourited(true)
    }
  }, [favourite])

  useEffect(() => {
    if (page !== undefined && page === "fev") setFavourited(true)
  }, [page])
  useEffect(() => {
    if (!favouriteLoading) {
      if (favouriteData) {
        message.success("Added to favorite successfully")
        // send mix panel events
        const properties = {
          source: "track single page",
          track_name: trackTitle.toString(),
          track_id: trackId
        }
        if (routeState.sessionDetails) {
          let newProperty = {
            ...properties,
            "session name": routeState.sessionDetails.name
              ? routeState.sessionDetails.name
              : "",
            "session id": routeState.sessionDetails.id,
            type: "session"
          }
          userMixPanelEvents("  click_favorite_rate", newProperty)
        } else {
          let newProperty = {
            ...properties,
            type: "single"
          }
          userMixPanelEvents("  click_favorite_rate", newProperty)
        }
        // send algolia events
        routeState = {
          ...routeState,
          eventComponent: "favorite"
        }

        sendEvents(routeState)
      }
      if (favouriteError) {
        message.error(favouriteError.message || favouriteError)
      }
    }
  }, [favouriteError, favouriteLoading, favouriteData])

  useEffect(() => {
    if (!unFavouriteLoading) {
      if (unFavouriteData) {
        message.success("Removed from favorite successfully")
      }
      if (unFavouriteError) {
        message.error(unFavouriteError.message || unFavouriteError)
      }
    }
  }, [unFavouriteLoading, unFavouriteError, unFavouriteData])

  const toggleFavouriteTracks = async () => {
    if (premium) {
      if (
        user.subscriptionIos === "paid" ||
        (user.subscriptionIos === "trial" &&
          moment(today) <
            moment(
              user.subscriptionGraceDate
                ? user.subscriptionGraceDate
                : new Date()
            ))
      ) {
        if (favourited) {
          setFavourited(!favourited)
          const response = await removeFavourite({
            variables: {
              trackId: trackId
            }
          })
          if (!response?.data) {
            setFavourited(!favourited)
          }
        } else {
          setFavourited(!favourited)
          // const response = await addTrackToFavourite(track.id)
          const response = await setFavourite({
            variables: {
              trackId: trackId
            }
          })
          if (!response?.data) {
            setFavourited(!favourited)
          }
        }
      } else {
        // setmodal true
        setIsPremiumModalOpen(true)
      }
    } else {
      if (favourited) {
        setFavourited(!favourited)
        const response = await removeFavourite({
          variables: {
            trackId: trackId
          }
        })
        if (!response?.data) {
          setFavourited(!favourited)
        }
      } else {
        setFavourited(!favourited)
        const response = await setFavourite({
          variables: {
            trackId: trackId
          }
        })
        if (!response?.data) {
          setFavourited(!favourited)
        }
      }
    }
  }

  return (
    <>
      <div onClick={toggleFavouriteTracks}>
        {favourited ? <LoveLikedIcon /> : <LoveIcon />}
      </div>

      <PremiumMessageModal
        isPremiumModalOpen={isPremiumModalOpen}
        setIsPremiumModalOpen={setIsPremiumModalOpen}
      />
    </>
  )
}

export default FavouriteButton
