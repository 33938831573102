import React, { useEffect } from "react"
import { Menu, message } from "antd"
import { NavLink } from "react-router-dom"

// assets
import musicIconActive from "../../assets/images/music-note-active.svg"
import { GET_CATEGORIES } from "graphql/queries/categories"
import { useLazyQueryHook, useQueryHook } from "graphql/hooks"
import { getErrors } from "utils/helper"
import { useState } from "react"
import { Skeleton } from "antd"
import "./styles.scss"

const CategoriesMenu = ({ lastUrlTitle }) => {
  const [category, setCategory] = useState([])
  const {
    loading: categoriesLoading,
    data: categoriesData,
    error: categoriesError
  } = useQueryHook({
    query: GET_CATEGORIES,
    variables: { page: 1, limit: 10, publishStatus: true },
    fetchPolicy: "cache-and-network"
  })

  useEffect(() => {
    if (categoriesError) {
      message.error(
        getErrors(categoriesError.graphQLErrors) || categoriesError.message
      )
    }
  }, [categoriesError])

  useEffect(() => {
    if (
      categoriesData &&
      categoriesData?.getCategories &&
      categoriesData?.getCategories?.categories &&
      categoriesData?.getCategories?.categories?.length
    ) {
      setCategory(categoriesData?.getCategories?.categories)
    }
  }, [categoriesData])

  return (
    <>
      <div className="categories">
        <Menu mode="inline" className="sidebar__menu" selectable={false}>
          {category &&
            category.map((category) => (
              <Menu.Item key={category.id}>
                <img className="sleep" src={musicIconActive} />

                <NavLink
                  exact
                  to={`/${category.slug}`}
                  activeClassName="active"
                >
                  {category.categoryName}
                </NavLink>
              </Menu.Item>
            ))}
        </Menu>
      </div>
    </>
  )
}

export default CategoriesMenu
