import { gql } from "@apollo/client"

export const LOGIN = gql`
  query ($username: String!, $password: String!) {
    login(loginInputDto: { username: $username, password: $password }) {
      access_token
      refresh_token
      type
    }
  }
`

export const ME = gql`
  query {
    me {
      firstName
      middleName
      lastName
      profilePicStorageKey
      email
    }
  }
`

// export const REGISTER = gql`
//   mutation($email: String!, $password: String!, $username: String!) {
//     registerUser(
//       userRegisterInput: {
//         email: $email
//         password: $password
//         username: $username
//       }
//     ){
//   }
// `
