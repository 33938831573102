import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { ArrowRightOutlined } from "@ant-design/icons"
import { IoCheckmarkSharp } from "react-icons/io5"
import CardRound from "../../components/Cards/CardRound"

import gimg1 from "../../assets/images/genre/abundance.png"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserProfile } from "../../services/auth.service"
import {
  fetchTags,
  updateUserTaggings,
  fetchTagGroups,
  updateUserTagGroups
} from "../../services/tags.service"
import Preloader from "../../components/UI/Preloader"
import { Button, Col, Form, Input, message, Modal, Row, useForm } from "antd"
import { GET_TAGS } from "../../query/tagsQuery"
import { ME } from "../../query/authQuery"
import { getCurrentSession, renderFullName } from "../../utils/helper"
import { useLazyQueryHook, useMutationHook, useQueryHook } from "graphql/hooks"
import {
  GET_USER_COMMITMENT,
  UPDATE_USER_COMMITMENTS,
  UPDATE_USER_TAGS
} from "graphql/queries/tags"
import { commitments } from "utils/dummyData"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import { from } from "@apollo/client"
import CardRectangle from "components/Cards/CardRectangle"
import userMixPanelEvents from "functions/mixPanelEvents"

const ChooseCommitment = () => {
  const [otherTagsValue, setOtherTagsValue] = useState("")
  const userdata = getItem(STORAGE_KEYS.USER)
  const userData = getCurrentSession().user
  const history = useHistory()


  // get user commitments

  const {
    loading: commitmentLoading,
    error: commitmentError,
    data: commitmentData
  } = useQueryHook({ query: GET_USER_COMMITMENT })


  const {
    func: updateUserCommitmentsFunc,
    loading: updateUserCommitmentsLoading,
    error: updateUserCommitmentsError,
    data: updateUserCommitmentsData
  } = useMutationHook({ query: UPDATE_USER_COMMITMENTS })

  const [selectedCommitmentsGroups, setSelectedCommitmentsGroups] = useState(
    undefined
  )

  // is tag selected
  const commitmentSelected = (id) => {
    if (selectedCommitmentsGroups) {
      if (selectedCommitmentsGroups === id) {
        return true
      }
    }
    return false
  }

  // toggle tag selection
  const toggleCommitmentSelection = (i) => {
    setSelectedCommitmentsGroups(i)
  }

  //update user's tags
  const [updatingTags, setUT] = useState(false)
  const updateUserCommitments = async () => {
    if (selectedCommitmentsGroups) {
      setUT(true)
      if (!updateUserCommitmentsLoading) {
        await updateUserCommitmentsFunc({
          variables: {
            commitment: selectedCommitmentsGroups
          }
        })
      }

      setUT(false)
    }

    // sending event to mix panel
    let properties = {
      distinctId: userData?.id,
      commitment_time: selectedCommitmentsGroups + " hr",
      source: "choose-commitments"
    }
    userMixPanelEvents("user_commitment", properties)
    history.push("/")
    message.success(
      `Welcome to good vibes, ${renderFullName(userData) || "User"}`
    )
    // }
  }

  useEffect(() => {
    if (!updateUserCommitmentsLoading) {
      {
        if (updateUserCommitmentsError) {
          message.error(
            updateUserCommitmentsError.message || updateUserCommitmentsError
          )
        }
      }
    }
  }, [updateUserCommitmentsLoading, updateUserCommitmentsError])

  // redirecting user to dashboard if user already choose commitment

  useEffect(() => {
    if (!commitmentLoading) {
      if (
        commitmentData !== undefined &&
        commitmentData !== null &&
        commitmentData?.getUserCommitment?.commitment !== ""
      ) {
        history.push("/")
      }
    }
  }, [commitmentData])

  return (
    <>
      {commitmentLoading ? (
        "Loading...."
      ) : (
        <section className="section-commitment">
          <div className="section-commitment-list">
            <div className="commitment-container">
              <h2 className="header-primary">
                <span>Would you like to make a commitment?</span>
              </h2>
              {commitments && commitments.length ? (
                <Row gutter={[20, 20]}>
                  {commitments?.map((commitment) => (
                    <Col xl={12} xs={24}>
                      <div
                        //   className="commitment-list-item"
                        key={commitment.id}
                        onClick={() => toggleCommitmentSelection(commitment.id)}
                      >
                        <CardRectangle
                          layout="title-center"
                          title={commitment.title}
                          time={commitment.time}
                          selected={commitmentSelected(commitment.id)}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>
              ) : (
                <p className="text-center">Sorry no Tags found!</p>
              )}
              <div className="commitment-btn">
                <Button
                  loading={updatingTags}
                  className="btn btn-primary btn-large"
                  style={{ marginLeft: "15px" }}
                  onClick={updateUserCommitments}
                  disabled={selectedCommitmentsGroups ? false : true}
                >
                  NEXT
                  <ArrowRightOutlined style={{ marginLeft: 7 }} />
                </Button>
              </div>
            </div>

            <div className="row row-btn">
              <div
                title={
                  selectedCommitmentsGroups && selectedCommitmentsGroups.length
                    ? false
                    : "Please select atleast one item!!"
                }
              ></div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default ChooseCommitment
