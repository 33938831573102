import { createSlice } from "@reduxjs/toolkit"
import { loadMoreHandler } from "../../utils/helper"

const initialState = {
  playedHistory: undefined,
  loadingPlayedHistory: false,
  metaPlayedHistory: undefined,
  errorPlayedHistory: undefined,
  userData: undefined,
  loadingSetting: false,
  errorSetting: undefined,
  loadingProfile: false,
  showDailyGoalModal: false,
  dailyGoal: undefined,
  weeklyReport: undefined
}

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    fetchPlayedHistoryRequest(state) {
      state.loadingPlayedHistory = true
    },

    fetchPlayedHistorySuccess(state, actions) {
      state.loadingPlayedHistory = false
      state.playedHistory = loadMoreHandler(
        state.playedHistory,
        actions.payload.meta.current_page,
        actions.payload.data
      )
      state.metaPlayedHistory = actions.payload.meta
    },

    fetchPlayedHistoryFail(state, actions) {
      state.loadingPlayedHistory = false
      state.errorPlayedHistory = actions.payload
    },

    fetchSettingRequest: (state, action) => {
      state.loadingSetting = true
    },

    fetchSettingSuccess: (state, actions) => {
      const { data } = actions?.payload
      state.userData = { ...state.userData, ...data }
      state.loadingSetting = false
    },
    fetchSettingFail: (state, actions) => {
      state.loadingSetting = false
    },

    updateUserData: (state, actions) => {
      const { data } = actions?.payload
      state.userData = data
    },

    fetchProfileRequest: (state, actions) => {
      state.loadingProfile = true
    },
    fetchProfileSuccess: (state, actions) => {
      const { data } = actions?.payload
      state.loadingProfile = false
      state.userData = {
        ...state.userData,
        ...data
      }
    },
    fetchProfileFail: (state, actions) => {
      state.loadingProfile = false
    },
    setDailyGoalModal: (state, actions) => {
      state.showDailyGoalModal = actions?.payload
    },
    updateDailyGoal: (state, actions) => {
      state.dailyGoal = actions?.payload
    },
    updateWeeklyReport: (state, actions) => {
      state.weeklyReport = actions?.payload
    }
  }
})

export const {
  fetchPlayedHistoryFail,
  fetchPlayedHistoryRequest,
  fetchPlayedHistorySuccess,
  fetchSettingSuccess,
  fetchSettingRequest,
  fetchSettingFail,
  updateUserData,
  fetchProfileRequest,
  fetchProfileSuccess,
  fetchProfileFail,
  setDailyGoalModal,
  updateDailyGoal,
  updateWeeklyReport
} = userSlice.actions

export default userSlice.reducer
