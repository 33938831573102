import { createSlice } from "@reduxjs/toolkit"
import { loadMoreHandler } from "../../utils/helper"

const initialState = {
  // recommended tracks by tags
  recommended_tracks: undefined,
  loadingRT: false,
  errorRT: undefined,
  metaRT: undefined,

  forYou: undefined,
  loadingForYou: false,
  errorFY: undefined,
  metaFY: undefined
}

const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    // recommended tracks by tags
    fetchRecommendedTracksRequest(state) {
      state.loadingRT = true
    },

    fetchRecommendedTracksSuccess(state, actions) {
      state.loadingRT = false
      state.recommended_tracks = loadMoreHandler(
        state.recommended_tracks,
        actions?.payload?.meta.current_page,
        actions.payload.recommended_tracks
      )
      state.metaRT = actions.payload.meta
    },

    fetchRecommendedTracksFail(state, actions) {
      state.loadingRT = false
      state.errorRT = actions.payload
    },
    // end recommended tracks by tags

    //forYou
    fetchForYouRequest(state) {
      state.loadingForYou = true
    },

    fetchForYouSuccess(state, actions) {
      state.loadingForYou = false
      state.forYou = loadMoreHandler(
        state.forYou,
        actions.payload.meta.current_page,
        actions.payload.tags
      )
      state.metaFY = actions.payload.meta
    },

    fetchForYouFail(state, actions) {
      state.loadingForYou = false
      state.errorFY = actions.payload
    },

    //end for you
    resetHome: (state) => initialState
  }
})

export const {
  fetchRecommendedTracksFail,
  fetchRecommendedTracksRequest,
  fetchRecommendedTracksSuccess,

  fetchForYouFail,
  fetchForYouRequest,
  fetchForYouSuccess,

  resetHome
} = homeSlice.actions

export default homeSlice.reducer
