import React, { useEffect } from "react"
import { Menu, Avatar, Dropdown } from "antd"
import {
  UserOutlined,
  LogoutOutlined,
  SettingOutlined
} from "@ant-design/icons"
import { fetchUserProfile, logout } from "../../services/auth.service"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Preloader from "../UI/Preloader"
import { Link } from "react-router-dom"
import { getCurrentSession } from "../../utils/helper"
import { removeItem, STORAGE_KEYS } from "../../utils/local-storage"
import DummyImg from "../../assets/images/avatar.png"
// import mixpanle events and mixpanle browser
import userMixPanelEvents from "functions/mixPanelEvents"
import Mixpanel from "mixpanel-browser"
import { useLocation } from "react-router-dom/cjs/react-router-dom"

const UserMenu = () => {
  const location = useLocation()

  // console.log("location sdf", location)

  const history = useHistory()
  const dispatch = useDispatch()

  const state = useSelector((state) => state)
  const { auth } = state

  const handleLogout = () => {
    // dispatch(logout());

    //clear local storage
    removeItem(STORAGE_KEYS.ACCESS_TOKEN)
    removeItem(STORAGE_KEYS.REFRESH_TOKEN)
    removeItem(STORAGE_KEYS.USER)
    history.push("/login")
    if (location.pathname === "/") {
      userMixPanelEvents("signout", {
        screen: "home"
      })
    } else {
      userMixPanelEvents("signout", {
        screen: location.pathname
      })
    }

    Mixpanel.reset()
  }

  const userProfile = () => {
    dispatch(fetchUserProfile())
  }

  useEffect(() => {
    if (!auth.user && auth.authenticated) userProfile()
  }, []) //eslint-disable-line

  const menu = (
    <Menu mode="horizontal" className="user-menu">
      <Menu.Item key="user" icon={<UserOutlined />}>
        <Link to={`/profile`}>Profile</Link>
      </Menu.Item>
      {/* <Menu.Item key="setting" icon={<SettingOutlined />}>
        <Link to={`/settings`}>Settings</Link>
      </Menu.Item> */}
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      {getCurrentSession().authenticated ? (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomCenter"
          style={{ cursor: "pointer" }}
        >
          {getCurrentSession().user && getCurrentSession().user.image ? (
            <Avatar
              size="large"
              src={DummyImg}
              style={{ cursor: "pointer" }}
              alt="dummy-img"
            />
          ) : (
            <Avatar size="large" icon={<UserOutlined />} />
          )}
        </Dropdown>
      ) : (
        <Avatar size="large" icon={<UserOutlined />} title="Guest" />
      )}
    </>
  )
}

export default UserMenu
