import React, { useEffect, useState } from "react"
import Dashsection from "../../components/DashSection"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col } from "antd"
import Preloader from "../../components/UI/Preloader"

//graphql imports
import { useLazyQueryHook, useQueryHook } from "../../graphql/hooks"
import { GET_TRACKS_BY_TAGS } from "../../graphql/queries/track"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const RecommendedPage = () => {
  // recommended tracks
  const {
    func: getTrackBytags,
    loading: getTracksByTagsLoading,
    data: trackByTagsData,
    error: trackByTagsDataError
  } = useLazyQueryHook({ query: GET_TRACKS_BY_TAGS })

  const location = useLocation()

  useEffect(() => {
    if (location.state.id !== "" && location.state.id !== undefined) {
      const variables = {
        tracksByTagsArgs: { tagIds: location.state.id, limit: 100, page: 1 }
      }
      getTrackBytags({ variables })
    }
  }, [])

  return (
    <>
      <Dashsection
        title={location?.state?.tagName}
        showBreadcrumb="recommended-list"
      >
        {getTracksByTagsLoading ? <Preloader /> : ""}
        <>
          {trackByTagsData &&
          trackByTagsData?.getTracksByTags?.tracks?.length ? (
            <>
              <RowCardlist
                cardlist={
                  <>
                    {trackByTagsData?.getTracksByTags?.tracks.map((item) => {
                      return (
                        <>
                          <Col className="card-list__item" key={item.id}>
                            <Card
                              img={
                                item.storageKeys.azure &&
                                item.storageKeys.azure.image
                                  ? item.storageKeys.azure.image
                                  : Cimg1
                              }
                              premium={
                                item.trackType === "PREMIUM" ||
                                item.trackType === "premium" ||
                                item.trackType === "PAID" ||
                                item.trackType === "paid" ||
                                item.trackType === "TRIAL" ||
                                item.trackType === "trial"
                                  ? true
                                  : false
                              }
                              title={item.title}
                              link={`/track/${item.slug}`}
                              id={item.id}
                            />
                          </Col>
                        </>
                      )
                    })}
                  </>
                }
              />
              {/*end infinite scrolling */}
            </>
          ) : (
            <>
              {getTracksByTagsLoading ? (
                ""
              ) : (
                <div>Sorry no recommendation found !!</div>
              )}
            </>
          )}
        </>
      </Dashsection>
    </>
  )
}

export default RecommendedPage
