import React from "react"

import { Route } from "react-router-dom"

const AppRoute = (props) => {
  const {
    component: Component,
    layout: Layout,
    path,
    image,
    onEnter,
    ...rest
  } = props

  return (
    <Route
      render={() => {
        return (
          <Layout image={image}>
            <Component />
          </Layout>
        )
      }}
      path={path}
      // onEnter={routeValidation(path)}
      {...rest}
    />
  )
}

export default AppRoute
