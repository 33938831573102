import { createSlice } from "@reduxjs/toolkit"
import { loadMoreHandler } from "../../utils/helper"

const initialState = {
  tracks: [],
  searchLoading: false,
  searchKeyword: undefined,
  searchMeta: undefined,
  searchSuggestionLoading: false,
  searchSuggestion: undefined
}

const serachSlice = createSlice({
  name: "serachSlice",
  initialState,
  reducers: {
    searchRequest: (state, action) => {
      state.searchLoading = true
    },
    searchSuccess: (state, action) => {
      const { data, meta } = action?.payload
      state.searchLoading = false
      state.tracks = loadMoreHandler(state.tracks, meta.current_page, data)
      state.searchMeta = meta
    },
    setSearchKeyword: (state, action) => {
      const { keyword } = action?.payload
      state.searchKeyword = keyword
    },
    searchSuggestionRequest: (state, action) => {
      state.searchSuggestionLoading = true
    },
    seachSuggestionSuccess: (state, action) => {
      state.searchSuggestionLoading = false
      state.searchSuggestion = action?.payload?.data
    },
    searchSuggestionFailure: (state, action) => {
      state.searchSuggestionLoading = false
    }
  }
})

export const {
  searchRequest,
  searchSuccess,
  setSearchKeyword,
  searchSuggestionRequest,
  seachSuggestionSuccess,
  searchSuggestionFailure
} = serachSlice.actions

export default serachSlice.reducer
