import { Modal, Form, Col, Row, Button, Input, message } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  createPlaylistService,
  togglePlaylistModalService
} from "../../services/playlist.service"
import PlaylistImage from "../../assets/images/favorite-tracks.png"
import { useMutationHook } from "graphql/hooks"
import { CREATE_PLAYLIST, GET_PLAYLISTS } from "graphql/queries/playlist"
import { getErrors } from "utils/helper"

const PlaylistCU = (props) => {
  const { showPlaylistModal, setShowPlaylistModal } = props
  const {
    func: createPlaylistFunc,
    loading: createPlaylistLoading,
    error: createPlaylistError,
    data: createPlaylistData
  } = useMutationHook({
    query: CREATE_PLAYLIST,
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: {
          page: 1,
          limit: 20
        }
      }
    ]
  })

  const [form] = Form.useForm()

  //state
  const [creating, setCreating] = useState(false)

  useEffect(() => {
    return () => {
      form.resetFields()
    }
  }, [])

  const onSubmit = async (values) => {
    // let form = setFormData(values)
    setCreating(true)
    const variables = { description: values.description, name: values.name }
    await createPlaylistFunc({ variables }).then(() => {
      setShowPlaylistModal(false)
      // message.success("Playlist created successfully")
    })

    // await dispatch(createPlaylistService(form, () => setShowPlaylistModal(false)))
    form.resetFields()
    setCreating(false)
  }

  // error message

  useEffect(() => {
    if (!createPlaylistLoading) {
      if (createPlaylistData?.createPlaylist?.id) {
        message.success(
          `${createPlaylistData.createPlaylist.playlistName} is successfully created`
        )
      }

      if (createPlaylistError) {
        message.error(
          getErrors(createPlaylistError.graphQLErrors) ||
            createPlaylistError.message
        )
      }
    }
  }, [createPlaylistLoading, createPlaylistError, createPlaylistData])

  // modal close
  const handleModalClose = () => {
    setShowPlaylistModal(false)
    form.resetFields()
  }

  return (
    <Modal
      visible={showPlaylistModal}
      title={"Create Playlist"}
      footer={[
        <div key={1}>
          <Button
            className="btn btn-primary"
            loading={creating}
            style={{ padding: "0.5em" }}
            htmlType="submit"
            form={"gv-create-playlist-form"}
          >
            Create Playlist
          </Button>
        </div>
      ]}
      onCancel={handleModalClose}
    >
      <div className="modal-body">
        <Row gutter={[12, 12]}>
          <Col xl={8} sm={24}>
            <div className="avatar-img">
              <img src={PlaylistImage} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col xl={16} sm={24}>
            <div className="modal-form">
              <Form
                onFinish={onSubmit}
                form={form}
                id="gv-create-playlist-form"
                layout="vertical"
                size="large"
                className="auth-form-wrapper"
              >
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Name is required !!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Playlist's name"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} style={{ marginTop: "-10px" }}>
                    <Form.Item
                      label="Description"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Description is required !!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Playlist's description"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default PlaylistCU

// import { Modal, Form, Col, Row, Button, Input, message } from "antd"
// import React, { useEffect, useState } from "react"
// import { useDispatch, useSelector } from "react-redux"
// import {
//   createPlaylistService,
//   togglePlaylistModalService
// } from "../../services/playlist.service"
// import PlaylistImage from "../../assets/images/favorite-tracks.png"
// import { setFormData } from "../../utils/helper"
// import { useMutationHook } from "graphql/hooks"
// import {
//   CREATE_PLAYLIST,
//   GET_PLAYLISTS,
//   UPDATE_PLAYLIST_BY_ID
// } from "graphql/queries/playlist"

// const PlaylistCU = (props) => {
//   const {
//     showPlaylistModal,
//     setShowPlaylistModal,
//     edit,
//     setEdit,
//     setPlaylist,
//     playlist
//   } = props
//   const {
//     func: createPlaylistFunc,
//     loading: createPlaylistLoading,
//     error: createPlaylistError,
//     data: createPlaylistData
//   } = useMutationHook({
//     query: CREATE_PLAYLIST,
//     refetchQueries: [
//       {
//         query: GET_PLAYLISTS,
//         variables: {
//           page: 1,
//           limit: 20
//         }
//       }
//     ]
//   })

//   const [form] = Form.useForm()

//   //state
//   const [creating, setCreating] = useState(false)
//   const [formValues, setFormValues] = useState({
//     playlistName: "",
//     description: ""
//   })
//   const [errors, setErrors] = useState({})

//   const {
//     func: updatePlaylistFunc,
//     loading: updatePlaylistLoading,
//     error: updatePlaylistError,
//     data: updatePlaylistData
//   } = useMutationHook({
//     query: UPDATE_PLAYLIST_BY_ID,
//     refetchQueries: [
//       {
//         query: GET_PLAYLISTS,
//         variables: {
//           page: 1,
//           limit: 20
//         }
//       }
//     ]
//   })

//   const onSubmit = async (values) => {
//     // let form = setFormData(values)
//     setCreating(true)
//     const variables = {
//       id: playlist?.id,
//       description: formValues.description,
//       name: formValues.playlistName
//     }

//     if (edit) {
//       await updatePlaylistFunc({ variables }).then(() => {
//         setShowPlaylistModal(false)
//         // success("Playlist updated successfully")
//       })
//     } else {
//       await createPlaylistFunc({ variables }).then(() => {
//         setShowPlaylistModal(false)
//         // message.success("Playlist created successfully")
//       })
//     }

//     // await dispatch(createPlaylistService(form, () => setShowPlaylistModal(false)))
//     form.resetFields()
//     setCreating(false)
//   }

//   // error message

//   useEffect(() => {
//     if (!createPlaylistLoading) {
//       if (createPlaylistData?.createPlaylist?.id) {
//         message.success(
//           `${createPlaylistData.createPlaylist.playlistName} is successfully created`
//         )
//       }

//       if (createPlaylistError) {
//         message.error("Upgrade to premium for unlimited playlist")
//       }
//     }
//   }, [createPlaylistLoading, createPlaylistError, createPlaylistData])

//   useEffect(() => {
//     setFormValues({ ...formValues, ...playlist })
//   }, [playlist])

//   // on change form values
//   const onChange = (e) => {
//     const { name, value } = e.target

//     if (!value) {
//       setErrors({ ...errors, playlistName: "Name is required!!" })
//     }
//     setFormValues({ ...formValues, [name]: value })
//   }

//   // modal close
//   const handleModalClose = () => {
//     if (edit) {
//       setEdit(false)
//       setPlaylist({})
//     }
//     setShowPlaylistModal(false)
//   }

//   return (
//     <Modal
//       visible={showPlaylistModal}
//       title={edit ? "Edit Playlist" : "Create Playlist"}
//       footer={[
//         <div key={1}>
//           <Button
//             className="btn btn-primary"
//             loading={creating}
//             style={{ padding: "0.5em" }}
//             onClick={onSubmit}
//           >
//             {edit ? "Save Changes" : "Create Playlist"}
//           </Button>
//         </div>
//       ]}
//       onCancel={handleModalClose}
//     >
//       <div className="modal-body">
//         <Row gutter={[12, 12]}>
//           <Col xl={8} sm={24}>
//             <div className="avatar-img">
//               <img src={PlaylistImage} alt="" className="img-fluid" />
//             </div>
//           </Col>
//           <Col xl={16} sm={24}>
//             <div className="modal-form">
//               <Row>
//                 <Col span={24}>
//                   <label for="playlistName">
//                     Name <span>*</span>
//                   </label>
//                   <Input
//                     name="playlistName"
//                     type="text"
//                     value={formValues?.playlistName}
//                     onChange={onChange}
//                   />
//                   <div style={{ color: "red", fontSize: "12px" }}>
//                     {errors && errors.playlistName}
//                   </div>
//                 </Col>
//                 <Col span={24} style={{ marginTop: "10px" }}>
//                   <label for="description">Description</label>
//                   <Input
//                     name="description"
//                     type="text"
//                     value={formValues?.description}
//                     onChange={onChange}
//                   />
//                 </Col>
//               </Row>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     </Modal>
//   )
// }

// export default PlaylistCU
