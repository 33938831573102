import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OnBoard from "../../assets/images/onBoard-1.png"
import OnBoard2 from "../../assets/images/onBoard-2.png"
import OnBoard3 from "../../assets/images/onBoard-3.png"
import OnBoard4 from "../../assets/images/Frame.png"
import OnBoard5 from "../../assets/images/onBoard-5.png"



export default function CarouselAuth() {
    return (
        <div className='left-wrapper'>
            <OwlCarousel className='owl-theme' loop items="1" margin={10} autoplay={5} autoplayHoverPause>
                {/* <div class='item'>
                    <div className='cara-items'>
                        <div className='cara-item-left' >
                            <img src={OnBoard} alt="brand" height="100%" width="100%" />
                        </div>
                        <div className='cara-item-right'>
                            <h2>Say Hi To Your Self-Care Journal</h2>
                            <p>Meditation is the key to happiness, productivity and longevity.
                                Find thousands of tracks to reduce stress, anxiety, stay calm, sleep better and meditate.</p>

                        </div>

                    </div>
                </div> */}
                <div class='item'>
                    <div className='cara-items'>
                        <div className='cara-item-left' >
                            <img src={OnBoard2} alt="brand" height="100%" width="100%" />
                        </div>
                        <div className='cara-item-right'>
                            <h2>Access 1000+ Sessions of sound therapy</h2>
                            <p>These sound therapy sessions can heal your body, balance your mind and unleash spirits.</p>

                        </div>

                    </div>
                </div>

                <div class='item'>
                    <div className='cara-items'>
                        <div className='cara-item-left' >
                            <img src={OnBoard3} alt="brand" height="100%" width="100%" />
                        </div>
                        <div className='cara-item-right'>
                            <h2>Play Sublime Binaural Frequencies</h2>
                            <p>Use headphones to get the most benefit of Binaural Beats and to experience the best sound quality</p>

                        </div>

                    </div>
                </div>


                <div class='item'>
                    <div className='cara-items'>
                        <div className='cara-item-left' >
                            <img src={OnBoard4} alt="brand" height="100%" width="100%" />
                        </div>
                        <div className='cara-item-right'>
                            <h2>Download & listen offline</h2>
                            <p>Take your sound therapy journey anywhere. Enjoy it on the go!
                            </p>

                        </div>

                    </div>
                </div>
                {/* <div class='item'>
                    <div className='cara-items'>
                        <div className='cara-item-left' >
                            <img src={OnBoard5} alt="brand" height="100%" width="100%" />
                        </div>
                        <div className='cara-item-right'>
                            <h2>Track Your Growth</h2>
                            <p>Weekly growth insights help you track your growth, and you get personalized recommendations</p>

                        </div>

                    </div>
                </div> */}

            </OwlCarousel>
        </div>
    )
}
