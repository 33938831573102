import React from "react"
import { Col, Row } from "antd"
// import styles
import "./styles.scss"
// import image
import Envalop from "../../../assets/images/offer/EnvelopeSimple.svg"
import Gift from "../../../assets/images/offer/Gift.svg"
import Paper from "../../../assets/images/offer/PaperPlaneTilt.svg"

const MidComponent = () => {
  return (
    <div className="mid-component">
      <div className="heading-wrapper">
        <h2>How it Works</h2>
      </div>
      <Row>
        <Col lg={8}>
          <div className="item-container">
            <div className="item-wrapper">
              <div className="item-wrapper_left common">
                <img src={Envalop} alt="envalop" />
              </div>
              <div className="item-wrapper_right">
                <span className="step">Step 1</span>
                <span className="content">Enter email </span>
              </div>
            </div>
            <div className="message">
              <p>
                Enter the email of your dear one who is going to receive your
                gift.
              </p>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="item-container">
            <div className="item-wrapper">
              <div className="item-wrapper_left-second common">
                <img src={Paper} alt="paper" />
              </div>
              <div className="item-wrapper_right">
                <span className="step">Step 2</span>
                <span className="content">Your Message </span>
              </div>
            </div>
            <div className="message">
              <p>Write your custom message and personalize your gift card.</p>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="item-container">
            <div className="item-wrapper">
              <div className="item-wrapper_left-third common">
                <img src={Gift} alt="Gift" />
              </div>
              <div className="item-wrapper_right">
                <span className="step">Step 3</span>
                <span className="content">Send Gift </span>
              </div>
            </div>
            <div className="message">
              <p>
                An email with a GoodVibes gift card will be sent to the
                recipient.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default MidComponent
