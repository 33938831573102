// import rect
import React from "react"
// import from antd
import { Row, Col } from "antd"
// import component
import Footer from "components/offerComponents/Footer/Footer"
// import scss
import "./styles.scss"
import OfferHeader from "components/offerComponents/Header/Header"
// import logo
import left from "../../assets/images/offer/left.svg"

const OfferLayout = ({ children }) => {
  return (
    <>
      <div className="offer-layout">
        <div className="header-wrapper">
          <OfferHeader />
        </div>
        <div className="left-img">
          <img src={left} alt="left" />
        </div>
        <div className="container-fluid">{children}</div>
      </div>
      <div className="footer-wrapper">
        <Footer />
      </div>
    </>
  )
}

export default OfferLayout
