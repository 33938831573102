import React, { useState, useEffect } from "react"
import { Row, Col, Switch, message } from "antd"
import { useMutationHook, useQueryHook } from "graphql/hooks"
// grapql import
import {
  GET_USER_NOTIFICATION,
  UPDATE_USER_NOTITIFICATION
} from "graphql/queries/auth"
import Preloader from "components/UI/Preloader"

const NotificationComponent = () => {
  const {
    loading: noficationLoading,
    data: notificationData,
    error: notiError
  } = useQueryHook({
    query: GET_USER_NOTIFICATION
  })
  const [notificationItem, setNotificationItem] = useState([])

  const {
    func: updateNotificationFunc,
    loading: updateNotificationLoading,
    data: updateNotificationData,
    error: uupdateNotification
  } = useMutationHook({
    query: UPDATE_USER_NOTITIFICATION,
    refetchQueries: [
      {
        query: GET_USER_NOTIFICATION
      }
    ]
  })
  const changeStatus = (key) => {
    let newItems = notificationItem.map((obj) => {
      if (obj.key === key) {
        let myobj = { ...obj }
        myobj.status = !myobj.status
        return myobj
      }
      return obj
    })
    const variables = newItems.reduce((init, n) => {
      init[n.value] = n.status
      return init
    }, {})

    updateNotificationFunc({ variables: variables })
  }
  // set array intially
  useEffect(() => {
    if (notificationData) {
      let arrray = [
        {
          key: 1,
          title: "Daily Updates",
          value: "daily_updates",
          status: notificationData?.getUserNotification?.daily_updates
        },

        {
          key: 2,
          title: "Others",
          value: "others",
          status: notificationData?.getUserNotification?.others
        },

        {
          key: 3,
          title: "",
          value: "offers",
          status: notificationData?.getUserNotification?.offers
        }
      ]
      setNotificationItem(arrray)
    }
  }, [notificationData])

  // set array after updating
  useEffect(() => {
    if (updateNotificationData !== undefined) {
      let arrray = [
        {
          key: 1,
          title: "Daily Updates",
          value: "daily_updates",
          status: updateNotificationData?.storeUserNotification?.daily_updates
        },

        {
          key: 2,
          title: "Others",
          value: "others",
          status: updateNotificationData?.storeUserNotification?.others
        },

        {
          key: 3,
          title: "",
          value: "offers",
          status: updateNotificationData?.storeUserNotification?.offers
        }
      ]
      setNotificationItem(arrray)
    }
  }, [updateNotificationData])

  return (
    <div className="card card--personal-info">
      <h3 className="card__header">Notifications</h3>
      <div className="switch-wrapper">
        {noficationLoading ? (
          <Preloader />
        ) : (
          notificationItem.map((item, index) =>
            item.title !== "" ? (
              <div className="switch-item" key={index}>
                <h4 className="switch-label">{item.title}</h4>
                <Switch
                  checked={item.status}
                  onChange={() => {
                    changeStatus(item.key)
                  }}
                />
              </div>
            ) : (
              ""
            )
          )
        )}
      </div>
    </div>
  )
}

export default NotificationComponent
