import React from "react"
import { Row, Col } from "antd"

const SubscriptionLayout = ({ children }) => {
  return (
    <div className="subscription-layout">
      <div className="container">
        {/* <Row>
          <Col xs={24} lg={12}>
            {sectionLeft}
          </Col>
          <Col xs={24} lg={12}>
            {sectionRight}
          </Col>
        </Row> */}
        {children}
      </div>
    </div>
  )
}

export default SubscriptionLayout
