import React, { useState } from "react"
import { Button, Form, Input } from "antd"
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Link, useHistory } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { useLazyQueryHook } from "graphql/hooks"
// import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie"
import { FORGET_PASSWORD_LINK } from "graphql/queries/auth"
import { setItem } from "utils/local-storage"

const ForgotPasswordComponent = (values) => {
  const history = useHistory()
  const [cookies, setCookie] = useCookies("")

  const [userEmail, setUserEmail] = useState("")

  const {
    func: sendEmail,
    loading: sendEmailloading,
    error: errorSendingEmail
  } = useLazyQueryHook({ query: FORGET_PASSWORD_LINK })

  const onFinish = (values) => {
    // e.preventDefault()
    sendEmail({
      variables: {
        email: values.email
      }
    }).then((data) => {
      history.push("/reset-success")
    })
    setItem("forgetEmail", values.email)
  }

  return (
    <div className="auth-wrapper">
      <div className="heading">
        <h2>Forgot Password?</h2>
      </div>
      <p className="text-small">
        Don’t worry! Just fill in your email and we’ll help you reset your
        password.
      </p>
      <Form
        layout="vertical"
        size="large"
        className="auth-forgot-wrapper"
        onFinish={onFinish}
        initialValues={{ remember: false }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input valid email address!"
            }
          ]}
        >
          <Input placeholder="Enter your email address" />
        </Form.Item>
        <Form.Item>
          {/* <Link to="/reset-success"> */}
          <Button
            className="btn btn-primary btn-large"
            loading={sendEmailloading}
            htmlType="submit"
            // onClick={onSubmit}
          >
            Send reset link
          </Button>
          {/* </Link> */}
        </Form.Item>
      </Form>
    </div>
  )
}

export default ForgotPasswordComponent
