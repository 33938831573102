export const GET_TAGS = `
  query ($searchAndFilterArgs: SearchAndFilterArgs!) {
    getTags(
        searchAndFilterArgs: $searchAndFilterArgs
    ){
        tags{
            id
            tagName 
            description
            storageKey
        }
        meta{
            total
        }
    }
  }
`

export const UPDATE_USER_TAGS = `
  mutation ($tags: [String!]!, $tagSuggestionDto : TagSuggestionDto){
    storeUserTag(createUserTagDto: {tags : $tags}, tagSuggestionDto:  $tagSuggestionDto){
      id
    }
  }
`

// update user commitment
export const UPDATE_USER_COMMITMENTS = `
  mutation ($commitment: String!){
    storeUserCommitment(userCommitmentDto: {commitment : $commitment}){
      id
      commitment
    }
  }
`


export const GET_USER_COMMITMENT = `
query
{
getUserCommitment{
commitment
createdDTM
id
isDeleted
updatedDTM
user{
  id
}
}
}

`