import React, { useState, useEffect } from "react"
import { Button, Form, Input, Checkbox, message } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Link, useHistory } from "react-router-dom"
import Facebook from "../../assets/images/facebook.png"
import Google from "../../assets/images/google.png"
import { GoogleLogin } from "react-google-login"
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../../config/app.config"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { ME, REGISTER, SOCIAL_LOGIN } from "graphql/queries/auth"
import { useLazyQueryHook, useMutationHook } from "graphql/hooks"
import { STORAGE_KEYS, setItem } from "../../utils/local-storage"
import { async } from "@firebase/util"
import { getErrors, renderFullName } from "utils/helper"
import userMixPanelEvents from "functions/mixPanelEvents"
import Mixpanel from "mixpanel-browser"
import "./styles.scss"

const SignUpComponent = () => {
  let history = useHistory()
  const [apiLoading, setApiLoading] = useState(false)
  const [tokenDetails, setTokenDetails] = useState({ tokenType: "", token: "" })

  const [form] = Form.useForm()
  const {
    func: registerFunc,
    loading: registerLoading,
    error: registerError,
    data: registerData
  } = useMutationHook({ query: REGISTER })

  const {
    func: socialLoginFunc,
    loading: socialLoginLoading,
    error: socialLoginError,
    data: socialLoginData
  } = useLazyQueryHook({
    query: SOCIAL_LOGIN,
    headers: { "token-type": tokenDetails.tokenType, token: tokenDetails.token }
  })

  const {
    func: meFunc,
    data: meData,
    loading: meLoading,
    error: meError
  } = useLazyQueryHook({ query: ME })

  useEffect(() => {
    if (!registerLoading) {
      if (registerData && registerData.registerUser) {
        //setting local storage
        const { access_token, refresh_token } = registerData.registerUser
        setItem(STORAGE_KEYS.ACCESS_TOKEN, access_token)
        setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh_token)
        message.success("You've been registered, please verify your account!")
        history.push("/resend-verification")
        // meFunc()
      }

      if (registerError) {
        message.error(
          getErrors(registerError.graphQLErrors) || registerError.message
        )
        setApiLoading(false)
      }
    }
  }, [registerLoading, registerData, registerError])

  useEffect(() => {
    if (!meLoading) {
      if (meData && meData.me) {
        const {
          id,
          firstName,
          lastName,
          email,
          profilePicStorageKey,
          tags,
          emailConfirmed,
          subscriptionIos,
          subscriptionIosExpiryDate
        } = meData.me

        // console.log("meData after sign up", meData)

        //setting user details
        const userDetails = {
          id,
          firstName,
          lastName,
          email,
          image: profilePicStorageKey,
          emailConfirmed,
          tags: tags,
          subscriptionIos,
          subscriptionIosExpiryDate
        }
        setItem(STORAGE_KEYS.USER, userDetails)

        setApiLoading(false)
        if (emailConfirmed) {
          if (tags && tags.length) {
            history.push("/")
            message.success(
              `Welcome to good vibes, ${renderFullName(userDetails) || "User"}`
            )
            let properties = { distinctId: id, email: email }
            userMixPanelEvents("signin", properties)

            Mixpanel.identify(meData.me.id)
            let userData = { email: meData.me.email }
            if (
              meData.me.firstName !== null ||
              meData.me.lastName !== null ||
              meData.me.gender !== null ||
              meData.me.dob !== null
            ) {
              if (meData.me.firstName !== null) {
                userData = { ...userData, firstName: meData.me.firstName }
              }
              if (meData.me.lastName !== null) {
                userData = { ...userData, lastName: meData.me.lastName }
              }
              if (meData.me.gender) {
                userData = { ...userData, gender: meData.me.gender }
              }
              if (meData.me.dob !== null) {
                userData = { ...userData, dob: meData.me.dob }
              }
              Mixpanel.people.set(userData)
            } else {
              Mixpanel.people.set(userData)
            }
          } else {
            history.push("/choose-tags")
          }
        } else {
          message.warning("You have not verified your email, Please verify it!")
          history.push("/resend-verification")
        }
      }

      if (meError) {
        message.error(meError.message || meError)
        setApiLoading(false)
      }
    }
  }, [meLoading, meData, meError])
  // get time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // on sign up form submit
  const handleRegister = async (values) => {
    if (
      Object.values(values).every((field) => field.length > 0) ||
      Object.values(values).includes(true)
    ) {
      setApiLoading(true)

      const variables = {
        email: values.email,
        password: values.password,
        termsAccepted: values.agreement,
        deviceTimeZone:
          userTimeZone === "Asia/Katmandu" ? "Asia/Kathmandu" : userTimeZone
      }
      await registerFunc({ variables }).then(() => {
        form.resetFields()
        setApiLoading(false)
      })
    }
  }

  const handleSocialLogin = async (token, provider) => {
    const socialToken =
      provider === "facebook" ? token.accessToken : token.tokenId

    //manage token type and token here
    setTokenDetails({ tokenType: provider, token: socialToken })

    // return;
    // dispatch(SocialLoginService(token, provider))
  }

  useEffect(() => {
    if (tokenDetails.tokenType && tokenDetails.tokenType !== "") {
      let variables = {
        deviceTimeZone:
          userTimeZone === "Asia/Katmandu" ? "Asia/Kathmandu" : userTimeZone
      }
      socialLoginFunc({ variables: variables })
    }
  }, [tokenDetails])

  useEffect(() => {
    if (!socialLoginLoading) {
      if (socialLoginData && socialLoginData.OauthLogin) {
        //setting local storage
        const { access_token, refresh_token } = socialLoginData.OauthLogin
        setItem(STORAGE_KEYS.ACCESS_TOKEN, access_token)
        setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh_token)

        meFunc()
      }

      if (socialLoginError) {
        message.error(socialLoginError.message || socialLoginError)
        setApiLoading(false)
      }
    }
  }, [socialLoginLoading, socialLoginError, socialLoginData])

  // mix panel event when page reload
  useEffect(() => {
    let properties = {
      screen: "Signup"
    }
    userMixPanelEvents("screen_view", properties)
  }, [])
  return (
    <div className="auth-wrapper auth-wrapper--signup">
      <h2>Get Started With Good Vibes..</h2>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleRegister}
        size="large"
        className="auth-form-wrapper"
      >
        <Form.Item
          label="Email Address"
          name="email"
          validateTrigger="onChange"
          rules={[
            {
              required: true,
              message: "Email is required !!"
            }
          ]}
        >
          <Input
            className="ant-form-item-control-input"
            placeholder="Enter Email Address"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Password is required !!"
            }
          ]}
        >
          <Input.Password
            placeholder="Enter Password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Form.Item
          className="text-small"
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Should accept agreement"))
            }
          ]}
        >
          <Checkbox>
            I agree to all
            <a
              href="https://goodvibesofficial.com/terms-and-condition"
              target="_blank"
            >
              Terms and Conditions
            </a>{" "}
          </Checkbox>
        </Form.Item>
        <Form.Item>
          {/* <Link to="/choose-genre"> */}
          <Button
            className="btn btn-primary btn-large"
            loading={apiLoading}
            htmlType="submit"
          >
            Sign Up
          </Button>
          {/* </Link> */}
        </Form.Item>
      </Form>
      <div className="social-login">
        <div className="social-divider">
          <span>Or</span>
        </div>
        <div className="social">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            callback={(token) => handleSocialLogin(token, "facebook")}
            render={(renderProps) => (
              <div className="social-icon" {...renderProps}>
                <img src={Facebook} alt="" />
                <h4>Facebook</h4>
              </div>
            )}
          />

          {/* <Link to="#" />
            <img src={Google} alt="" />
            <h4>Google</h4> */}
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            className="social-icon"
            icon={false}
            onSuccess={(token) => handleSocialLogin(token, "google")}
            onFailure={(e) => console.log("google login failure", e)}
          >
            <img src={Google} alt="" />
            <h4>Google</h4>
          </GoogleLogin>
          {/* <div className="social-icon">
            <Link
              to="/guest"
              onClick={() => {
                logEvent(analytics, "logged_in_as_guest")
              }}
            />
            <img src={Guest} alt="" />
            <h4>As Guest</h4>
          </div> */}
        </div>
        <span>Already Have a Account?</span>&nbsp;
        <Link to="/" className="btn-link">
          Log In
        </Link>
      </div>
    </div>
  )
}

export default SignUpComponent
