import React, { Fragment, useEffect, useState } from "react"
import Dashsection from "../../components/DashSection"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col, message } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { fetchTracksByTagsRecommendation } from "../../services/track.service"
import Preloader from "../../components/UI/Preloader"
import ScrollPagination from "components/ScrollPagination"

//graphql imports
import { useLazyQueryHook, useQueryHook } from "../../graphql/hooks"
import { GET_RECOMMENDATION_BY_TAGS } from "../../graphql/queries/track"
import {
  GET_SESSION_BY_SLUG,
  GET_TRACKS_OF_SESSIONS
} from "graphql/queries/playlist"
import { useParams } from "react-router-dom"
import { getErrors } from "utils/helper"

const SessionPage = () => {
  const { slug } = useParams()
  // variables for api call
  const [limit, setLimit] = useState(15)
  const [currentPage, setcurrentPage] = useState(1)
  const {
    func: sessionBySlugFunc,
    loading: sessionDataLoading,
    error: sessionBySlugError,
    data: sessionByslugData
  } = useLazyQueryHook({ query: GET_SESSION_BY_SLUG })

  const {
    func: sessionDetailFunc,
    loading: sessionDetailLoading,
    error: sessionDetailError,
    data: sessionDetailData
  } = useLazyQueryHook({ query: GET_TRACKS_OF_SESSIONS })

  useEffect(() => {
    if (slug) {
      const variables = { sessionSlug: slug }
      sessionBySlugFunc({ variables })
    }
  }, [])

  useEffect(() => {
    if (sessionByslugData) {
      const id = sessionByslugData.getSessionBySlug.id
      const variables = {
        page: currentPage,
        limit: limit,
        sessionId: id
      }
      sessionDetailFunc({ variables })
    }
  }, [sessionByslugData, currentPage])

  useEffect(() => {
    if (!sessionDetailLoading) {
      if (sessionDetailError) {
        message.error(
          getErrors(sessionDetailError.graphQLErrors) ||
            sessionDetailError.message
        )
      }
    }
  }, [sessionDetailLoading])

  // array for displaying data
  const [sessionTrackdispRow, setSessionTrackdispRow] = useState([])
  const [sessionTrackCount, setSessionTrackCount] = useState(0)

  useEffect(() => {
    if (
      sessionDetailData &&
      sessionDetailData.getSessionTracks &&
      sessionDetailData.getSessionTracks.sessionTrack &&
      sessionDetailData.getSessionTracks.sessionTrack.length
    ) {
      setSessionTrackCount(sessionDetailData?.getSessionTracks.count)
      setSessionTrackdispRow([
        ...sessionTrackdispRow,
        ...sessionDetailData.getSessionTracks.sessionTrack
      ])
    }
  }, [sessionDetailData])

  function changeImageUrl(url) {
    let word = "vertical"
    const splitUrl = url.split("/")
    const insertIndex = 4
    splitUrl.splice(insertIndex, 0, word)
    const newUrl = splitUrl.join("/")
    // console.log("my url", newUrl)

    return newUrl
  }

  return (
    <Fragment>
      <Dashsection
        title={
          sessionDetailData?.getSessionTracks?.sessionTrack[0]?.session?.name
        }
      >
        {/* {sessionDetailLoading ? <Preloader /> : ""} */}
        <>
          {sessionTrackdispRow ? (
            <>
              <RowCardlist
                cardlist={
                  <>
                    {sessionTrackdispRow.map(({ track }) => {
                      return (
                        <>
                          <Col className="card-list__item" key={track.id}>
                            <Card
                              img={
                                track.storageKeys.azure &&
                                track.storageKeys.azure.image
                                  ? track.storageKeys.azure.image
                                  : Cimg1
                              }
                              defaultImg={Cimg1}
                              title={track.title}
                              link={`/track/${track.slug}`}
                              premium={
                                track.trackType === "PREMIUM" ||
                                track.trackType === "PAID" ||
                                track.trackType === "TRIAL"
                                  ? true
                                  : false
                              }
                              id={track.id}
                            />
                          </Col>
                        </>
                      )
                    })}
                  </>
                }
              />

              {/*end infinite scrolling */}
            </>
          ) : (
            <>
              {sessionDetailLoading ? (
                ""
              ) : (
                <div>Sorry no recommendation found !!</div>
              )}
            </>
          )}
        </>
      </Dashsection>

      <ScrollPagination
        current_page={currentPage}
        // pagination={loadMoreRecentlyPlayedSongs}
        // HasMore={recentlyPlayedAllHasMoreData}
        loadingMore={sessionDetailLoading}
        setLimit={setLimit}
        seTcurrentPage={setcurrentPage}
        limit={limit}
        data={sessionDetailData}
        total={sessionDetailData?.getSessionTracks?.meta?.total_items}
        total_pages={sessionDetailData?.getSessionTracks?.meta?.total_pages}
      />
    </Fragment>
  )
}

export default SessionPage
