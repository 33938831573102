import React, { useEffect, useState } from "react"
import Cimg1 from "../../assets/images/Thumbnail.png"
import Card from "../Cards/Card"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import LazyLoad from "react-lazyload"
import DashSection from "../../components/DashSection"

// Import Swiper styles
import "swiper/swiper-bundle.css"
import CardSkeleton from "../Skeletons/CardSkeletons"
import { useLazyQueryHook } from "graphql/hooks"
import { GET_TRACKS_OF_SESSIONS } from "graphql/queries/playlist"
import { message } from "antd"
import { getErrors } from "utils/helper"
import "./styles.scss"

SwiperCore.use([Navigation, Pagination])

export const RowSessions = (props) => {
  const [items, setItems] = useState([])

  const { noItemsText, type, session } = props
  const {
    func: sessionDetailFunc,
    loading: sessionDetailLoading,
    error: sessionDetailError,

    data: sessionDetailData
  } = useLazyQueryHook({
    query: GET_TRACKS_OF_SESSIONS,
    fetchPolicy: "cache-and-network"
  })

  useEffect(async () => {
    if (Object.values(session).length > 0) {
      await sessionDetailFunc({
        variables: {
          page: 1,
          limit: 5,
          sessionId: session.id
        }
      })
    }
  }, [session])

  useEffect(async () => {
    if (!sessionDetailLoading) {
      if (
        sessionDetailData &&
        sessionDetailData.getSessionTracks &&
        sessionDetailData.getSessionTracks.sessionTrack &&
        sessionDetailData.getSessionTracks.sessionTrack.length
      ) {
        await setItems(sessionDetailData.getSessionTracks.sessionTrack)
      }

      if (sessionDetailError) {
        message.error(
          getErrors(sessionDetailError.graphQLErrors) ||
            sessionDetailError.message
        )
      }
    }
  }, [sessionDetailLoading, sessionDetailData])

  return (
    <>
      {items?.length ? (
        <DashSection
          title={session.name}
          moreLink={`/sessions-tracks/${session.slug}`}
          // sessionId={session.id}
          description={session.description}
        >
          {sessionDetailLoading ? (
            <CardSkeleton />
          ) : items && items.length ? (
            <Swiper
              spaceBetween={30}
              // navigation
              className="swiper-wrapper--row"
              breakpoints={{
                0: {
                  slidesPerView: 1
                },
                480: {
                  slidesPerView: 2
                },

                767: {
                  slidesPerView: 3
                },

                992: {
                  slidesPerView: 3
                },

                1100: {
                  slidesPerView: 4
                },
                1700: {
                  slidesPerView: 5
                }
              }}
            >
              {items.map(({ track }, index) => {
                return (
                  <SwiperSlide key={index}>
                    {type === "tags" ? (
                      <LazyLoad>
                        <Card
                          img={track.svg_image_url || Cimg1}
                          title={track.name}
                          link={`/tag-groups/${track?.slug}`}
                          premium={
                            track.trackType === "PREMIUM" ||
                            track.trackType === "premium" ||
                            track.trackType === "PAID" ||
                            track.trackType === "paid" ||
                            track.trackType === "TRIAL" ||
                            track.trackType === "trial"
                              ? true
                              : false
                          }
                          fromTrack="session"
                          sessionDetails={session}
                        />
                      </LazyLoad>
                    ) : (
                      <Card
                        img={
                          track.storageKeys.azure &&
                          track.storageKeys.azure.image
                            ? track.storageKeys.azure.image
                            : Cimg1
                        }
                        defaultImg={Cimg1}
                        title={track.title}
                        link={`/track/${track.slug}`}
                        premium={
                          track.trackType === "PREMIUM" ||
                          track.trackType === "premium" ||
                          track.trackType === "PAID" ||
                          track.trackType === "paid" ||
                          track.trackType === "TRIAL" ||
                          track.trackType === "trial"
                            ? true
                            : false
                        }
                        id={track.id}
                        fromTrack="session"
                        sessionDetails={session}
                      />
                    )}
                  </SwiperSlide>
                )
              })}
            </Swiper>
          ) : (
            <h4 className="text-center">{noItemsText}</h4>
          )}
        </DashSection>
      ) : (
        ""
      )}
    </>
  )
}

export const BannerSlider = (props) => {
  return (
    <Swiper
      direction="vertical"
      navigation
      pagination
      id="swiper-wrapper--banner"
    >
      {props.children}
    </Swiper>
  )
}
