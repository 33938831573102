import {
  setChoosedPackage,
  fetchProductListingSuccess,
  fetchProductListingRequest,
  fetchProductListingFail,
  fetchAllSubscriptionSuccess
} from "../store/slice/subscription.slice"
import httpBase from "../utils/axios"
import { handleError } from "./error.service"

export const selectPackageService = (pack) => {
  return (dispatch) => {
    dispatch(setChoosedPackage(pack))
  }
}

export const fetchProductListingApi = () => {
  return async (dispatch) => {
    dispatch(fetchProductListingRequest())
    try {
      const url = "stripes/products"
      const response = await httpBase().get(url)
      const responseData = response?.data
      dispatch(
        fetchProductListingSuccess({
          products: responseData?.data
        })
      )
    } catch (e) {
      dispatch(handleError(e, fetchProductListingFail))
    }
  }
}

export const subscribeToProductApi = ({
  finalCallback,
  successCallback,
  data
}) => {
  return async (dispatch) => {
    try {
      const url = "stripes/subscribe"
      const response = await httpBase().post(url, data)
      const responseData = response?.data
      if (responseData?.success) {
        successCallback(responseData?.data)
      }
    } catch (e) {
      // dispatch(handleError(e, subscribeToProductApiFail));
    } finally {
      finalCallback()
    }
  }
}

export const fetchAllSubscriptionApi = () => {
  return async (dispatch) => {
    try {
      // console.log('subscription api called')
      const url = "subscriptions"
      const response = await httpBase().get(url)
      const responseData = response?.data
      if (responseData?.success) {
        dispatch(
          fetchAllSubscriptionSuccess({
            data: responseData?.data
          })
        )
      }
    } catch (e) {
    } finally {
    }
  }
}
