import { connectRouter } from "connected-react-router"
import history from "../utils/history"

import AuthReducer from "./slice/auth.slice"
import TagsReducer from "./slice/tags.slice"
import HomeReducer from "./slice/home.slice"
import TrackReducer from "./slice/track.slice"
import PlayerReducer from "./slice/player.slice"
import UserReducer from "./slice/user.slice"
import PlaylistReducer from "./slice/playlist.slice"
import SubscriptionReducer from "./slice/subscription.slice"
import SearchReducer from "./slice/search.slice"
import GenreReducer from "./slice/genre.slice"

const reducers = {
  auth: AuthReducer,
  home: HomeReducer,
  player: PlayerReducer,
  playlists: PlaylistReducer,
  router: connectRouter(history),
  subscription: SubscriptionReducer,
  tags: TagsReducer,
  track: TrackReducer,
  user: UserReducer,
  search: SearchReducer,
  genre: GenreReducer
}

export default reducers
