import React, { Fragment, useEffect, useState, useContext } from "react"
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { Row, Col, Radio } from "antd"
import Logo from "../../assets/images/Brand-Logo.png"
import Diamond from "../../assets/images/diamond.png"
import Check from "../../assets/images/check.png"
import PopularPacks from "../../config/popular-packages"
import { useDispatch, useSelector } from "react-redux"
import {
  selectPackageService,
  fetchProductListingApi
} from "../../services/subscription.service"
import HorizontalLoader from "../../components/UI/Loader/horizontalLoader"
import _ from "lodash"
import { CloseOutlined } from "@ant-design/icons"
import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "../../index"
import { clearPlayer } from "../../store/slice/player.slice"

import { useLazyQueryHook } from "graphql/hooks"
import { GET_SUBSCRIPTION_PLANS } from "graphql/queries/payment"
import premium from "../../assets/images/premium.png"
import premiumLeft from "../../assets/images/premium-btn-img.png"
import premiumRight from "../../assets/images/premium-btn-img-right.png"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import moment from "moment"
import { UserContext } from "contexts/userContext"
import userMixPanelEvents from "functions/mixPanelEvents"
import ColumnGroup from "antd/lib/table/ColumnGroup"

const Subscription = () => {
  const analytics = getAnalytics(app)

  const history = useHistory()
  const [products, setProducts] = useState([])
  const [selectedPlan, setSelectedPlan] = useState(products[0])

  const { path, params } = useRouteMatch()
  const { pathname } = useLocation()

  const user = useContext(UserContext)

  const {
    func: getSubscriptionPlansFunction,
    data: getSubscriptionPlansData,
    loading: getSubscriptionPlansLoading,
    error: getSubscriptionPlansError
  } = useLazyQueryHook({ query: GET_SUBSCRIPTION_PLANS })

  //  event send to mix panel
  useEffect(() => {
    userMixPanelEvents("screen_view", { screen: "subscription_purchase" })
  }, [])

  // today's date
  const today = Math.floor(new Date())

  if (
    ((path === "/subscription" ||
      path === "/subscription/:planId" ||
      path === "/subscription/:planId?promocode=" ||
      path === "/subscription/:planId?email=") &&
      user?.subscriptionIos === "paid") ||
    (user?.subscriptionIos === "trial" &&
      moment(today) <
        moment(
          user.subscriptionGraceDate ? user.subscriptionGraceDate : new Date()
        ))
  ) {
    history.push("/subscribed")
  } else if (
    (pathname === "/subscribed" || pathname === "/subscription-success") &&
    user?.subscriptionIos === "free" &&
    user?.subscriptionIosExpiryDate === null
  ) {
    history.push("/subscription")
  }

  useEffect(() => {
    getSubscriptionPlansFunction()
  }, [])

  useEffect(() => {
    if (!getSubscriptionPlansLoading) {
      if (
        getSubscriptionPlansData &&
        getSubscriptionPlansData.getSubscriptionPlans &&
        getSubscriptionPlansData.getSubscriptionPlans.length
      ) {
        setProducts(getSubscriptionPlansData.getSubscriptionPlans)
        setSelectedPlan(getSubscriptionPlansData.getSubscriptionPlans[0])
      }
    }
  }, [
    getSubscriptionPlansLoading,
    getSubscriptionPlansData,
    getSubscriptionPlansError
  ])

  /**@var dispatch hook */
  const dispatch = useDispatch()

  // close player on subscription page
  useEffect(() => {
    dispatch(clearPlayer())
  })

  /**choose a subscription package */
  const selectPackage = (pack) => {
    logAnalytics(pack)
    dispatch(selectPackageService(pack))
  }

  const logAnalytics = (plan) => {
    logEvent(analytics, "plan_selected", {
      plan: plan?.name
    })
  }

  return (
    <Fragment>
      <div className="subscription-layout ">
        <div className="cancel-wrapper">
          <CloseOutlined
            style={{ fontSize: 30 }}
            onClick={() => {
              history.push("/")
            }}
          />
        </div>
        <div className="container">
          <Row gutter={[25, 25]}>
            <Col xs={24}>
              <img src={Logo} alt="brand-logo" className="logo" />
            </Col>
          </Row>
          <Row gutter={[15, 15]}>
            <Col xs={24} lg={12}>
              <div className="subscription-left">
                <img src={premium} alt="" />
                <h2 className="header-primary text-primary">
                  Unlock the full Good Vibes <br /> Experience
                </h2>
                <h3>Try 7 days for free </h3>
                <ul className="feature-list">
                  <li>
                    <img src={Check} alt="" />
                    Create unlimited playlists
                  </li>
                  <li>
                    <img src={Check} alt="" />
                    Re-order your tracks in the playlist
                  </li>
                  <li>
                    <img src={Check} alt="" />
                    Unlimited tracks in playlist
                  </li>
                  <li>
                    <img src={Check} alt="" />
                    Listed to high quality audio
                  </li>
                </ul>
                {/* <Link
                to="/payment"
                className="btn  btn-primary btn-large"
                onClick={() => {
                  selectPackage(selectedPlan)
                  logAnalytics(selectedPlan)
                }}
              >
                Get Premium
              </Link> */}
              </div>
            </Col>
            <Col xs={24} lg={12}>
              <div className="subscription-right">
                <Row gutter={[30, 30]}>
                  {getSubscriptionPlansLoading ? (
                    <div className="loader-wrapper">
                      <HorizontalLoader />
                    </div>
                  ) : (
                    <>
                      {" "}
                      {products?.map((pack, index) => (
                        <>
                          <Col xs={24} md={24} key={index}>
                            <div
                              className={`subscription-info-card ${
                                selectedPlan === pack ? "active" : ""
                              }`}
                              onClick={() => setSelectedPlan(pack)}
                            >
                              {pack.most_popular ? (
                                <span className="popular">Most Popular</span>
                              ) : (
                                ""
                              )}
                              <div className="subscription-info-card-content-left">
                                <div className="subscription-info-card-content-left-radio">
                                  {selectedPlan === pack ? (
                                    <div className="subscription-info-card-content-left-radio-under" />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="subscription-info-card-content-left-text">
                                  <p className="time">{pack.name}</p>
                                  <span>{pack.description}</span>
                                </div>
                              </div>

                              <div className="subscription-info-card-content-right">
                                <h3 className="price">
                                  ${pack?.price_detail?.unit_amount / 100}
                                </h3>
                              </div>
                              {/* {pack.most_popular ? (
                              <span className="popular">Most Popular</span>
                            ) : (
                              ""
                            )}
                            <p className="time">{pack.name}</p>
                            <p className="discount">{pack.description}</p>
                            <h3 className="price">
                              ${pack?.price_detail?.unit_amount / 100}
                            </h3>
                            <Link
                              to="/payment"
                              onClick={() => selectPackage(pack)}
                            >
                              Get Started
                            </Link> */}
                            </div>
                          </Col>
                        </>
                      ))}
                    </>
                  )}
                </Row>
                {!getSubscriptionPlansLoading ? (
                  <Row style={{ marginTop: "50px" }}>
                    <Link
                      to={`/subscription/${selectedPlan?.id}`}
                      className="btn  btn-primary"
                      onClick={() => {
                        selectPackage(selectedPlan)
                        logAnalytics(selectedPlan)
                      }}
                      style={{ width: "100%" }}
                    >
                      <img src={premiumLeft} alt="" />{" "}
                      <span style={{ padding: "0 15px" }}>Get Premium</span>{" "}
                      <img src={premiumRight} alt="" />
                    </Link>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}

export default Subscription
