import React, { useState } from "react"
import { Button, Modal } from "antd"

import mobileMock from "../../assets/images/mobile-mock.svg"
import check from "../../assets/images/check-small.svg"
import { appMessagepoints } from "./appMessagePoints"
import history from "utils/history"
import { appStoreLink, playstoreLink } from "constants/urls"
import { Link } from "react-router-dom"

const AppMessageModal = (props) => {
  const { isAppModalOpen, setIsAppModalOpen, appModal, platform } = props

  const handleCancel = () => {
    setIsAppModalOpen(false)
    clearInterval(appModal)
  }

  // console.log({ platform })

  return (
    <div className="app-message-modal">
      <Modal
        title={null}
        footer={null}
        visible={isAppModalOpen}
        onCancel={handleCancel}
      >
        <div className="app-message-modal-image">
          <img src={mobileMock} alt="mobile-mock" />
        </div>
        <p className="app-message-modal-title">
          Download our app for complete Good Vibes experience
        </p>
        {appMessagepoints.map((point) => (
          <div className="app-message-modal-points" key={point.id}>
            <img src={check} alt="check" />
            <span>{point.text}</span>
          </div>
        ))}
        <div className="app-message-modal-button">
          <a
            href={platform === "iOS" ? appStoreLink : playstoreLink}
            target="_blank"
          >
            <Button className="btn btn-primary">Download App</Button>
          </a>
        </div>
      </Modal>
    </div>
  )
}
export default AppMessageModal
