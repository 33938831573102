import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import mastercard from "../../assets/images/credit-card-icons/mastercard.png"
import { useSelector } from "react-redux"
import moment from "moment"
import _ from "lodash"
import { getItem } from "utils/local-storage"
import { Button, Modal } from "antd"
import {} from "graphql/queries/auth"
import { useMutationHook, useQueryHook } from "graphql/hooks"
import {
  CANCEL_SUBSCRIPTION,
  MY_SUBSCRIPTION_INFO
} from "graphql/queries/payment"
import { message } from "antd"
import CancelModal from "./SubscriptionCancelModel"
import { getErrors } from "utils/helper"
import history from "utils/history"

const PaymentComponent = () => {
  const subscriptionReducer = useSelector((state) => state.subscription)
  const [currentSubscription, setCurrentSubscription] = useState()

  const [userData, setUserData] = useState()

  const { loading, data, error } = useQueryHook({
    query: MY_SUBSCRIPTION_INFO
  })
  const {
    func: cancelSubscriptionFun,
    loading: cancelSubscriptionLoading,
    data: cancelSubscriptionData,
    error: cancelSubscriptionError
  } = useMutationHook({
    query: CANCEL_SUBSCRIPTION,
    refetchQueries: [{ query: MY_SUBSCRIPTION_INFO }]
  })
  useEffect(() => {
    let userData = getItem("__u__")
    setUserData(userData)
  }, [])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showPlaylistModal, setShowPlaylistModal] = useState(false)

  const cancelSubscription = () => {
    cancelSubscriptionFun()
  }

  useEffect(() => {
    if (!cancelSubscriptionLoading) {
      if (cancelSubscriptionError) {
        console.error("error in cancelSubscription cancelSubscriptionError")
        console.error(
          "error in cancelSubscription cancelSubscriptionError.graphQLErrors",
          cancelSubscriptionError.graphQLErrors
        )
        console.error(
          "error in cancelSubscription cancelSubscriptionError.message",
          cancelSubscriptionError.message
        )
        message.error(
          getErrors(cancelSubscriptionError.graphQLErrors) ||
            cancelSubscriptionError.message
        )
        setIsModalOpen(false)
        return
      }
      if (cancelSubscriptionData && !cancelSubscriptionError) {
        console.log("cancelSubscriptionData is -> ", cancelSubscriptionData)
        message.success("Cancel subscription success")
        setIsModalOpen(false)
      }
    }
  }, [
    cancelSubscriptionData,
    cancelSubscriptionError,
    cancelSubscriptionLoading
  ])

  const [subscriptionData, setSubscriptionData] = useState({})

  const today = Math.floor(new Date())

  // console.log("subsss", subscriptionData)

  useEffect(() => {
    setSubscriptionData(data?.getMySubscriptionInfo)
  }, [data])
  // confirm model
  // confirmation model

  const modelmessage = `You are going to cancel your subscription which expires on ${moment(
    subscriptionData?.expiryDate
  ).format(
    "L"
  )}. All payments made are non-refundable and non-transferable except as expressly provided.`

  return (
    <div className="card card--personal-info">
      <div className="card-top">
        <div className="heading">
          <h3 className="card__header">Current Subscription</h3>
        </div>
      </div>

      {subscriptionData && userData?.subscriptionIos !== "free" ? (
        <div className="payment-card-info">
          {/* <img src={mastercard} alt="" /> */}
          <div className="payment-card-text">
            <p>
              Subscription Plan:{" "}
              <span className="subscription-type">
                {" "}
                {subscriptionData && subscriptionData.plan}
              </span>
            </p>
            {subscriptionData?.platform &&
            subscriptionData?.platform === "web" &&
            (subscriptionData?.status == "Active" ||
              subscriptionData?.status == "Trial" ||
              subscriptionData?.status == "Renewed") &&
            moment(today) < moment(subscriptionData?.graceDate) ? (
              <div className="cancel-btn">
                <Button type="link" block onClick={() => setIsModalOpen(true)}>
                  Cancel Subscription
                </Button>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="subcription-date">
            <div className="purches-date">
              <div>
                <p>
                  Purchase date:{" "}
                  <span>
                    {subscriptionData?.purchaseDate
                      ? moment(
                          subscriptionData && subscriptionData?.purchaseDate
                        ).format("L")
                      : ""}
                  </span>
                </p>
              </div>
            </div>
            <div className="expire-date">
              <p>
                Expires on:{" "}
                <span className="">
                  {subscriptionData?.expiryDate
                    ? moment(
                        subscriptionData && subscriptionData?.expiryDate
                      ).format("L")
                    : ""}
                </span>
              </p>
            </div>
          </div>
          <div className="expire-date">
            {moment(subscriptionData?.expiryDate) < moment(new Date()) ? (
              <>
                <span style={{ marginRight: 5 }}>Grace days:</span>
                <span>
                  {Math.abs(
                    moment(subscriptionData?.expiryDate).diff(
                      moment(today),
                      "days"
                    )
                  )}
                  /
                  {Math.abs(
                    moment(subscriptionData?.graceDate).diff(
                      moment(subscriptionData?.expiryDate),
                      "days"
                    )
                  )}
                </span>
              </>
            ) : Math.floor(moment(subscriptionData?.expiryDate)) ===
              Math.floor(moment(subscriptionData?.graceDate)) ? (
              <span>
                {Math.abs(
                  moment(subscriptionData?.expiryDate)
                    .add(1, "d")
                    .diff(moment(today), "days")
                )}{" "}
                days trial
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="payment-card-info">
            {/* <img src={mastercard} alt="" /> */}
            <div className="payment-card-text">
              <p>
                Subscription Plan:{" "}
                <span className="subscription-type"> Free</span>
              </p>

              <div className="upgrade-btn">
                <Button
                  type="link"
                  block
                  onClick={() => history.push("/subscription")}
                >
                  Upgrade to Premium
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      <CancelModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        cancelSubscription={cancelSubscription}
        cancelSubscriptionLoading={cancelSubscriptionLoading}
        modelmessage={modelmessage}
      />
    </div>
  )
}

export default PaymentComponent
