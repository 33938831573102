export const commitments = [
  {
    id: "0.5",
    title: "Casual",
    time: "30 minutes a day"
  },
  {
    id: "2",
    title: "Regular",
    time: "2 hours a day"
  },
  {
    id: "6",
    title: "Advance",
    time: "6 hours a day"
  },
  {
    id: "8",
    title: "Professional",
    time: "8 hours a day"
  }
]

export const deleteAccountOptions = [
  {
    id:1,
    title: "This is temporary. I’ll be back."
  },
  {
    id:2,
    title: "I find this app too complex."
  },
  {
    id:3,
    title: "I found a better alternative."
  },
  {
    id:4,
    title: "It’s expensive."
  }
]
