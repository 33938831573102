// import from react
import React, { useState, useContext, useEffect } from "react"
// import form react-router-dom
import { useHistory, useRouteMatch } from "react-router-dom"

// import PreLoader from "../../components/UI/Preloader"
// import { useSelector, useDispatch } from "react-redux"
import Cardsonglist from "../../components/Cards/Cardsonglist"
import Songlistitem from "../../components/songlist"
import Cardregular from "../../components/Cards/Card"
import { Button, Col } from "antd"
import Cimg1 from "../../assets/images/Thumbnail.png"
// import Cimg5 from "../../assets/images/anxiety.png"
// import Cimg2 from "../../assets/images/healing.png"
// import Cimg3 from "../../assets/images/creativity.png"
// import Cimg4 from "../../assets/images/Sleep.png"
// import ScrollPagination from "../../components/UI/Scrollpagination"
// import { searchApi } from "../../services/search.service"
import { getTotalTracksTime } from "utils/helper"
import premiumIcon from "../../assets/images/search/premium.svg"
import starIcon from "../../assets/images/search/star.svg"
import hashIcon from "../../assets/images/search/hash.svg"
import noResult from "../../assets/images/search/no-result.png"

import algoliasearch from "algoliasearch"
import Cookies from "js-cookie"
import { UserContext } from "contexts/userContext"

import "./styles.scss"
import useSendEvents from "functions/useSendEvents"
import { useLazyQueryHook } from "graphql/hooks"
import { GET_CUSTOM_CATEGORIES } from "graphql/queries/search"
import userMixPanelEvents from "functions/mixPanelEvents"
import ScrollPagination from "components/ScrollPagination"
// import CustomScroll from "components/customScroll"

let appId = process.env.REACT_APP_ALGOLIA_APP_ID
let appKey = process.env.REACT_APP_ALGOLIA_KEY
let appIndexName = process.env.REACT_APP_ALGOLIA_TRACK_INDEX_NAME

// filter list

const Search = () => {
  const client = algoliasearch(appId, appKey)
  const [filterList, setFilterList] = useState([])
  const index = client.initIndex(appIndexName)
  // state for page
  const params = new URLSearchParams(window.location.search)
  const user = useContext(UserContext)
  const history = useHistory()
  const [searchList, setSearchList] = useState([])
  const [craftedList, setCraftedList] = useState([])
  const [suggestionList, setSuggestionList] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterType, setFilterType] = useState("")
  const [trackTypeFilter, setTrackTypeFilter] = useState("")
  const [durationFilter, setDurationFilter] = useState("")
  const [trackCatFilter, setTrackCatFilter] = useState("")
  const [query, setQuery] = useState("")
  const [searchQueryId, setSearchQueryId] = useState("")
  const [maxTackDuration, setMaxTrackDuration] = useState(0)
  const [durationData, setDurationData] = useState({})
  const [contentData, setContentData] = useState({})
  const [algoliaQuery, setAlgoliaQuery] = useState({})
  const [categoriesFilterList, setCategoriesFilterList] = useState([])
  const [loading, setLoading] = useState(false)
  const [algoliaRes, setAlgoliaRes] = useState({})
  const [selectedCategory, setSelectedCategory] = useState([])
  const [paginationProps, setPaginationProps] = useState({
    page: 0,
    paginationStatus: false
  })
  const [userFilterIni, setUserFilterIni] = useState({})
  const [showFilterBtn, setShowFilterBtn] = useState(false)

  // console.log("searchList searchList", searchList)

  // console.log("algoliaRes algoliaRes", algoliaRes)

  // console.log("selected category", selectedCategory)

  // console.log("userFiltern initially ", userFilterIni)

  // console.log("crafterd for you", craftedList)
  // console.log("crafterd for you suggesse=>", suggestionList)

  // gaphql query

  const {
    func: getCustomCategories,
    loading: customCategoriesLoading,
    data: customCategoriesData,
    error: customCategoriesError
  } = useLazyQueryHook({
    query: GET_CUSTOM_CATEGORIES
  })
  // console.log("customCategoriesData", customCategoriesData)

  // getting from browser
  const term = params.get("keyword")

  console.log("term", term)
  console.log("query query", query)

  // handle filter function

  const handleShowFilter = (e) => {
    e.preventDefault()
    setShowFilter(!showFilter)
    setTrackTypeFilter("")
    setDurationFilter("")
    setFilterType("")
  }
  // setting query from search term
  useEffect(() => {
    if (term) {
      setQuery(term)
      setSearchList([])
      setPaginationProps((prevState) => ({
        ...prevState,
        page: 0
      }))
    }
  }, [term])
  // showing filter options
  const handleShowFilterOption = (e, option) => {
    e.preventDefault()
    if (option === filterType) {
      setFilterType(!option)
      // let newSelectedCat = selectedCategory.filter((item) => item !== option)
      // setSelectedCategory(newSelectedCat)
    } else {
      setFilterType(option)
      // setSelectedCategory([...selectedCategory, option])
    }
  }
  // mix panel events
  useEffect(() => {
    userMixPanelEvents("screen_view", { screen: "search" })
  }, [])

  // showing sub categories after selecting parent category
  useEffect(() => {
    if (query && Object.keys(algoliaRes).length) {
      let categoriesFilter = algoliaRes.facets?.["categories.categoryName"]
      let trackDurationFilter = algoliaRes.facets?.["duration"]
      let trackFilterType = algoliaRes.facets?.["trackType"]
      if (trackDurationFilter) {
        const max = Object.entries(trackDurationFilter).reduce(
          (maxValue, [key, value]) => {
            const numericKey = parseInt(key)
            return numericKey > maxValue ? numericKey : maxValue
          },
          -Infinity
        )
        setMaxTrackDuration(max)
      }

      setDurationData(trackDurationFilter)
      setContentData(trackFilterType)

      let categoriesName = categoriesFilter
        ? Object.keys(categoriesFilter).map((cf) => {
            return cf
          })
        : null

      if (categoriesName) {
        getCustomCategories({
          variables: {
            categoryNames: categoriesName,
            publicStatus: true
          }
        })
      }
    }
  }, [query, showFilter])

  // handle category filter
  const handleCatFilter = (e, type) => {
    e.preventDefault()
    if (trackCatFilter === type) {
      setTrackCatFilter("")
      let newSelectedCat = selectedCategory.filter(
        (item) => item.categoryName !== "category"
      )
      // removing userfilter value for preventing trigger repeat event
      const filteredUserFilter = Object.entries(userFilterIni).filter(
        ([key, value]) => value !== type
      )
      const filteredObj = Object.fromEntries(filteredUserFilter)
      setUserFilterIni(filteredObj)
      setSelectedCategory(newSelectedCat)
    } else {
      setTrackCatFilter(type)
      setSelectedCategory([...selectedCategory, { categoryName: "category" }])
    }
    // setPaginationProps((prevState) => ({
    //   ...prevState,
    //   page: 0
    // }))
    setSearchList([])
  }

  // handle track duration filter
  const handleDurationFilter = (e, type) => {
    // console.log("type from function", type)
    e.preventDefault()
    if (durationFilter == type) {
      setDurationFilter("")
      let newSelectedCat = selectedCategory.filter(
        (item) => item.categoryName !== "track_duration"
      )
      setSelectedCategory(newSelectedCat)
      // removing userfilter value for preventing trigger repeat event
      const filteredUserFilter = Object.entries(userFilterIni).filter(
        ([key, value]) => value !== type
      )
      // console.log("filteredUserFilter INSIDE ", filteredUserFilter)
      const filteredObj = Object.fromEntries(filteredUserFilter)
      setUserFilterIni(filteredObj)
    } else {
      // console.log("DURATION TYPE", type)
      setDurationFilter(type)
      setSelectedCategory([
        ...selectedCategory,
        { categoryName: "track_duration" }
      ])
    }
    // setPaginationProps((prevState) => ({
    //   ...prevState,
    //   page: 0
    // }))
    setSearchList([])
  }

  // handle track type filter trck type can be premium
  const handleTrackTypeFilter = (e, type) => {
    e.preventDefault()
    if (trackTypeFilter === type) {
      setTrackTypeFilter("")
      let newSelectedCat = selectedCategory.filter(
        (item) => item.categoryName !== "content"
      )
      setSelectedCategory(newSelectedCat)
      // removing userfilter value for preventing trigger repeat event
      const filteredUserFilter = Object.entries(userFilterIni).filter(
        ([key, value]) => value !== type
      )
      const filteredObj = Object.fromEntries(filteredUserFilter)
      setUserFilterIni(filteredObj)
    } else {
      setTrackTypeFilter(type)
      setSelectedCategory([...selectedCategory, { categoryName: "content" }])
    }
    // setPaginationProps((prevState) => ({
    //   ...prevState,
    //   page: 0
    // }))
    setSearchList([])
  }

  // useEffect function for data after search for data
  useEffect(() => {
    if (query) {
      let searchCookie = Cookies.get("search")
        ? JSON.parse(Cookies.get("search"))
        : []
      let filters = [
        `${durationFilter ? `duration:${durationFilter} ` : ""}`,
        `${trackTypeFilter ? `trackType:${trackTypeFilter}` : ""}`,
        `${trackCatFilter ? `categories.categoryName:${trackCatFilter}` : ""}`
      ]

      async function fetchDatafunc() {
        if (paginationProps.page === 0) {
          await setLoading(true)
        }
        let queryAtrribute = {
          userToken: user.id,
          facets: ["*"],
          enablePersonalization: true,
          clickAnalytics: true,
          // facetFilters: filters.filter((data) => data !== ""),
          // numericFilters: [`duration>${durationFilter}`],
          page: paginationProps.page,
          hitsPerPage: 100
        }
        if (durationFilter !== "" && durationFilter !== undefined) {
          let filtersNew = [
            `${trackTypeFilter ? `trackType:${trackTypeFilter}` : ""}`,
            `${
              trackCatFilter ? `categories.categoryName:${trackCatFilter}` : ""
            }`
          ]
          let newQuery = {
            ...queryAtrribute,
            facetFilters: filtersNew.filter((data) => data !== ""),
            numericFilters: [`duration<=${durationFilter}`]
          }

          index.search(query, newQuery).then((res) => {
            searchCookie = [...searchCookie, query]
            setAlgoliaRes(res)
            // setDurationFilter("")
            // console.log("response from algolia", res)
            let uniqueArr = searchCookie.filter((elem, index) => {
              return searchCookie.indexOf(elem) === index
            })
            Cookies.set("search", JSON.stringify(uniqueArr))
            if (trackTypeFilter || durationFilter || trackCatFilter) {
              let userFilter = {
                userSearchEvent: true,
                keyword: query,
                filterDuration: durationFilter,
                trackFilterType: trackTypeFilter,
                searchQueryId: searchQueryId,
                categoriesFilterList: trackCatFilter,
                eventComponent: "filter"
              }
              setUserFilterIni(userFilter)
            }
            let data = [...res.hits, ...searchList]
            data = [...new Map(data.map((item) => [item.id, item])).values()] //removing duplicates
            data = data.map((d, i) => {
              return {
                arrPostion: i + 1,
                ...d
              }
            })
            // console.log("data from algolia", data)
            // console.log("res from algolia", res)
            setSearchList(data)
            setSearchQueryId(res.queryID)
            setLoading(false)
          })
        } else {
          let queralgoQuery = {
            ...queryAtrribute,
            facetFilters: filters.filter((data) => data !== "")
          }
          index.search(query, queralgoQuery).then((res) => {
            searchCookie = [...searchCookie, query]
            setAlgoliaRes(res)
            // setDurationFilter("")
            let uniqueArr = searchCookie.filter((elem, index) => {
              return searchCookie.indexOf(elem) === index
            })
            Cookies.set("search", JSON.stringify(uniqueArr))
            if (trackTypeFilter || durationFilter || trackCatFilter) {
              let userFilter = {
                userSearchEvent: true,
                keyword: query,
                filterDuration: durationFilter,
                trackFilterType: trackTypeFilter,
                searchQueryId: searchQueryId,
                categoriesFilterList: trackCatFilter,
                eventComponent: "filter"
              }
              setUserFilterIni(userFilter)
            }
            let data = [...res.hits, ...searchList]
            data = [...new Map(data.map((item) => [item.id, item])).values()] //removing duplicates
            data = data.map((d, i) => {
              return {
                arrPostion: i + 1,
                ...d
              }
            })

            // console.log("data from algolia response", data)

            setSearchList(data)
            setSearchQueryId(res.queryID)
            setLoading(false)
          })
        }
      }
      fetchDatafunc()
    }
  }, [query, trackCatFilter, trackTypeFilter, durationFilter])

  // setting data after geting response from algolia
  useEffect(() => {
    if (searchList.length) {
      if (searchList.length !== algoliaRes.nbHits) {
        setPaginationProps((prevState) => ({
          ...prevState,
          paginationStatus: true
        }))
      } else {
        setPaginationProps((prevState) => ({
          ...prevState,
          paginationStatus: false
        }))
      }

      // console.log("search list insid use effect", searchList)

      let craftedData = searchList
        .map((sl, index) => {
          return {
            ...sl
          }
        })
        .filter(
          (sl) =>
            sl.trackType === "premium" ||
            sl.trackType === "paid" ||
            sl.trackType === "trial"
        )
      let suggestData = searchList
        .map((sl, index) => {
          return {
            ...sl
          }
        })
        .filter(
          (sl) =>
            sl.trackType !== "premium" &&
            sl.trackType !== "paid" &&
            sl.trackType !== "trial"
        )

      let sugData = []

      if (craftedData.length > 10) {
        // let sliceData = craftedData.slice(10, craftedData.length - 1)
        let sliceData = craftedData

        sugData = [...sugData, ...sliceData, ...suggestData]
      } else {
        sugData = [...sugData, ...suggestData]
      }

      sugData = [...new Map(sugData.map((item) => [item.id, item])).values()] //removing duplicates
      let sessonFilterSugData = []
      let sessonFilterCraftedData = []
      sugData.forEach((data) => {
        if (!data.tracks) {
          sessonFilterSugData = [...sessonFilterSugData, data]
        } else {
          sessonFilterSugData = [...sessonFilterSugData]
        }
      })

      craftedData.forEach((data) => {
        if (!data.tracks) {
          sessonFilterCraftedData = [...sessonFilterCraftedData, data]
        } else {
          sessonFilterCraftedData = [...sessonFilterCraftedData]
        }
      })

      setCraftedList(sessonFilterCraftedData.slice(0, 10))
      setSuggestionList(sessonFilterSugData)
    } else {
      setCraftedList([])
      setSuggestionList([])
    }
  }, [searchList])

  // useeffect  function for gettting category

  useEffect(() => {
    if (customCategoriesData) {
      const { getCustomCategories } = customCategoriesData

      setCategoriesFilterList(getCustomCategories)
    }
  }, [customCategoriesData])

  const setShoewFunc = () => {
    // console.log("show filkter btn this functio is callling234")
    // console.log("show searchList.length", searchList.length)

    if (searchList.length > 0) {
      setShowFilterBtn(true)
      setFilterList([
        {
          id: 1,
          title: "category",
          name: "Category"
        },
        {
          id: 2,
          title: "track_duration",
          name: "Track duration"
        },
        {
          id: 3,
          title: "content",
          name: "Content"
        }
      ])
      // console.log("show filkter btn this functio is callling")
    }
  }

  useEffect(() => {
    const intervalId = setTimeout(() => {
      // Call the function here after the specified interval
      setShoewFunc()
    }, 100) // 5000 milliseconds = 5 seconds

    return () => {
      clearTimeout(intervalId)
    }
  }, [searchList])

  // console.log("suggestionList?.length ", suggestionList?.length)
  // console.log("craftedList?.length", craftedList?.length)
  // console.log("searchList", searchList.length)
  // //
  // console.log("categoriesFilterList", categoriesFilterList)

  // console.log("show filkter btn", showFilterBtn)
  // console.log("filterlist", filterList)

  return (
    <div>
      <section className="dash-section dash-section--playlist">
        <h2 className="header-primary" style={{ marginBottom: 15 }}>
          Results for {query}
        </h2>

        <div className="filter">
          {showFilterBtn ? (
            <div className="filter_index_btn">
              <Button onClick={handleShowFilter}>
                <img src={premiumIcon} className="filter_index_btn_img" />
                Filter
              </Button>
            </div>
          ) : (
            ""
          )}

          {showFilter && (
            <>
              <div
                style={{
                  display: "flex"
                }}
                className="filter_btns"
              >
                {filterList.map((d, i) => {
                  return (
                    <div key={i}>
                      <Button
                        onClick={(e) => handleShowFilterOption(e, d.title)}
                        className={selectedCategory.map((item) =>
                          item.categoryName === d.title ? "selected" : ""
                        )}
                      >
                        {" "}
                        {d.name}{" "}
                      </Button>
                    </div>
                  )
                })}
              </div>
              <div>
                {filterType === "category" ? (
                  <div className="filter_sub_cat_btns">
                    {categoriesFilterList.length
                      ? categoriesFilterList
                          .filter((item) => item.publishStatus == true)
                          .map((dd) => {
                            return (
                              <>
                                <Button
                                  key={dd.id}
                                  onClick={(e) =>
                                    handleCatFilter(e, dd.categoryName)
                                  }
                                  className={`${
                                    trackCatFilter == dd.categoryName
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={dd.storageKey}
                                    className="filter_index_btn_img"
                                  />
                                  {dd.categoryName}{" "}
                                </Button>
                              </>
                            )
                          })
                      : ""}
                  </div>
                ) : filterType === "track_duration" ? (
                  <div className="filter_sub_cat_btns">
                    {maxTackDuration > 300 ? (
                      <Button
                        onClick={(e) => handleDurationFilter(e, 300)}
                        className={`${durationFilter == 300 ? "selected" : ""}`}
                      >
                        5 mins
                      </Button>
                    ) : (
                      ""
                    )}
                    {maxTackDuration > 900 ? (
                      <Button
                        onClick={(e) => handleDurationFilter(e, 900)}
                        className={`${durationFilter == 900 ? "selected" : ""}`}
                      >
                        15 mins
                      </Button>
                    ) : (
                      ""
                    )}
                    {maxTackDuration > 1800 ? (
                      <Button
                        onClick={(e) => handleDurationFilter(e, 1800)}
                        className={`${
                          durationFilter == 1800 ? "selected" : ""
                        }`}
                      >
                        30 mins
                      </Button>
                    ) : (
                      ""
                    )}
                    {maxTackDuration > 3600 ? (
                      <Button
                        onClick={(e) => handleDurationFilter(e, 3600)}
                        className={`${
                          durationFilter == 3600 ? "selected" : ""
                        }`}
                      >
                        1 hour
                      </Button>
                    ) : (
                      ""
                    )}

                    {maxTackDuration > 3600 ? (
                      <Button
                        onClick={(e) => handleDurationFilter(e, 7200)}
                        className={`${
                          durationFilter == 7200 ? "selected" : ""
                        }`}
                      >
                        2 hour
                      </Button>
                    ) : (
                      ""
                    )}

                    {maxTackDuration > 7200 ? (
                      <Button
                        onClick={(e) => handleDurationFilter(e, 10800)}
                        className={`${
                          durationFilter == 10800 ? "selected" : ""
                        }`}
                      >
                        3 hour
                      </Button>
                    ) : (
                      ""
                    )}
                    {/* <Button>4 hour</Button> */}
                  </div>
                ) : filterType === "content" ? (
                  <div className="filter_sub_cat_btns">
                    {contentData !== undefined &&
                    contentData !== null &&
                    Object.keys(contentData).length
                      ? Object?.keys(contentData).map((cd, index) => {
                          return (
                            <Button
                              id={index}
                              onClick={(e) => handleTrackTypeFilter(e, cd)}
                              className={`${
                                trackTypeFilter == cd ? "selected" : ""
                              }`}
                            >
                              {cd.toUpperCase()}
                            </Button>
                          )
                        })
                      : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}

          {(craftedList.length || suggestionList.length) && !loading ? (
            <>
              <div className="searchResult">
                {craftedList && craftedList.length ? (
                  <div className="searchResult_searchTitle">
                    <img src={starIcon} />
                    <h2>Crafted for you</h2>
                  </div>
                ) : (
                  ""
                )}

                <div style={{ marginBottom: 30 }}>
                  {craftedList && craftedList.length ? (
                    <Cardsonglist
                      songlist={
                        <>
                          {craftedList.map((item, index) => {
                            return (
                              <Songlistitem
                                index={
                                  index < 9 ? "0" + (index + 1) : index + 1
                                }
                                title={item.title}
                                category={
                                  item.category
                                    ? item.category.categoryName
                                    : "N/A"
                                }
                                duration={getTotalTracksTime(item.duration)}
                                thumbnail={
                                  item?.storageKeys?.azure?.image || Cimg1
                                }
                                track={item}
                                allTracks={searchList}
                                premium={
                                  item.trackType.toUpperCase() === "PREMIUM" ||
                                  item.trackType.toUpperCase() === "PAID" ||
                                  item.trackType.toUpperCase() === "TRIAL"
                                    ? true
                                    : false
                                }
                                fromTag={true}
                                query={query}
                                objectId={item.objectID}
                                positionIndex={item.arrPostion}
                                userFilterAfterSearch={userFilterIni}
                                // filterDuration={duration}
                                // trackFilterType={type}
                                searchQueryId={searchQueryId}
                                categoriesFilterList={categoriesFilterList}
                              />
                            )
                          })}
                        </>
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                {suggestionList && suggestionList.length ? (
                  <>
                    <div className="searchResult_searchTitle">
                      <img src={hashIcon} />
                      <h2>Suggestions</h2>
                    </div>
                    <Cardsonglist
                      songlist={
                        <>
                          {suggestionList.map((item, index) => {
                            {
                              /* item = item.track; */
                            }
                            return (
                              <Songlistitem
                                index={
                                  index < 9 ? "0" + (index + 1) : index + 1
                                }
                                title={item.title}
                                category={
                                  item.category
                                    ? item.category.categoryName
                                    : "N/A"
                                }
                                duration={getTotalTracksTime(item.duration)}
                                thumbnail={
                                  item?.storageKeys?.azure?.image || Cimg1
                                }
                                track={item}
                                allTracks={searchList}
                                premium={
                                  item?.trackType?.toUpperCase() ===
                                    "PREMIUM" ||
                                  item?.trackType?.toUpperCase() === "PAID" ||
                                  item?.trackType?.toUpperCase() === "TRIAL"
                                    ? true
                                    : false
                                }
                                fromTag={true}
                                query={query}
                                positionIndex={item.arrPostion}
                                userFilterAfterSearch={userFilterIni}
                                objectId={item.objectID}
                                // filterDuration={duration}
                                // trackFilterType={type}
                                searchQueryId={searchQueryId}
                                categoriesFilterList={categoriesFilterList}
                              />
                            )
                          })}
                        </>
                      }
                    />
                    {/* <ScrollPagination
                      meta={query}
                      pagination={1}
                      endText={"No more tracks"}
                      loading={loading}
                    />  */}

                    {/*end infinite scrolling */}
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : loading ? (
            "Loading"
          ) : (
            <div
              className="no-result"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10%"
              }}
            >
              <h2>
                Sorry, we couldn’t find any results matching for "{query}"
              </h2>
              <img src={noResult} alt="no-result" />
              <p>No Track available</p>
              <p>Please try different kewords</p>
            </div>
          )}
        </div>
      </section>
    </div>
  )
}

export default Search
