//user created playlist details
import React, { useEffect, useState } from "react"
import Cardbanner from "../../../components/Cards/Card-banner"
import Cardsonglist from "../../../components/Cards/Cardsonglist"
import Songlistitem from "../../../components/songlist"
import Cimg1 from "../../../assets/images/Thumbnail.png"
import PlaylistIcon from "../../../assets/images/Medition-mix.png"
import SimilarTracksComponent from "../../../components/similarTracks"
import { useParams, useLocation } from "react-router"
import Preloader from "../../../components/UI/Preloader"
import _, { set } from "lodash"

import {
  getLastItemImage,
  getTotalTracksTime,
  setPageTitle
} from "../../../utils/helper"
import {
  GET_PLAYLIST_BY_ID,
  GET_PLAYLIST_BY_SLUG,
  GET_TRACKS_OF_PLAYLIST,
  REMOVE_TRACK_TO_PLAYLIST
} from "graphql/queries/playlist"
import { useLazyQueryHook, useMutationHook } from "graphql/hooks"
import { message, Modal } from "antd"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import history from "utils/history"
import Bradcrumb from "components/Bradcrumb"

const UserPlaylistDetail = () => {
  const location = useLocation()

  const [id, setId] = useState(null)
  // const id = location.state

  const { slug } = useParams()
  const {
    func: playlistDetailFunc,
    loading: playlistDetailLoading,
    error: playlistDetailError,
    data: playlistDetailData
  } = useLazyQueryHook({ query: GET_TRACKS_OF_PLAYLIST })

  const {
    func: individualPlaylistFunc,
    loading: individualPlaylistLoading,
    error: individualPlaylistError,
    data: individualPlaylistData
  } = useLazyQueryHook({ query: GET_PLAYLIST_BY_SLUG })

  const RedireactFun = () => {
    history.push("/subscription")
  }

  const {
    func: removeTrackFromPlaylistFunc,
    loading: removeTrackFromPlaylistLoading,
    data: removeTrackFromPlaylistData,
    error: removeTrackFromPlaylistError
  } = useMutationHook({
    query: REMOVE_TRACK_TO_PLAYLIST,
    refetchQueries: [
      {
        query: GET_TRACKS_OF_PLAYLIST,
        variables: {
          page: 1,
          limit: 10,
          playlistId: id
        }
      },
      {
        query: GET_PLAYLIST_BY_ID,
        variables: {
          playlistId: id
        }
      }
    ]
  })

  useEffect(() => {
    // if (location.state !== undefined) {
    // setId(location.state)
    // } else {
    setId(individualPlaylistData?.getPlaylistBySlug?.id)
    // }
  }, [individualPlaylistData])
  // confirmation model
  const confirm = () => {
    Modal.confirm({
      // title: 'Confirm',
      icon: "",
      content:
        "You cannot access to this playlist because your subscription has been expired. Please renew your subscription to gain access to all your playlists.",
      okText: "Renew Subscription",
      cancelText: "Cancel",
      onOk: () => {
        RedireactFun()
      }
    })
  }

  useEffect(() => {
    const variables = { page: 1, limit: 100, playlistSlug: slug }

    if (slug) {
      individualPlaylistFunc({ variables })
    }
  }, [slug]) //eslint-disable-line

  useEffect(() => {
    if (individualPlaylistData?.getPlaylistBySlug?.id) {
      let variablesForDetails = {
        page: 1,
        limit: 100,
        playlistId: individualPlaylistData?.getPlaylistBySlug?.id
      }
      playlistDetailFunc({ variables: variablesForDetails })
    }
  }, [individualPlaylistData])

  useEffect(() => {
    if (playlistDetailError) {
      message.error(playlistDetailError.message)
    }
    if (individualPlaylistError) {
      message.error(individualPlaylistError.message)
    }
  }, [playlistDetailError, individualPlaylistError])

  const [userData, setUserData] = useState({})
  useEffect(() => {
    let userData = getItem(STORAGE_KEYS.USER)
    setUserData(userData)
  }, [])

  // breadcrumbs links

  const linkItem = [
    {
      linkName: "Home",
      linkUrl: "/"
    },

    {
      linkName: individualPlaylistData?.getPlaylistBySlug?.playlistName,
      linkUrl: `/my-playlist/${individualPlaylistData?.getPlaylistBySlug?.slug}`,
      slug: individualPlaylistData?.getPlaylistBySlug?.slug
    }
  ]

  // // handle remove function
  const handleRemoveTrack = async (trackId) => {
    const variables = { playlistId: id, trackId: trackId }
    await removeTrackFromPlaylistFunc({ variables }).then((res) => {
      if (res.data !== null) {
        message.success("Track is successfully removed")
      }
      if (res?.errors) {
        message.error(res?.errors[0].message)
      }
    })
  }

  return (
    <>
      {playlistDetailLoading || individualPlaylistLoading ? <Preloader /> : ""}
      <>
        {/* {playlist ? */}
        <>
          <section className="dash-section">
            <div className="bradcrumb-container">
              <Bradcrumb linkItem={linkItem} />
            </div>
            <Cardbanner
              img={
                playlistDetailData?.getTracksOfPlaylist?.playlistTracks[
                  playlistDetailData?.getTracksOfPlaylist?.playlistTracks
                    ?.length - 1
                ]?.playlist?.storageKey || PlaylistIcon
              }
              title={_.upperFirst(
                individualPlaylistData?.getPlaylistById?.playlistName
              )}
              text={individualPlaylistData?.getPlaylistById?.description}
              track={playlistDetailData?.getTracksOfPlaylist?.playlistTracks.map(
                (track) => track?.track
              )}
              totalTracks={individualPlaylistData?.getPlaylistById?.tracksCount}
              fromPlaylist={true}
              fromFav={true}
            />
          </section>
        </>
        {/* : ""} */}
        <section className="dash-section dash-section--playlist">
          {playlistDetailData &&
          playlistDetailData.getTracksOfPlaylist &&
          playlistDetailData.getTracksOfPlaylist.playlistTracks &&
          playlistDetailData.getTracksOfPlaylist.playlistTracks.length ? (
            <>
              <Cardsonglist
                songlist={
                  <>
                    <>
                      {playlistDetailData.getTracksOfPlaylist.playlistTracks
                        .slice(0, 5)
                        .map((playlistItem, index) => (
                          // let playlistItem?.track = playlistItem?.track

                          <>
                            <Songlistitem
                              index={index < 10 ? "0" + (index + 1) : index + 1}
                              title={playlistItem?.track.title}
                              category={
                                playlistItem?.track.category
                                  ? playlistItem?.track.category.categoryName
                                  : "N/A"
                              }
                              duration={getTotalTracksTime(
                                playlistItem?.track.duration
                              )}
                              thumbnail={
                                playlistItem?.track.storageKeys.azure.image ||
                                Cimg1
                              }
                              page="playlist"
                              playlistIdPage={id}
                              track={playlistItem?.track}
                              isPlaylist={true}
                              id={playlistItem?.id}
                              trackIdInd={playlistItem?.track.id}
                              // allTracks={userPlaylistTracks}
                              allTracks={playlistDetailData.getTracksOfPlaylist?.playlistTracks.map(
                                (track) => track?.track
                              )}
                              pathname={
                                individualPlaylistData?.getPlaylistBySlug?.slug
                              }
                              handleRemoveFunc={handleRemoveTrack}
                              loadingafterfetch={removeTrackFromPlaylistLoading}
                            />
                          </>
                        ))}
                    </>

                    <>
                      {playlistDetailData.getTracksOfPlaylist.playlistTracks
                        .slice(5)
                        .map((playlistItem, index) => (
                          // let playlistItem?.track = playlistItem?.track

                          <>
                            {userData.subscriptionIos === "free" ? (
                              <div className="locked-track">
                                lock
                                <Songlistitem
                                  index={
                                    index + 6 < 10
                                      ? "0" + (index + 6)
                                      : index + 6
                                  }
                                  title={playlistItem?.track.title}
                                  category={
                                    playlistItem?.track.category
                                      ? playlistItem?.track.category
                                          .categoryName
                                      : "N/A"
                                  }
                                  duration={getTotalTracksTime(
                                    playlistItem?.track.duration
                                  )}
                                  lock={confirm}
                                  track={playlistItem?.track}
                                  isPlaylist={true}
                                  id={playlistItem?.id}
                                  playlistIdPage={id}
                                  trackIdInd={playlistItem?.track.id}
                                  page="playlist"
                                  playlistId={id}
                                  // allTracks={userPlaylistTracks}
                                  allTracks={playlistDetailData.getTracksOfPlaylist?.playlistTracks
                                    // .reverse()
                                    .map((track) => track?.track)}
                                  pathname={
                                    individualPlaylistData?.getPlaylistBySlug
                                      ?.slug
                                  }
                                  handleRemoveFunc={handleRemoveTrack}
                                  loadingafterfetch={
                                    removeTrackFromPlaylistLoading
                                  }
                                />
                              </div>
                            ) : (
                              <>
                                <Songlistitem
                                  index={
                                    index + 6 < 10
                                      ? "0" + (index + 6)
                                      : index + 6
                                  }
                                  title={playlistItem?.track.title}
                                  category={
                                    playlistItem?.track.category
                                      ? playlistItem?.track.category
                                          .categoryName
                                      : "N/A"
                                  }
                                  duration={getTotalTracksTime(
                                    playlistItem?.track.duration
                                  )}
                                  thumbnail={
                                    playlistItem?.track.storageKeys.azure
                                      .image || Cimg1
                                  }
                                  page="plalyist"
                                  track={playlistItem?.track}
                                  isPlaylist={true}
                                  id={playlistItem?.id}
                                  trackIdInd={playlistItem?.track.id}
                                  playlistIdPage={id}
                                  playlistId={id}
                                  // allTracks={userPlaylistTracks}
                                  allTracks={playlistDetailData.getTracksOfPlaylist?.playlistTracks
                                    // .reverse()
                                    .map((track) => track?.track)}
                                  pathname={
                                    individualPlaylistData?.getPlaylistById
                                      ?.slug
                                  }
                                  handleRemoveFunc={handleRemoveTrack}
                                  loadingafterfetch={
                                    removeTrackFromPlaylistLoading
                                  }
                                />
                              </>
                            )}
                          </>
                        ))}
                    </>
                  </>
                }
              />
            </>
          ) : (
            <div className="text-center">No tracks found in the playlist!!</div>
          )}
        </section>
        {/* <SimilarTracksComponent
          trackId={
            userPlaylistTracks?.length > 0
              ? userPlaylistTracks[0]?.track?.id
              : ""
          }
        /> */}
      </>
    </>
  )
}

export default UserPlaylistDetail
