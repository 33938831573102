import { Modal } from "antd"

import React from "react"

const PlaylistModal = ({
  setIsModalOpen,
  isModalOpen,
  playlist,
  handleDeletePlaylistOk,
  removePlaylistLoading
}) => {
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <Modal
      title="Delete Playlist"
      visible={isModalOpen}
      onOk={() => handleDeletePlaylistOk(playlist)}
      okText="Delete Playlist"
      onCancel={handleCancel}
      confirmLoading={removePlaylistLoading}
    >
      <p>
        All you sure you want to delete the playlist ‘{playlist?.playlistName}’?
      </p>
    </Modal>
  )
}
export default PlaylistModal
