//library playlist detail
import React, { useEffect, useState } from "react"
import Cardbanner from "../../components/Cards/Card-banner"
import Cardregular from "../../components/Cards/Card"
import Cardsonglist from "../../components/Cards/Cardsonglist"
import Songlistitem from "../../components/songlist"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { useLocation } from "react-router"
import {
  fetchPlaylistById,
  fetchPlaylistPlaylables
} from "../../services/playlist.service"
import Preloader from "../../components/UI/Preloader"
import { useDispatch, useSelector } from "react-redux"
import { PicCenterOutlined } from "@ant-design/icons"
import ScrollPagination from "../../components/UI/Scrollpagination"
import _ from "lodash"
import {
  GET_TRACKS_OF_SESSIONS,
  GET_SESSION_BY_ID
} from "graphql/queries/playlist"
import { useLazyQueryHook } from "graphql/hooks"
import { message } from "antd"
import { getTotalTracksTime } from "utils/helper"

const PlaylistDetail = () => {
  const location = useLocation()

  const id = location.plalistId

  // console.log("location",location)
  const {
    func: sessionDetailFunc,
    loading: sessionDetailLoading,
    error: sessionDetailError,
    data: sessionDetailData
  } = useLazyQueryHook({ query: GET_TRACKS_OF_SESSIONS })

  const {
    func: individualSessionFunc,
    loading: individualSessionLoading,
    error: individualSessionError,
    data: individualSessionData
  } = useLazyQueryHook({ query: GET_SESSION_BY_ID })

  useEffect(() => {
    const variables = { page: 1, limit: 20, sessionId: id }

    if (id) {
      sessionDetailFunc({ variables })
      individualSessionFunc({ variables })
    }
  }, [id]) //eslint-disable-line

  useEffect(() => {
    if (sessionDetailError) {
      message.error(sessionDetailError.message)
    }
    if (individualSessionError) {
      message.error(individualSessionError.message)
    }
  }, [sessionDetailError, individualSessionError])

  // console.log("data fron server", sessionDetailData)

  return (
    <>
      {sessionDetailLoading || individualSessionLoading ? <Preloader /> : ""}
      <>
        {individualSessionData && individualSessionData.getSessionById ? (
          <>
            <section className="dash-section">
              <Cardbanner
                img={individualSessionData.getSessionById.image || Cimg1}
                title={individualSessionData.getSessionById.name}
                text={individualSessionData.getSessionById.description}
                track={sessionDetailData?.getSessionTracks?.sessionTrack?.map(
                  (track) => track.track
                )}
                totalTracks={individualSessionData.getSessionById.tracksCount}
                fromPlaylist={true}
              />
            </section>
            <section className="dash-section dash-section--playlist">
              {sessionDetailData &&
              sessionDetailData.getSessionTracks &&
              sessionDetailData.getSessionTracks.sessionTrack &&
              sessionDetailData.getSessionTracks.sessionTrack.length ? (
                <>
                  <Cardsonglist
                    songlist={
                      <>
                        {sessionDetailData.getSessionTracks.sessionTrack.map(
                          (item, index) => {
                            item = item.track
                            return (
                              <Songlistitem
                                index={
                                  index < 10 ? "0" + (index + 1) : index + 1
                                }
                                title={item.title}
                                category={
                                  item.category
                                    ? item.category.categoryName
                                    : "N/A"
                                }
                                duration={getTotalTracksTime(item.duration)}
                                thumbnail={
                                  item.storageKeys.azure.image || Cimg1
                                }
                                track={item}
                                allTracks={sessionDetailData.getSessionTracks.sessionTrack.map(
                                  (track) => track.track
                                )}
                              />
                            )
                          }
                        )}
                      </>
                    }
                  />
                  {/* infinite scrolling */}
                  {/* {metaPlaylistTracks ? (
                    <ScrollPagination
                      meta={metaPlaylistTracks}
                      pagination={loadPlayables}
                      endText={"No more playlables!"}
                      loading={loadingPlaylistTracks}
                    />
                  ) : (
                    ""
                  )} */}
                  {/*end infinite scrolling */}
                </>
              ) : (
                <div className="text-center">
                  No tracks found in the playlist!!
                </div>
              )}
            </section>
            {/* <section className="dash-section">
						<h2 className="header-primary" style={{ marginBottom: 25 }}>Similar Tracks</h2>
						<div className="row row-card-list">
							{cardListItem}
						</div>
					</section> */}
          </>
        ) : (
          <div>
            {!individualSessionLoading
              ? "The playlist that your looking for doesn't exist!!"
              : ""}
          </div>
        )}
      </>
    </>
  )
}

export default PlaylistDetail
// rupak+092@clickandpress.com
// Rupak@12345
