import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  gql,
  Observable
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { v4 as uuidv4 } from "uuid"
import {
  STORAGE_KEYS,
  getItem,
  setItem,
  removeItem
} from "../utils/local-storage"

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const appUrl = process.env.REACT_APP_API_URL

const retryLink = new RetryLink()

const httpLink = createHttpLink({
  uri: appUrl
})

let accessToken = getItem(STORAGE_KEYS.ACCESS_TOKEN)
let refreshToken = getItem(STORAGE_KEYS.REFRESH_TOKEN)
let uniqueDeviceId = getItem(STORAGE_KEYS.UNIQUE_DEVICE_ID_FOR_WEB)

if (!uniqueDeviceId) {
  uniqueDeviceId = uuidv4()
  setItem(STORAGE_KEYS.UNIQUE_DEVICE_ID_FOR_WEB, uniqueDeviceId)
}

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the latest access token before each request
  const latestAccessToken = getItem(STORAGE_KEYS.ACCESS_TOKEN)

  operation.setContext({
    headers: {
      authorization: latestAccessToken ? `Bearer ${latestAccessToken}` : "",
      "gv-device-id": uniqueDeviceId,
      "gv-device-type": "web",
      devicetype: "web",
      "gv-device-timezone": userTimeZone,
      ...operation.getContext().headers
    }
  })

  return forward(operation)
})

const getNewToken = () => {
  return new Promise((resolve, reject) => {
    if (!refreshToken) {
      reject("No refresh token available.")
      return
    }

    new ApolloClient({
      uri: appUrl,
      cache: new InMemoryCache(),
      headers: {
        authorization: `Bearer ${refreshToken}`,
        "gv-device-id": uniqueDeviceId,
        "gv-device-type": "web",
        devicetype: "web",
        "gv-device-timezone": userTimeZone
      }
    }).query({
      query: gql`
        query {
          refreshAccessToken {
            access_token
            refresh_token
          }
        }
      `
    }).then((res) => {
      accessToken = res.data.refreshAccessToken.access_token
      setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken)

      if (res.data.refreshAccessToken.refresh_token) {
        refreshToken = res.data.refreshAccessToken.refresh_token
        setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken)
      }

      resolve(accessToken)
    }).catch((error) => {
      if (error.message === "Unauthorized") {
        removeTokensAndRedirect()
      }
      reject(error)
    })
  })
}

const removeTokensAndRedirect = () => {
  removeItem(STORAGE_KEYS.ACCESS_TOKEN)
  removeItem(STORAGE_KEYS.REFRESH_TOKEN)
  removeItem(STORAGE_KEYS.USER)

  window.location.href = "/login"
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.message === "Unauthorized") {
        return new Observable((observer) => {
          getNewToken()
            .then((accessToken) => {
              // Update the access token in local storage
              setItem(STORAGE_KEYS.ACCESS_TOKEN, accessToken)

              // Re-run the request with the new access token
              operation.setContext(({ headers = {} }) => ({
                headers: {
                  ...headers,
                  authorization: `Bearer ${accessToken}`
                }
              }))

              // Continue with the request
              forward(operation).subscribe(observer)
            })
            .catch((error) => {
              observer.error(error)
            })
        })
      }
    }
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, retryLink, errorLink, httpLink]),
  cache: new InMemoryCache()
})
