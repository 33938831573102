import React, { useEffect, useState } from "react"
import Cimg1 from "../assets/images/Thumbnail.png"
import Card from "./Cards/Card"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"
import CategoryTrackCard from "../components/Cards/Categories-track-card"

// Import Swiper styles
import "swiper/swiper-bundle.css"
import CardSkeleton from "./Skeletons/CardSkeletons"
import "./styles.scss"

SwiperCore.use([Navigation, Pagination])

export const RowSlider = (props) => {
  const { items, loading, noItemsText, type, categoryTracks, sectiontitle } =
    props

  // console.log("row slider items", items)

  function changeImageUrl(url) {
    let word = "square"
    const splitUrl = url.split("/")
    const insertIndex = 4
    splitUrl.splice(insertIndex, 0, word)
    const newUrl = splitUrl.join("/")

    return newUrl
  }

  return (
    <>
      {loading ? (
        <CardSkeleton />
      ) : items && items.length ? (
        <Swiper
          spaceBetween={0}
          // pagination={true}
          pagination={{ clickable: true }}
          className="swiper-wrapper--row"
          breakpoints={{
            0: {
              slidesPerView: 1
            },
            480: {
              slidesPerView: 2
            },

            767: {
              slidesPerView: 3
            },

            992: {
              slidesPerView: 3
            },

            1100: {
              slidesPerView: 4
            },
            1700: {
              slidesPerView: 5
            }
          }}
        >
          {items &&
            items.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="swiper-slide swiper-slide-active"
                >
                  {type === "tags" ? (
                    <Card
                      key={index}
                      img={item.svg_image_url || Cimg1}
                      title={item.name}
                      link={`/tag-groups/${item?.slug}`}
                      id={item.id}
                    />
                  ) : (
                    <>
                      {!categoryTracks ? (
                        <Card
                          img={
                            item?.storageKeys.azure &&
                            item.storageKeys.azure.image
                              ? item.storageKeys.azure.image
                              : Cimg1
                          }
                          // defaultImg={item.storageKeys.image}
                          defaultImg={Cimg1}
                          title={item?.title}
                          link={`/track/${item?.slug}`}
                          premium={
                            item?.trackType === "PREMIUM" ||
                            item?.trackType === "premium" ||
                            item?.trackType === "PAID" ||
                            item?.trackType === "paid" ||
                            item?.trackType === "TRIAL" ||
                            item?.trackType === "trial"
                              ? true
                              : false
                          }
                          id={item?.id}
                          sectiontitle={sectiontitle}
                        />
                      ) : (
                        <CategoryTrackCard
                          img={
                            item.storageKeys.azure &&
                            item.storageKeys.azure.image
                              ? item.storageKeys.azure.image
                              : Cimg1
                          }
                          key={index}
                          title={item.title}
                          link={`/track/${item.slug}`}
                          premium={
                            item.trackType === "PREMIUM" ||
                            item?.trackType === "premium" ||
                            item.trackType === "PAID" ||
                            item.trackType === "TRIAL"
                              ? true
                              : false
                          }
                          id={item.id}
                          sectiontitle={sectiontitle}
                        />
                      )}
                    </>
                  )}
                </SwiperSlide>
              )
            })}
        </Swiper>
      ) : (
        <h4 className="text-center">{noItemsText}</h4>
      )}
    </>
  )
}

export const BannerSlider = (props) => {
  return (
    <Swiper
      direction="vertical"
      navigation
      pagination
      id="swiper-wrapper--banner"
    >
      {props.children}
    </Swiper>
  )
}
