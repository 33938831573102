import { createSlice } from "@reduxjs/toolkit"
import { loadMoreHandler } from "../../utils/helper"

const initialState = {
  loadingSingleTrack: false,
  singleTrack: undefined,
  errorSingleTrack: undefined,

  tracks: undefined,
  loadingTracks: false,
  metaTracks: undefined,
  errorTracks: undefined,

  popularTracks: undefined,
  loadingPopularTracks: false,
  metaPopularTracks: undefined,
  errorPopularTracks: undefined,

  favouriteTracks: undefined,
  metaFavouriteTracks: undefined,
  loadingFavouriteTracks: false,
  errorFavouriteTracks: undefined,

  tagGroupTracks: undefined,
  loadingTGtracks: false,
  metaTGtracks: undefined,
  errorTGtracks: undefined,

  loadingSimilarTracks: false,
  similarTracks: undefined,
  errorSimilarTracks: undefined,

  loadingTracksByGenre: false,
  tracksByGenre: undefined,
  metaTracksByGenre: undefined
}

const trackSlice = createSlice({
  name: "track",
  initialState: initialState,
  reducers: {
    // single track for single page
    fetchSingleTrackRequest(state) {
      state.loadingSingleTrack = true
    },

    fetchSingleTrackSuccess(state, actions) {
      state.singleTrack = actions.payload
      state.loadingSingleTrack = false
    },

    fetchSingleTrackFail(state, actions) {
      state.errorSingleTrack = actions.payload
      state.loadingSingleTrack = false
    },

    removeSingleTrack(state) {
      state.singleTrack = undefined
    },

    // tracks
    fetchTracksRequest(state) {
      state.loadingTracks = true
    },

    fetchTracksSuccess(state, actions) {
      state.loadingTracks = false
      state.tracks = loadMoreHandler(
        state.tracks,
        actions.payload.meta.current_page,
        actions.payload.tracks
      )
      state.metaTracks = actions.payload.meta
    },

    fetchTracksFail(state, actions) {
      state.loadingTracks = false
      state.errorTracks = actions.payload
    },

    // favourite tracks
    fetchFavouriteTracksRequest(state) {
      state.loadingFavouriteTracks = true
    },

    fetchFavouriteTracksSuccess(state, actions) {
      state.loadingFavouriteTracks = false
      state.favouriteTracks = loadMoreHandler(
        state.favouriteTracks,
        actions.payload.meta.current_page,
        actions.payload.tracks
      )
      state.metaFavouriteTracks = actions.payload.meta
    },

    fetchFavouriteTracksFail(state, actions) {
      state.loadingFavouriteTracks = false
      state.errorFavouriteTracks = actions.payload
    },

    // tracks by tag group

    fetchTGtracksRequest(state) {
      state.loadingTGtracks = true
    },

    fetchTGtracksSuccess(state, actions) {
      state.loadingTGtracks = false
      state.tagGroupTracks = actions.payload.tracks
      state.metaTGtracks = actions.payload.meta
    },

    fetchTGtracksFail(state, actions) {
      state.loadingTGtracks = false
      state.errorTGtracks = actions.payload
    },

    // popular tracks
    fetchPopularTracksRequest(state) {
      state.loadingPopularTracks = true
    },

    fetchPopularTracksSuccess(state, actions) {
      state.loadingPopularTracks = false
      state.popularTracks = loadMoreHandler(
        state.popularTracks,
        actions.payload.meta.current_page,
        actions.payload.tracks
      )
      state.metaPopularTracks = actions.payload.meta
    },

    fetchPopularTracksFail(state, actions) {
      state.loadingPopularTracks = false
      state.errorPopularTracks = actions.payload
    },

    //similar tracks
    fetchSimilarTracksRequest(state) {
      state.loadingSimilarTracks = true
    },

    fetchSimilarTracksSucess(state, actions) {
      const { data } = actions?.payload
      state.loadingSimilarTracks = false
      state.similarTracks = data
    },

    fetchSimilarTracksFail(state, actions) {
      state.loadingSimilarTracks = false
      // state.errorSimilarTracks
    },

    fetchTrackByGenreRequest(state) {
      state.loadingTracksByGenre = true
    },
    fetchTrackByGenreSuccess(state, actions) {
      const { data, meta } = actions?.payload
      state.loadingTracksByGenre = false
      state.tracksByGenre = loadMoreHandler(
        state.tracksByGenre,
        meta?.current_page,
        data
      )
      state.metaTracksByGenre = meta
    },
    fetchTrackByGenreFail(state, actions) {
      state.loadingTracksByGenre = false
    }
  }
})

export const {
  fetchSingleTrackFail,
  fetchSingleTrackRequest,
  fetchSingleTrackSuccess,
  removeSingleTrack,

  fetchTracksFail,
  fetchTracksRequest,
  fetchTracksSuccess,

  fetchFavouriteTracksFail,
  fetchFavouriteTracksRequest,
  fetchFavouriteTracksSuccess,

  fetchTGtracksFail,
  fetchTGtracksRequest,
  fetchTGtracksSuccess,

  fetchPopularTracksFail,
  fetchPopularTracksRequest,
  fetchPopularTracksSuccess,

  fetchSimilarTracksRequest,
  fetchSimilarTracksSucess,
  fetchSimilarTracksFail,

  fetchTrackByGenreRequest,
  fetchTrackByGenreSuccess,
  fetchTrackByGenreFail
} = trackSlice.actions

export default trackSlice.reducer
