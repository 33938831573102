import { Button, message } from "antd"
import React, { useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import { RESEND_VERIFY_EMAIL } from "graphql/queries/auth"
import { useLazyQueryHook } from "graphql/hooks"
import { STORAGE_KEYS, getItem } from "utils/local-storage"
import { getErrors } from "utils/helper"

const VerifyAccountCom = () => {
  let history = useHistory()
  const access_token = getItem(STORAGE_KEYS.ACCESS_TOKEN)
  if (!access_token) {
    // redirect to login
    history.push("/login")
  }
  const { func, loading, data, error } = useLazyQueryHook({
    query: RESEND_VERIFY_EMAIL,
    headers: {
      authorization: `Bearer ${access_token}`
    }
  })

  //   error message
  useEffect(() => {
    if (!loading) {
      if (error) {
        message.error(getErrors(error.graphQLErrors) || error.message)
      }

      if (data) {
        message.success(data.verifyEmailResend)
      }
    }
  }, [error, data, loading])

  // redirect to login page if no access token
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper__reset">
        <div className="heading">
          <h2>Please verify your account</h2>
        </div>
        <div className="text-small_verification">
          <p>
            Please check your email address and click on the link to verify your
            account.
          </p>
        </div>
        <p style={{ marginBottom: "-5px" }}>
          Didn’t receive verification code?
        </p>
        <Button
          className="btn btn-primary btn-large"
          loading={loading}
          htmlType="submit"
          style={{ width: "88%", marginTop: "25px" }}
          onClick={() => func()}
        >
          Resend Verification
        </Button>
      </div>
    </div>
  )
}

export default VerifyAccountCom
