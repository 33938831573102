import React, { useEffect } from "react"

import { useParams } from "react-router-dom"

import { useLazyQueryHook } from "graphql/hooks"

import { GET_CATEGORY_BY_ID, GET_TRACKS } from "graphql/queries/categories"

import { Col, message, Row } from "antd"

import CategoryTrackCard from "components/Cards/Categories-track-card"
import DashSection from "../../components/DashSection"
import Preloader from "components/UI/Preloader"
import { getErrors } from "utils/helper"

const CategoryTracks = () => {
  const { catId } = useParams()

  // mutation queries
  const {
    func: categoryFunc,
    loading: categoryLoading,
    data: categoryData,
    error: categoryError
  } = useLazyQueryHook({
    query: GET_CATEGORY_BY_ID
  })

  const {
    func: tracksFunc,
    loading: tracksLoading,
    data: tracksData,
    error: tracksError
  } = useLazyQueryHook({
    query: GET_TRACKS
  })

  useEffect(() => {
    if (catId && catId !== null) {
      const variables = { page: 1, limit: 12, id: catId }
      tracksFunc({ variables })
      categoryFunc({ variables })
    }
  }, [catId])

  // error handling category data
  useEffect(() => {
    if (categoryError) {
      message.error(
        getErrors(categoryError.graphQLErrors) || categoryError.message
      )
    }
    if (tracksError) {
      message.error(getErrors(tracksError.graphQLErrors) || tracksError.message)
    }
  }, [categoryError, tracksError])

  // console.log({ categoryData })

  return (
    <>
      <DashSection
        title={categoryData?.getCategoryByCatId?.category?.categoryName}
      >
        {tracksLoading || categoryLoading ? <Preloader /> : ""}
        <Row gutter={[16, 30]}>
          {tracksData &&
            tracksData.getTracks &&
            tracksData.getTracks.tracks &&
            tracksData.getTracks.tracks.length &&
            tracksData.getTracks.tracks.map((item) => (
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <CategoryTrackCard
                  img={
                    item.storageKeys.azure &&
                    item.storageKeys.azure.image &&
                    item.storageKeys.azure.image
                  }
                  title={item.title}
                  link={`/track/${item.slug}`}
                  premium={
                    item.trackType === "PREMIUM" || item.trackType === "premium"
                      ? true
                      : false
                  }
                  id={item.id}
                />
              </Col>
            ))}
        </Row>
      </DashSection>
    </>
  )
}

export default CategoryTracks
