import { useLazyQueryHook } from "graphql/hooks";
import {React,useState} from "react"
import { useEffect } from "react";
import { Link } from "react-router-dom"
import { useCookies } from 'react-cookie';
import { FORGET_PASSWORD_LINK } from "graphql/queries/auth";
import { getItem } from "utils/local-storage";
import { Button } from "antd";

const ResetPasswordComponent = () => {
// for cookie

const[userEmail,setUserEmail]=useState("")
  // for timer
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  const{func: sendEmail,loading:sendEmailloading,error:errorSendingEmail}=useLazyQueryHook({query:FORGET_PASSWORD_LINK});
  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

   const togglerTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const resendEmail=()=>{
    sendEmail({
      variables:{
      email:userEmail
    }
  })
  // cookies.remove("forgetEmail");
  togglerTimer()
}

  useEffect(()=>{
    togglerTimer()
    // console.log("hello")
  },[])

  useEffect(()=>{
    let userEmail=getItem("forgetEmail")
    setUserEmail(userEmail)
    // console.log("helo")
  },[])
  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper__reset">
      <div className="heading">
      <h2>
      Reset Link has been sent
            </h2>
      </div>
      <div className="text-small">
      <p>Please check your email address and follow the instructions.</p>
      </div>
          { countDown!==0?
      <div className="link-btn-nobg">
            Resend in {minutes}:{seconds}
          </div>:
          <Button className="btn btn-primary btn-large"  
            loading={sendEmailloading}
            htmlType="submit"
            style={{width:"85%",marginTop:"25px"}}
            onClick={resendEmail}
            >
                     Resend Verification
            </Button>
}


      </div>
      <Button className="btn btn-primary btn-large"  
            loading={sendEmailloading}
            style={{width:"85%",marginTop:"25px"}}
            >
              <Link to="/login">
              Login

              </Link>

            </Button>
      
 
    </div>
  )
}

export default ResetPasswordComponent
