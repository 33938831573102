import React from "react"
import { useState } from "react"

const CategoryTags = ({ title, id, selected }) => {
  

  return (
    <div className={`category-tags ${selected ? "selected" : ""} `}>
      <span>{title}</span>
    </div>
  )
}

export default CategoryTags
