import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { matchPath, useLocation, useParams } from "react-router-dom"
import Cardbanner from "../../components/Cards/Card-banner"
import {
  fetchSingleTrack,
  removeSingleTrackService
} from "../../services/track.service"
import Cimg1 from "../../assets/images/Thumbnail.png"
import Preloader from "../../components/UI/Preloader"
import Cardsonglist from "../../components/Cards/Cardsonglist"
import Songlistitem from "../../components/songlist"
import SimilarTracksComponent from "../../components/similarTracks"
import { getTotalTracksTime, setPageTitle } from "../../utils/helper"

//graphql imports
import { useLazyQueryHook, useQueryHook } from "../../graphql/hooks"
import {
  GET_TRACK,
  GET_TRACK_BY_SLUG,
  IS_USER_FAVOURITE_TRACK
} from "../../graphql/queries/track"
import { message } from "antd"
import { setPlayingSong } from "store/slice/player.slice"
import Bradcrumb from "components/Bradcrumb"
import { useHistory } from "react-router-dom"
import { useState } from "react"
import { useRef } from "react"
import { getItem, setItem, STORAGE_KEYS } from "utils/local-storage"
import moment from "moment"
import { useContext } from "react"
import { UserContext } from "contexts/userContext"

const TrackSingle = ({ page }) => {
  let location = useLocation()
  let history = useHistory()

  // mix panel events
  // userMixPanelEvents("click_through_rate", {
  //   source: sectiontitle,
  //   platform: "web",
  //   name: id
  // }

  const userData = useContext(UserContext)

  const [pathname, setPathname] = useState("")

  const [playlistId, setplaylistId] = useState("")
  const [pageName, setPageName] = useState("")
  const [routerData, setRouterData] = useState("")
  // console.log("routerData", routerData)

  useEffect(() => {
    if (location.state) {
      setPathname(location.state.pathname)
      setplaylistId(location.state.playlistId)
      setPageName(location.state.pagename ? location.state.pagename : "")
      setRouterData(location.state)
    }
  }, [location])

  const [id, setId] = useState(null)

  const { slug } = useParams()
  const {
    func: trackFunc,
    loading: trackLoading,
    data: trackData,
    error: trackError
  } = useLazyQueryHook({ query: GET_TRACK_BY_SLUG })
  
  const {
    func: trackFavFunc,
    loading: trackFavLoading,
    data: trackFavData,
    error: trackFavError
  } = useLazyQueryHook({
    query: IS_USER_FAVOURITE_TRACK
  })

  const today = Math.floor(new Date())

  useEffect(() => {
    if (slug && slug !== "" && Object.keys(userData).length) {
      const variables = {
        slug: slug
      }
      trackFunc({ variables })
    }
  }, [slug, userData])

  useEffect(() => {
    if (trackData && trackData.getTrackBySlug && trackData.getTrackBySlug.id) {
      if (
        userData.subscriptionIos === "free" &&
        (trackData.getTrackBySlug.trackType === "PREMIUM" ||
          trackData.getTrackBySlug.trackType === "premium" ||
          trackData.getTrackBySlug.trackType === "PAID" ||
          trackData.getTrackBySlug.trackType === "paid" ||
          trackData.getTrackBySlug.trackType === "TRIAL" ||
          trackData.getTrackBySlug.trackType === "trial")
      ) {
        return history.push("/subscription")
      } else if (
        userData.subscriptionIos &&
        (trackData.getTrackBySlug.trackType === "PREMIUM" ||
          trackData.getTrackBySlug.trackType === "premium" ||
          trackData.getTrackBySlug.trackType === "PAID" ||
          trackData.getTrackBySlug.trackType === "paid" ||
          trackData.getTrackBySlug.trackType === "TRIAL" ||
          trackData.getTrackBySlug.trackType === "trial") &&
        moment(today) <= moment(Math.floor(userData.subscriptionGraceDate))
      ) {
        return history.push("/subscription")
      }
      setId(trackData.getTrackBySlug.id)
      const variables = {
        trackId: trackData.getTrackBySlug.id
      }
      trackFavFunc({ variables })
    }
  }, [trackData])

  useEffect(() => {
    if (!trackLoading) {
      if (trackError) {
        message.error(trackError.message || trackError)
      }
    }
  }, [trackLoading, trackError])

  const getLinkurl = (pathname) => {
    if (
      pathname === "favorite-tracks" ||
      ("recently-played" && pageName === "")
    ) {
      return `/${pathname}`
    } else if (pageName !== " " && pageName === "playlist") {
      return `/my-playlist/${pathname}`
    } else {
      return `/${pathname}`
    }
  }
  // breadcrumbs links

  const linkItem = [
    {
      linkName: "Home",
      linkUrl: "/"
    },
    {
      linkName: pathname ? pathname : "",
      linkUrl: getLinkurl(pathname),
      id: playlistId
    },
    {
      linkName: trackData?.getTrackBySlug?.title,
      linkUrl: `/track/${trackData?.getTrackBySlug?.slug}`,
      id: id,
      disable: true
    }
  ]

  useEffect(() => {
    if (trackData) {
      // console.log("trackdata id",trackData.getTrackBySlug.id)
      // const previousData=getItem(STORAGE_KEYS.SINGLE_TRACK)
      // if(!previousData==""){
      // localStorage.clear(STORAGE_KEYS.SINGLE_TRACK);
      setItem(STORAGE_KEYS.SINGLE_TRACK, trackData.getTrackBySlug.id)
      // }
      // else{
      //   setItem(STORAGE_KEYS.SINGLE_TRACK, trackData.getTrackBySlug.id)
      // }
    }
  }, [trackData])

  return (
    <>
      {trackLoading ? (
        <Preloader />
      ) : (
        <>
          {trackData && trackData.getTrackBySlug ? (
            <>
              <section className="dash-section">
                <div className="bradcrumb-container">
                  <Bradcrumb linkItem={linkItem} />
                </div>
                <Cardbanner
                  img={
                    trackData.getTrackBySlug.storageKeys.azure &&
                    trackData.getTrackBySlug.storageKeys.azure.image
                      ? trackData.getTrackBySlug.storageKeys.azure.image
                      : Cimg1
                  }
                  title={trackData.getTrackBySlug.title || ""}
                  text={trackData.getTrackBySlug.description}
                  duration={
                    getTotalTracksTime(trackData.getTrackBySlug.duration) || 0
                  }
                  favourite={trackFavData?.isUserFavoriteTrack}
                  loadingFav={trackFavLoading}
                  track={[trackData.getTrackBySlug]}
                  routerData={routerData}
                />
                {trackData.getTrackBySlug.description ? (
                  <div
                    className="single-track-description"
                    style={{ marginTop: "20px" }}
                  >
                    <h2>Track Description</h2>

                    <p
                      className="banner-subtext"
                      dangerouslySetInnerHTML={{
                        __html: trackData.getTrackBySlug.description
                      }}
                    ></p>
                  </div>
                ) : (
                  ""
                )}
              </section>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  )
}

export default TrackSingle
