import AuthLayout from "../layout/Auth"
import PrivateLayout from "../layout/App"
import PublicLayout from "../layout"

//images
import Signupimg from "../assets/images/signup-img.png"
import Loginimg from "../assets/images/login-img.png"
import MaiSent from "../assets/images/maiSent.png"
import melo from "../assets/images/onBoard-1.png"

import VerificationAccount from "../assets/images/verifyAccount.png"

import ForgotPW from "../assets/images/forgotPW.png"

// Public components
import LoginComponent from "../pages/Login"
import SignUpComponent from "../pages/Signup"
import ForgotPasswordComponent from "../pages/Forgot-Password"
import ResetPasswordComponent from "../pages/Reset-Success"

// protected components
import Dashboard from "../pages/Dashboard"
import Discover from "../pages/Discover"
import PlaylistDetail from "../pages/Playlist/_details"
import Playlists from "../pages/Playlist"
import Favoritetracks from "../pages/Favourite-Tracks"
import Recentlyplayed from "../pages/Recently-Played"
import ChooseGenre from "../pages/Choose-Genre"
import TrackSingle from "../pages/Track/_id"
import Profile from "../pages/Profile"
import Subscription from "../pages/subscription"
import Payment from "../pages/payment"
import Settings from "../pages/settings"
import Tags from "../pages/tags"
import NewReleases from "../pages/New-Releases"
import PremiumTracks from "../pages/Premium-Tracks"
import PopularTracks from "../pages/Popular-Tracks"
import RecentTracks from "../pages/Recent-Tracks"
import Recommended from "../pages/Recommended"

import TagGroupSingle from "../pages/Tag-group/id"
import UserPlaylistDetail from "../pages/Playlist/User-Playlist/_id"
import GuestLayout from "../layout/Guest"

import Search from "../pages/Search"
import ChooseCommitment from "pages/Choose-Commitment"
import ForgotPasswordLayout from "layout/ForgetPassword"
import ResetStatus from "pages/Reset-status"
import VerifyAccountCom from "pages/Verify-Account"
import SubscriptionSuccessCom from "pages/Subscription-Success"
import SessionPage from "pages/Sessions-Tracks"
import Subscribed from "pages/subscribed"
import Category from "pages/Category"
import CategoryTracks from "pages/CategoryTracks"
import SubscriptionLayout from "layout/Subscription"
import Offfer from "pages/Offer"
import OfferLayout from "layout/offer"
import RecommendedList from "pages/RecommendedList"
import OfferPayment from "../pages/Offer-Payments"
import AlgoliaRecommendation from "../pages/AlgoliaRecommendation"
import RecentlyplayedDetail from "pages/RecentlyPlayedDetail"
import RecommendedPage from "../pages/Recommended"

const Routes = [
  {
    path: "/login",
    layout: AuthLayout,
    component: LoginComponent,
    image: Loginimg
  },

  {
    path: "/offer",
    layout: OfferLayout,
    component: Offfer
  },

  {
    path: "/signup",
    layout: AuthLayout,
    component: SignUpComponent,
    image: Signupimg
  },

  {
    path: "/forgot-password",
    layout: ForgotPasswordLayout,
    component: ForgotPasswordComponent,
    image: ForgotPW
  },

  {
    path: "/resend-verification",
    layout: ForgotPasswordLayout,
    component: VerifyAccountCom,
    image: VerificationAccount
  },

  {
    path: "/reset-success",
    layout: ForgotPasswordLayout,
    component: ResetPasswordComponent,
    image: MaiSent
  },

  {
    path: "/reset-status",
    layout: ForgotPasswordLayout,
    component: ResetStatus,
    image: MaiSent
  },

  // guest
  {
    path: "/guest",
    layout: GuestLayout,
    component: Dashboard
  },

  //app

  {
    path: "/subscribed",
    layout: ForgotPasswordLayout,
    component: Subscribed,
    image: melo
  },

  {
    path: "/",
    layout: PrivateLayout,
    component: Dashboard
  },

  {
    path: "/explore",
    layout: GuestLayout,
    component: Discover
  },

  {
    path: "/favorite-tracks",
    layout: PrivateLayout,
    component: Favoritetracks
  },

  {
    path: "/recently-played",
    layout: PrivateLayout,
    component: Recentlyplayed
  },

  // {
  //   path: "/playlist",
  //   layout: PrivateLayout,
  //   component: Playlists
  // },

  {
    path: "/playlist/:slug",
    layout: PrivateLayout,
    component: PlaylistDetail
  },

  {
    path: "/my-playlist/:slug",
    layout: PrivateLayout,
    component: UserPlaylistDetail
  },

  {
    path: "/choose-tags",
    layout: PublicLayout,
    component: ChooseGenre
  },
  {
    path: "/choose-commitments",
    layout: PublicLayout,
    component: ChooseCommitment
  },

  {
    path: "/track/:slug",
    layout: PrivateLayout,
    component: TrackSingle
  },

  {
    path: "/profile",
    layout: PrivateLayout,
    component: Settings
  },
  {
    path: "/subscription",
    layout: PublicLayout,
    component: Subscription
  },
  {
    path: "/subscription-success",
    layout: ForgotPasswordLayout,
    component: SubscriptionSuccessCom,
    image: VerificationAccount
  },
  {
    path: "/subscription/:planId",
    layout: SubscriptionLayout,
    component: Payment
  },
  // {
  //   path: '/settings',
  //   layout: PrivateLayout,
  //   component: Settings,
  // },
  {
    path: "/tag-groups/:id",
    layout: GuestLayout,
    component: TagGroupSingle
  },
  {
    path: "/search",
    layout: PrivateLayout,
    component: Search
  },
  {
    path: "/tags/:id",
    layout: PrivateLayout,
    component: Tags
  },
  {
    path: "/new-releases",
    layout: PrivateLayout,
    component: NewReleases
  },
  {
    path: "/premium-tracks",
    layout: PrivateLayout,
    component: PremiumTracks
  },
  {
    path: "/popular-tracks",
    layout: PrivateLayout,
    component: PopularTracks
  },
  {
    path: "/sessions-tracks/:slug",
    layout: PrivateLayout,
    component: SessionPage
  },
  {
    path: "/recently-played-tracks",
    layout: PrivateLayout,
    component: RecentTracks
  },
  {
    path: "/recommended",
    layout: PrivateLayout,
    component: Recommended
  },
  {
    path: "/algoliaRecommendation/:tagName",
    layout: PrivateLayout,
    component: AlgoliaRecommendation
  },
  {
    path: "/recently-played-detail",
    layout: PrivateLayout,
    component: RecentlyplayedDetail
  },
  {
    path: "/recommendedlist/:title",
    layout: PrivateLayout,
    component: RecommendedPage
  },
  {
    path: "/recommendedlist",
    layout: PrivateLayout,
    component: RecommendedPage
  },

  {
    path: "/:slug",
    layout: PrivateLayout,
    component: Category
  },
  {
    path: "/category-tracks/:catId",
    layout: PrivateLayout,
    component: CategoryTracks
  },

  {
    path: "/payment",
    layout: PublicLayout,
    component: OfferPayment
  },
  {
    path: "/payment/:planId",
    layout: SubscriptionLayout,
    component: OfferPayment
  }
]

export default Routes
