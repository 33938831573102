import React from "react"

import { Row } from "antd"

const Rowcardlist = ({ cardlist: Cardlist }) => {
  return (
    <Row gutter={[30, 30]} className="row-card-list">
      {Cardlist}
    </Row>
  )
}

export default Rowcardlist
