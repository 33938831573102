import React, { useState, useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Dropdown, Modal, Menu, message, Spin, Row, Col } from "antd"
import Lock from "../../assets/images/Lock.png"
import { LockOutlined } from "@ant-design/icons"
// import {ReactComponent as RemoveIcon }   from "../../assets/images/Remove-icon-new.svg"
import RemoveIcon from "../../assets/images/Remove-icon-new.svg"
import RemoveIconHover from "../../assets/images/Remove-Icon-hover.svg"
import moment from "moment"
import PremiumMessageModal from "components/PremiumMessageModal"
import { UserContext } from "contexts/userContext"
import useSendEvents from "functions/useSendEvents"
import "./styles.scss"
import { useLazyQueryHook, useMutationHook } from "graphql/hooks"
import {
  GET_FAVOURITES_TRACKS,
  IS_USER_FAVOURITE_TRACK,
  REMOVE_TRACK_TO_FAVOURITE
} from "graphql/queries/track"
import {
  GET_PLAYLIST_BY_ID,
  GET_TRACKS_OF_PLAYLIST,
  REMOVE_TRACK_TO_PLAYLIST
} from "graphql/queries/playlist"

const Songlistitem = ({
  handleRemoveFunc,
  isPlaylist,
  thumbnail: Thumbnail,
  title: Title,
  category: Category,
  duration: Duration,
  index: Index,
  track,
  lock,
  playlistIdPage,
  page,
  id: PlaylistId,
  trackIdInd,
  pathname,
  premium,
  query,
  userFilterAfterSearch,
  filterDuration,
  trackFilterType,
  searchQueryId,
  objectId,
  positionIndex,
  loadingafterfetch,
  categoriesFilterList
}) => {
  // console.log(
  //   "user filter after search from track list component",
  //   userFilterAfterSearch
  // )
  console.log("playlistIdPage $ id", playlistIdPage, PlaylistId)
  // state for page
  const [sendEvents] = useSendEvents()
  const [removeIcon, setRemoveIcon] = useState(RemoveIcon)
  const [isPremiumModalOpen, setIsPremiumModalOpen] = useState(false)
  const trackId = track?.length ? track[0].id : track ? track.id : ""
  const user = useContext(UserContext)
  const currentPath = window.location.pathname
  const isMyPlaySongsRoute =
    currentPath.includes("/my-playlist") ||
    currentPath.includes("/favorite-tracks")

  const {
    func: trackFavFunc,
    loading: trackFavLoading,
    data: trackFavData,
    error: trackFavError
  } = useLazyQueryHook({
    query: IS_USER_FAVOURITE_TRACK
  })
  useEffect(() => {
    const variables = {
      trackId: trackIdInd
    }
    if (trackIdInd && trackIdInd !== null) {
      trackFavFunc({ variables })
    }
  }, [trackIdInd])

  // // handle remove function
  const handleRemoveTrack = async () => {
    // console.log("playlistIdPage", playlistIdPage)
    const variables = { playlistId: playlistIdPage, trackId: track.id }
    await removeTrackFromPlaylistFunc({ variables }).then(() => {
      message.success("Track is successfully removed")
    })
  }

  const handleRemoveFavTrack = async () => {
    // const variables = { trackId: trackIdInd, trackId: track.id }
    const variables = { trackId: track.id }
    await removeTrackFromFavoriteFunc({ variables }).then(() => {
      message.success("Favorite track is successfully removed")
    })
  }

  const {
    func: removeTrackFromPlaylistFunc,
    loading: removeTrackFromPlaylistLoading,
    data: removeTrackFromPlaylistData,
    error: removeTrackFromPlaylistError
  } = useMutationHook({
    query: REMOVE_TRACK_TO_PLAYLIST,
    refetchQueries: [
      {
        query: GET_TRACKS_OF_PLAYLIST,
        variables: {
          page: 1,
          limit: 10,
          playlistId: playlistIdPage
        }
      },
      {
        query: GET_PLAYLIST_BY_ID,
        variables: {
          playlistId: playlistIdPage
        }
      }
    ]
  })

  const {
    func: removeTrackFromFavoriteFunc,
    loading: removeTrackFromFavoriteLoading,
    data: removeTrackFromFavoriteData,
    error: removeTrackFromFavoriteError
  } = useMutationHook({
    query: REMOVE_TRACK_TO_FAVOURITE,
    refetchQueries: [
      {
        query: GET_FAVOURITES_TRACKS,
        variables: {
          options: {
            page: 1,
            limit: 10
          }
        }
      }
    ]
  })
  const removeFunction = () => {
    if (currentPath === "/favorite-tracks") {
      handleRemoveFavTrack()
    } else {
      handleRemoveTrack()
    }
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleRemoveFunc(trackIdInd)}>
        {loadingafterfetch ? <Spin size="small" /> : " "} Remove track from list
      </Menu.Item>
    </Menu>
  )

  let history = useHistory()
  const RedireactFun = () => {
    history.push("/subscription")
  }

  const confirm = () => {
    Modal.confirm({
      // title: 'Confirm',
      icon: "",
      content:
        "You cannot access to this songs because your subscription has been expired. Please renew your subscription to gain access to all your playlists.",
      okText: "Renew Subscription",
      cancelText: "Cancel",
      onOk: () => {
        RedireactFun()
      }
    })
  }

  // console.log("tack filter type", trackFilterType)

  const today = Math.floor(new Date())
  const subscriptionLink = "/subscription"
  const Cardlink = `/track/${
    track?.length ? track[0].slug : track ? track.slug : ""
  }`

  const toInnerPage = async (id) => {
    let userEvents = {
      userSearchEvent: true,
      keyword: query,
      positionIndex: positionIndex,
      searchQueryId: searchQueryId,
      eventComponent: "click",
      id: objectId
    }
    await sendEvents(userEvents)

    if (
      userFilterAfterSearch?.categoriesFilterList ||
      userFilterAfterSearch?.filterDuration ||
      userFilterAfterSearch?.trackFilterType
    ) {
      let newUserEvents = {
        ...userFilterAfterSearch,
        duration: Duration,
        id: objectId
      }
      await sendEvents(newUserEvents)
    }

    setTimeout(() => {
      history.push({
        pathname: getTrackRoute(),
        state: {
          id: id,
          pathname: pathname,
          pagename: page ? page : "",
          userSearchEvent: true,
          keyword: query,
          filterDuration: filterDuration,
          userFilterAfterSearch: userFilterAfterSearch,
          trackFilterType: trackFilterType,
          searchQueryId: searchQueryId,
          positionIndex: positionIndex
          // categoriesFilterList: categoriesFilterList
        }
      })
    }, 100)
  }

  const getTrackRoute = () => {
    if (premium) {
      if (
        user.subscriptionIos === "paid" ||
        (user.subscriptionIos === "trial" &&
          moment(today) <=
            moment(
              user.subscriptionGraceDate
                ? user.subscriptionGraceDate
                : new Date()
            ))
      ) {
        return Cardlink
      } else {
        return subscriptionLink
      }
    } else {
      return Cardlink
    }
  }

  return (
    <>
      <div className="row-song-list">
        <div className="list-index">{Index}</div>
        <div className="list-title" onClick={() => toInnerPage(trackId)}>
          {premium && user?.subscriptionIos === "free" ? (
            <div className="card-premium">
              <LockOutlined />
            </div>
          ) : (
            ""
          )}

          {/* {premium && ""} */}

          {Thumbnail ? (
            <div className="song-img">
              <img src={Thumbnail} alt="" height="100%" width="100%" />
            </div>
          ) : (
            <div className="song-locked-img">
              <img src={Lock} alt="" />
            </div>
          )}
          <div className="song-title" onClick={lock ? lock : ""}>
            <span>{Title}</span>
          </div>
        </div>

        <div className="list-duration">{Duration}</div>

        {isMyPlaySongsRoute == true && (
          <div className="action-div">
            <div className="action-delete-btn">
              <Dropdown overlay={menu} trigger={["click"]}>
                <div className="" style={{ marginBottom: "10px" }}>
                  <img
                    src={removeIcon}
                    alt="remove-icon"
                    onMouseEnter={() => {
                      setRemoveIcon(RemoveIconHover)
                    }}
                    onMouseOut={() => {
                      setRemoveIcon(RemoveIcon)
                    }}
                    height="100%"
                    width="100%"
                  />
                </div>
              </Dropdown>
            </div>
            {/* ) : (
              ""
            )} */}
          </div>
        )}
      </div>
      <PremiumMessageModal
        isPremiumModalOpen={isPremiumModalOpen}
        setIsPremiumModalOpen={setIsPremiumModalOpen}
      />
    </>
  )
}

export default Songlistitem
