import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "../../src/index"
import _ from "lodash"
import Bradcrumb from "./Bradcrumb"
import { useLocation } from "react-router-dom"


const SectionSlider = (props) => {
  const analytics = getAnalytics(app)
  // breadcrumbs links
  const location = useLocation();
  const linkItem = [
    {
      linkName: "Home",
      linkUrl: "/"
    },

    {
      linkName: props.title,
      linkUrl: props.link
    }
  ]
  return (
    <>
      <section className="dash-section">
        {props.moreLink !== "" && props.showBreadcrumb ? (
          <Bradcrumb linkItem={linkItem} />
        ) : (
          ""
        )}
        <div className="section-header">
          <h2 className="header-primary">{props.title}</h2>
          <div className="nav-link">
            {props.moreLink ? (

              <Link
                to={{pathname:props.moreLink, state:{id:props?.state?.tagId?props.state.tagId:"",tagName:props?.state?.tagName?props?.state?.tagName:""}}}
                className="btn-link"
                onClick={() => {
                  logEvent(
                    analytics,
                    `see_all_click - ${_.lowerCase(props?.title)}`
                  )
                }}
              >
                {location.pathname === "/recently-played" ? "" : "See All"}
                {/* See All */}
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
        <p
          className="section-description"
          dangerouslySetInnerHTML={{ __html: props.description }}
        ></p>
        <div className="row row--slider">{props.children}</div>
      </section>

    </>
  )
}

export default SectionSlider
