import { Col, Row } from "antd"
import React from "react"

const Cardsonglist = ({ songlist: Songlist }) => {
  const layout = {
    full: {
      xxl: { span: 24 },
      xl: { span: 24 },
      lg: { span: 24 },
      md: { span: 24 },
      sm: { span: 24 },
      xs: { span: 24 }
    }
  }
  const currentPath = window.location.pathname
  const isMyPlaySongsRoute =
    currentPath.includes("/my-playlist") ||
    currentPath.includes("/favorite-tracks")
  return (
    <>
      <div className="card-song-list">
        {/* row-song-list card-list-header */}
        <Row>
          <Col {...layout.full}>
            <div className="row-song-list card-list-header">
              <div className="list-index">
                <h3>#</h3>
              </div>
              <div className="list-title">
                <h3>Track</h3>
              </div>

              {/* <div className="list-category" style={{width:"10%"}}>


            <h3>Category</h3>
          </div>  */}
              {/* <div className="list-fav">
            <h3>Add to Favorite</h3>
          </div> */}
              <div className="list-duration">
                <h3>Duration</h3>
              </div>

              {isMyPlaySongsRoute == true && (
                <div className="list-action">
                  <h3>Action</h3>
                </div>
              )}
              {/* <div className="list-more"></div> */}
            </div>
          </Col>
        </Row>

        <Row>
          <Col {...layout.full}>
            <div>{Songlist}</div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Cardsonglist
