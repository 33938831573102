import React from "react"
import { useSelector } from "react-redux"
import { Redirect, useLocation, useRouteMatch } from "react-router-dom"
import { getCurrentSession } from "utils/helper"

const Layout = ({ children }) => {
  const { path, params } = useRouteMatch()
  const { pathname, search } = useLocation()

  if (getCurrentSession().authenticated) {
    return <>{children}</>
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { path, pathname, params, search }
        }}
      />
    )
  }
}

export default Layout
