export const CREATE_PLAYLIST = `
mutation ($description: String!, $name: String!, $pic: String){
    createPlaylist(createPlaylistInput: {
        description: $description
        name: $name
        pic: $pic
      }){
        id
        playlistName
      }
}
`

export const GET_PLAYLISTS = `
  query ($page: Int, $limit: Int) {
    getPlaylists(
        paginationInput: { page:$page, limit:$limit}
    ){
        count
        playlist{
            id
            playlistName
            description
            storageKey
            updatedDTM
            createdDTM
            slug

        }
    }
  }
`
export const GET_PLAYLIST_BY_ID = `
  query ($playlistId: String!) {
    getPlaylistById(
       playlistId:$playlistId
    ){    
        id
        playlistName
        description
        tracksCount
        storageKey 
        slug
    }
  }
`

export const GET_TRACKS_OF_PLAYLIST = `
  query ($page: Int, $limit: Int, $playlistId:String!) {
    getTracksOfPlaylist(
        paginationInput: { page:$page, limit:$limit}, playlistId: $playlistId
    ){
        count
        playlistTracks{
            id
            playlist{
                id
                playlistName
                storageKey
            }
            
            track{
                id
                title
                slug
                storageKeys{azure{image,menifestFileUrl}}
                category{
                  categoryName
                }
                duration
            }
        }
    }
  }
`

export const ADD_TRACK_TO_PLAYLIST = `
mutation ($playlistId: String!, $trackId: String!){
  addTrackInPlaylist(addTrackInPlaylistInput: {
        playlistId: $playlistId
        trackId: $trackId


      }){
        id
       playlist{
        playlistName

       }

      }
}
`

export const REMOVE_TRACK_TO_PLAYLIST = `
mutation ($playlistId: String!, $trackId: String!){
  removeTrackOfPlaylist(playlistId: $playlistId, trackId: $trackId)
}
`

// admin's playlist
export const GET_SESSIONS = `
  query ($page: Int, $limit: Int) {
    getSessions(
        paginationInput: { page:$page, limit:$limit}
    ){
        count
        session{
            id
            name
            image
           slug
           description

        }
        
     
    }
  }
`

export const GET_SESSION_BY_ID = `
  query ($sessionId: String!) {
    getSessionById(
       sessionId:$sessionId
    ){    
        id
        name
        description
        tracksCount
        image
    }
  }
`

export const GET_TRACKS_OF_SESSIONS = `
  query ($page: Int, $limit: Int, $sessionId:String!) {
    getSessionTracks(
        paginationInput: { page:$page, limit:$limit}, sessionId: $sessionId
    ){
        sessionTrack{
            id
            session{
                id
                name
                image
                description


            }
            track{
                id
                title
                slug
                category{
                  categoryName
                }
                storageKeys{azure{image,menifestFileUrl}}
                trackType
                duration
            }

        

        }
        meta{
          limit
          page
          total
          total_items
          total_pages
        }
    
    }
  }
`

// remove playlist
export const REMOVE_PLAYLIST = `
mutation ($playlistId: String!){
  removePlaylist(playlistId: $playlistId)
}
`

// update playlist
export const UPDATE_PLAYLIST_BY_ID = `
mutation($id:String! , $name: String!, $description: String){
  updatePlaylistById(updatePlaylistByIdInput: {id:$id , name: $name, description: $description}){
    id
    playlistName
  }
}
`
// get playlist by slug
export const GET_PLAYLIST_BY_SLUG = `
query ($playlistSlug: String!) {
  getPlaylistBySlug(
    playlistSlug:$playlistSlug
  ){    
    id
        playlistName
        description
        tracksCount
        storageKey
        slug
  }
}
`
// get session by slug
export const GET_SESSION_BY_SLUG = `
query ($sessionSlug: String!) {
  getSessionBySlug(
    sessionSlug:$sessionSlug
  ){    
    id
        name
        slug
  }
}
`
