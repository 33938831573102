import React from "react"

import cimg from "../../assets/images/Thumbnail.png"

const CardRound = ({
  img: ImgSrc,
  title: Title,
  layout: Layout,
  selected,
  selectedTagGroups
}) => {
  // ${
  //   selectedTagGroups?.length > 2 ? "disabled-card" : ""
  // }

  return (
    <>
      <div
        className={`card-round ${
          Layout === "title-center"
            ? selectedTagGroups?.length > 2 && !selected
              ? "disabled-card"
              : "card-round--title-center"
            : ""
        } ${selected ? "card-selected" : ""} `}
      >
        <div className={`image-wrapper`}>
          <img src={ImgSrc || cimg} alt="" />
        </div>
        <h4>{Title}</h4>
      </div>
    </>
  )
}

export default CardRound