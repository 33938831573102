import Mixpanel from "mixpanel-browser"
async function userMixPanelEvents(eventName, mixpanelEventsProperty) {
    Mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)
    let mixpanelEventsPropertyDe = {
        platform: "web"
    }

    // console.log("eventName from events", eventName, "mixpanelEventsProperty from events", mixpanelEventsProperty)
    Mixpanel.track(eventName, { ...mixpanelEventsPropertyDe, ...mixpanelEventsProperty });
    return;
}

export default userMixPanelEvents