import React from "react"
import Logo from "../../../assets/images/Brand-Logo.png"

import "./styles.scss"

const OfferHeader = () => {
  return (
    <div className="header-container">
      <div className="header-container_logo-wrapper">
        <img src={Logo} alt="logo" />
      </div>
    </div>
  )
}

export default OfferHeader
