import React, { useState, useEffect } from "react"
import { setPageTitle } from "../../utils/helper"
import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "../../index"
import _ from "lodash"
// graphql imports
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import userMixPanelEvents from "functions/mixPanelEvents"
import Recentlyplayed from "pages/Recently-Played"
import NewReleased from "pages/NewRelease"
import RecommendedComponent from "components/Recommended"
import SessionComponent from "components/Session"

const Dashboard = () => {
  const analytics = getAnalytics(app)
  // set store
  const [limit, setLimit] = useState(15)
  const [currentPage, setcurrentPage] = useState(1)

  const [token, setToken] = useState("")
  useEffect(() => {
    // @ts-ignore
    let meStorageValueNew = getItem(STORAGE_KEYS.USER)

    if (meStorageValueNew !== "") {
      // @ts-ignore
      setToken(token)
      setToken(token)
    }
  }, [])

  useEffect(() => {
    setPageTitle("Binaural Beats for Study,Sleep,Relax.")
    logEvent(analytics, "screen_view", {
      firebase_screen: "Dashboard"
      // firebase_screen_class: screenClass
    })
    userMixPanelEvents("screen_view", { screen: "home" })
  }, [])

  return (
    <>
      <Recentlyplayed />
      <RecommendedComponent />
      <SessionComponent />
      <NewReleased />
    </>
  )
}

export default Dashboard
