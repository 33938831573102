// export const API_URL = process.env.REACT_APP_API_URL + '/api/v2/'
export const API_URL = process.env.REACT_APP_API_URL

export const ITEM_PER_PAGE = 10
export const PLAY_HISTORY_ANALYTIC_TIME = 4 // no of seconds until song history is set
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
// SOCIAL LOGIN API KEYS
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
