import { useLazyQueryHook } from "graphql/hooks"
import { SEND_USER_EVENTS } from "graphql/queries/search"

let preDefinedAlgoDuration = [
  "3600.039002",
  "273",
  "913",
  "3600.090998",
  "3615.216009"
]

let eventName = {
  afterSearchEvent: {
    favorite: "ADDED_TO_FAVORITE_AFTER_SEARCH",
    playlist: "ADDED_TO_PLAYLIST_AFTER_SEARCH",
    timer: "USED_PAID_TIMER_AFTER_SEARCH",
    engagement: "ENGAGEMENT_WITH_CONTENT_AFTER_SEARCH",
    click: "CLICK_ON_RESULT_AFTER_SEARCH",
  },
  afterFilterEvent: {
    favorite: "ADDED_TO_FAVORITE_AFTER_FILTER",
    playlist: "ADDED_TO_PLAYLIST_AFTER_FILTER",
    timer: "USED_PAID_TIMER_AFTER_FILTER",
    filter: "FILTER_APPLIED"
  },
  singleEvent: {
    favorite: "ADDED_TO_FAVORITE",
    playlist: "ADDED_TO_PLAYLIST",
    timer: "USED_PAID_TIMER",
    engagement: "ENGAGEMENT_WITH_CONTENT"
  }
}

function useSendEvents() {
  const {
    func: sendUserEvents,
    loading: userEventLoading,
    data: userEventData,
    error: userEventError
  } = useLazyQueryHook({
    query: SEND_USER_EVENTS,
    fetchPolicy: 'network-only',

  })
  const sendEvents = async (eventState) => {
    // console.log("eventState from userevent fucntion", eventState)
    let name = eventState.eventComponent
    let variables = {
      queryId: eventState.searchQueryId,
      objectId: eventState.id,
      position: eventState.positionIndex

    }
    // click event for click after search
    if (name === "click") {
      variables = {
        ...variables,
        eventName: eventName.afterSearchEvent[name]

      }
      // await sendUserEvents({ variables })

    }
    // favorite events
    if (name === "favorite") {
      if (
        eventState.searchQueryId &&
        eventState.id &&
        eventState.positionIndex) {
        if (eventState.userFilterAfterSearch && (eventState.userFilterAfterSearch.filterDuration ||
          eventState.userFilterAfterSearch.categoriesFilterList || eventState.userFilterAfterSearch.trackFilterType)) {
          let data = []
          if (eventState.userFilterAfterSearch.filterDuration) {
            data = [
              `duration:${eventState.userFilterAfterSearch.duration}`
            ]
          }
          if (eventState.userFilterAfterSearch.trackFilterType) {
            data = [...data, `trackType:${eventState.trackFilterType}`]
          }
          if (eventState.userFilterAfterSearch.categoriesFilterList) {
            data = [...data, `category.categoryName:${eventState.userFilterAfterSearch.categoriesFilterList}`]
          }
          variables = {
            ...variables,
            eventName: eventName.afterFilterEvent[name],
            filter: data
          }
          // console.log("variables added to favorite track aftwer fiter", variables)
          // await sendUserEvents({ variables })
        } else {
          variables = {
            ...variables,
            eventName: eventName.afterSearchEvent[name]

          }
          // await sendUserEvents({ variables })
        }
      } else {
        variables = {
          ...variables,
          eventName: eventName.singleEvent[name]

        }

      }
    }



    // search and filter event 
    if (name === "filter") {
      let data = []
      if (eventState.filterDuration) {
        data = [
          `duration:${eventState.duration}`
        ]
      }
      if (eventState.trackFilterType) {
        data = [...data, `trackType:${eventState.trackFilterType}`]
      }
      if (eventState.categoriesFilterList) {
        data = [...data, `category.categoryName:${eventState.categoriesFilterList}`]
      }
      variables = {
        ...variables,
        eventName: eventName.afterFilterEvent[name],
        filter: data

      }
    }
    // event added to playlist by users 
    if (name === "playlist") {
      if (
        eventState.searchQueryId &&
        eventState.id &&
        eventState.positionIndex
      ) {

        if (eventState.userFilterAfterSearch && (eventState.userFilterAfterSearch.filterDuration ||
          eventState.userFilterAfterSearch.categoriesFilterList || eventState.userFilterAfterSearch.trackFilterType)) {
          let data = []
          if (eventState.userFilterAfterSearch.filterDuration) {
            data = [
              `duration:${eventState.userFilterAfterSearch.duration}`
            ]
          }
          if (eventState.userFilterAfterSearch.trackFilterType) {
            data = [...data, `trackType:${eventState.userFilterAfterSearch.trackFilterType}`]
          }
          if (eventState.userFilterAfterSearch.categoriesFilterList) {
            data = [...data, `category.categoryName:${eventState.userFilterAfterSearch.categoriesFilterList}`]
          }
          variables = {
            ...variables,
            eventName: eventName.afterFilterEvent[name],
            filter: data
          }
          // console.log("variables added to favorite track aftwer fiter", variables)
          // await sendUserEvents({ variables })
        } else {
          variables = {
            ...variables,
            eventName: eventName.afterSearchEvent[name]

          }
          // await sendUserEvents({ variables })
        }
      } else {
        variables = {
          ...variables,
          eventName: eventName.singleEvent[name]

        }
      }

    }
    // engagement with content event

    // click event for click after search
    if (name === "engagement") {
      if (
        eventState.searchQueryId &&
        eventState.id &&
        eventState.positionIndex

      ) {
        variables = {
          ...variables,
          eventName: eventName.afterSearchEvent[name]

        }
      } else {
        variables = {
          ...variables,
          eventName: eventName.singleEvent[name]

        }
      }

    }
    //   // await sendUserEvents({ variables })

    // }

    // timer event 

    // click event for click after search
    if (name === "timer") {
      if (
        eventState.searchQueryId &&
        eventState.id &&
        eventState.positionIndex
      ) {
        variables = {
          ...variables,
          eventName: eventName.afterSearchEvent[name]

        }
        // await sendUserEvents({ variables })

      } else {
        variables = {
          ...variables,
          eventName: eventName.singleEvent[name]

        }
      }

    }


    await sendUserEvents({ variables })

  }

  return [sendEvents]
}

export default useSendEvents
