import React from "react"
import BrandLogo from "../../assets/images/Brand-Logo.png"
import CarouselAuth from "./carouselAuth"

const AuthLayout = (props) => {
  // <div className="public-layout">
  //     <div className="public-layout__left"></div>
  return (
    <div className="public-layout">
      <div className="public-layout__left">
         <img src={BrandLogo} alt="Logo" className="brand-logo" />
        {/* <img src={props.image} alt="" className="layout-img" /> */} 
        <CarouselAuth/>
      </div>
      <div className="public-layout__right">{props.children}</div>
    </div>
  )
}

export default AuthLayout
