import {
  Slider,
  Row,
  Col,
  Dropdown,
  Menu,
  Tooltip,
  message,
  Popconfirm
} from "antd"
import { useRef } from "react"
import { useState } from "react"
import {
  IoPlayCircleSharp,
  IoVolumeMediumOutline,
  IoPauseCircleSharp
} from "react-icons/io5"
import { BiStopwatch } from "react-icons/bi"
import ReactPlayer from "react-player"
import { useDispatch, useSelector } from "react-redux"
import {
  clearPlayer,
  playPause,
  setPlayingSong
} from "store/slice/player.slice"

import {
  getCurrentSession,
  getFormattedTime,
  getTotalTracksTime
} from "../../utils/helper"

import history from "utils/history"

import "./index.scss"
import { useEffect } from "react"
import moment, { duration } from "moment"
import { setVolume } from "services/player.service"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import timer from "constants/timer"
import { Link, useLocation } from "react-router-dom"
import { FiLock } from "react-icons/fi"
import { useMutationHook } from "graphql/hooks"
import {
  GET_RECENTLY_PLAYED_TRACKS,
  RECENTLY_PLAYED_TRACK_MUSIC,
  STORE_PLAY_HISTORY_INPUT,
  UPDATE_TRACK_COUNT
} from "graphql/queries/track"
import { CloseCircleOutlined } from "@ant-design/icons"
import useSendEvents from "functions/useSendEvents"
import userMixPanelEvents from "functions/mixPanelEvents"

const MusicPlayer = () => {
  const ref = useRef(null)
  const dispatch = useDispatch()

  const user = getItem(STORAGE_KEYS.USER)
  const authenticated = () => getCurrentSession().authenticated
  const player = useSelector((state) => state.player)

  let { state: routeState } = useLocation()

  // console.log("user user data from, timer", user)

  const [sendEvents] = useSendEvents()

  const { isPlaying, playingSong, volume, activeSongId, activeSongIndex } =
    player
  // const {
  //   func: recentlyPlayFunc,
  //   loading: recentlyPlayLoading,
  //   error: recentlyPlayError,
  //   data: recentlyPlayData
  // } = useMutationHook({
  //   query: RECENTLY_PLAYED_TRACK_MUSIC,
  //   refetchQueries: [
  //     {
  //       query: GET_RECENTLY_PLAYED_TRACKS,
  //       variables: { getTracksDto: { page: 1, limit: 12, platform: "WEB" } }
  //     }
  //   ]
  // })

  // console.log("recentlyPlayData", recentlyPlayData)

  const {
    func: userPlayHistoryFunc,
    loading: userPlayHistoryLoading,
    error: userPlayHistoryError,
    data: userPlayHistoryData
  } = useMutationHook({
    query: STORE_PLAY_HISTORY_INPUT
  })

  const { func: updateTrackCount } = useMutationHook({
    query: UPDATE_TRACK_COUNT
  })

  const [playedProgress, setPlayedProgress] = useState(0)
  const [trackDuration, setTrackDuration] = useState(undefined)
  const [playTime, setPlayTime] = useState({
    title: "Default",
    time: 0
  })
  const [timerStartDuration, setTimerStartDuration] = useState()
  const [loop, setLoop] = useState(false)
  const [updateCount, setUpdateCount] = useState(false)

  const handlePlay = () => {
    dispatch(playPause(true))
  }

  const handlePause = (e) => {
    dispatch(playPause(false))
  }

  const volumeChange = (vol) => {
    dispatch(setVolume(vol))
  }

  const handleProgress = (val) => {
    setPlayedProgress(val.playedSeconds)
  }

  const handleDuration = (duration) => {
    setTrackDuration(duration)
  }

  // const onEnd = async (e) => {
  //   if (playingSong.length > 0) {
  //     dispatch(playPause(true))
  //   } else {
  //     dispatch(playPause(false))
  //   }
  // }

  const onEnd = async (e) => {
    const nextIndex = activeSongIndex + 1
    const nextSong = playingSong.find((_, i) => i === nextIndex)

    if (nextSong) {
      dispatch(setPlayingSong({ id: nextSong.id, index: nextIndex }))
    } else {
      if (playingSong.length > 1) {
        dispatch(setPlayingSong({ id: playingSong[0].id, index: 0 }))
      } else {
        dispatch(playPause(false))
        ref.current.seekTo(0, "seconds")
        dispatch(playPause(true))
      }
    }
  }

  const handleSeekChange = (value) => {
    let playProgressChange = Math.floor(parseFloat(value))
    setPlayedProgress(playProgressChange)
    ref.current.seekTo(playProgressChange)
  }

  const handleSetTimer = (value) => {
    let time = Math.floor(value?.time)
    setPlayTime({
      title: value.title ? value.title : "",
      time: value.time ? time : undefined
    })

    // console.log("duratio", value)
    // console.log("track name", playingSong[activeSongIndex].title)

    // send mix panel events
    const properties = {
      source: "track single page",
      "track name": playingSong[activeSongIndex].title,
      "track id": activeSongId,
      "song duration": value.time ? time : undefined
    }
    userMixPanelEvents("click_timer_rate", properties)

    setTimerStartDuration(0)
    if (user.subscriptionIos === "paid") {
      // console.log("user is paid and ", user.subscriptionIos)
      routeState = {
        ...routeState,
        eventComponent: "timer"
      }
      sendEvents(routeState)
    }
  }

  const closeMusicPlayer = (e) => {
    //call api
    const playedTime = getFormattedTime(playedProgress)
    userPlayHistoryFunc({
      variables: { trackId: activeSongId, duration: playedTime }
    })
    e.preventDefault()
    dispatch(clearPlayer())
    setPlayTime({
      title: "Default",
      time: 0
    })
  }

  const handleMediaPlayerClick = (e) => {
    e.preventDefault()
    history.push(`/track/${playingSong[activeSongIndex].slug}`)
  }

  useEffect(() => {
    if (activeSongId) {
      setPlayedProgress(0)
      setTrackDuration(0)
      setUpdateCount(false)
    }
  }, [activeSongId])

  useEffect(() => {
    if (!isPlaying && playTime.time) {
      setTimerStartDuration(timerStartDuration)
    }
  }, [isPlaying])

  useEffect(() => {
    if (playTime.title === "infinite") {
      setLoop(true)
    }
    if (playTime.time && isPlaying) {
      const id = setInterval(
        () => setTimerStartDuration((oldCount) => oldCount + 1),
        1000
      )

      return () => {
        clearInterval(id)
      }
    }
  })

  useEffect(() => {
    if (isPlaying) {
      const recentlyPlayedTrackTime = 1
      const trackDurations = getTotalTracksTime(trackDuration)

      if (
        Math.floor(playedProgress) === recentlyPlayedTrackTime &&
        !updateCount
      ) {
        let variables = {
          userPlayHistoryInput: {
            duration: getFormattedTime(playedProgress),
            trackId: activeSongId
          }
        }
        // recentlyPlayFunc({ variables }) //update store recent played

        updateTrackCount({
          //update track count  played
          variables: {
            trackId: activeSongId
          }
        }).then((res) => {
          if (res.data) {
            setUpdateCount(true)
          }
        })
      }
    }
    if (isPlaying && playTime.time) {
      if (timerStartDuration > playTime.time) {
        //check play time exceed
        message.warn("Player paused as it exceed playing time !")
        dispatch(playPause(false))
        setPlayTime({
          title: "Default",
          time: 0
        })
      }

      if (playTime.time) {
        if (Math.floor(playedProgress) + 1 === Math.floor(trackDuration)) {
          if (playTime.time > timerStartDuration) {
            setLoop(true) //setting loop true if play time does'nt exceed
            dispatch(playPause(true))
          }
        }
      }
    }
  }, [playedProgress])

  const menu = (
    <>
      <Menu defaultSelectedKeys={"Default"} className="gv-player-timer">
        {timer.map((time, index) => {
          let title = time.title
          if (
            user?.subscriptionIos === "free" &&
            title !== "Default" &&
            title !== "30 mins"
          ) {
            title = (
              <Tooltip
                placement="topLeft"
                title={
                  <Link
                    to={authenticated ? "/subscription" : "/login"}
                    className="tooltip-upgrade-btn"
                  >
                    {authenticated ? "Upgrade?" : "Login?"}
                  </Link>
                }
              >
                {title}
              </Tooltip>
            )
          }
          return (
            <Menu.Item
              key={index}
              className={playTime.title === time.title ? "active" : ""}
              onClick={() => {
                handleSetTimer(time)
                message.success(`Timer set to ${time.title}`)
              }}
              disabled={user?.subscriptionIos === "free" && time?.isPremium}
            >
              {user?.subscriptionIos === "free" && time?.isPremium ? (
                <FiLock className="lock-icon" />
              ) : (
                <></>
              )}
              {title}
            </Menu.Item>
          )
        })}
      </Menu>
    </>
  )

  if (playingSong) {
    let currentActiveSong =
      playingSong[activeSongIndex].storageKeys.azure.menifestFileUrl
    let currentActiveSongImage =
      playingSong[activeSongIndex].storageKeys.azure.image
    let currentActiveSongTitle = playingSong[activeSongIndex].title
    return (
      <div className="gv-player-wrapper" id="gv-player-wrapper">
        <ReactPlayer
          ref={ref}
          id="gv_player"
          className="gv_player"
          controls={false}
          light={false}
          url={currentActiveSong}
          playing={isPlaying}
          volume={volume}
          onEnded={onEnd}
          onProgress={handleProgress}
          onDuration={handleDuration}
          loop={loop}
        />
        <div className="gv-player--controls">
          <Slider
            value={playedProgress}
            className="gv-music-player-slider"
            onChange={handleSeekChange}
            min={0}
            max={trackDuration}
            tipFormatter={(value) => {
              return getFormattedTime(value)
            }}
          />
          <Row gutter={[8, 8]}>
            <Col xs={24} md={24} lg={10}>
              <div
                className="gv-song-details"
                onClick={(e) => handleMediaPlayerClick(e)}
              >
                <div className="song-img">
                  <div className="image">
                    <img src={currentActiveSongImage} alt="" />
                  </div>
                </div>
                <div className="song-title"> {currentActiveSongTitle} </div>
              </div>
            </Col>
            <Col xs={24} md={12} lg={4}>
              <div
                className="gv-player-control-btns"
                style={{ cursor: "pointer" }}
              >
                {isPlaying ? (
                  <div className="play-pause-btn" onClick={handlePause}>
                    <IoPauseCircleSharp />
                  </div>
                ) : (
                  <div className="play-pause-btn" onClick={handlePlay}>
                    <IoPlayCircleSharp />
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} md={12} lg={10}>
              <div className="gv-player-settings" style={{ cursor: "pointer" }}>
                <div className="gv-player-settings--timer">
                  <span>{getFormattedTime(playedProgress)}</span> /{" "}
                  {getFormattedTime(trackDuration)}
                </div>

                <div className="gv-player-settings--volume">
                  <div className="volume-icon">
                    <IoVolumeMediumOutline />
                  </div>
                  <Slider
                    className="volume-slider"
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={(vol) => volumeChange(vol)}
                    value={volume}
                    tipFormatter={(value) => {
                      return value * 100
                    }}
                    id="volumeID"
                  />
                </div>

                <div className="gv-player-settings--clock">
                  <Dropdown
                    overlay={menu}
                    placement="topCenter"
                    trigger={["click"]}
                    overlayClassName={"timer-set-menu"}
                  >
                    <BiStopwatch />
                  </Dropdown>
                  {playTime.title === "infinite" ? (
                    <div className="remaining-time">Infinite</div>
                  ) : playTime.time > 0 ? (
                    <div className="remaining-time">
                      {moment
                        .utc((playTime.time - timerStartDuration) * 1000)
                        .format("HH:mm:ss")}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="close-player-wrapper">
          <Popconfirm
            placement="topRight"
            title="Do you really want to close the music player?"
            onConfirm={(e) => closeMusicPlayer(e)}
            okText="Yes"
            cancelText="No"
          >
            <CloseCircleOutlined
              title="Close Music Player"
              style={{
                color: "red",
                fontSize: 18
              }}
            />
          </Popconfirm>
        </div>
      </div>
    )
  } else {
    return ""
  }
}

export default MusicPlayer
