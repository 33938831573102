export const GET_CATEGORIES = `
  query ($page: Int, $limit: Int, $publishStatus: Boolean) {
    getCategories(
        searchAndFilterArgs: { page:$page, limit:$limit, publishStatus:$publishStatus}
    ){
        categories{
            id
            categoryName
            slug
            publishStatus
        }
    }
  }
`

export const GET_CATEGORY_BY_ID = `
  query ($id: String!) {
    getCategoryByCatId(
        id:$id
    ){
        category{
            id
            categoryName
            description
            storageKey
            tracksCount

            children{
              id
              categoryName
              description
              storageKey
              tracksCount
            }
        }
        
    }
  }
`

export const GET_TRACKS = `
  query ($categoryId: String,$tagId: String, $page: Int, $limit: Int, $publishedStatus:String) {
    getTracks(
      searchAndFilterArgs: {categoryId:$categoryId,tagId:$tagId, page:$page, limit:$limit,publishedStatus:$publishedStatus}
    ){
        tracks{
            id
            title
            slug
            duration
            trackType
            storageKeys{azure{image,menifestFileUrl}},
         publishStatus
  
        }
        meta{
          limit
          page
          total
          total_items
          total_pages
        }
    }
  }
`

export const GET_TAGS_BY_CATEGORY_ID = `
  query ($categoryId: String!) {
    getTagsByCategoryId(
      categoryId:$categoryId
    ){
        id
        tagName
        tracksCount 
    }
  }`
// category by sulg
export const GET_CATEGORY_BY_SLUG = `
  query ($slug: String!) {
    getCategoryBySlug(
        slug:$slug
    ){
            id
            categoryName
            description
            storageKey
            tracksCount

    }
  }
`
