import React from "react"
import CheckoutForm from "./checkoutForm"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { STRIPE_PUBLIC_KEY } from "config/app.config"

const stripeTestPromise = loadStripe(STRIPE_PUBLIC_KEY)

const Payment = () => {
  return (
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm />
    </Elements>
  )
}

export default Payment
