import React, { useState } from "react"
import Sidebar from "../../components/Sidebar"
import Header from "../../components/Header"
import { Layout } from "antd"
import { useSelector } from "react-redux"
import { Redirect, useLocation, useRouteMatch } from "react-router-dom"
import ScrollToTop from "../../components/UI/ScrollToTop"
import { getCurrentSession } from "../../utils/helper"

const { Content } = Layout

const Mainlayout = (props) => {
  const state = useSelector((state) => state)
  const { path, params } = useRouteMatch()
  const { pathname, search } = useLocation()
  const { auth, player } = state

  const [sidebarStatus, setSidebarStatus] = useState(false)

  const changeSidebarStatus = () => {
    setSidebarStatus(!sidebarStatus)
  }

  if (getCurrentSession().authenticated) {
    return (
      <Layout
        theme="light"
        className={`main-layout ${sidebarStatus ? "active" : ""}`}
        id="gv-main-layout"
      >
        <div
          className={`sidebar-active-bg ${sidebarStatus ? "active" : ""}`}
          onClick={() => {
            changeSidebarStatus()
          }}
        ></div>
        <Sidebar sidebarStatus={sidebarStatus} />
        <ScrollToTop>
          <Layout
            className={`site-layout ${
              player?.playingSong ? "site-layout-with-player" : ""
            }`}
            theme="light"
          >
            <Header sidebarStatus={changeSidebarStatus} />
            <Content>{props.children}</Content>
          </Layout>
        </ScrollToTop>
      </Layout>
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { path, pathname, params, search }
        }}
      />
    )
  }
}

export default Mainlayout
