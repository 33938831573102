import React, { useState, useEffect, useContext } from "react"
import { Button, Form, Input, message } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Link, useHistory, useLocation } from "react-router-dom"
import Facebook from "../../assets/images/facebook.png"
import Google from "../../assets/images/google.png"
import Guest from "../../assets/images/user.png"
// import ChooseGenre from './Choosegenre';
import { GoogleLogin } from "react-google-login"
import { GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from "../../config/app.config"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "../../index"
import _ from "lodash"
import { STORAGE_KEYS, setItem } from "../../utils/local-storage"
import { clearPlayer } from "../../store/slice/player.slice"
import { useDispatch } from "react-redux"

//** graphql imports */
import { useLazyQueryHook } from "../../graphql/hooks"
import { LOGIN, ME, SOCIAL_LOGIN } from "../../graphql/queries/auth"
import { renderFullName } from "utils/helper"
import { UserContext } from "contexts/userContext"
import Mixpanel from "mixpanel-browser"
import userMixPanelEvents from "functions/mixPanelEvents"
import "./styles.scss"

const LoginComponent = () => {
  const history = useHistory()
  const location = useLocation()
  // const [userMixPanelEventsNew] = userMixPanelEvents()
  const dispatch = useDispatch()
  const user = useContext(UserContext)
  const [apiLoading, setApiLoading] = useState(false)
  const [tokenDetails, setTokenDetails] = useState({ tokenType: "", token: "" })
  const analytics = getAnalytics(app)

  const [currentTimeZone, setCurrentTimeZone] = useState(null)
  const [currentCountry, setCurrentCountry] = useState(null)

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  //close player on login page
  useEffect(() => {
    dispatch(clearPlayer())
  })
  const {
    func: loginFunc,
    loading: loginLoading,
    error: loginError,
    data: loginData
  } = useLazyQueryHook({ query: LOGIN })
  const {
    func: socialLoginFunc,
    loading: socialLoginLoading,
    error: socialLoginError,
    data: socialLoginData
  } = useLazyQueryHook({
    query: SOCIAL_LOGIN,
    headers: { "token-type": tokenDetails.tokenType, token: tokenDetails.token }
  })
  const {
    func: meFunc,
    data: meData,
    loading: meLoading,
    error: meError
  } = useLazyQueryHook({ query: ME, fetchPolicy: "network-only" })

  useEffect(async () => {
    if (!loginLoading) {
      if (loginData && loginData.login) {
        //setting local storage
        const { access_token, refresh_token } = loginData.login
        await setItem(STORAGE_KEYS.ACCESS_TOKEN, access_token)
        await setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh_token)
        await meFunc()
        // await userMixPanelEvents()
      }

      if (loginError) {
        message.error(loginError.message || loginError)
        setApiLoading(false)
      }
    }
  }, [loginLoading, loginData, loginError])
  // mix panel event when page reload

  useEffect(() => {
    if (meData && meData.me) {
      // console.log("user unique data", meData.me.id)
      let properties = { distinctId: meData.me.id, email: meData.me.email }
      // console.log("meData.me", meData.me)
      userMixPanelEvents("signin", properties)
      // setting me data into mix panel
      Mixpanel.identify(meData.me.id)
      let userData = { email: meData.me.email }
      if (
        meData.me.firstName !== null ||
        meData.me.lastName !== null ||
        meData.me.gender !== null ||
        meData.me.dob !== null
      ) {
        if (meData.me.firstName !== null) {
          userData = { ...userData, firstName: meData.me.firstName }
        }
        if (meData.me.lastName !== null) {
          userData = { ...userData, lastName: meData.me.lastName }
        }
        if (meData.me.gender) {
          userData = { ...userData, gender: meData.me.gender }
        }

        if (meData.me.dob !== null) {
          userData = { ...userData, dob: meData.me.dob }
        }
        Mixpanel.people.set(userData)
      } else {
        Mixpanel.people.set(userData)
      }
    } else {
      userMixPanelEvents("screen_view", { screen: "signin" })
    }
  }, [meData])

  useEffect(() => {
    if (!meLoading) {
      if (meData && meData.me) {
        const {
          id,
          firstName,
          lastName,
          email,
          profilePicStorageKey,
          tags,
          emailConfirmed,
          subscriptionIos,
          subscriptionIosExpiryDate,
          subscriptionGraceDate
        } = meData.me

        //setting user details
        const userDetails = {
          firstName,
          lastName,
          email,
          image: profilePicStorageKey,
          emailConfirmed,
          tags: tags,
          subscriptionIos,
          subscriptionIosExpiryDate,
          subscriptionGraceDate
        }
        setItem(STORAGE_KEYS.USER, userDetails)

        setApiLoading(false)
        if (emailConfirmed) {
          if (tags && tags.length) {
            if (location?.state?.path) {
              history.push(location?.state?.pathname + location?.state?.search)
            } else {
              // let properties = { distinctId: id, email: email }
              // userMixPanelEvents("signin", properties)

              history.push("/")
              message.success(
                `Welcome to good vibes, ${
                  renderFullName(userDetails) || "User"
                }`
              )
            }
          } else {
            history.push("/choose-tags")
          }
        } else {
          message.warning("You have not verified your email, Please verify it!")
          history.push("/resend-verification")
        }
      }

      if (meError) {
        message.error(meError.message || meError)
        setApiLoading(false)
      }
    }
  }, [meLoading, meData, meError])

  const handleLogin = (values) => {
    if (Object.values(values).every((field) => field.length > 0)) {
      setApiLoading(true)
      const variables = {
        username: values.email,
        password: values.password,
        deviceTimeZone:
          userTimeZone === "Asia/Katmandu" ? "Asia/Kathmandu" : userTimeZone
      }
      loginFunc({ variables })
    }
  }

  const handleSocialLogin = async (token, provider) => {
    const socialToken =
      provider === "facebook" ? token.accessToken : token.tokenId

    //manage token type and token here
    setTokenDetails({ tokenType: provider, token: socialToken })

    // return;
    // dispatch(SocialLoginService(token, provider))
  }

  useEffect(() => {
    if (tokenDetails.tokenType && tokenDetails.tokenType !== "") {
      let variables = {
        deviceTimeZone:
          userTimeZone === "Asia/Katmandu" ? "Asia/Kathmandu" : userTimeZone
      }
      socialLoginFunc({ variables: variables })
    }
  }, [tokenDetails])

  useEffect(() => {
    if (!socialLoginLoading) {
      if (socialLoginData && socialLoginData.OauthLogin) {
        //setting local storage
        const { access_token, refresh_token } = socialLoginData.OauthLogin
        setItem(STORAGE_KEYS.ACCESS_TOKEN, access_token)
        setItem(STORAGE_KEYS.REFRESH_TOKEN, refresh_token)

        meFunc()
      }

      if (socialLoginError) {
        message.error(socialLoginError.message || socialLoginError)
        setApiLoading(false)
      }
    }
  }, [socialLoginLoading, socialLoginError, socialLoginData])

  return (
    <div className="auth-wrapper">
      <h2>
        Hi! Welcome to <span>Good Vibes..</span>
      </h2>
      <Form
        layout="vertical"
        size="large"
        onFinish={handleLogin}
        className="auth-form-wrapper"
      >
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please enter valid email !!"
            }
          ]}
        >
          <Input
            className="ant-form-item-control-input"
            placeholder="Enter Email Address"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter password !!"
            }
          ]}
        >
          <Input.Password
            placeholder="Enter Password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item className="text-small">
          <div className="forgot-passsword">
            <span>Forgot Password?</span>
            <Link className="btn-link" to="/forgot-password">
              Reset Password
            </Link>
          </div>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            htmlType="submit"
            loading={apiLoading}
            className="btn btn-primary btn-large"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      <div className="social-login">
        <div className="social-divider">
          <span>Or</span>
        </div>
        <div className="social">
          {/* <FacebookLogin
            appId={FACEBOOK_APP_ID}
            autoLoad={false}
            callback={(token) => handleSocialLogin(token, "facebook")}
            render={(renderProps) => (
              <div className="social-icon" {...renderProps}>
                <img src={Facebook} alt="" />
                <h4>Facebook</h4>
              </div>
            )}
          /> */}

          {/* <Link to="#" />
            <img src={Google} alt="" />
            <h4>Google</h4> */}
          <GoogleLogin
            clientId={GOOGLE_CLIENT_ID}
            className="social-icon"
            icon={false}
            onSuccess={(token) => handleSocialLogin(token, "google")}
            onFailure={(e) => console.log("google login failure", e)}
          >
            <img src={Google} alt="" />
            <h4>Google</h4>
          </GoogleLogin>
          {/* <div className="social-icon">
            <Link
              to="/guest"
              onClick={() => {
                logEvent(analytics, "logged_in_as_guest")
              }}
            />
            <img src={Guest} alt="" />
            <h4>As Guest</h4>
          </div> */}
        </div>
        <Form.Item className="text-small text-small-account">
          <span>Don't Have a Account?</span>
          <Link className="btn-link" to="/signup">
            Sign Up
          </Link>
        </Form.Item>
      </div>
    </div>
  )
}

export default LoginComponent
