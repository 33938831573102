export const GET_SUBSCRIPTION_PLANS = `
  query {
    getSubscriptionPlans {
      id
      name
      description
      active
      created
      price_detail {
        id
        active
        billing_scheme
        currency
        recurring {
          aggregate_usage
          interval
          interval_count
          trial_period_days
          usage_type
        }
        unit_amount
        unit_amount_decimal
      }
    }
  }
`

export const GET_SUBSCRIPTION_PLAN_BY_ID = `
  query($subscriptionId: String!) {
    getSubscriptionPlanById(subscriptionId:$subscriptionId) {
      id
      description
      active
      name
      price_detail {
        id
        active
        billing_scheme
        currency
        recurring {
          aggregate_usage
          interval
          interval_count
          trial_period_days
          usage_type
        }
        unit_amount
        unit_amount_decimal
      }
    }
  }
`

export const CREATE_PAYMENT_INTENT = `
  mutation($subscriptionPlanId: String!, $amount: Float!) {
    createPaymentIntent(subscriptionPlanId: $subscriptionPlanId, amount: $amount) {
      clientSecret
    }
  }
`

export const VERIFY_PAYMENT = `
  mutation($paymentId: String!) {
    verifyPayment (paymentId: $paymentId)
  }
`
export const CREATE_PAYMENT_SUBSCRIPTION = `
  mutation($email:String!, $cardName: String!, $cardEmail: String!, $paymentMethodId: String!, $subscriptionPlanId: String!, $promotionCode: String, $subscriptionOffer: UserSubscritionOfferInput) {
    createPaymentSubscription(email:$email, cardName: $cardName, cardEmail: $cardEmail, paymentMethodId: $paymentMethodId, subscriptionPlanId: $subscriptionPlanId, promotionCode: $promotionCode, subscriptionOffer:$subscriptionOffer) {
      invoiceId
      clientSecret
      promotionCodeDetails {
        id
        name
        amountOff
        percentOff
      }
    }
  }
`

export const VERIFY_PROMOTION_CODE = `
  query ($promotionCode: String!, $subscriptionPlanId: String!){
    verifyPromotionCode(promotionCode: $promotionCode, subscriptionPlanId: $subscriptionPlanId)
    {
      id
      amountOff
      name
      percentOff
    }
  }
`

export const VERIFY_SUBSCRIPTION = `
  mutation ($invoiceId: String!){
    verifyPaymentSubscription(invoiceId: $invoiceId)
  }
`
export const MY_SUBSCRIPTION_INFO = `
query{
  getMySubscriptionInfo{
  purchaseDate,
  expiryDate,
  graceDate
  plan
  status
  platform
}
}
`
export const CANCEL_SUBSCRIPTION = `
  mutation {
    
    cancelPaymentSubscription
  }`

export const VERIFY_PAYMENT_EMAIL = `
  query($email: String!, $subscriptionOffer: UserSubscritionOfferInput) {
    validatePaymentSubscription(email: $email, subscriptionOffer:$subscriptionOffer)
  }
`
