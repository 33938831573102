import React, { useState, useEffect } from "react"
import { Form, Row, Col, Input, Button, message } from "antd"
import { setFormData } from "../../utils/helper"
import { updateUserPassword } from "../../services/user.service"

//graphql imports
import { useMutationHook } from "graphql/hooks"
import { UPDATE_PASSWORD } from "graphql/queries/auth"
import { useHistory } from "react-router-dom"

const ChangePassword = () => {
  const [form] = Form.useForm()

  const history = useHistory()

  const [submitting, setSubmitting] = useState(false)

  const {
    func: updatePasswordFunc,
    loading: updatePasswordLoading,
    data: updatePasswordData,
    error: updatePasswordError
  } = useMutationHook({ query: UPDATE_PASSWORD })
  const handleSubmit = async (values) => {
    // const formdata = setFormData(values)
    // setSubmitting(true)
    // await updateUserPassword(formdata, form.resetFields())
    // setSubmitting(false)

    const passwordDetails = {
      oldPassword: values.current_password,
      newPassword: values.new_password
    }
    await updatePasswordFunc({ variables: passwordDetails })
  }

  useEffect(() => {
    if (!updatePasswordLoading) {
      if (updatePasswordData && updatePasswordData.updatePassword) {
        message.success("Password Updated.")
        history.push("/login")
      }
      if (updatePasswordError) {
        message.error(updatePasswordError.message || updatePasswordError)
      }
    }
  }, [updatePasswordLoading, updatePasswordData, updatePasswordError])

  return (
    <>
      <div className="card card--personal-info">
        <h3 className="card__header">Change Password</h3>
        <Form
          layout="vertical"
          size="large"
          onFinish={handleSubmit}
          form={form}
          className="auth-form-wrapper form--password"
        >
          <Row gutter={[15, 15]}>
            <Col xs={24} md={12}>
              <Form.Item
                label="Old Password"
                name="current_password"
                rules={[
                  {
                    required: true,
                    message: "This field is required !!"
                  }
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="******"
                  className="form-input-control"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="New Password"
                name="new_password"
                rules={[
                  {
                    required: true,
                    message: "This field is required !!"
                  }
                ]}
              >
                <Input.Password
                  type="password"
                  placeholder="******"
                  className="form-input-control"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={16}>
              <Button
                className="btn btn-primary btn-large"
                loading={updatePasswordLoading}
                htmlType="submit"
              >
                Update Password
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default ChangePassword
