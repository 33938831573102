import { Button } from "antd"
import React from "react"
import { Link } from "react-router-dom"

const ResetStatus = () => {
    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__reset">
                <div className="heading">
                    <h2>
                        Reset Link has been sent
                    </h2>
                </div>
                <div className="text-small">
                    <p>Please check your email address and follow the instructions.</p>
                </div>
                <div className="link-btn">
                    <Link to="/login">
                        {/* <Button className="btn btn-primary btn-large"> */}
                        Login
                        {/* </Button> */}
                    </Link>
                </div>
                <div className="resend-link">
                    <Link to="/reset-status">Resend in 04:12 minutes</Link>
                </div>
            </div>

        </div>
    )
}

export default ResetStatus