import React, { useEffect, useState } from "react"
import { Menu, Dropdown, message, Spin, Modal } from "antd"
import {
  LockOutlined,
  MoreOutlined,
  PlusCircleOutlined
} from "@ant-design/icons"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import {
  addTrackToPlaylist,
  fetchUserPlaylist,
  removeTrackFromPlaylist,
  togglePlaylistModalService
} from "../../../services/playlist.service"
import { useLazyQueryHook, useMutationHook, useQueryHook } from "graphql/hooks"
import {
  ADD_TRACK_TO_PLAYLIST,
  GET_PLAYLISTS,
  GET_PLAYLIST_BY_ID,
  GET_TRACKS_OF_PLAYLIST,
  REMOVE_TRACK_TO_PLAYLIST
} from "graphql/queries/playlist"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import { Fragment } from "react"
import { useHistory, useLocation } from "react-router-dom"

import PluseIcon from "../../../assets/images/PluseIcon.svg"
import PluseIconHover from "../../../assets/images/PluseHover.svg"
import PlaylistCU from "components/Playlist/PlaylistCU"
import { SEND_USER_EVENTS } from "graphql/queries/search"
import useSendEvents from "functions/useSendEvents"
import userMixPanelEvents from "functions/mixPanelEvents"
import { togglePlaylistModal } from "store/slice/playlist.slice"

const { SubMenu } = Menu

const DropdownTrack = (props) => {
  const { track, isPlaylist, playlistId, page, premium } = props
  let history = useHistory()

  const dispatch = useDispatch()

  const [trackId, setTrackId] = useState("")
  const [showPlaylistModal, setShowPlaylistModal] = useState(false)

  let { state: routeState } = useLocation()

  // console.log("show route state from playlist form", routeState)

  const [sendEvents] = useSendEvents()

  useEffect(() => {
    if (track && track.length) {
      const trackID = track?.map((track) => track.id)
      setTrackId(trackID)
    } else {
      const trackID = track.id
      setTrackId(trackID)
    }
  }, [track])

  // const {
  //   func: playlistDetailFunc,
  //   loading: playlistDetailLoading,
  //   error: playlistDetailError,
  //   data: playlistDetailData
  // } = useLazyQueryHook({ query: GET_TRACKS_OF_PLAYLIST })

  const {
    loading: playlistLoading,
    data: playlistData,
    error: playlistError
  } = useQueryHook({
    query: GET_PLAYLISTS,
    variables: { page: 1, limit: 20 }
  })

  const {
    func: trackToPlaylistFunc,
    loading: trackToPlaylistLoading,
    data: trackToPlaylistData,
    error: trackToPlaylistError
  } = useMutationHook({
    query: ADD_TRACK_TO_PLAYLIST
  })

  const {
    func: sendUserEvents,
    loading: userEventLoading,
    data: userEventData,
    error: userEventError
  } = useLazyQueryHook({
    query: SEND_USER_EVENTS
  })

  const {
    func: removeTrackFromPlaylistFunc,
    loading: removeTrackFromPlaylistLoading,
    data: removeTrackFromPlaylistData,
    error: removeTrackFromPlaylistError
  } = useMutationHook({
    query: REMOVE_TRACK_TO_PLAYLIST,
    refetchQueries: [
      {
        query: GET_TRACKS_OF_PLAYLIST,
        variables: {
          page: 1,
          limit: 20,
          playlistId: playlistId
        }
      },
      {
        query: GET_PLAYLIST_BY_ID,
        variables: {
          playlistId: playlistId
        }
      }
    ]
  })

  //open create playlist modal
  const handleCreatePlaylist = () => {
    dispatch(togglePlaylistModal(true))
  }

  // add track to playlist
  const handleAddTrack = async (playlist) => {
    // console.log("playlist for add", playlist)
    const variables = { playlistId: playlist.id, trackId: trackId.toString() }
    // addTrackToPlaylist(playlist, track.id)
    await trackToPlaylistFunc({ variables })
  }

  /**
   * Remove track from playlist
   */
  const handleRemoveTrack = async () => {
    // dispatch(removeTrackFromPlaylist(listId))

    const variables = { playlistId: playlistId, trackId: track.id }
    await removeTrackFromPlaylistFunc({ variables }).then(() => {
      message.success("Track is successfully removed")
    })
  }

  // error message
  useEffect(() => {
    if (!trackToPlaylistLoading) {
      if (trackToPlaylistError) {
        message.error(trackToPlaylistError.message)
      }

      if (trackToPlaylistData) {
        message.success("Track is added successfully to playlist")
        // send mix panel events
        const properties = {
          source: "track single page",
          "playlist name":
            trackToPlaylistData?.addTrackInPlaylist.playlist?.playlistName,
          "playlist id": trackId?.toString()
        }
        userMixPanelEvents(" click_add_to_playlist_rate", properties)
        // send event to algolia event
        routeState = {
          ...routeState,
          eventComponent: "playlist"
        }

        sendEvents(routeState)
      }
    }
  }, [trackToPlaylistError, trackToPlaylistLoading, trackToPlaylistData])

  // playlist for display
  const [userplaylist, setUserplaylist] = useState([])

  useEffect(() => {
    if (
      playlistData &&
      playlistData.getPlaylists &&
      playlistData.getPlaylists.playlist &&
      playlistData.getPlaylists.playlist.length &&
      playlistData.getPlaylists.playlist
    ) {
      setUserplaylist(playlistData.getPlaylists.playlist)
    }
  }, [playlistData])

  useEffect(() => {
    if (!playlistLoading) {
      if (playlistError) {
        message.error(playlistError.message)
      }
    }
  }, [playlistError, playlistLoading])

  const [userData, setUserData] = useState({})
  useEffect(() => {
    let userData = getItem(STORAGE_KEYS.USER)
    setUserData(userData)
  }, [])
  const getLatestPlaylist = playlistData?.getPlaylists?.playlist[0]

  // Redirrect function after user confirm
  const RedirectFun = () => {
    history.push("/subscription")
  }
  // confirmation model
  const confirm = () => {
    Modal.confirm({
      // title: 'Confirm',
      icon: "",
      content:
        "You cannot access to this playlist because your subscription has been expired. Please renew your subscription to gain access to all your playlists.",
      okText: "Renew Subscription",
      cancelText: "Cancel",
      onOk: () => {
        RedirectFun()
      }
    })
  }

  const [img, setImg] = useState(PluseIcon)

  const menu = (
    <Menu triggerSubMenuAction="click">
      {isPlaylist ? (
        <Menu.Item onClick={handleRemoveTrack}>
          {removeTrackFromPlaylistLoading ? <Spin size="small" /> : " "} Remove
          track from playlist
        </Menu.Item>
      ) : (
        <>
          <SubMenu key="1" title={"Add track to playlist"}>
            <Menu.Item
              key={getLatestPlaylist?.id}
              onClick={() => handleAddTrack(getLatestPlaylist)}
            >
              {page !== "playlist" ? (
                <span>{getLatestPlaylist?.playlistName} </span>
              ) : (
                ""
              )}
              {/* {page} */}
            </Menu.Item>
            {/* .slice(1) */}
            {page !== "playlist" ? (
              userplaylist ? (
                userplaylist
                  .filter((playlist) => playlist.id !== playlistId)
                  .slice(1)
                  .map((playlist) => (
                    <Menu.Item key={playlist.id}>
                      {userData.subscriptionIos === "free" ? (
                        <Fragment>
                          <LockOutlined style={{ color: " #9797DE" }} />
                          <span onClick={confirm}>
                            &nbsp;{playlist.playlistName}
                          </span>
                        </Fragment>
                      ) : (
                        <span onClick={() => handleAddTrack(playlist)}>
                          {playlist.playlistName}
                        </span>
                      )}
                    </Menu.Item>
                  ))
              ) : (
                <Menu.Item>No playlist found</Menu.Item>
              )
            ) : (
              <Fragment>
                {/* it only works for other page except playlist page */}
                {userplaylist ? (
                  userplaylist
                    .filter((playlist) => playlist.id !== playlistId)
                    .map((playlist) => (
                      <Menu.Item key={playlist.id}>
                        {userData.subscriptionIos === "free" ? (
                          <Fragment>
                            <LockOutlined style={{ color: " #9797DE" }} />
                            <span onClick={confirm}>
                              &nbsp;{playlist.playlistName}
                            </span>
                          </Fragment>
                        ) : (
                          <span onClick={() => handleAddTrack(playlist)}>
                            {playlist.playlistName}
                          </span>
                        )}
                      </Menu.Item>
                    ))
                ) : (
                  <Menu.Item>No playlist found</Menu.Item>
                )}
              </Fragment>
            )}
            <Menu.Item onClick={handleCreatePlaylist} className="text-primary">
              Create new playlist
            </Menu.Item>
          </SubMenu>
        </>
      )}
    </Menu>
  )

  return (
    <div style={{ cursor: "pointer" }}>
      <Dropdown overlay={menu} trigger={["click"]} disabled={premium}>
        <div className="">
          {/* <PlusCircleOutlined /> */}
          {/* <img src={RemoveIcon} alt="remove-icon" /> */}

          <img
            src={img}
            onMouseEnter={() => {
              setImg(PluseIconHover)
            }}
            onMouseOut={() => {
              setImg(PluseIcon)
            }}
            alt="pluseIcon"
          />
        </div>
      </Dropdown>
      {/* <PlaylistCU
        setShowPlaylistModal={setShowPlaylistModal}
        showPlaylistModal={showPlaylistModal}
      /> */}
    </div>
  )
}

export default DropdownTrack
