import React, { useEffect } from "react"
import { Link, Redirect } from "react-router-dom"
import { Row, Col } from "antd"
import Logo from "../../assets/images/Brand-Logo.png"

import { Button, Form, Input, Select } from "antd"
import visa from "../../assets/images/credit-card-icons/visa.png"
import mastercard from "../../assets/images/credit-card-icons/mastercard.png"
import americanexpress from "../../assets/images/credit-card-icons/american-express.png"
import { useSelector } from "react-redux"
import CheckoutForm from "./checkoutForm"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { STRIPE_PUBLIC_KEY } from "config/app.config"
import userMixPanelEvents from "functions/mixPanelEvents"

const { Option } = Select

// updated live key to prod branch
// Note: update the public key to be used from env so that it can be setup for different environment.


//  event send to mix panel

const stripeTestPromise = loadStripe(STRIPE_PUBLIC_KEY)

const Payment = () => {
  /** redux state */
  // const state = useSelector((state) => state)
  // const { choosedPackage } = state.subscription
  // if (!choosedPackage) {
  //   return <Redirect to="/subscription" />
  // } else {
  return (
    <Elements stripe={stripeTestPromise}>
      <CheckoutForm />
    </Elements>
  )
  // }
}

export default Payment
