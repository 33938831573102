import React, { useEffect, useState } from "react"
import { Switch, withRouter, useHistory, useLocation } from "react-router-dom"
import routes from "./routes/routes"
import AppRoute from "./routes/Route"
import MusicPlayer from "./components/Musicplayer"
import { useDispatch, useSelector } from "react-redux"
import { getCurrentSession, setPageTitle } from "./utils/helper"
import _ from "lodash"
import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "./index"
import { gapi } from "gapi-script"
import { GOOGLE_CLIENT_ID } from "./config/app.config"
import { message } from "antd"
import AppMessageModal from "components/AppMessageModal"
import { getItem, setItem, STORAGE_KEYS } from "utils/local-storage"
import { useLazyQueryHook } from "graphql/hooks"
import { ME } from "graphql/queries/auth"
import { UserContext } from "contexts/userContext"
import { UserEventContext } from "contexts/userEventContext"
// firebase config
import "firebase/remote-config"
import { initializeApp } from "firebase/app"
import { getRemoteConfig, getString } from "firebase/remote-config"
import { fetchAndActivate } from "firebase/remote-config"
import { firebaseConfig } from "./config/firebase.config"
import Maintenance from "components/maintenance"
import "bootstrap/dist/css/bootstrap.min.css"
import { MixpanelProvider } from "react-mixpanel-browser"
import PlaylistCU from "components/Playlist/PlaylistCU"
import { togglePlaylistModal } from "store/slice/playlist.slice"

const App = () => {
  const analytics = getAnalytics(app)

  const dispatch = useDispatch()

  // warning before leaving the app
  const state = useSelector((state) => state.player)
  const { showCreatePlaylistModal } = useSelector((state) => state.playlists)
  const { playingSong } = state
  const history = useHistory()
  const { search, pathname } = useLocation()

  // remote config data set
  const [configData, setConfigData] = useState({
    is_force: true,
    isUnderMaintenance: false,
    web: {
      is_force: true,
      isUnderMaintenance: false
    },
    web_qa: {
      is_force: true,
      isUnderMaintenance: false
    }
  })
  // state
  const [isAppModalOpen, setIsAppModalOpen] = useState(true)
  const [appModal, setAppModal] = useState()
  const [mobileWidth, setMobileWidth] = useState(false)
  const [user, setUser] = useState({})
  const [userEventContext, setUserEventContext] = useState({})

  const params = new URLSearchParams(search)

  const accessToken = getItem(STORAGE_KEYS.ACCESS_TOKEN)
  const view = new URLSearchParams(search).get("view")
  // firebase initialization
  // remote config
  useEffect(() => {
    if (typeof window !== "undefined") {
      const remoteConfig = getRemoteConfig(app)
      
      remoteConfig.settings.minimumFetchIntervalMillis = 60
      fetchAndActivate(remoteConfig)
        .then(() => {
          const fireBaseConfigData = getString(remoteConfig, "app_configs")
          const data = JSON.parse(fireBaseConfigData)
          setConfigData(data)
          // console.log("fireBaseConfigData", fireBaseConfigData)
        })
        .catch((err) => {
          console.log("firebase error", err)
        })
    }
  }, [])

  // console.log("configData", configData)

  //detect os platform
  const getOS = () => {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform || window.navigator.platform,
      iosPlatforms = ["iPhone", "iPad", "iPod"],
      os = null

    if (iosPlatforms.indexOf(platform) !== -1) {
      os = "iOS"
    } else if (/Android/.test(userAgent)) {
      os = "Android"
    } else if (/Linux/.test(platform)) {
      os = "Linux"
    }

    return os
  }
  // mix panel init
  //used for setting google login
  useEffect(() => {
    const initClient = () => {
      gapi?.client?.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: ""
      })
    }
    gapi.load("client:auth2", initClient)
  }, [])

  useEffect(() => {
    let pathSplit = history?.location.pathname.split("/")
    let lastTitle = pathSplit[pathSplit.length - 1]
    let pageTitle = lastTitle?.replace(/-/g, " ")
    if (pageTitle) {
      setPageTitle(_.capitalize(pageTitle))
      logEvent(analytics, "page_view", {
        page_title: _.capitalize(pageTitle)
      })
    }
  }, [history?.location?.pathname])

  useEffect(() => {
    if (pathname === "/search") {
      const keyword = params.get("keyword")
      const trackType = params.get("track_type")
      const trackDuration = params.get("track_duration")

      setUserEventContext({
        userSearched: true,
        keyword: keyword,
        trackType: trackType,
        trackDuration: trackDuration
      })
    } else {
      setUserEventContext({})
    }
  }, [pathname, search])

  useEffect(() => {
    if (playingSong) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = () => undefined
    }
  }, [playingSong])
  //only when song is playing

  // calculate view width
  useEffect(() => {
    const viewWidth = window.innerWidth
    if (viewWidth < 992) {
      setMobileWidth(true)
    } else {
      setMobileWidth(false)
    }
  }, [window])

  useEffect(() => {
    if (!isAppModalOpen) {
      const appModal = setInterval(() => {
        setIsAppModalOpen(true)
      }, 300000)
      setAppModal(appModal)
    }

    // clearInterval(appModal)
  }, [isAppModalOpen])

  const {
    func: meFunc,
    data: meData,
    loading: meLoading,
    error: meError
  } = useLazyQueryHook({ query: ME })

  // calling user data on refresh for instant chnage in subscription

  useEffect(() => {
    if (!meLoading) {
      if (meData) {
        const {
          firstName,
          lastName,
          email,
          profilePicStorageKey,
          tags,
          emailConfirmed,
          subscriptionIos,
          subscriptionIosExpiryDate,
          subscriptionGraceDate,
          id
        } = meData.me

        const userDetails = {
          id,
          firstName,
          lastName,
          email,
          image: profilePicStorageKey,
          emailConfirmed,
          tags: tags,
          subscriptionIos,
          subscriptionIosExpiryDate,
          subscriptionGraceDate
        }
        setUser(userDetails)
        setItem(STORAGE_KEYS.USER, userDetails)
      }
    }
  }, [meData, meLoading])

  useEffect(() => {
    if (accessToken && accessToken !== "") {
      meFunc()
    }
  }, [accessToken])

  return (
    <>
      {configData?.web_qa?.isUnderMaintenance ? (
        <Maintenance />
      ) : (
        <UserContext.Provider value={user}>
          <UserEventContext.Provider value={userEventContext}>
            <MixpanelProvider>
              <Switch>
                {routes.map((route, i) => (
                  <AppRoute
                    key={i}
                    layout={route.layout}
                    component={route.component}
                    path={route.path}
                    image={route.image}
                    exact
                  />
                ))}
              </Switch>
              <PlaylistCU
                setShowPlaylistModal={(status) =>
                  dispatch(togglePlaylistModal(status))
                }
                showPlaylistModal={showCreatePlaylistModal}
              />
              <MusicPlayer />
              <div className="app-download-message-modal">
                {mobileWidth &&
                getCurrentSession().authenticated &&
                (!view || view === "") ? (
                  <AppMessageModal
                    setIsAppModalOpen={setIsAppModalOpen}
                    isAppModalOpen={isAppModalOpen}
                    appModal={appModal}
                    platform={getOS()}
                  />
                ) : (
                  ""
                )}
              </div>
            </MixpanelProvider>
          </UserEventContext.Provider>
        </UserContext.Provider>
      )}
    </>
  )
}

export default withRouter(App)
