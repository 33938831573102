export default [
  {
    title: "Default",
    time: undefined,
    isPremium: false
  },
  {
    title: "30 mins",
    time: 30 * 60,
    isPremium: false
  },
  {
    title: "1 hr",
    time: 60 * 60,
    isPremium: true
  },
  {
    title: "2 hr",
    time: 2 * 60 * 60,
    isPremium: true
  },
  {
    title: "4 hr",
    time: 4 * 60 * 60,
    isPremium: true
  },
  {
    title: "6 hr",
    time: 6 * 60 * 60,
    isPremium: true
  },
  {
    title: "8 hr",
    time: 8 * 60 * 60,
    isPremium: true
  },
  {
    title: "infinite",
    time: undefined,
    isPremium: true
  }
]

export const dailyGoalTimer = [
  {
    title: "15 min",
    value: 15
  },
  {
    title: "30 min",
    value: 30
  },
  {
    title: "1 hr",
    value: 60
  },
  {
    title: "2 hr",
    value: 120
  },
  {
    title: "3 hr",
    value: 180
  },
  {
    title: "8 hr",
    value: 480
  },
  {
    title: "24 hr",
    value: 1440
  }
]
