// import react
import React from "react"
// import image
import FooterImg from "../../../assets/images/offer/footerImg.svg"
import FooterLogo from "../../../assets/images/offer/Footer-Logo.svg"
import insta from "../../../assets/images/offer/insta.svg"
import yt from "../../../assets/images/offer/yt.svg"
import fb from "../../../assets/images/offer/fb.svg"
import tw from "../../../assets/images/offer/tw.svg"

// import styles
import "./styles.scss"

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="img-wrapper">
        <img src={FooterImg} alt="footer" height="100%" width="100%"></img>
      </div>
      <div className="links-wrapper">
        <div className="social-links-wrapper">
          <div className="footer-logo-wrapper">
            <img src={FooterLogo} alt="footer-logo" />
          </div>
          <div className="social-links-item">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/gvofficialapp/"
                  target="_blank"
                >
                  <div className="social-link common">
                    <img src={insta} alt="insta" />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@GoodVibesMusic"
                  target="_blank"
                >
                  <div className="social-link common">
                    <img src={yt} alt="yt" />
                  </div>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/gvofficialapp" target="_blank">
                  <div className="social-link common">
                    <img src={tw} alt="twitter" />
                  </div>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/gvofficialapp/"
                  target="_blank"
                >
                  <div className="social-link common">
                    <img src={fb} alt="insta" />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-last">
          <span>© 2023 Copyright GoodVibes, All Rights Reserved.</span>
          <span>Privacy Policy | Terms and Conditions</span>
        </div>
      </div>
    </div>
  )
}

export default Footer
