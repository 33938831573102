import {
  loginRequest,
  loginFail,
  loginSuccess,
  logoutSuccess,
  fetchUserProfileFail,
  fetchUserProfileRequest,
  fetchUserProfileSuccess
} from "../store/slice/auth.slice"
import { push } from "connected-react-router"
import httpBase from "../utils/axios"
import { setItem } from "../utils/local-storage"
import { message } from "antd"
import { resetHome } from "../store/slice/home.slice"
import { resetTags } from "../store/slice/tags.slice"
import { clearPlayer } from "../store/slice/player.slice"
import { renderFullName } from "../utils/helper"

const ManageLoginRedirect = (user) => {
  return (dispatch) => {
    if (user && user.tags_selected) {
      message.success(
        `Welcome to good vibes, ${renderFullName(user) || "User"}`
      )
      dispatch(push("/"))
    } else {
      dispatch(push("/choose-genre"))
    }
  }
}

// export const login = (data, userData) => {
//   const datas = data.login
//   console.log({ userData })
//   return async (dispatch) => {
//     dispatch(loginRequest())
    // try {
    // const response = await httpBase().post("login", formData);

    // setItem("accessToken", datas.access_token)
    // setItem("refreshToken", datas.refresh_token) // sets token to the localstorage
    // const authData = {
    //   accessToken: datas.access_token,
    //   refreshToken: datas.refresh_token,
    //   user: userData.me
    // }
    // dispatch(loginSuccess(authData))
    // dispatch(ManageLoginRedirect(userData.me))
    // message.success("You are successfully logged in")
    // } catch (e) {
    // if (e && e.response && e.response.data) {
    //   dispatch(loginFail(e.response.data))
    //   message.error(e.response.data.error)
    // } else {
    //   dispatch('Something went wrong')
    //   message.error(loginFail('Something went wrong'))
    // }
    // }
//   }
// }

export const SocialLoginService = (token, provider) => {
  return async (dispatch) => {
    dispatch(loginRequest())

    try {
      let formData

      if (provider === "google") {
        const { tokenId } = token
        formData = {
          provider: provider,
          access_token: tokenId
        }
      } else {
        const { accessToken } = token
        formData = {
          provider: provider,
          access_token: accessToken
        }
        // console.log(accessToken);
      }

      const response = await httpBase().post("login", formData)
      const data = response.data.data
      setItem("token", data.auth_token) // sets token to the localstorage
      const authData = { token: data.auth_token, user: data.user }
      dispatch(loginSuccess(authData))
      dispatch(ManageLoginRedirect(data.user))
    } catch (e) {
      if (e && e.response && e.response.data) {
        dispatch(loginFail(e.response.data))
        message.error(e.response.data.error)
      } else {
        dispatch("Something went wrong")
        message.error(loginFail("Something went wrong"))
      }
    }
  }
}

export const logout = () => {
  return async (dispatch) => {
    try {
      dispatch(resetHome())
      dispatch(resetTags())
      dispatch(clearPlayer())
      dispatch(logoutSuccess())
      localStorage.clear()
      message.success("Logged out successfully!!")
    } catch (e) {
      // console.log(e)
    }
  }
}

export const fetchUserProfile = () => {
  return async (dispatch) => {
    dispatch(fetchUserProfileRequest())
    try {
      const response = await httpBase().get("settings")
      dispatch(fetchUserProfileSuccess(response.data.data))
    } catch (e) {
      if (e && e.response && e.response.data) {
        dispatch(fetchUserProfileFail(e.response.data))
        // message.error(e.response.data.error);
      } else {
        dispatch(fetchUserProfileFail("Something went wrong"))
        // message.error('Something went wrong');
      }
    }
  }
}
