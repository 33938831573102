import {
  playPause,
  setSongToPlay,
  updateVolume
} from "../store/slice/player.slice"
import httpBase from "../utils/axios"

export const playSong = (song) => {
  return (dispatch) => {
    dispatch(setSongToPlay(song))
  }
}

export const setPlaying = (boolean) => {
  return (dispatch) => {
    dispatch(playPause(boolean))
  }
}

export const setVolume = (vol) => {
  return (dispatch) => {
    dispatch(updateVolume(vol))
  }
}

/**
 *
 * @param {*} songId
 */
export const TrackPlayHistory = async (songId) => {
  try {
    await httpBase().post(`tracks/${songId}/play`)
  } catch (e) {
    // console.log(e)
  }
}
