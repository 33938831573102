import React, { useState, useEffect } from "react"

import { Button, Input, message, Modal } from "antd"

// graphql
import { useMutationHook } from "graphql/hooks"
import { DELETE_ACCOUNT } from "graphql/queries/accountDelete"

// utils
import { deleteAccountOptions } from "utils/dummyData"
import { getErrors, handleLogout } from "utils/helper"
import history from "utils/history"
import { removeItem, STORAGE_KEYS } from "utils/local-storage"

const DeleteAccount = () => {
  const { TextArea } = Input
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteSwitchCase, setDeleteSwitchCase] = useState("")
  const [deleteReason, setDeleteReason] = useState("")
  const [selectedReasons, setSelectedReasons] = useState(undefined)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    setDeleteSwitchCase("")
  }

  const toggleSelection = (i) => {
    setSelectedReasons(i)
  }

  const reasonSelected = (id) => {
    if (selectedReasons) {
      if (selectedReasons.id === id) {
        return true
      }
    }
    return false
  }

  // handle delete query
  const {
    func: deleteAccFunc,
    loading: deleteAccLoading,
    data: deleteAccData,
    error: deleteAccError
  } = useMutationHook({ query: DELETE_ACCOUNT })

  useEffect(() => {
    if (!deleteAccLoading) {
      if (deleteAccData && deleteAccData.deleteAccount) {
        handleLogout()
      }

      if (deleteAccError) {
        message.error(
          getErrors(deleteAccError.graphQLErrors) || deleteAccError.message
        )
      }
    }
  }, [deleteAccLoading])

  // handle delete account
  const handleDelete = async () => {
    if (selectedReasons?.title || deleteReason !== "") {
      const variables = {
        reason: `${selectedReasons.title} ${deleteReason}`
      }

      await deleteAccFunc({ variables })
    }
  }

  const handleDeleteSwitch = () => {
    switch (deleteSwitchCase) {
      case "":
        return (
          <>
            <h3 className="delete-account-container-modal-title">
              Account Deletion is a reversible action
            </h3>
            <p className="delete-account-container-modal-description">
              Please note that you can login any time you want within 30 days of
              deletion. After 30 days, your account will be deleted permanently.
              All saved sessions and history will be lost when an account is
              deleted . <br /> <br /> Meanwhile, deletion your account will not
              cancel your subscription because these cancellations are handled
              through Apple or Google channels. <br /> <br /> For More
              information on subscriptions cancellation please refer to our Help
              Center.
            </p>
            <div className="delete-account-container-modal-button">
              <Button className="btn btn-outline" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="btn btn-primary"
                onClick={() => setDeleteSwitchCase("deleteForm")}
              >
                Yes, delete
              </Button>
            </div>
          </>
        )

      case "deleteForm":
        return (
          <>
            <h3 className="delete-account-container-modal-title">
              Please let us know why you want to delete your account.
            </h3>
            {deleteAccountOptions.map((item) => (
              <div
                className="delete-account-container-modal-options"
                key={item.id}
                onClick={() => toggleSelection(item)}
              >
                {reasonSelected(item.id) ? (
                  <div className="delete-account-container-modal-options-selected" />
                ) : (
                  <div className="delete-account-container-modal-options-unselected" />
                )}
                <p className="delete-account-container-modal-options-title">
                  {item.title}
                </p>
              </div>
            ))}
            <div className="delete-account-container-modal-reason">
              <TextArea
                rows={4}
                placeholder="Please explain further.."
                onChange={(e) => setDeleteReason(e.target.value)}
              />
            </div>

            <div className="delete-account-container-modal-button">
              <Button className="btn btn-outline" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                className="btn btn-primary"
                loading={deleteAccLoading}
                disabled={deleteAccLoading}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
          </>
        )

      default:
        break
    }
  }

  return (
    <>
      <div className="delete-account-container">
        <Button className="btn btn-primary" onClick={showModal}>
          Delete Account
        </Button>

        <Modal
          className="delete-account-container-modal"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={null}
          footer={null}
        >
          {handleDeleteSwitch(deleteSwitchCase)}
        </Modal>
      </div>
    </>
  )
}

export default DeleteAccount
