import moment from "moment"
import _ from "lodash"
import { STORAGE_KEYS, getItem, removeItem } from "./local-storage"
import Cimg1 from "../assets/images/gv-thumbnail.png"
import history from "./history"

export const setFormData = (values) => {
  if (values) {
    const fd = new FormData()
    Object.keys(values).forEach((key) => {
      fd.append(key, values[key])
    })
    return fd
  }
  return null
}

export function getFormattedTime(timeToFormat) {
  // If the duration is > of 86400'' (i.e. 24h), this will not work.
  return moment
    .utc(moment.duration(timeToFormat, "seconds").as("milliseconds"))
    .format("HH:mm:ss")
}

export const setUrl = (params, url) => {
  if (params) {
    Object.keys(params).forEach((item, i) => {
      if (i === 0) {
        url += `/?${item}=${params[item]}`
      } else {
        url += `&${item}=${params[item]}`
      }
    })
    return url
  }
  return ""
}

export const hasMoreData = (meta) => {
  if (meta && meta.total && meta.per_page && meta.current_page) {
    let totalPage = Number(meta.total) / Number(meta.per_page)
    // let totalPage = meta.total;
    if (totalPage !== Math.floor(totalPage)) {
      totalPage = Math.floor(totalPage) + 1
    }
    if (totalPage > meta.current_page) {
      return true
    }
  }
  return false
}

export const loadMoreHandler = (state, currentPage, payload) => {
  if (state && currentPage !== 1) {
    return state.concat(payload)
  } else {
    return payload
  }
}

export const handleVisiblePopover = (boolean) => {
  if (boolean) {
    document.body.classList.add("auth-required")
  } else {
    document.body.classList.remove("auth-required")
  }
}

/**
 * @tutorial set margin when player is playing
 * @params Boolean boolean
 * @return void
 */
export const setPlayerMargin = (boolean) => {
  let root = document.getElementById("root")
  let userPlaylistItem = document.getElementById("gv-user-playlist")
  if (userPlaylistItem) {
    if (boolean) {
      root.classList.add("mb-4")
      userPlaylistItem.classList.add("mb-6")
    } else {
      root.classList.remove("mb-4")
      userPlaylistItem.classList.remove("mb-6")
    }
  }
}

export const stripTags = (str) => {
  let stripped = str?.replace(/<\/?[^>]+(>|$)/g, "")
  return stripped
}

export const setPageTitle = (title) => {
  document.title = `Good Vibes - ${_.capitalize(title ?? "")}`
}

export const getTime = (time) => {
  let ntime = time + " min"
  if (time >= 60) {
    ntime = time / 60 + " hr"
    return ntime
  } else {
    return ntime
  }
}

export const getTotalTracksTime = (time) => {
  let formattedTime = ""
  let hrs = Math.floor(time / 3600)
  let mins = Math.floor((time % 3600) / 60)
  let secs = Math.floor(time % 60)

  let t = time ? time.toString().includes(":") : ""

  if (t) {
    return (formattedTime = time)
  } else {
    if (hrs > 0) {
      formattedTime += hrs + ":"
    }

    formattedTime += (mins < 10 ? "0" : "") + mins + ":"
    formattedTime += (secs < 10 ? "0" : "") + secs

    return formattedTime
  }
}

// get fullname
export const renderFullName = (row) => {
  const firstName = row.firstName !== null ? row.firstName : ""
  const middleName = row.middleName !== null ? row.middleName : ""
  const lastName = row.lastName !== null ? row.lastName : ""

  const fullName = firstName.concat(
    " ",
    middleName ? middleName : "",
    " ",
    lastName
  )

  if (fullName !== " ") {
    return fullName
  } else {
    return null
  }
}

export const getCurrentSession = () => {
  let currentSession = {
    authenticated: false,
    accessToken: null,
    refreshToken: null,
    user: null,
    emailVerified: false
  }

  const accessToken = getItem(STORAGE_KEYS.ACCESS_TOKEN)
  const user = getItem(STORAGE_KEYS.USER)

  if (accessToken && user && user.emailConfirmed) {
    currentSession.authenticated = true
    currentSession.accessToken = accessToken
    currentSession.user = user
  }


  // console.log('current session -->', currentSession)

  return currentSession
}

// get images

export const getImages = (image) => {
  if (image !== null) {
    if (image.startsWith("http")) {
      return image
    }
  }
  return Cimg1
}

export const getErrors = (error) => {
  const errors = error?.map((err) => {
    if (err.errors) {
      return err.errors?.response?.message
    } else {
      return err.message
    }
  })

  return errors
}

export const handleLogout = () => {
  removeItem(STORAGE_KEYS.ACCESS_TOKEN)
  removeItem(STORAGE_KEYS.REFRESH_TOKEN)
  removeItem(STORAGE_KEYS.USER)

  history.push("/login")
}
