import axios from "axios"
import { API_URL } from "../config/app.config"

import store from "../store/store"

const httpBase = () => {
  const state = store.getState()

  // console.log({ state })

  const instance = axios.create({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: state.auth.accessToken ? state.auth.accessToken : ""
    },
    baseURL: API_URL
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error && error.response.status === 401) {
      }
      if (error && error.response.status === 500) {
      }
      return Promise.reject(error)
    }
  )

  return instance
}

export default httpBase
