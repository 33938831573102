import React, { useEffect } from "react"

import CardRound from "../../components/Cards/CardRound"
import Cardbanner from "../../components/Cards/Card-banner"
import { BannerSlider } from "../../components/RowSlider"

import { SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper-bundle.css"

import { useDispatch } from "react-redux"
import { fetchPopularTracks } from "../../services/track.service"
import { fetchAllGenres } from "../../services/genre.service"
import { useSelector } from "react-redux"
import Preloader from "../../components/UI/Preloader"
import { Link, useHistory } from "react-router-dom"
import { useQueryHook } from "graphql/hooks"
import { GET_TAGS } from "graphql/queries/tags"
import { getImages } from "utils/helper"
import Bradcrumb from "components/Bradcrumb"

const Discover = () => {
  const history = useHistory()

  // breadcrumbs links

  const linkItem = [
    {
      linkName: "Home",
      linkUrl: "/"
    },
    {
      linkName: "Explore",
      linkUrl: "/explore"
    }
  ]

  const { loading, data, error } = useQueryHook({
    query: GET_TAGS,
    variables: {
      searchAndFilterArgs: { page: 1, limit: 12, publishStatus: true }
    }
  })

  const taglistItem =
    data &&
    data.getTags &&
    data.getTags.tags &&
    data.getTags.tags.length &&
    data.getTags.tags?.map((item, index) => {
      return (
        <li
          className="genre-list__item"
          key={index}
          onClick={() => {
            history.push({
              pathname: `/tags/${item?.id}`,
              state: {
                tags: item
              }
            })
          }}
        >
          <CardRound img={getImages(item.storageKey)} title={item.tagName} />
        </li>
      )
    })

  return (
    <>
      {/* <section className="dash-section dash-section--discover-banner"> */}
      {/* <div className="row">
          {loading ? (
            <Preloader />
          ) : (
            <>
              {popularTracks && popularTracks.length ? (
                <BannerSlider>
                  {popularTracks.slice(0, 5).map((track, index) => (
                    <SwiperSlide key={index}>
                      <Cardbanner
                        title={track.name}
                        titleSize="large"
                        subtitle="trending"
                        text={track.description}
                        favoutire={true}
                        height={"60px"}
                        track={[track]}
                      />
                    </SwiperSlide>
                  ))}
                </BannerSlider>
              ) : (
                ""
              )}
            </>
          )}
        </div> */}
      {/* </section> */}

      <section className="dash-section ">
        <div className="bradcrumb-container">
          <Bradcrumb linkItem={linkItem} />
        </div>
        <div className="row">
          <h2 className="header-primary">
            {" "}
            <span>Top Picks</span>
          </h2>
          <ul className="genre-list">{taglistItem}</ul>
        </div>
      </section>
    </>
  )
}

export default Discover
