import React, { useState, useEffect, useContext } from "react"
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom"
import { Row, Col, Divider, message } from "antd"
import Logo from "../../../assets/images/Brand-Logo.png"
import premiumLeft from "../../../assets/images/premium-btn-img.png"
import premiumRight from "../../../assets/images/premium-btn-img-right.png"

import { Button, Form, Input, Select } from "antd"

import { useSelector, useDispatch } from "react-redux"
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js"
import { subscribeToProductApi } from "../../../services/subscription.service"
import ConfirmationModal from "./confirmationModal"
import { toggleConfirmationModal } from "../../../store/slice/subscription.slice"
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons"
import { getAnalytics, logEvent } from "firebase/analytics"
import { app } from "../../../index"
import visa from "../../../assets/images/credit-card-icons/visa.png"
import mastercard from "../../../assets/images/credit-card-icons/mastercard.png"
import americanexpress from "../../../assets/images/credit-card-icons/american-express.png"
import _ from "lodash"
import { STORAGE_KEYS, getItem, setItem } from "utils/local-storage"

import { useMutationHook, useLazyQueryHook } from "graphql/hooks"
import {
  CREATE_PAYMENT_INTENT,
  CREATE_PAYMENT_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLAN_BY_ID,
  VERIFY_PAYMENT_EMAIL,
  VERIFY_PROMOTION_CODE,
  VERIFY_SUBSCRIPTION
} from "graphql/queries/payment"

import premium from "../../../assets/images/premium.png"
import Check from "../../../assets/images/check.png"
import { ME } from "graphql/queries/auth"
import { getErrors } from "utils/helper"
import { Fragment } from "react"
import Preloader from "components/UI/Preloader"
import moment from "moment"
import { UserContext } from "contexts/userContext"

const { TextArea } = Input

const CheckoutForm = () => {
  const analytics = getAnalytics(app)

  const accessToken = getItem(STORAGE_KEYS.ACCESS_TOKEN)

  const [form] = Form.useForm()
  const { planId } = useParams()
  const { path, params } = useRouteMatch()
  const { pathname, search } = useLocation()

  const user = useContext(UserContext)

  const stripe = useStripe()
  const elements = useElements()

  const [choosedPackage, setChoosedPackage] = useState({})
  const [amount, setAmount] = useState()
  const [errors, setErrors] = useState()
  const [subscribing, setSubscribing] = useState(false)
  const [clientSecret, setClientSecret] = useState()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [userInputValues, setUserInputValues] = useState({})
  const [promotionCode, setPromotionCode] = useState("")
  const [totalAmountWithDiscount, setTotalAmountWithDiscount] = useState(0)
  const [discountedAmount, setDiscountAmount] = useState(0)
  const [promoCodeName, setPromoCodeName] = useState("")
  const [promoCode, setPromocode] = useState("")
  const [promoCodeValue, setPromoCodeValue] = useState("")
  const [email, setEmail] = useState("")
  const [paymentSubmitMessage, setPaymentSubmitMessage] = useState("")

  const purchaseDetails = getItem(STORAGE_KEYS.PURCHASE_USER)

  const senderEmail = purchaseDetails?.senderEmail
  const name = purchaseDetails?.name
  const type = purchaseDetails?.type
  const emails = getItem(STORAGE_KEYS.EMAIL)

  const history = useHistory()

  // logged in email
  const getLoggedInUserEmail = () => {
    let loggedInUserEmail = ""

    const loggedInUser = getItem(STORAGE_KEYS.USER)

    if (loggedInUser && loggedInUser.email) {
      loggedInUserEmail = loggedInUser.email
    }

    return loggedInUserEmail
  }

  // today's date
  const today = Math.floor(new Date())

  if (!emails && getLoggedInUserEmail()) {
    if (
      (path === "/subscription/:planId" ||
        (path === "/subscription/:planId" && search)) &&
      user?.subscriptionIos === "paid" &&
      moment(today) <
        moment(
          user.subscriptionGraceDate ? user.subscriptionGraceDate : new Date()
        )
    ) {
      history.push("/subscribed")
    } else if (
      (pathname === "/subscribed" || pathname === "/subscription-success") &&
      user?.subscriptionIos === "free" &&
      user?.subscriptionIosExpiryDate === null
    ) {
      history.push("/subscription")
    }
  }

  useEffect(() => {
    if (Object.values(choosedPackage).length > 0) {
      setAmount(choosedPackage?.price_detail?.unit_amount / 100)
    }
  }, [choosedPackage])

  useEffect(() => {
    if (purchaseDetails?.promoCode) {
      const promocode = purchaseDetails.promoCode
      setPromocode(promocode)

      // console.log({ promoCode })
    }
  }, [purchaseDetails])

  const {
    func: verifyPromotionCodeFunction,
    loading: verifyPromotionCodeLoading,
    data: verifyPromotionCodeData,
    error: verifyPromotionCodeError
  } = useLazyQueryHook({ query: VERIFY_PROMOTION_CODE })

  const {
    func: planDataFunction,
    loading: planDataLoading,
    data: planDataData,
    error: planDataError
  } = useLazyQueryHook({ query: GET_SUBSCRIPTION_PLAN_BY_ID })

  // query for payment email validation

  const {
    func: verifyPaymentEmailFunc,
    data: verifyPaymentEmailData,
    loading: verifyPaymentEmailLoading,
    error: verifyPaymentEmailError
  } = useLazyQueryHook({ query: VERIFY_PAYMENT_EMAIL })

  // error handling on validation of email

  useEffect(() => {
    if (!verifyPaymentEmailLoading) {
      if (verifyPaymentEmailError) {
        setPaymentSubmitMessage("")
        setSubscribing(false)
        message.error(
          getErrors(verifyPaymentEmailError.graphQLErrors) ||
            verifyPaymentEmailError.message
        )
      }
    }
  }, [verifyPaymentEmailLoading, verifyPaymentEmailError])

  // fetch subscription plan data
  useEffect(() => {
    const variables = { subscriptionId: planId }
    if (planId !== null) {
      planDataFunction({ variables })
    }
  }, [planId])

  // error and plan data handling
  useEffect(() => {
    if (!planDataLoading) {
      if (planDataData && planDataData.getSubscriptionPlanById) {
        setChoosedPackage(planDataData.getSubscriptionPlanById)
      }

      if (planDataError) {
        message.error(
          getErrors(planDataError.graphQLErrors) || planDataError.message
        )
      }
    }
  }, [planDataError, planDataData])

  // promocode call from url
  useEffect(() => {
    if (promoCode) {
      const variables = {
        promotionCode: promoCode,
        subscriptionPlanId: planId
      }

      verifyPromotionCodeFunction({ variables })
        .then((data) => {
          if (data && data.data && data.data.verifyPromotionCode) {
            setPromotionCode(promoCode)
            setPromoCodeValue("")
          }
        })
        .catch((err) => {
          if (err && err.message) {
            setPromotionCode("")
            setDiscountAmount(0)
            setTotalAmountWithDiscount(0)
            setPromoCodeValue("")
          }
        })
    }
  }, [promoCode])

  // const handlePromoCodeSubmit = (values) => {
  //   const variables = {
  //     promotionCode: values.promoCode,
  //     subscriptionPlanId: planId
  //   }

  //   verifyPromotionCodeFunction({ variables })
  //     .then((data) => {
  //       form.resetFields()
  //       if (data && data.data && data.data.verifyPromotionCode) {
  //         setPromotionCode(values.promoCode)
  //         setPromoCodeValue("")
  //       }
  //     })
  //     .catch((err) => {
  //       if (err && err.message) {
  //         setPromotionCode("")
  //         setDiscountAmount(0)
  //         setTotalAmountWithDiscount(0)
  //         setPromoCodeValue("")
  //       }
  //     })
  // }

  useEffect(() => {
    if (!verifyPromotionCodeLoading) {
      if (
        verifyPromotionCodeData &&
        verifyPromotionCodeData.verifyPromotionCode &&
        amount
      ) {
        if (
          verifyPromotionCodeData.verifyPromotionCode.amountOff ||
          verifyPromotionCodeData.verifyPromotionCode.percentOff
        ) {
          setPromoCodeName(verifyPromotionCodeData?.verifyPromotionCode?.name)
          let AmountAfterDiscount = 0
          let discountAmount = 0
          if (verifyPromotionCodeData.verifyPromotionCode.amountOff !== null) {
            AmountAfterDiscount = (
              amount - verifyPromotionCodeData.verifyPromotionCode.amountOff
            ).toFixed(2)
            discountAmount =
              verifyPromotionCodeData.verifyPromotionCode.amountOff.toFixed(2)
          } else {
            AmountAfterDiscount = (
              amount -
              (amount / 100) *
                verifyPromotionCodeData.verifyPromotionCode.percentOff
            ).toFixed(2)

            discountAmount = (
              (amount / 100) *
              verifyPromotionCodeData.verifyPromotionCode.percentOff
            ).toFixed(2)
          }

          setTotalAmountWithDiscount(AmountAfterDiscount)
          setDiscountAmount(discountAmount)
        }
      }
    }
  }, [verifyPromotionCodeData, verifyPromotionCodeLoading, amount])

  useEffect(() => {
    if (!verifyPromotionCodeLoading) {
      if (verifyPromotionCodeError) {
        setPromotionCode("")
        setTotalAmountWithDiscount(0)
        setPromoCodeValue("")
        message.error(
          getErrors(verifyPromotionCodeError.graphQLErrors) ||
            verifyPromotionCodeError.message
        )
      }
    }
  }, [verifyPromotionCodeLoading])

  // payment creation

  const handleStripeSubmit = async (values) => {
    setPaymentSubmitMessage("Creating your payment...")
    const cardElement = elements.getElement(CardNumberElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: values.name,
        email: values.email
      }
    })

    if (!error) {
      // console.log("Stripe 23 | token generated!", paymentMethod)
      handleSubscribeProduct(values, paymentMethod.id)

      // if (paymentMethod && paymentMethod.id) {
      //   setClientSecret(paymentMethod.id)
      // }
    } else {
      setPaymentSubmitMessage("")
      setSubscribing(false)
      setErrors({ ...errors, card_info: error.message })
    }
  }

  const handleSubmit = async (values) => {
    setUserInputValues(values)
    setSubscribing(true)

    const variables = {
      email: emails,
      subscriptionOffer: {
        senderEmail: senderEmail,
        name: name,
        type: type,
        senderName: values.userName,
        textMessage: values.message
      }
    }

    if (emails && emails !== null) {
      setPaymentSubmitMessage("Validating email...")
      verifyPaymentEmailFunc({ variables: variables }).then((data) => {
        if (data.data) {
          handleStripeSubmit(values)
        }
      })
    } else {
      handleStripeSubmit(values)
    }
  }

  const {
    func: createPaymentSubscriptionFunction,
    loading: createPaymentSubscriptionLoading,
    data: createPaymentSubscriptionData,
    error: createPaymentSubscriptionError
  } = useMutationHook({ query: CREATE_PAYMENT_SUBSCRIPTION })

  const {
    func: confirmSubscriptionFunction,
    loading: confirmSubscriptionLoading,
    data: confirmSubscriptionData,
    error: confirmSubscriptionError
  } = useMutationHook({ query: VERIFY_SUBSCRIPTION })

  const {
    func: meFunc,
    data: meData,
    loading: meLoading,
    error: meError
  } = useLazyQueryHook({ query: ME })

  const handleSubscribeProduct = (userInputValues, paymentMethodId) => {
    setPaymentSubmitMessage("Confirming your payment...")
    let variables = {
      email: emails ? emails : getLoggedInUserEmail(),
      cardName: userInputValues.name,
      cardEmail: userInputValues.email,
      paymentMethodId: paymentMethodId,
      subscriptionPlanId: planId,
      subscriptionOffer: {
        senderEmail: senderEmail,
        name: name,
        type: type,
        senderName: userInputValues.userName,
        textMessage: userInputValues.message
      }
    }

    if (promotionCode !== "") {
      variables.promotionCode = promotionCode
    }

    createPaymentSubscriptionFunction({ variables: variables })
  }

  useEffect(() => {
    if (!createPaymentSubscriptionLoading) {
      if (
        createPaymentSubscriptionData &&
        createPaymentSubscriptionData.createPaymentSubscription
      ) {
        logEvent(analytics, "plan_subscribed")

        if (
          createPaymentSubscriptionData.createPaymentSubscription.clientSecret
        ) {
          stripe
            .confirmCardPayment(
              createPaymentSubscriptionData.createPaymentSubscription
                .clientSecret
            )
            .then((result) => {
              if (result.error) {
                message.error(result?.error?.message ?? "Something went wrong")
                setPaymentSubmitMessage("")
                setSubscribing(false)
              } else {
                if (result?.paymentIntent?.status === "succeeded") {
                  // dispatch(productPaymentSuccess({ data: result?.paymentIntent }))
                  logEvent(analytics, "plan_payment_success")
                  confirmSubscriptionFunction({
                    variables: {
                      invoiceId:
                        createPaymentSubscriptionData.createPaymentSubscription
                          .invoiceId
                    }
                  })
                } else {
                  logEvent(analytics, "plan_payment_failure")
                  message.error("Something went wrong")
                  setPaymentSubmitMessage("")
                  setSubscribing(false)
                }
              }
            })
            .catch((err) => {
              logEvent(analytics, "plan_payment_failure", {
                message: err?.message
              })
              message.error(err?.message ?? "Something went wrong")
              setPaymentSubmitMessage("")
              setSubscribing(false)
            })
        } else {
          logEvent(analytics, "plan_payment_success")
          confirmSubscriptionFunction({
            variables: {
              invoiceId:
                createPaymentSubscriptionData.createPaymentSubscription
                  .invoiceId
            }
          })
        }
      }

      if (createPaymentSubscriptionError) {
        setPaymentSubmitMessage("")
        setSubscribing(false)
        message.error(
          createPaymentSubscriptionError.message ||
            createPaymentSubscriptionError
        )
      }
    }
  }, [
    createPaymentSubscriptionLoading,
    createPaymentSubscriptionData,
    createPaymentSubscriptionError
  ])

  useEffect(() => {
    if (!confirmSubscriptionLoading) {
      if (confirmSubscriptionData) {
        setPaymentSubmitMessage("Updating your payment...")
        if (accessToken === null) {
          history.push("/subscription-success" + search)
        } else {
          meFunc()
        }
      }
    }
  }, [confirmSubscriptionLoading, confirmSubscriptionData])

  useEffect(() => {
    if (!meLoading) {
      if (meData) {
        const {
          firstName,
          lastName,
          email,
          profilePicStorageKey,
          tags,
          emailConfirmed,
          subscriptionIos,
          subscriptionIosExpiryDate
        } = meData.me

        const userDetails = {
          firstName,
          lastName,
          email,
          image: profilePicStorageKey,
          emailConfirmed,
          tags: tags,
          subscriptionIos,
          subscriptionIosExpiryDate
        }
        setItem(STORAGE_KEYS.USER, userDetails)
        history.push("/subscription-success" + search)
        message.success("Payment successful")
      }
    }
  }, [meData, meLoading])

  // error hide on onChange
  const onValuesChange = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field)
      const promoCode = form.getFieldValue("promoCode")

      setPromoCodeValue(promoCode)

      if (!error.length) {
        return
      }
      // Clear error message of field
      form.setFields([
        {
          name: field,
          errors: []
        }
      ])
    })
  }

  const cancelPromoCode = () => {
    setTotalAmountWithDiscount("")
    setAmount(amount)
    setPromotionCode("")
    setDiscountAmount(0)
  }

  const getPromoCodeTag = () => {
    return (
      <div className="promocode-tag">
        <div>{promotionCode}</div>
        <div className="promocode-tag-close" onClick={cancelPromoCode}>
          <CloseCircleFilled />
        </div>
      </div>
    )
  }

  // redirection on email doesn't exist

  if (emails === null && getLoggedInUserEmail() === "") {
    history.push({
      pathname: "/login",
      state: { path, pathname, params, search }
    })
  }

  return (
    <div className="offer-payment-container">
      {planDataLoading ? (
        <Preloader />
      ) : (
        <div className="subscription-layout subscription-layout--card-details">
          {/* {!view || view === "" || view !== "mobile" ? (
            <div className="cancel-wrapper">
              <CloseOutlined
                style={{ fontSize: 30 }}
                onClick={() => history.push("/subscription")}
              />
            </div>
          ) : (
            ""
          )} */}
          <div className="container">
            <div className="payment-logo">
              <img src={Logo} alt="brand-logo" className="logo" />
            </div>
            {/* <div className="payment-title">
              <h1 className="choose-package-title">{choosedPackage?.name}</h1>
            </div> */}
            <Form
              layout="vertical"
              size="large"
              initialValues={{
                promoCode: promoCode !== "" ? promoCode : ""
              }}
              onFinish={(values) => {
                handleSubmit(values)
              }}
              onValuesChange={onValuesChange}
            >
              <Row gutter={[25, 15]} align="center">
                <Col xs={24} lg={12}>
                  <div className="subscription-right promo-card-container-wrapper">
                    <label className="form-label" htmlFor="userName">
                      Your Name *
                    </label>
                    <Form.Item
                      name="userName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name!!"
                        }
                      ]}
                      className="name-on-card"
                    >
                      <Input placeholder="Eg: John Doe" />
                    </Form.Item>
                    <label className="form-label" htmlFor="message">
                      Your Message *
                    </label>
                    <Form.Item
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your message!!"
                        }
                      ]}
                      className="name-on-card"
                    >
                      <TextArea rows={10} placeholder="Write your message" />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24} lg={12}>
                  <div className="subscription-right promo-card-container-wrapper">
                    <div className="auth-form-wrapper">
                      <div className="credit-card-wrapper">
                        <h3>
                          Card Info <span className="asterisk">*</span>
                        </h3>

                        <div className="credit-card-icon">
                          <div className="card-number-wrapper">
                            <CardNumberElement
                              options={{
                                style: {
                                  invalid: {
                                    color: "#9e2146"
                                  }
                                }
                              }}
                            />
                          </div>
                          <div className="card-icon-list">
                            <img src={visa} alt="" />
                            <img src={mastercard} alt="" />
                            <img src={americanexpress} alt="" />
                          </div>
                        </div>
                        <div className="credit-card-info">
                          <div className="card-element-wrapper">
                            <CardExpiryElement
                              options={{
                                style: {
                                  invalid: {
                                    color: "#9e2146"
                                  }
                                }
                              }}
                            />
                          </div>
                          <div className="card-element-wrapper">
                            <CardCvcElement
                              options={{
                                style: {
                                  invalid: {
                                    color: "#9e2146"
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>

                        {errors?.card_info ? (
                          <span>{errors?.card_info}</span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <label className="form-label" htmlFor="name">
                        Name on Card *
                      </label>
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !!"
                          }
                        ]}
                        className="name-on-card"
                      >
                        <Input placeholder="Enter Your Name" />
                      </Form.Item>

                      <label className="form-label" htmlFor="email">
                        Email on Card *
                      </label>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !!"
                          }
                        ]}
                        className="name-on-card"
                      >
                        <Input placeholder="Enter Your Email" />
                      </Form.Item>

                      <p className="payment-progress-message">
                        {paymentSubmitMessage}
                      </p>
                    </div>
                  </div>
                  {/* </Col>
              <Col xs={24} lg={10}> */}
                  <div className="promo-card-container">
                    <div className="promo-card-container-wrapper">
                      <label className="form-label">Promo Code</label>

                      {/* <Form
                      className="promo-card-container-wrapper-form"
                      onFinish={handlePromoCodeSubmit}
                      form={form}
                      validateTrigger="onSubmit"
                      onValuesChange={onValuesChange}
                    > */}
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required !!"
                          }
                        ]}
                        name="promoCode"
                        className="name-on-card"
                      >
                        <Input placeholder="Enter promo code" />
                      </Form.Item>
                      {/* <Form.Item>
                        <Button
                          htmlType="submit"
                          className="btn btn-primary promo-card-container-wrapper-form-btn"
                          disabled={promoCodeValue === ""}
                          loading={verifyPromotionCodeLoading}
                        >
                          APPLY
                        </Button>
                      </Form.Item>
                    </Form> */}
                      <div style={{ display: "flex" }}>
                        {promotionCode !== "" &&
                        !verifyPromotionCodeError &&
                        !verifyPromotionCodeError?.length
                          ? getPromoCodeTag()
                          : ""}
                      </div>
                    </div>

                    <div className="promo-card-container-wrapper">
                      <b>Order Summary</b>
                      <Row style={{ marginTop: "10px" }}>
                        <Col span={20}>
                          {/* <span>{choosedPackage?.name}</span> */}
                          <span>1-year Good Vibes Premium</span>
                        </Col>
                        <Col span={4}>
                          <span>${amount}</span>
                        </Col>
                      </Row>
                      {promotionCode !== "" && !verifyPromotionCodeError ? (
                        <Row>
                          <Col span={20}>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div style={{ marginRight: "5px" }}>
                                Promo code discount
                              </div>{" "}
                              {getPromoCodeTag()}
                            </div>
                          </Col>
                          <Col span={4}>
                            <span style={{ marginLeft: "-6px" }}>
                              -${discountedAmount}
                            </span>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      <Row style={{ marginTop: "20px" }}>
                        <Col span={20}>
                          <b>Total after discount </b>
                        </Col>
                        <Col span={4}>
                          <b>
                            $
                            {totalAmountWithDiscount
                              ? totalAmountWithDiscount
                              : amount}
                          </b>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="btn btn-primary btn-large"
                      loading={subscribing}
                      style={{ width: "100%", marginTop: "0" }}
                    >
                      Purchase 1-Year Subscription
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <ConfirmationModal
            clientSecret={clientSecret}
            showConfirmationModal={showConfirmationModal}
            closeConfirmationModal={() => setShowConfirmationModal(false)}
            userInputValues={userInputValues}
            packageDetail={choosedPackage}
          />
        </div>
      )}
    </div>
  )
}

export default CheckoutForm
