import React, { useEffect } from "react"
import Dashsection from "../../components/DashSection"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { fetchTracksByTagsRecommendation } from "../../services/track.service"
import Preloader from "../../components/UI/Preloader"
import ScrollPagination from "components/ScrollPagination"

//graphql imports
import { useQueryHook } from "../../graphql/hooks"
import { GET_POPULAR_TRACKS_BY_TAGS } from "../../graphql/queries/track"
import { useState } from "react"

const PopularPage = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const { recommended_tracks, loadingRT, metaRT } = state.home
  const [limit, setLimit] = useState(20)
  const [currentPage, setcurrentPage] = useState(1)

  const { loading, data, error } = useQueryHook({
    query: GET_POPULAR_TRACKS_BY_TAGS,
    variables: {
      getTracksDto: { page: currentPage, limit: limit, platform: "WEB" }
    }
  })
  const [popularDataRow, setPopularDataRow] = useState([])
  useEffect(() => {
    if (data && data.getPopularTracksByTags?.length > 0) {
      setPopularDataRow(data.getPopularTracksByTags)
    }
  }, [data])

  const [total, setTotal] = useState(30)
  function changeImageUrl(url) {
    let word = "vertical"
    const splitUrl = url.split("/")
    const insertIndex = 4
    splitUrl.splice(insertIndex, 0, word)
    const newUrl = splitUrl.join("/")
    // console.log("my url", newUrl)

    return newUrl
  }

  return (
    <>
      <Dashsection title="Popular Tracks" link="/popular-tracks">
        {loading ? <Preloader /> : ""}
        <>
          {popularDataRow ? (
            <>
              <RowCardlist
                cardlist={
                  <>
                    {popularDataRow.map((item) => {
                      {
                        /* const { track } = item; */
                      }
                      return (
                        <>
                          <Col
                            // md={12}
                            // lg={8}
                            // xl={6}
                            // xxl={4}
                            className="card-list__item"
                            key={item.id}
                          >
                            <Card
                              img={
                                item.storageKeys.azure &&
                                item.storageKeys.azure.image
                                  ? item.storageKeys.azure.image
                                  : Cimg1
                              }
                              defaultImg={Cimg1}
                              premium={
                                item.trackType === "PREMIUM" ||
                                item.trackType === "premium" ||
                                item.trackType === "PAID" ||
                                item.trackType === "paid" ||
                                item.trackType === "TRIAL" ||
                                item.trackType === "trial"
                                  ? true
                                  : false
                              }
                              title={item.title}
                              link={`/track/${item.slug}`}
                              id={item.id}
                            />
                          </Col>
                        </>
                      )
                    })}
                  </>
                }
              />

              {/*end infinite scrolling */}
            </>
          ) : (
            <>{loading ? "" : <div>Sorry no premium tracks found !!</div>}</>
          )}
        </>
      </Dashsection>

      <ScrollPagination
        current_page={currentPage}
        // // pagination={loadMoreRecentlyPlayedSongs}
        // // HasMore={recentlyPlayedAllHasMoreData}
        loadingMore={loading}
        setLimit={setLimit}
        seTcurrentPage={setcurrentPage}
        limit={limit}
        data={popularDataRow}
        total={total}
      />
    </>
  )
}

export default PopularPage
