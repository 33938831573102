//**apolo */
import { gql } from "@apollo/client"

export const GET_TAGS = gql`
  query ($limit: Int!, $page: Int!, $keyword: String, $publishStatus:Boolean) {
    getTags(
      searchAndFilterArgs: { limit: $limit, page: $page, keyword: $keyword ,publishStatus:$publishStatus}
    ) {
      tags {
        id
        description
        tagName
        publishStatus
        createdDTM
        storageKey
        tracksCount
      }
      meta {
        page
        limit
        total
        total_pages
        total_items
      }
    }
  }
`

export const GET_TAG_BY_ID = gql`
  query ($id: String!) {
    getTagById(id: $id) {
      description
      id
      tagName
    }
  }
`
