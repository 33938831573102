import React, { useEffect, useState } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { fetchTracksByGenre } from "../../services/track.service"
import { fetchSingleGenre } from "../../services/genre.service"
import Dashsection from "../../components/DashSection"
import Rowcardlist from "../../components/Cards/RowCardList"
import { Col, message } from "antd"
import Card from "../../components/Cards/Card"
import Preloader from "../../components/UI/Preloader"
import Cimg1 from "../../assets/images/Thumbnail.png"
import Songlistitem from "../../components/songlist"
import Cardsonglist from "../../components/Cards/Cardsonglist"
import Cardbanner from "../../components/Cards/Card-banner"
import ScrollPagination from "../../components/UI/Scrollpagination"
import { getImages, getTotalTracksTime, setPageTitle } from "../../utils/helper"
import { GET_TRACKS_BY_TAGS } from "graphql/queries/track"
import { useQueryHook } from "graphql/hooks"
import Bradcrumb from "components/Bradcrumb"
import { GET_TAG_BY_ID } from "query/tagsQuery"

const Tags = () => {
  const { id } = useParams()
  const location = useLocation()

  const { loading, data, error } = useQueryHook({
    query: GET_TRACKS_BY_TAGS,
    variables: {
      tracksByTagsArgs: { page: 1, limit: 12, tagIds: [id] }
    }
  })

  const {
    loading: tagsLoading,
    data: tagsData,
    error: tagsError
  } = useQueryHook({
    query: GET_TAG_BY_ID,
    variables: { id }
  })

  // console.log("tagsData", tagsData)
  const [tagesName, setTagsname] = useState("")
  useEffect(() => {
    if (location !== undefined) setPageTitle(location?.state?.tags?.tagName)
    setTagsname(location?.state?.tags?.tagName)
  }, [location])

  useEffect(() => {
    if (!loading) {
      if (error) {
        message.error(error.message || error)
      }
    }
  }, [loading, error])

  const linkItem = [
    {
      linkName: "Home",
      linkUrl: "/"
    },
    {
      linkName: "Explore",
      linkUrl: "/explore"
    },
    {
      linkName: tagesName,
      linkUrl: "/explore",
      // slug: individualPlaylistData?.getPlaylistBySlug?.slug
      disable: true
    }
  ]
  return (
    <>
      {loading ? <Preloader /> : ""}
      <>
        {tagsData !== "" ? (
          <>
            <section className="dash-section">
              <Bradcrumb linkItem={linkItem} />

              <Cardbanner
                img={location?.state?.tags.storageKey || Cimg1}
                title={tagesName}
                text={location?.state?.tags.description}
                track={data?.getTracksByTags?.tracks}
                // totalTracks={metaTracksByGenre?.total}
                fromTag={true}
              />
            </section>
          </>
        ) : (
          ""
        )}

        <section className="dash-section dash-section--playlist">
          {data &&
          data.getTracksByTags &&
          data.getTracksByTags.tracks &&
          data.getTracksByTags.tracks.length ? (
            <>
              <Cardsonglist
                songlist={
                  <>
                    {data.getTracksByTags.tracks.map((item, index) => {
                      {
                        /* item = item.track; */
                      }
                      return (
                        <Songlistitem
                          index={index < 9 ? "0" + (index + 1) : index + 1}
                          title={item?.title}
                          category={
                            item?.category ? item?.category?.name : "N/A"
                          }
                          duration={getTotalTracksTime(item?.duration)}
                          thumbnail={getImages(item?.storageKeys?.azure?.image)}
                          track={item}
                          fromTag={true}
                          // allTracks={tracksByGenre}
                          isPlaylist={true}
                          pathname={tagesName}
                        />
                      )
                    })}
                  </>
                }
              />
              {/* infinite scrolling */}
              {/* {metaTracksByGenre ? (
                <ScrollPagination
                  meta={metaTracksByGenre}
                  pagination={loadTracks}
                  endText={"No more tracks"}
                  loading={loadingTracksByGenre}
                />
              ) : (
                ""
              )} */}
              {/*end infinite scrolling */}
            </>
          ) : (
            <div className="text-center">No tracks found.</div>
          )}
        </section>
      </>
    </>
  )
}

export default Tags
