import React from "react"
import { useLocation } from "react-router-dom"

import { Button, Col, Form, Input, Row } from "antd"

import { setItem, STORAGE_KEYS } from "utils/local-storage"
import history from "utils/history"

// import css
import "./styles.scss"
// image import
import BtnLeft from "../../../assets/images/offer/btn-left.svg"
import BtnRight from "../../../assets/images/offer/btn-right.svg"

import offerBannerImg from "../../../assets/images/offer/offerBannerImg.svg"

const Banner = () => {
  const { search } = useLocation()

  const planId = "prod_K1yyMpWSvxjzyl"

  const senderEmail = new URLSearchParams(search).get("senderEmail")
  const name = new URLSearchParams(search).get("name")
  const type = new URLSearchParams(search).get("type")
  const promoCode = new URLSearchParams(search).get("promoCode")

  const purchaseDetails = {
    senderEmail: senderEmail,
    name: name,
    type: type,
    promoCode: promoCode
  }

  const onFinish = (values) => {
    setItem(STORAGE_KEYS.EMAIL, values.email)
    setItem(STORAGE_KEYS.PURCHASE_USER, purchaseDetails)
    history.push(`/payment/${planId}`)
  }
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo)
  }
  return (
    <div className="banner-wrapper">
      <Row>
        <Col md={16} lg={14} xs={24} sm={24}>
          <div className="banner-wrapper_left">
            <h2>Gift GoodVibes Experience</h2>
            <p>
              This Valentine’s gift your loved one <span>one-year</span>{" "}
              GoodVibes subscription. Let them know how much you care about
              their well-being and happiness.
            </p>
            <div className="form-wrapper">
              <Form
                name="basic"
                labelCol={{
                  span: 24
                }}
                wrapperCol={{
                  span: 16
                }}
                style={{
                  maxWidth: 400
                }}
                initialValues={{
                  remember: true
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Gift Receiver’s Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Email is not valid!"
                    },
                    {
                      required: true,
                      message: "Please input email!"
                    }
                  ]}
                >
                  <Input placeholder="Enter email address" />
                </Form.Item>
                <div className="btn-wrapper">
                  <Button htmlType="submit">
                    <img src={BtnRight} alt="btn-left" />
                    &nbsp; Send Gift&nbsp;
                    <img src={BtnLeft} alt="btn-left" />
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col md={8} lg={10} xs={24} sm={24}>
          <div className="banner-right">
            <img src={offerBannerImg} alt="offer-banner" />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Banner
