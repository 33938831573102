import { LockOutlined } from "@ant-design/icons"
import { UserContext } from "contexts/userContext"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import gvLogo from "../../assets/images/gv-icon-white.png"
import cimg from "../../assets/images/Thumbnail.png"
import userMixPanelEvents from "functions/mixPanelEvents"

const Cardregular = ({
  img: Imagesrc,
  id: id,
  title: Title,
  layout,
  link: Cardlink,
  subtitle: Subtitle,
  onPlusClick,
  pathname,
  sectiontitle,
  onPlayClick,
  existsInTheQueue,
  premium,
  defaultImg,
  fromTrack,
  sessionDetails
}) => {
  const user = useContext(UserContext)
  let history = useHistory()
  const today = Math.floor(new Date())
  const subscriptionLink = "/subscription"

  const toInnerPage = (id) => {
    history.push({
      pathname: getTrackRoute(),
      state: {
        id: id,
        pathname: pathname,
        sessionDetails: sessionDetails !== undefined ? sessionDetails : "",
        fromTrack: fromTrack ? fromTrack : ""
      }
    })
    // mix panel events
    userMixPanelEvents("click_through_rate", {
      source: sectiontitle,
      name: id
    })
  }

  const getTrackRoute = () => {
    if (premium) {
      if (
        user.subscriptionIos === "paid" ||
        (user?.subscriptionIos === "trial" &&
          moment(today) <=
            moment(
              user.subscriptionGraceDate
                ? user.subscriptionGraceDate
                : new Date()
            ))
      ) {
        return Cardlink
      } else {
        return subscriptionLink
      }
    } else {
      return Cardlink
    }
  }

  if (layout === "horizontal") {
    return (
      <div className="card card--horizontal">
        <Link
          to={{ pathname: { Cardlink }, state: id }}
          className="card-link"
        />
        <div className="card-img">
          <img src={Imagesrc || cimg} alt="" />
          <span
            class="iconlyBulk-Play"
            onClick={() => {
              onPlayClick()
            }}
          >
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
        </div>
        <div className="card__body">
          <h3>{Title}</h3>
          {existsInTheQueue ? (
            <></>
          ) : (
            <div
              className="card__add-btn"
              onClick={() => {
                onPlusClick()
              }}
            >
              <i class="iconly-Plus icli"></i>
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="card card--regular" onClick={() => toInnerPage(id)}>
        {premium && user?.subscriptionIos === "free" ? (
          <div className="card-premium">
            <LockOutlined /> Premium
          </div>
        ) : (
          ""
        )}
        {/* <Link
          to={{ pathname: getTrackRoute(), state: id }}
          className="card-link"
        /> */}
        <div className="card__header">
          <img
            src={Imagesrc || cimg}
            alt=""
            className="card-img"
            onError={(e) =>
              // (e.target.onerror = null)
              (e.target.src = defaultImg)
            }
            // width="100%"
            // height="100%"
          />
        </div>
        <div className="card__body">
          <h3 title={Title}>
            {/* <Link
              to={{ pathname: getTrackRoute(), state: id }}
              style={{ color: "#000" }}
            > */}{" "}
            {Title?.length > 50 ? Title.substring(0, 45) + "..." : Title}
            {/* </Link> */}
          </h3>
          {/* <h3>{Title}</h3> */}
          {/* <p className={`${Subtitle ? "subtitle" : "d-none"}`}>{Subtitle}</p> */}
        </div>
      </div>
    )
  }
}

export default Cardregular
