import React, { useEffect, useState } from "react"
import Dashsection from "../../components/DashSection"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col } from "antd"
import { GET_RECENTLY_PLAYED_TRACKS } from "graphql/queries/track"
import { useQueryHook } from "graphql/hooks"
import userMixPanelEvents from "functions/mixPanelEvents"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { RowSlider } from "components/RowSlider"

const RecentlyplayedDetail = () => {
  // variables for query
  const [limit, setLimit] = useState(30)
  const [currentPage, setcurrentPage] = useState(1)

  // graphql query
  const { loading, data, error } = useQueryHook({
    query: GET_RECENTLY_PLAYED_TRACKS,
    fetchPolicy: "cache-and-network",
    variables: {
      getTracksDto: { page: currentPage, limit: limit, platform: "WEB" }
    }
  })
  // mix panel events
  useEffect(() => {
    userMixPanelEvents("screen_view", { screen: "recently-played-detail" })
  }, [])

  // function changeImageUrl(url) {
  //   let word = "square"
  //   const splitUrl = url.split("/")
  //   const insertIndex = 4
  //   splitUrl.splice(insertIndex, 0, word)
  //   const newUrl = splitUrl.join("/")

  //   return newUrl
  // }

  const [currentLocation, setlocation] = useState("")
  const location = useLocation()
  useEffect(() => {
    setlocation(location.pathname)
  }, [currentLocation])

  useEffect(() => {
    if (currentLocation === "/recently-played") {
      setLimit(30)
    } else {
      setLimit(7)
    }
  }, [currentLocation])

  return (
    <>
      {data && data?.getRecentlyPlayedTracks?.length > 0 ? (
        <Dashsection
          title="Recently Played"
          // link="/recently-played"
          showBreadcrumb={
            currentLocation === "/recently-played-detail" ? true : false
          }
        >
          {/* {loading ? <Preloader /> : ""} */}
          <>
            {data &&
            data?.getRecentlyPlayedTracks &&
            data?.getRecentlyPlayedTracks?.length ? (
              <>
                <RowCardlist
                  cardlist={
                    <>
                      {data?.getRecentlyPlayedTracks.map((item) => {
                        // const { track } = item
                        return (
                          <>
                            <Col
                              // {...layout.full}
                              className="card-list__item"
                              key={item.id}
                            >
                              <Card
                                img={
                                  item.storageKeys.azure &&
                                  item.storageKeys.azure.image
                                    ? item.storageKeys.azure.image
                                    : Cimg1
                                }
                                defaultImg={Cimg1}
                                premium={
                                  item.trackType === "PREMIUM" ||
                                  item.trackType === "PAID" ||
                                  item.trackType === "TRIAL"
                                    ? true
                                    : false
                                }
                                title={item.title}
                                link={`/track/${item.slug}`}
                                id={item.id}
                                pathname="recently-played"
                              />
                            </Col>
                          </>
                        )
                      })}
                    </>
                  }
                />
              </>
            ) : (
              <>{loading ? "" : <div>Sorry no play history found !!</div>}</>
            )}
          </>
        </Dashsection>
      ) : currentLocation === "/recently-played-detail" ? (
        <div style={{ marginLeft: "4rem", marginTop: "1rem" }}>
          Sorry no play history found !!
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default RecentlyplayedDetail
