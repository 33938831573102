import { useQuery, useLazyQuery, useMutation, gql } from "@apollo/client"

const defaultErrorPolicy = "all"
const defaultFetchPolicy = "network-only"
const defaultNotifyOnNetworkStatusChange = true

export const useQueryHook = ({ query, variables, fetchPolicy }) => {
  const { loading, data, error } = useQuery(
    gql`
      ${query}
    `,
    {
      variables: variables || {},
      errorPolicy: defaultErrorPolicy,
      fetchPolicy: fetchPolicy || defaultFetchPolicy,
      notifyOnNetworkStatusChange: defaultNotifyOnNetworkStatusChange
    }
  )

  return { loading, data, error }
}

export const useLazyQueryHook = ({ query, headers, fetchPolicy }) => {
  const [func, { loading, data, error }] = useLazyQuery(
    gql`
      ${query}
    `,
    {
      errorPolicy: defaultErrorPolicy,
      fetchPolicy: fetchPolicy || defaultFetchPolicy,
      notifyOnNetworkStatusChange: defaultNotifyOnNetworkStatusChange,
      context: {
        headers: headers ? headers : {}
      }
    }
  )

  return { func, loading, data, error }
}

export const useMutationHook = ({ query, refetchQueries, fetchPolicy }) => {
  const [func, { loading, data, error }] = useMutation(
    gql`
      ${query}
    `,
    {
      errorPolicy: defaultErrorPolicy,
      fetchPolicy: fetchPolicy || defaultFetchPolicy,
      notifyOnNetworkStatusChange: defaultNotifyOnNetworkStatusChange,
      refetchQueries:
        refetchQueries && refetchQueries.length
          ? refetchQueries.map((r) => ({
            ...r,
            query: gql`
                ${r.query}
              `
          }))
          : []
    }
  )

  return { func, loading, data, error }
}
