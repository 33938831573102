import React, { useState, useEffect } from "react"
import { Row, Col, Form, Input, Switch, Modal, message } from "antd"

import ChangePassword from "./changepassword-form"
import PaymentComponent from "./payment.component"
import EditUserDetail from "./editUserDetail"
import EditAvatar from "./editAvatar"
import { updateProfileApi } from "../../services/user.service"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllSubscriptionApi } from "../../services/subscription.service"
import { productPaymentSuccess } from "../../store/slice/subscription.slice"
import Notification from "./notifications"

import Preloader from "../../components/UI/Preloader"
//graphql imports
import { useQueryHook } from "graphql/hooks"
import { ME } from "graphql/queries/auth"
import DeleteAccount from "./deleteAccount"
import userMixPanelEvents from "functions/mixPanelEvents"

const Settings = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [avatarLoading, setAvatarLoading] = useState(false)
  const subscriptionReducer = useSelector((state) => state.subscription)
  const userReducer = useSelector((state) => state.user)

  const [paymentResponse, setPaymentResponse] = useState()

  const { loading: meLoading, data: meData, error: meError } = useQueryHook({
    query: ME
  })

  // const handleProfileUpdate = ({ data, isAvatar }) => {
  //   if (isAvatar) {
  //     setAvatarLoading(true)
  //   } else {
  //     setLoading(true)
  //   }
  //   dispatch(
  //     updateProfileApi({
  //       data: data,
  //       successCallback: () => {
  //         message.success("Update Success")
  //       },
  //       finalCallback: () => {
  //         setLoading(false)
  //         setAvatarLoading(false)
  //       }
  //     })
  //   )
  // }

  useEffect(() => {
    userMixPanelEvents("screen_view", { screen: "profile" })
  }, [])

  useEffect(() => {
    if (
      paymentResponse !== subscriptionReducer?.productPaymentResponse &&
      subscriptionReducer?.productPaymentResponse
    ) {
      dispatch(fetchAllSubscriptionApi())
      dispatch(productPaymentSuccess({ data: undefined }))
    }
    if (subscriptionReducer?.productPaymentResponse) {
      setPaymentResponse(subscriptionReducer?.productPaymentResponse)
    }
  }, [subscriptionReducer?.productPaymentResponse])

  if (userReducer?.loadingSetting) {
    return <Preloader />
  }

  return (
    <section className="dash-section">
      <Row gutter={[30, 30]}>
        <Col xs={24} lg={12}>
          <div className="card card--personal-info card--personal-info--avatar">
            <h3 className="card__header">Your Avatar</h3>
            <EditAvatar
              // handleProfileUpdate={handleProfileUpdate}
              loading={meLoading}
              userData={meData && meData.me ? meData.me : null}
            />
            <EditUserDetail
              // handleProfileUpdate={handleProfileUpdate}
              loading={meLoading}
              userData={meData && meData.me ? meData.me : null}
            />
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <Notification
          // notificationData={notificationObj?.getUserNotification}
          />

          <ChangePassword />

          <PaymentComponent />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={12} md={6} lg={4}>
          <DeleteAccount />
        </Col>
      </Row>
    </section>
  )
}

export default Settings
