import { Button, message } from "antd"
import { useQueryHook } from "graphql/hooks"
import { ME } from "graphql/queries/auth"
import React, { useEffect } from "react"
import { Link, useHistory, useLocation } from "react-router-dom"
import { getErrors } from "utils/helper"
import { setItem, STORAGE_KEYS } from "utils/local-storage"

const SubscriptionSuccessCom = () => {

  const { search } = useLocation()

  const view = new URLSearchParams(search).get("view")

  let history = useHistory()
  const RedirectHome = () => {
    history.push("/")
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper__success">
        <div className="heading">
          <h2>Purchase Successful!</h2>
        </div>
        <div className="text-small">
          <p>
            You have successfully purchased Good Vibes Premium. Enjoy the of
            Good Vibes experience.
          </p>
        </div>
        {!view || view === "" || view !== "mobile" ?
          <><div className="link-btn-nobg">
            <Link to="/profile">Go to My Subscription</Link>
          </div>
            <Button
              className="btn btn-primary btn-large"
              //   loading={sendEmailloading}
              htmlType="submit"
              style={{ width: "85%", marginTop: "25px" }}
              onClick={RedirectHome}
            >
              Go to Home Page
            </Button> </> : ''}
      </div>
    </div>
  )
}

export default SubscriptionSuccessCom
