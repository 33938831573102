import React, { useEffect, useState } from "react"
import Dashsection from "../../components/DashSection"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col } from "antd"
import { GET_RECENTLY_PLAYED_TRACKS } from "graphql/queries/track"
import { useQueryHook } from "graphql/hooks"
import userMixPanelEvents from "functions/mixPanelEvents"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { RowSlider } from "components/RowSlider"
import CardSkeleton from "components/Skeletons/CardSkeletons"

const Recentlyplayed = () => {
  // variables for query
  const [limit, setLimit] = useState(30)
  const [currentPage, setcurrentPage] = useState(1)

  // graphql query
  const { loading, data, error } = useQueryHook({
    query: GET_RECENTLY_PLAYED_TRACKS,
    fetchPolicy: "cache-and-network",
    variables: {
      getTracksDto: { page: currentPage, limit: limit, platform: "WEB" }
    }
  })
  // mix panel events
  useEffect(() => {
    userMixPanelEvents("screen_view", { screen: "recently_played" })
  }, [])

  const [currentLocation, setlocation] = useState("")
  const location = useLocation()
  useEffect(() => {
    setlocation(location.pathname)
  }, [currentLocation])

  useEffect(() => {
    if (currentLocation === "/recently-played") {
      setLimit(30)
    } else {
      setLimit(7)
    }
  }, [currentLocation])

  return (
    <>
      {loading ? (
        <Dashsection
          title="Recently Played"
          // link="/recently-played"
          moreLink="recently-played-detail"
          showBreadcrumb={currentLocation === "/recently-played" ? true : false}
        >
          <CardSkeleton />
        </Dashsection>
      ) : (
        <>
          {data && data?.getRecentlyPlayedTracks?.length > 0 ? (
            <Dashsection
              title="Recently Played"
              // link="/recently-played"
              moreLink="recently-played-detail"
              showBreadcrumb={
                currentLocation === "/recently-played" ? true : false
              }
            >
              {/* {loading ? <Preloader /> : ""} */}
              <>
                {data &&
                data?.getRecentlyPlayedTracks &&
                data?.getRecentlyPlayedTracks?.length ? (
                  <>
                    <RowSlider
                      items={
                        data?.getRecentlyPlayedTracks
                          ? data?.getRecentlyPlayedTracks
                          : []
                      }
                    />
                  </>
                ) : (
                  <>
                    {loading ? "" : <div>Sorry no play history found !!</div>}
                  </>
                )}
              </>
            </Dashsection>
          ) : currentLocation === "/recently-played" && loading ? (
            <div style={{ marginLeft: "4rem", marginTop: "1rem" }}>
              Sorry no play history found !!
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  )
}

export default Recentlyplayed
