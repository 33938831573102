import React from "react"
import { Col, Modal, Row } from "reactstrap"
import "./styles.scss"
import PageImg from "../../assets/images/meloImg.svg"

const Maintenance = () => {
  return (
    <div className="maintenance">
      <div className="">
        <div className="main-popup">
          <Modal isOpen={true} centered className="custom-modal">
            <div className="modal-inner ">
              <Row>
                <Col md={10}>
                  <div className="d-flex justify-content-center align-items-center">
                    <Row>
                      <Col md={9}>
                        <div>
                          <img
                            src={PageImg}
                            alt="page img"
                            className="img-fluid"
                          />
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="modal-inner-txt">
                          <h2 className="headingSmall">
                            WEBSITE UNDER CONSTRUCTION
                          </h2>
                          <p>we are currently working on this site</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Maintenance
