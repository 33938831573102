import { message } from "antd"
import { ITEM_PER_PAGE } from "../config/app.config"
import {
  fetchRecommendedTracksFail,
  fetchRecommendedTracksRequest,
  fetchRecommendedTracksSuccess
} from "../store/slice/home.slice"
import {
  fetchSingleTrackFail,
  fetchSingleTrackRequest,
  fetchSingleTrackSuccess,
  fetchTracksRequest,
  fetchTracksFail,
  fetchTracksSuccess,
  removeSingleTrack,
  fetchFavouriteTracksRequest,
  fetchFavouriteTracksSuccess,
  fetchFavouriteTracksFail,
  fetchTGtracksFail,
  fetchTGtracksRequest,
  fetchTGtracksSuccess,
  fetchPopularTracksFail,
  fetchPopularTracksRequest,
  fetchPopularTracksSuccess,
  fetchSimilarTracksRequest,
  fetchSimilarTracksSucess,
  fetchSimilarTracksFail,
  fetchTrackByGenreRequest,
  fetchTrackByGenreSuccess,
  fetchTrackByGenreFail
} from "../store/slice/track.slice"
import httpBase from "../utils/axios"
import { setFormData, setUrl } from "../utils/helper"
import { handleError } from "./error.service"

export const fetchTracks = (params = { page: 1 }) => {
  return async (dispatch) => {
    dispatch(fetchTracksRequest())
    try {
      const url = setUrl(
        {
          ...params,
          per_page: ITEM_PER_PAGE
        },
        "tracks"
      )
      const response = await httpBase().get(url)
      const responseData = response.data
      dispatch(
        fetchTracksSuccess({
          tracks: responseData.data,
          meta: responseData.page_meta
        })
      )
    } catch (e) {
      dispatch(handleError(e, fetchTracksFail))
    }
  }
}

export const fetchTGtracks = (params = { page: 1 }) => {
  return async (dispatch) => {
    dispatch(fetchTGtracksRequest())
    try {
      const url = setUrl(
        {
          ...params,
          per_page: ITEM_PER_PAGE
        },
        "tracks"
      )
      const response = await httpBase().get(url)
      const responseData = response.data
      dispatch(
        fetchTGtracksSuccess({
          tracks: responseData.data,
          meta: responseData.page_meta
        })
      )
    } catch (e) {
      dispatch(handleError(e, fetchTGtracksFail))
    }
  }
}

export const fetchTracksByTagsRecommendation = (params = { page: 1 }) => {
  return async (dispatch) => {
    dispatch(fetchRecommendedTracksRequest())
    try {
      const url = setUrl(
        {
          ...params,
          per_page: ITEM_PER_PAGE
        },
        "tracks/tag_recommended"
      )
      const response = await httpBase().get(url)
      const responseData = response.data
      dispatch(
        fetchRecommendedTracksSuccess({
          recommended_tracks: responseData.data,
          meta: responseData.page_meta
        })
      )
    } catch (e) {
      dispatch(handleError(e, fetchRecommendedTracksFail))
    }
  }
}

export const fetchSingleTrack = (id) => {
  return async (dispatch) => {
    dispatch(fetchSingleTrackRequest())
    try {
      const response = await httpBase().get(`tracks/${id}`)
      dispatch(fetchSingleTrackSuccess(response.data.data))
    } catch (e) {
      dispatch(handleError(e, fetchSingleTrackFail))
    }
  }
}

export const removeSingleTrackService = () => {
  return (dispatch) => {
    dispatch(removeSingleTrack())
  }
}

/**
 * Favourite tracks
 */

export const fetchFavouriteTracks = (params = { page: 1 }) => {
  return async (dispatch) => {
    dispatch(fetchFavouriteTracksRequest())
    try {
      const url = setUrl(
        {
          ...params,
          per_page: ITEM_PER_PAGE
        },
        "favourite_tracks"
      )
      const response = await httpBase().get(url)
      const responseData = response.data
      dispatch(
        fetchFavouriteTracksSuccess({
          tracks: responseData.data,
          meta: responseData.page_meta
        })
      )
    } catch (e) {
      dispatch(handleError(e, fetchFavouriteTracksFail))
    }
  }
}

//**add track to favourite */
export const addTrackToFavourite = async (id) => {
  try {
    const form = setFormData({
      track_id: id
    })
    const response = await httpBase().post("favourite_tracks/add", form)
    if (response.data.success === true) {
      message.success("Songs added to favourites!")
      return true
    }
    return false
  } catch (e) {
    if (e && e.response && e.response.data) {
      message.error(e.response.data.error)
    } else {
      message.error("Something went wrong!")
    }
    return false
  }
}

/** remove tracks from favoutite */
export const removeTrackToFavourite = async (id) => {
  try {
    const form = setFormData({
      track_id: id
    })
    const response = await httpBase().post("favourite_tracks/remove", form)
    if (response.data.success === true) {
      message.success("Songs remove from favourites!")
      return true
    }
    return false
  } catch (e) {
    if (e && e.response && e.response.data) {
      message.error(e.response.data.error)
    } else {
      message.error("Something went wrong!")
    }
    return false
  }
}

/**
 * @tutorial fetch popular tracks
 * @params params
 * @return void
 */
export const fetchPopularTracks = (params = { page: 1 }) => {
  return async (dispatch) => {
    dispatch(fetchPopularTracksRequest())
    try {
      const url = setUrl(
        {
          ...params,
          per_page: ITEM_PER_PAGE
        },
        "tracks/popular"
      )
      const response = await httpBase().get(url)
      const responseData = response.data
      dispatch(
        fetchPopularTracksSuccess({
          tracks: responseData.data,
          meta: responseData.page_meta
        })
      )
    } catch (e) {
      dispatch(handleError(e, fetchPopularTracksFail))
    }
  }
}

export const fetchSimilarTracks = ({ trackId }) => {
  // console.log('fetch similar track id', trackId)
  return async (dispatch) => {
    dispatch(fetchSimilarTracksRequest())
    try {
      const url = `tracks/${trackId}/similar_suggestions`
      const response = await httpBase().get(url)
      const responseData = response?.data
      // console.log('similar track response', responseData)
      if (responseData?.success) {
        dispatch(
          fetchSimilarTracksSucess({
            data: responseData?.data
          })
        )
      }
    } catch (e) {
      dispatch(handleError(e, fetchSimilarTracksFail))
    }
  }
}

export const fetchTracksByGenre = ({ genreId, page }) => {
  return async (dispatch) => {
    dispatch(fetchTrackByGenreRequest())
    try {
      const url = `tracks?genre_id=${genreId}&page=${page}&per_page=10`
      const response = await httpBase().get(url)
      const responseData = response?.data
      if (responseData?.success) {
        dispatch(
          fetchTrackByGenreSuccess({
            data: responseData?.data,
            meta: responseData?.page_meta
          })
        )
      }
    } catch (e) {
      dispatch(handleError(e, fetchTrackByGenreFail))
    }
  }
}

export const trackProgressApi = ({ data }) => {
  return async (dispatch) => {
    try {
      const url = `daily_goal_histories/track`
      const response = await httpBase().post(url, data)
    } catch (e) {}
  }
}
