import DashSection from "../../components/DashSection"
import { TrendingItems } from "@algolia/recommend-react"
import recommend from "@algolia/recommend"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import { useEffect, useState } from "react"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col } from "antd"
import { useParams } from "react-router-dom"
import "./styles.scss"

const AlgoliaRecommendation = () => {
  const [token, setToken] = useState("")
  const [loadingTagItem, setLoadingTagItem] = useState(true)
  console.log("loadingTagItem", loadingTagItem)
  useEffect(() => {
    // @ts-ignore
    let meStorageValueNew = getItem(STORAGE_KEYS.USER)

    if (meStorageValueNew !== "") {
      // @ts-ignore
      setToken(token)
    }
  }, [])

  const urlData = useParams()

  // let meStorageValueNew = getItem(STORAGE_KEYS.USER)

  // const userTags = meStorageValueNew?.tags?.map((item, index) => {
  //   const tagList = { tagName: item.tagName, tagId: item.id }
  //   return tagList
  // })

  const recommendClient = recommend(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_KEY
  )

  const indexName = process.env.REACT_APP_ALGOLIA_TRACK_INDEX_NAME

  function recommendedListView(props) {
    // console.log("view props", props)
    if (props && props.items.length) {
      setLoadingTagItem(false)
    }

    return (
      <>
        {loadingTagItem == false ? (
          <>
            {props && props?.items?.length && (
              <>
                <RowCardlist
                  cardlist={
                    <>
                      {props?.items?.map((item) => {
                        {
                          /* const { track } = item; */
                        }
                        return (
                          <>
                            <Col
                              // md={12}
                              // lg={8}
                              // xl={6}
                              // xxl={5}
                              className="card-list__item"
                              key={item.id}
                            >
                              <Card
                                img={
                                  item.storageKeys.azure &&
                                  item.storageKeys.azure.image
                                    ? item.storageKeys.azure.image
                                    : Cimg1
                                }
                                premium={
                                  item.trackType === "PREMIUM" ||
                                  item.trackType === "PAID" ||
                                  item.trackType === "TRIAL"
                                    ? true
                                    : false
                                }
                                title={item.title}
                                link={`/track/${item.slug}`}
                                id={item.id}
                                className="card_size"
                              />
                            </Col>
                          </>
                        )
                      })}
                    </>
                  }
                />
              </>
            )}
          </>
        ) : (
          "Loading..."
        )}
      </>
    )
  }
  return (
    <DashSection title={urlData.tagName} showBreadcrumb={true}>
      <div className="recommendation_detail_page">
        <div className="trendingContainer">
          <div className="recommendedTagItem">
            <TrendingItems
              recommendClient={recommendClient}
              indexName={indexName}
              view={recommendedListView}
              facetName={"tags.tagName"}
              facetValue={urlData.tagName}
            />
            <br />
          </div>
        </div>
      </div>
    </DashSection>
  )
}

export default AlgoliaRecommendation
