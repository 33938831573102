import React, { Fragment, useEffect, useState } from "react"
import { NavLink, useHistory } from "react-router-dom"

import BrandLogo from "../../assets/images/Brand-Logo.png"

import { Button, Layout, Menu, message, Modal, Popover } from "antd"
import {
  PlusSquareOutlined,
  LockOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons"
import { useDispatch } from "react-redux"

import HorizontalLoader from "../UI/Loader/horizontalLoader"
import { library } from "../../config/navigation.config"
import { getCurrentSession, handleVisiblePopover } from "../../utils/helper"
import AuthReqWrapper from "../UI/AuthRequiredWrapper"
import _ from "lodash"
import { useLazyQueryHook, useMutationHook, useQueryHook } from "graphql/hooks"
import { GET_PLAYLISTS, REMOVE_PLAYLIST } from "graphql/queries/playlist"
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import PlaylistModal from "./sidebarModal"
import { getErrors } from "utils/helper"
import EditPlaylist from "../Playlist/EditPlaylist"
import CategoriesMenu from "./categoriesMenu"
import { togglePlaylistModal } from "store/slice/playlist.slice"

const { Sider } = Layout

const Sidebar = ({ sidebarStatus: SidebarStatus }) => {
  const history = useHistory()

  const dispatch = useDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [playlist, setPlaylist] = useState({})
  const [showPlaylistModal, setShowPlaylistModal] = useState(false)
  const [editPlaylistModal, setEditPlaylistModal] = useState(false)
  const [lastUrlTitle, setLastTitleUrl] = useState()

  useEffect(() => {
    let pathSplit = history?.location.pathname.split("/")
    let lastTitle = pathSplit[pathSplit.length - 1]
    setLastTitleUrl(lastTitle)
  }, [])

  /** load user playlist*/
  const {
    loading: playlistLoading,
    data: playlistData,
    error: playlistError
  } = useQueryHook({
    query: GET_PLAYLISTS,
    fetchPolicy: "cache-and-network",
    variables: { page: 1, limit: 20 }
  })

  //eslint-disable-line
  const [userData, setUserData] = useState({})
  useEffect(() => {
    let userData = getItem(STORAGE_KEYS.USER)
    setUserData(userData)
  }, [])

  const RedirectFun = () => {
    history.push("/subscription")
  }

  // confirmation model
  const confirm = () => {
    Modal.confirm({
      // title: 'Confirm',
      icon: "",
      content:
        "You cannot access to this playlist because your subscription has been expired. Please renew your subscription to gain access to all your playlists.",
      okText: "Renew Subscription",
      cancelText: "Cancel",
      onOk: () => {
        RedirectFun()
      }
    })
  }

  const {
    func: removePlaylistFunc,
    loading: removePlaylistLoading,
    data: removePlaylistData,
    error: removePlaylistError
  } = useMutationHook({
    query: REMOVE_PLAYLIST,
    refetchQueries: [
      {
        query: GET_PLAYLISTS,
        variables: { page: 1, limit: 20 }
      }
    ]
  })

  const handleDeletePlaylistOk = (playlist) => {
    let variables = { playlistId: playlist.id }
    removePlaylistFunc({ variables }).then(() => {
      setIsModalOpen(false)
      history.push("/")
    })
  }

  useEffect(() => {
    if (!removePlaylistLoading) {
      if (removePlaylistData) {
        message.success("Playlist deleted successfully")
      }
      if (removePlaylistError) {
        message.error(
          getErrors(removePlaylistError.graphQLErrors) ||
            removePlaylistError.message
        )
      }
    }
  }, [removePlaylistData, removePlaylistError, removePlaylistLoading])

  const handlePlaylistDeleteModal = (playlist) => {
    setPlaylist({ ...playlist })
    setIsModalOpen(true)
  }

  // edit playlist

  const handleCreatePlaylist = () => {
    setPlaylist({})
    dispatch(togglePlaylistModal(true))
  }

  // edit playlist

  const handleEditPlaylist = (playlist) => {
    setPlaylist(playlist)
    setEditPlaylistModal(true)
  }

  const getLatestPlaylist = playlistData?.getPlaylists?.playlist[0]

  const [playlistDispData, setPlaylistDispData] = useState([])

  useEffect(() => {
    if (
      playlistData &&
      playlistData.getPlaylists &&
      playlistData.getPlaylists.playlist &&
      playlistData.getPlaylists.playlist.length
    ) {
      setPlaylistDispData(playlistData.getPlaylists.playlist)
    }
  }, [playlistData])

  // const changeLocationFUN=(name,id)=>{
  //   history.push({pathname:`/my-playlist/${name}`,state:{plalistId:id}})
  // }

  return (
    <>
      <Sider
        theme="light"
        collapsible="false"
        className={`sidebar ${SidebarStatus ? "active" : ""}`}
      >
        <div className="logo">
          <img src={BrandLogo} alt="Good Vibes Logo" />
        </div>
        <Menu mode="inline" className="sidebar__menu" selectable={false}>
          <Menu.Item
            key="1"
            icon={
              <>
                <span
                  class={`${
                    lastUrlTitle === "" || lastUrlTitle === "guest"
                      ? "iconlyBulk-Home icon-menu"
                      : "iconly-Home icli icon-menu"
                  }`}
                ></span>
              </>
            }
          >
            <NavLink
              exact
              to={getCurrentSession().authenticated ? "/" : "/guest"}
              activeClassName="active"
            >
              Home
            </NavLink>
          </Menu.Item>
          {/* <Menu.Item
            key="2"
            icon={
              <>
                <span
                  class={`${
                    lastUrlTitle === "explore"
                      ? "iconly-Discovery icbo icon-menu"
                      : "iconly-Discovery icli icon-menu"
                  }`}
                ></span>
              </>
            }
          >
            <NavLink exact to="/explore" activeClassName="active">
              Explore
            </NavLink>
          </Menu.Item> */}
        </Menu>

        <CategoriesMenu lastUrlTitle={lastUrlTitle} />

        <Menu
          mode="inline"
          className="sidebar__menu"
          selectable={getCurrentSession().authenticated}
        >
          <h4>Library</h4>
          {library.map((items) => {
            const {
              link,
              icon: Icon,
              title,
              disabledMessage,
              currentUrl,
              activeIcon,
              inactiveIcon
            } = items
            return (
              <Menu.Item
                key={link}
                className="text-disabled"
                icon={
                  <>
                    <span
                      class={`${
                        lastUrlTitle === currentUrl
                          ? activeIcon + " icon-menu"
                          : inactiveIcon + " icon-menu"
                      }`}
                    ></span>
                  </>
                }
              >
                {getCurrentSession().authenticated ? (
                  <NavLink exact to={link} activeClassName="active">
                    {title}
                  </NavLink>
                ) : (
                  <Popover
                    placement="right"
                    title={false}
                    afterVisibleChange={handleVisiblePopover}
                    trigger="click"
                    content={
                      <div
                        dangerouslySetInnerHTML={{ __html: disabledMessage }}
                      ></div>
                    }
                  >
                    <div className="text-disabled">{title}</div>
                  </Popover>
                )}
              </Menu.Item>
            )
          })}
        </Menu>

        <Menu
          selectable={false}
          mode="inline"
          className="sidebar__menu sidebar__menu--playlist"
          id="gv-user-playlist"
        >
          <h4>Your Playlists</h4>
          {getLatestPlaylist?.playlistName ? (
            <div className="sidebar-playlist">
              <Menu.Item>
                <NavLink
                  exact
                  to={{
                    pathname: `/my-playlist/${getLatestPlaylist?.slug}`,
                    state: getLatestPlaylist?.id
                  }}
                  title={getLatestPlaylist?.playlistName}
                >
                  {getLatestPlaylist?.playlistName}
                </NavLink>
              </Menu.Item>
              <div className="sidebar-playlist-btn">
                <div className="sidebar-playlist-btn-icons">
                  <span className="sidebar-playlist-btn-icons-edit">
                    <EditOutlined
                      onClick={() => handleEditPlaylist(getLatestPlaylist)}
                    />
                  </span>
                  <span
                    className="sidebar-playlist-btn-icons-delete"
                    onClick={() => handlePlaylistDeleteModal(getLatestPlaylist)}
                    // onClick={confirm}
                  >
                    <DeleteOutlined />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {getCurrentSession().authenticated ? (
            playlistDispData ? (
              playlistDispData.slice(1).map((playlist) => (
                <div className="sidebar-playlist">
                  <Menu.Item key={`/my-playlist/${playlist.slug}`}>
                    {userData?.subscriptionIos === "free" ? (
                      <Fragment>
                        <span onClick={confirm} style={{ color: " #9797DE" }}>
                          <LockOutlined style={{ color: " #9797DE" }} />
                          &nbsp;
                          {playlist.playlistName}
                        </span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <NavLink
                          exact
                          to={{
                            pathname: `/my-playlist/${playlist.slug}`,
                            state: playlist.id
                          }}
                          title={playlist.playlistName}
                        >
                          <span>{playlist.playlistName}</span>
                        </NavLink>
                      </Fragment>
                    )}
                  </Menu.Item>
                  <div className="sidebar-playlist-btn">
                    <div className="sidebar-playlist-btn-icons">
                      <span className="sidebar-playlist-btn-icons-edit">
                        <EditOutlined
                          onClick={() => handleEditPlaylist(playlist)}
                        />
                      </span>
                      {userData?.subscriptionIos === "free" ? (
                        <span
                          className="sidebar-playlist-btn-icons-delete"
                          // onClick={() => handlePlaylistDeleteModal(playlist)}
                          onClick={confirm}
                        >
                          <DeleteOutlined />
                        </span>
                      ) : (
                        <span
                          className="sidebar-playlist-btn-icons-delete"
                          onClick={() => handlePlaylistDeleteModal(playlist)}
                        >
                          <DeleteOutlined />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Menu.Item>No playlist found!!</Menu.Item>
            )
          ) : (
            ""
          )}

          <Menu.Item
            key="8"
            icon={<PlusSquareOutlined />}
            className="text-primary"
          >
            {getCurrentSession().authenticated ? (
              <a type="button" onClick={handleCreatePlaylist}>
                Create Playlist
              </a>
            ) : (
              <Popover
                afterVisibleChange={handleVisiblePopover}
                trigger="click"
                placement="right"
                title={false}
                content={"sigup or login to create a playlist!"}
              >
                <a type="button">Create Playlist</a>
              </Popover>
            )}
          </Menu.Item>
        </Menu>
        <PlaylistModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          playlist={playlist}
          handleDeletePlaylistOk={handleDeletePlaylistOk}
          removePlaylistLoading={removePlaylistLoading}
        />
        {/* <GoogleAds /> */}
      </Sider>
      {/* <PlaylistCU
        setShowPlaylistModal={setShowPlaylistModal}
        showPlaylistModal={showPlaylistModal}
      /> */}
      <EditPlaylist
        setEditPlaylistModal={setEditPlaylistModal}
        editPlaylistModal={editPlaylistModal}
        setPlaylist={setPlaylist}
        playlist={playlist}
        playlistData={playlistData}
      />
      {/* molal */}
      {/* <LocalizedModal /> */}
      {/*  */}
      <AuthReqWrapper />
    </>
  )
}

export default Sidebar
