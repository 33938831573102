//home->for you->tag group detail
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { fetchTGtracks } from "../../../services/track.service"
import Cimg1 from "../../../assets/images/Thumbnail.png"
import Preloader from "../../../components/UI/Preloader"
import Cardsonglist from "../../../components/Cards/Cardsonglist"
import Songlistitem from "../../../components/songlist"
import { fetchSingleTagGroup } from "../../../services/tags.service"
import Cardbanner from "../../../components/Cards/Card-banner"

const TagGroupSingle = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  //store
  const state = useSelector((state) => state)
  const { track } = state
  const { tagGroupTracks, loadingTGtracks } = track

  //states
  const [tagGroup, setTagGroup] = useState(undefined)
  const [loadingTG, setLoadingTG] = useState(false)

  //fetch track by tag group
  const fetchtracksByTagGroup = (param = { page: 1 }) => {
    let params = {
      ...param,
      tag_group_id: tagGroup?.id
    }
    dispatch(fetchTGtracks(params))
  }

  //fetch single tag group
  const fetchTG = async () => {
    setLoadingTG(true)
    const response = await fetchSingleTagGroup(id)
    setTagGroup(response)
    setLoadingTG(false)
  }

  useEffect(() => {
    fetchTG()
  }, [id]) //eslint-disable-line

  useEffect(() => {
    if (tagGroup) {
      fetchtracksByTagGroup()
    }
  }, [tagGroup])

  return (
    <>
      {loadingTGtracks || loadingTG ? (
        <Preloader />
      ) : (
        <>
          {tagGroup ? (
            <section className="dash-section">
              <Cardbanner
                img={tagGroup.image_url || Cimg1}
                title={tagGroup.name || ""}
                text={tagGroup.description}
                track={tagGroupTracks}
                totalTracks={tagGroupTracks?.length}
              />
            </section>
          ) : (
            ""
          )}

          {tagGroupTracks && tagGroupTracks.length ? (
            <section className="dash-section dash-section--playlist">
              <Cardsonglist
                songlist={tagGroupTracks.map((singleTrack, i) => (
                  <Songlistitem
                    index={i + 1 < 10 ? "0" + (i + 1) : i + 1}
                    title={singleTrack.name}
                    category={
                      singleTrack.category ? singleTrack.category.name : "N/A"
                    }
                    duration={singleTrack?.duration_without_units}
                    thumbnail={singleTrack.image || Cimg1}
                    track={singleTrack}
                    allTracks={tagGroupTracks}
                  />
                ))}
              />
            </section>
          ) : (
            <div className="dash-section dash-section--playlist">
              Sorry no tracks found !!
            </div>
          )}
        </>
      )}
    </>
  )
}

export default TagGroupSingle
