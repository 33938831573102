import React, { useEffect, useState } from "react"
import { Modal, Button, message, Descriptions } from "antd"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setDailyGoalModal } from "../../store/slice/user.slice"
import { Select } from "antd"
import timer, { dailyGoalTimer } from "../../constants/timer"
import { updateDailyGoalApi } from "../../services/user.service"
import { Tag } from "antd"

const { Option } = Select
const { CheckableTag } = Tag

const DailyGoalModal = (props) => {
  const { handleRefresh, dailyGoal } = props
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const { showDailyGoalModal } = state.user
  const [selectedGoal, setSelectedGoal] = useState(dailyGoal)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setSelectedGoal(dailyGoal)
  }, [dailyGoal])

  const handleSet = () => {
    setSubmitting(true)
    dispatch(
      updateDailyGoalApi({
        data: {
          daily_goal: selectedGoal
        },
        finalCallback: () => {
          setSubmitting(false)
        },
        successCallback: () => {
          message.success("Daily goal updated.")
          handleRefresh()
          closeModal()
        }
      })
    )
  }

  const closeModal = () => {
    dispatch(setDailyGoalModal(false))
  }

  const handleSelectionChange = (value) => {
    setSelectedGoal(value?.value)
  }

  return (
    <Modal
      visible={showDailyGoalModal}
      title={"Set Daily Goal"}
      centered
      // closable={false}
      maskClosable={false}
      footer={[
        <div key={1}>
          <Button
            className="btn btn-primary "
            htmlType="submit"
            form={"gv-create-playlist-form"}
            onClick={() => handleSet()}
            loading={submitting}
          >
            Set
          </Button>
        </div>
      ]}
      onCancel={() => {
        // message.warning('Payment Incomplete.')
        closeModal()
      }}
      className="daily-goal-modal"
    >
      <div>
        {dailyGoalTimer?.map((time, timeIndex) => {
          let isSelected = selectedGoal == time?.value ? true : false
          return (
            <CheckableTag
              key={time?.title}
              checked={isSelected}
              onChange={() => handleSelectionChange(time)}
              className={`daily-goal-modal--item ${isSelected ? "active" : ""}`}
            >
              {time?.title}
            </CheckableTag>
          )
        })}
      </div>
    </Modal>
  )
}

export default DailyGoalModal
