import React, { useEffect } from "react"
import {
  Row,
  Col,
  Form,
  Input,
  Switch,
  Modal,
  Select,
  DatePicker,
  Button,
  message
} from "antd"
import moment from "moment"

//graphql imports
import { useMutationHook } from "graphql/hooks"
import { EDIT_ME } from "graphql/queries/auth"

const { Option } = Select

const EditUserDetail = (props) => {
  const { handleProfileUpdate, loading, userData } = props

  const [form] = Form.useForm()

  const capitalizeLetter = (str) => {
    const capitalizedLetter = str.charAt(0).toUpperCase() + str.slice(1)
    return capitalizedLetter
  }

  const disabledDate = (current) => {
    return current && current > moment().toDate()
  }

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        fname: userData.firstName ? userData.firstName : "",
        email: userData.email ? userData.email : "",
        lname: userData.lastName ? userData.lastName : "",
        gender: userData.gender ? capitalizeLetter(userData.gender) : "",
        dob: userData.dob ? moment(userData.dob) : ""
      })
    }
  }, [userData])

  const {
    func: editMeFunc,
    loading: editMeLoading,
    data: editMeData,
    error: editMeError
  } = useMutationHook({ query: EDIT_ME })

  const handleFinish = (values) => {
    const userDetail = {}
    if (values.fname) {
      userDetail.firstName = values.fname
    }

    if (values.email) {
      userDetail.email = values.email
    }

    if (values.lname) {
      userDetail.lastName = values.lname
    }

    if (values.gender) {
      userDetail.gender = values.gender.toUpperCase()
    }

    if (values.dob) {
      userDetail.dob = values.dob
    }

    editMeFunc({ variables: userDetail })
  }

  useEffect(() => {
    if (!editMeLoading) {
      if (editMeData && editMeData.editMe) {
        message.success("Profile Updated.")
      }
    }
  }, [editMeLoading, editMeData, editMeError])

  return (
    <Form
      layout="vertical"
      size="large"
      className="auth-form-wrapper"
      onFinish={(values) => handleFinish(values)}
      initialValues={{}}
      form={form}
    >
      <Row gutter={[15, 15]}>
        <Col xs={24} lg={12} xl={12}>
          <Form.Item
            label="First Name"
            name="fname"
            className="form-input-control"
            rules={[
              {
                required: true,
                message: "This field is required."
              }
            ]}
          >
            <Input placeholder="First Name" className="form-input-control" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12} xl={12}>
          <Form.Item
            label="Last Name"
            name="lname"
            rules={[
              {
                required: true,
                message: "This field is required."
              }
            ]}
          >
            <Input placeholder="Last Name" className="form-input-control" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12} xl={12}>
          <Form.Item
            label="Date of Birth"
            name="dob"
            rules={[
              {
                required: true,
                message: "This field is required."
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && value < moment().subtract(13, "years")) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error("Age must be greater than 13!")
                  )
                }
              })
            ]}
            initialValue={userData?.dob ? moment(userData?.dob) : ""}
          >
            <DatePicker
              className="form-input-control"
              format={"YYYY-MM-DD"}
              disabledDate={disabledDate}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[
              {
                required: true,
                message: "This field is required !!"
              }
            ]}
            initialValue={userData ? userData?.gender : ""}
          >
            <Select
              placeholder="Select one"
              // onChange={onGenderChange}
              allowClear
              className="form-input-select"
            >
              <Option value="MALE">Male</Option>
              <Option value="FEMALE">Female</Option>
              <Option value="OTHER">Other</Option>
              {/* <Option value="OTHER">Other</Option> */}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <Form.Item
            label="Email"
            name="email"
            className="form-input-control"
            rules={[
              {
                required: true,
                message: "This field is required."
              }
            ]}
          >
            <Input
              placeholder="Example@gmail.com"
              className="form-input-control"
              disabled={userData?.email ? true : false}
            />
          </Form.Item>
        </Col>

        <Col xs={24} md={24}>
          <Button
            className="btn btn-primary btn-large btn--update-profile"
            loading={editMeLoading}
            htmlType="submit"
          >
            Update Profile
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default EditUserDetail
