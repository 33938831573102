import { configureStore } from "@reduxjs/toolkit"
import reducer from "./reducer"
import history from "../utils/history"
import { routerMiddleware } from "connected-react-router"

const store = configureStore({
  reducer: reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(routerMiddleware(history))
})

export default store
