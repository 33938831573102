import { Row } from "antd"
import React, { useEffect, useRef } from "react"
import { hasMoreData } from "../../../utils/helper"

const ScrollPagination = (props) => {
  const { pagination, meta, endText, loading } = props

  const paginationElement = useRef(null)

  const handleNextPage = (page) => {
    let params
    params = {
      page: Number(page) + 1
    }
    pagination(params)
  }

  const intersectionCallback = (entries, page) => {
    if (entries[0].isIntersecting) {
      handleNextPage(page)
    }
  }

  useEffect(() => {
    if (meta) {
      let observer = new IntersectionObserver((e) =>
        intersectionCallback(e, meta.current_page)
      )
      if (paginationElement && paginationElement.current) {
        observer.observe(paginationElement.current)
      }
    }
  }, [paginationElement, meta]) //eslint-disable-line

  return (
    <>
      {meta && hasMoreData(meta) && !loading ? (
        <Row>
          <div ref={paginationElement}></div>
        </Row>
      ) : (
        <Row style={{ justifyContent: "center", marginTop: "1.5em" }}>
          {hasMoreData(meta) ? "" : endText || "No more data"}
        </Row>
      )}
    </>
  )
}

export default ScrollPagination
