export const REGISTER = `
  mutation ($email: String!, $password: String!, $termsAccepted: Boolean!, $deviceTimeZone:String!
    ) {
    registerUser(
      userRegisterInput: {
        email: $email
        password: $password
        termsAccepted: $termsAccepted
        deviceTimeZone:$deviceTimeZone
      }
    ){
      access_token
      refresh_token
      type
  }
}
`

export const LOGIN = `
  query ($username: String!, $password: String!,$deviceTimeZone:String) {
    login(loginInputDto: { username: $username, password: $password, deviceTimeZone:$deviceTimeZone }) {
      access_token
      refresh_token
      type
    }
  }
`

export const ME = `
  query {
    me {
      id
      firstName
      middleName
      lastName
      profilePicStorageKey
      dob
      gender
      email
      emailConfirmed
      subscriptionIos
      subscriptionIosExpiryDate
      subscriptionGraceDate
      tags{
        id
        description
        storageKey
        tagName
      }
    }
  }
`

export const EDIT_ME = `
  mutation ($firstName: String, $lastName: String, $gender: GenderTypes, $dob: DateTime,$profilePicStorageKey: String    ) {
    editMe (editMeInput: { firstName: $firstName, lastName: $lastName, gender: $gender, dob: $dob,profilePicStorageKey: $profilePicStorageKey }) {
      id  
    }
  }
`

export const UPDATE_PASSWORD = `
  mutation ($oldPassword: String!, $newPassword: String!) {
    updatePassword (updatePasswordInput: { oldPassword: $oldPassword, newPassword: $newPassword })
  }
`

export const SOCIAL_LOGIN = `
  query($deviceTimeZone:String){
    OauthLogin(deviceTimeZone:$deviceTimeZone){
      access_token
      refresh_token
      type
    }
  }
`

export const RESEND_VERIFY_EMAIL = `
query{
  verifyEmailResend
}
`

export const FORGET_PASSWORD_LINK = `
  query ($email: String!) {
    forgetPasswordGenerateCode(email:$email)
  }
  `
// user notification query
export const GET_USER_NOTIFICATION = `
  query{
    getUserNotification{
      offers
      id
      daily_updates
      others
    }
  }
  `

// usernotificetion update mutation
export const UPDATE_USER_NOTITIFICATION = `
  mutation ($daily_updates: Boolean!, $others: Boolean!,$offers:Boolean!) {
    storeUserNotification(storeUserNotificationDto: { daily_updates: $daily_updates, others: $others,offers: $offers}){
      others
      daily_updates
    }
  }
`
