import { message } from "antd"

export const handleError = (e, callback) => {
  return (dispatch) => {
    if (e && e.response && e.response.data) {
      dispatch(callback(e.response.data))
      message.error(e.response.data.error)
    } else {
      dispatch(callback("Something went wrong"))
      message.error("Something went wrong")
    }
  }
}
