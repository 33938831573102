import React, { useState, useRef } from "react"
import { Row, Col, Form, Input, Switch, Modal, Upload, Button } from "antd"

import fileUpload from "../../assets/images/file-upload.svg"
import fileUploading from "../../assets/images/file-uploading.svg"

import Avatar from "../../assets/images/avatar.png"

const EditAvatar = (props) => {
  const { handleProfileUpdate, loading, userData } = props
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [file, setFile] = useState()
  const uploadRef = useRef()
  const statusRef = useRef()
  const loadTotalRef = useRef()
  const progressRef = useRef()
  // const [uploadInProgress, setUploadInProgress] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleChange = (image) => {
    // console.log('handle image change', image)
  }

  const handleImageChange = (image) => {
    // console.log('beforeUpload', image)
    let data = {
      image: image
    }
    handleProfileUpdate({
      data: data,
      isAvatar: true
    })
  }

  // file upload
  const UploadFile = () => {
    console.log({ uploadRef })
    const file = uploadRef.current.files[0]
    setFile(URL.createObjectURL(file))
    var formData = new FormData()
    formData.append("image", file)
    var xhr = new XMLHttpRequest()
    xhr.upload.addEventListener("progress", ProgressHandler, false)
    xhr.addEventListener("load", SuccessHandler, false)
    xhr.addEventListener("error", ErrorHandler, false)
    xhr.addEventListener("abort", AbortHandler, false)
    // xhr.open("POST", "fileupload.php")
    // xhr.send(formData)
  }

  const ProgressHandler = (e) => {
    loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`
    var percent = (e.loaded / e.total) * 100
    progressRef.current.value = Math.round(percent)
    statusRef.current.innerHTML = Math.round(percent) + "% uploaded..."
  }

  const SuccessHandler = (e) => {
    statusRef.current.innerHTML = e.target.responseText
    progressRef.current.value = 0
  }
  const ErrorHandler = () => {
    statusRef.current.innerHTML = "upload failed!!"
  }
  const AbortHandler = () => {
    statusRef.current.innerHTML = "upload aborted!!"
  }

  return (
    <Row gutter={[15, 15]} className="row--avatar">
      <Col xs={24} md={8}>
        <div className="avatar-img-wrapper">
          <div className="avatar-img">
            <img src={userData?.versioned_image_urls?.large ?? Avatar} alt="" />
          </div>
        </div>
      </Col>
      <Col xs={24} md={16}>
        <div className="settings-personal-info-btn">
          {/* <Upload
            // onChange={handleChange}
            showUploadList={false}
            beforeUpload={handleImageChange}
            listType="picture"
          > */}
          {/* <Button
            className="btn btn-primary btn-large"
            loading={loading}
            onClick={showModal}
          >
            Change Profile Pic
          </Button> */}
          {/* </Upload> */}
          {/* <Button
            className="btn btn-outline"
            onClick={() => {
              handleImageChange("")
            }}
          >
            Remove Profile Pic
          </Button> */}
        </div>

        <Modal
          className="avatar-modal"
          title="Upload Photo"
          visible={isModalVisible}
          footer={null}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="modal-body">
            <div className="avatar-img">
              <img src={file} alt="" />
            </div>

            <div className="modal-body-photo-container">
              {/* <div className="modal-body-photo-container-content">
                <img src={fileUpload} alt="file upload icon" />
                <p>Drag files to upload</p>
              </div> */}

              <label>
                File progress:{" "}
                <progress ref={progressRef} value="0" max="100" />
              </label>

              <p ref={statusRef}></p>
              <p ref={loadTotalRef}></p>
            </div>

            <div className="modal-body-button">
              <input
                type="file"
                name="file"
                ref={uploadRef}
                onChange={UploadFile}
                className="modal-body-button-file"
              />
              <Button className="btn btn-primary">Choose File</Button>
            </div>
          </div>
        </Modal>
      </Col>
    </Row>
  )
}

export default EditAvatar
