import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Row, Col, message } from "antd"
import { useLazyQueryHook, useQueryHook } from "graphql/hooks"
import CardCategories from "components/Cards/Card-categories"
import CategoryTrackCard from "components/Cards/Categories-track-card"
import {
  GET_CATEGORY_BY_SLUG,
  GET_TAGS_BY_CATEGORY_ID,
  GET_TRACKS
} from "graphql/queries/categories"
import { getErrors, getImages, getTotalTracksTime } from "utils/helper"
import ScrollPagination from "components/ScrollPagination"
import CategoryTags from "../../components/CategoryTags"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination } from "swiper"
// Import Swiper styles
import "swiper/swiper-bundle.css"
import ChildrenCategory from "./childrenCategory"
import Bradcrumb from "components/Bradcrumb"
import { GET_TAGS } from "graphql/queries/tags"
import { GET_TRACKS_BY_TAGS } from "graphql/queries/track"
import { RowSlider } from "components/RowSlider"

SwiperCore.use([Navigation, Pagination])

const Category = () => {
  const { slug } = useParams()
  // variables for api call
  const [limit, setLimit] = useState(10)
  const [currentPage, setcurrentPage] = useState(1)
  const [selectedTagGroups, setSelectedTagGroups] = useState(undefined)
  const [trackdispRow, setTraksDispRow] = useState([])

  // mutation queries
  const {
    func: categoryFunc,
    loading: categoryLoading,
    data: categoryData,
    error: categoryError
  } = useLazyQueryHook({
    query: GET_CATEGORY_BY_SLUG
  })

  const {
    func: tracksFunc,
    loading: tracksLoading,
    data: tracksData,
    error: tracksError
  } = useLazyQueryHook({
    query: GET_TRACKS
  })

  const {
    func: tagsFunc,
    loading: tagsLoading,
    data: tagsData,
    error: tagsError
  } = useLazyQueryHook({
    query: GET_TAGS_BY_CATEGORY_ID
  })

  const {
    func: getTags,
    loading: tagLoading,
    error: tagError,
    data: tagData
  } = useLazyQueryHook({ query: GET_TAGS })

  const {
    func: getTracks,
    loading: trackLoading,
    data: trackData,
    error: trackError
  } = useLazyQueryHook({
    query: GET_TRACKS_BY_TAGS
  })

  // call category by id
  useEffect(() => {
    let variables = { searchAndFilterArgs: { limit: 30, page: 1 } }
    getTags({ variables })
    // console.log("uses asjldkfjaslf",variables)
  }, [])

  const sleepTag = tagData && tagData?.getTags?.tags?.map((item) => ({ item }))

  const isSleepTagPresent = sleepTag?.filter(
    (item) => item?.item.tagName === "Sleep"
  )
  const sleepId = isSleepTagPresent?.map((tagId) => {
    return tagId.item.id
  })
  let sleepIdNew = sleepId?.toString()

  // const[sleepIdNew,setSleepId]=useState("")

  useEffect(() => {
    if (sleepIdNew !== "" && sleepIdNew !== undefined) {
      // let sleepIdNew=sleepId.toString()
      const variables = { tracksByTagsArgs: { tagIds: sleepIdNew } }
      getTracks({ variables })
    }
  }, [sleepIdNew])

  useEffect(() => {
    if (slug !== null) {
      const variables = { slug: slug }
      categoryFunc({ variables })
      setTraksDispRow([])
      setcurrentPage(1)
      setSelectedTagGroups(undefined)
      // tracksFunc({ variables })
    }
  }, [slug])

  useEffect(() => {
    if (categoryData?.getCategoryBySlug?.id) {
      const variables = {
        categoryId: categoryData.getCategoryBySlug.id,
        page: currentPage,
        limit: limit,
        publishedStatus: "true"
      }

      tracksFunc({ variables: variables })
    }
  }, [categoryData, currentPage])

  useEffect(() => {
    if (categoryData?.getCategoryBySlug?.id) {
      const variables = { categoryId: categoryData?.getCategoryBySlug?.id }
      tagsFunc({ variables })
    }
  }, [categoryData])

  // call

  // error handling category data
  useEffect(() => {
    if (categoryError) {
      message.error(
        getErrors(categoryError.graphQLErrors) || categoryError.message
      )
    }
    if (tracksError) {
      message.error(getErrors(tracksError.graphQLErrors) || tracksError.message)
    }
  }, [categoryError, tracksError])

  useEffect(() => {
    if (tagsError) {
      message.error(getErrors(tagsError.graphQLErrors) || tagsError.message)
    }
  }, [tagsError])

  // is tag selected
  const tagSelected = (id) => {
    if (selectedTagGroups) {
      if (selectedTagGroups === id) {
        return true
      }
    }
    return false
  }

  // toggle tag selection
  const toggletagSelection = (i) => {
    setSelectedTagGroups(i)
    setcurrentPage(1)
    setLimit(10)
    if (selectedTagGroups === i) {
      setSelectedTagGroups(undefined)
    }
  }

  useEffect(() => {
    if (selectedTagGroups && selectedTagGroups !== undefined) {
      const variables = {
        categoryId: categoryData?.getCategoryBySlug?.id,
        tagId: selectedTagGroups,
        page: currentPage,
        limit: limit,
        publishedStatus: "true"
      }
      tracksFunc({ variables })
    } else if (selectedTagGroups === undefined) {
      const variables = {
        categoryId: categoryData?.getCategoryBySlug?.id,
        page: currentPage,
        limit: limit,
        publishedStatus: "true"
      }
      tracksFunc({ variables })
    }
  }, [selectedTagGroups])

  // useState

  useEffect(() => {
    if (
      !tracksLoading &&
      tracksData &&
      tracksData.getTracks &&
      tracksData.getTracks?.tracks
    ) {
      if (currentPage === 1) {
        setTraksDispRow(tracksData?.getTracks?.tracks)
      } else {
        const newTracks = [...trackdispRow, ...tracksData.getTracks.tracks]

        setTraksDispRow(newTracks)
      }
    }
  }, [tracksData])

  const linkItem = [
    {
      linkName: "Home",
      linkUrl: "/"
    },
    {
      linkName: slug,
      linkUrl: `/${slug}`,
      slug: slug
    }
  ]

  return (
    <>
      <section className="dash-section category-container">
        <Bradcrumb linkItem={linkItem} />
        <CardCategories
          title={categoryData?.getCategoryBySlug?.categoryName}
          imgsrc={categoryData?.getCategoryBySlug?.storageKey}
          description={categoryData?.getCategoryBySlug?.description}
          totalTracks={categoryData?.getCategoryBySlug?.tracksCount}
        />
        {slug === "sleep-stories" ? (
          ""
        ) : tagsData &&
          tagsData.getTagsByCategoryId &&
          tagsData.getTagsByCategoryId.length ? (
          <div className="category-tags-container">
            <Swiper
              spaceBetween={10}
              className="swiper-wrapper--row"
              breakpoints={{
                0: {
                  slidesPerView: 2
                },
                480: {
                  slidesPerView: 3
                },

                767: {
                  slidesPerView: 4
                },

                992: {
                  slidesPerView: 6
                },

                1100: {
                  slidesPerView: 8
                },
                1700: {
                  slidesPerView: 10
                }
              }}
            >
              {tagsData.getTagsByCategoryId.map((item) => (
                <SwiperSlide onClick={() => toggletagSelection(item.id)}>
                  <CategoryTags
                    title={item.tagName}
                    id={item.id}
                    selected={tagSelected(item.id)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : (
          ""
        )}
        {/* <div className="category-tracks">
          {!categoryData?.getCategoryByCatId?.category?.children?.length ? (
            <>
              <Row gutter={[16, 30]}>
                {trackdispRow?.length ? (
                  trackdispRow.map((item) => (
                    <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                      <CategoryTrackCard
                        img={item.storageKeys?.image}
                        duration={getTotalTracksTime(item.duration)}
                        title={item.title}
                        premium={
                          item.trackType === "PREMIUM" ||
                          item.trackType === "PAID" ||
                          item.trackType === "TRIAL"
                            ? true
                            : false
                        }
                        link={`/track/${item.slug}`}
                        id={item.id}
                        pathname={slug}
                        // subpath={slug ? slug : ""}
                      />
                    </Col>
                  ))
                ) : (
                  <p>&nbsp;&nbsp;&nbsp;No Tracks</p>
                )}
              </Row>
            </>
          ) : (
            <>
              {categoryData?.getCategoryByCatId?.category?.children?.length &&
                categoryData.getCategoryByCatId.category.children.map(
                  (item) => (
                    <ChildrenCategory
                      id={item.id}
                      categoryName={item.categoryName}
                    />
                  )
                )}
            </>
          )}
        </div> */}
        <div className="category-tracks">
          <Row gutter={[16, 30]}>
            {trackdispRow?.map((tracksByTags) => (
              <>
                <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                  <CategoryTrackCard
                    img={tracksByTags.storageKeys.azure?.image}
                    duration={getTotalTracksTime(tracksByTags.duration)}
                    title={tracksByTags.title}
                    premium={
                      tracksByTags.trackType === "PREMIUM" ||
                      tracksByTags.trackType === "premium" ||
                      tracksByTags.trackType === "PAID" ||
                      tracksByTags.trackType === "paid" ||
                      tracksByTags.trackType === "TRIAL" ||
                      tracksByTags.trackType === "trial"
                        ? true
                        : false
                    }
                    link={`/track/${tracksByTags.slug}`}
                    id={tracksByTags.id}
                    pathname={slug}
                    // subpath={slug ? slug : ""}
                  />
                </Col>
                {/* {tracksByTags.} */}
                {/* <RowSlider
            items={
              tracksByTags
                ?tracksByTags
                : []
            }
            // loading={popularLoading}
            noItemsText={"Sorry no Sleep tracks found !"}
            // type="popular"
            // sectiontitle="Popular-Tracks"
          /> */}
              </>
            ))}
          </Row>
        </div>
        <ScrollPagination
          current_page={currentPage}
          // pagination={loadMoreRecentlyPlayedSongs}
          // HasMore={recentlyPlayedAllHasMoreData}
          loadingMore={tracksLoading}
          setLimit={setLimit}
          seTcurrentPage={setcurrentPage}
          limit={limit}
          data={tracksData}
          total={tracksData?.getTracks?.meta?.total_items}
          total_pages={tracksData?.getTracks?.meta?.total_pages}
        />
      </section>
    </>
  )
}

export default Category
