import { Modal } from "antd"

const CancelModal = ({
  setIsModalOpen,
  isModalOpen,
  cancelSubscription,
  cancelSubscriptionLoading,
  modelmessage
}) => {
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <Modal
      title="Cancel Subscription"
      visible={isModalOpen}
      onOk={() => cancelSubscription()}
      okText="Cancel Subscription"
      onCancel={handleCancel}
      cancelText="Close"
      confirmLoading={cancelSubscriptionLoading}
    >
      <p>{modelmessage}</p>
    </Modal>
  )
}
export default CancelModal
