import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loadingTags: false,
  tags: undefined,
  error: undefined,
  meta: undefined,
  tagGroups: undefined,
  loadingTagGroups: false
}

const tagSlice = createSlice({
  name: "tags",
  initialState: initialState,
  reducers: {
    loadTagsRequest(state) {
      state.loadingTags = true
    },

    loadTagsSuccess(state, actions) {
      state.loadingTags = false
      state.tags = actions.payload.tags
      state.meta = actions.payload.meta
    },

    loadTagsFail(state, actions) {
      state.loadingTags = false
      state.error = actions.payload
    },
    loadTagGroupsRequest(state) {
      state.loadingTagGroups = true
    },

    loadTagGroupsSuccess(state, actions) {
      state.loadingTagGroups = false
      state.tagGroups = actions.payload.tagGroups
      state.meta = actions.payload.meta
    },

    loadTagGroupsFail(state, actions) {
      state.loadingTagGroups = false
      state.error = actions.payload
    },

    resetTags: (state) => initialState
  }
})

export const {
  loadTagsFail,
  loadTagsRequest,
  loadTagsSuccess,
  resetTags,
  loadTagGroupsRequest,
  loadTagGroupsSuccess,
  loadTagGroupsFail
} = tagSlice.actions

export default tagSlice.reducer
