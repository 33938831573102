import { message } from "antd"
import {
  loadTagsFail,
  loadTagsRequest,
  loadTagsSuccess,
  loadTagGroupsRequest,
  loadTagGroupsSuccess,
  loadTagGroupsFail
} from "../store/slice/tags.slice"
import {
  fetchForYouRequest,
  fetchForYouSuccess,
  fetchForYouFail
} from "../store/slice/home.slice"
import httpBase from "../utils/axios"
import { handleError } from "./error.service"
import { ITEM_PER_PAGE } from "../config/app.config"

export const fetchTags = (page = 1) => {
  return async (dispatch) => {
    dispatch(loadTagsRequest())
    try {
      const response = await httpBase().get("tags/?page=" + page)
      const tags = response.data
      dispatch(
        loadTagsSuccess({
          tags: tags.data,
          meta: tags.page_meta
        })
      )
    } catch (e) {
      if (e && e.response && e.response.data) {
        dispatch(loadTagsFail(e.response.data))
        message.error(e.response.data.error)
      } else {
        dispatch("Something went wrong")
        message.error(loadTagsFail("Something went wrong"))
      }
    }
  }
}

export const fetchTagGroups = (page = 1) => {
  return async (dispatch) => {
    dispatch(loadTagGroupsRequest())
    try {
      const response = await httpBase().get("tag_groups/?page=" + page)
      const responseData = response.data
      dispatch(
        loadTagGroupsSuccess({
          tagGroups: responseData.data,
          meta: responseData.page_meta
        })
      )
    } catch (e) {
      if (e && e.response && e.response.data) {
        dispatch(loadTagGroupsFail(e.response.data))
        message.error(e.response.data.error)
      } else {
        dispatch("Something went wrong")
        message.error(loadTagGroupsFail("Something went wrong"))
      }
    }
  }
}

export const updateUserTaggings = async (tags) => {
  try {
    let formData = new FormData()
    tags.forEach((tag, i) => {
      formData.append(`tag_ids[]`, tag)
    })
    await httpBase().post("taggings", formData)
    return true
  } catch (e) {
    // console.log(e)
    return false
  }
}

export const updateUserTagGroups = async (tags) => {
  try {
    let formData = new FormData()
    tags.forEach((tag, i) => {
      formData.append(`tag_group_ids[]`, tag)
    })
    await httpBase().post("tag_groups", formData)
    return true
  } catch (e) {
    // console.log(e)
    return false
  }
}

export const fetchForYouTagGroups = (params = { page: 1 }) => {
  return async (dispatch) => {
    dispatch(fetchForYouRequest())
    try {
      const response = await httpBase().get(
        `tag_groups/for_me?per_page=${ITEM_PER_PAGE}&page=${params?.page}`
      )
      dispatch(
        fetchForYouSuccess({
          tags: response.data.data,
          meta: response.data.page_meta
        })
      )
    } catch (e) {
      dispatch(handleError(e, fetchForYouFail))
    }
  }
}

export const fetchSingleTagGroup = async (id) => {
  try {
    const response = await httpBase().get(`tag_groups/${id}`)
    return response.data.data
  } catch (e) {
    console.log(e)
    return undefined
  }
}
