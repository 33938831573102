import React, { useState, useEffect } from "react"
import { RowSlider } from "../../components/RowSlider"
import DashSection from "../../components/DashSection"
// graphql imports
import { getItem, STORAGE_KEYS } from "utils/local-storage"
import { TrendingItems } from "@algolia/recommend-react"
import recommend from "@algolia/recommend"
import "./styles.scss"
import { useLazyQueryHook, useQueryHook } from "graphql/hooks"
import { GET_TRACKS_BY_TAGS } from "graphql/queries/track"

const RecommendedComponent = () => {
  // set store
  const [token, setToken] = useState("")
  const {
    func: getTrackBytags,
    loading: getTracksByTagsLoading,
    data: trackByTagsData,
    error: trackByTagsDataError
  } = useLazyQueryHook({
    query: GET_TRACKS_BY_TAGS,
    fetchPolicy: "cache-and-network"
  })

  const {
    func: getTrackBytagsOne,
    loading: getTracksByTagsLoadingOIne,
    data: trackByTagsDataOne,
    error: trackByTagsDataErrorOne
  } = useLazyQueryHook({
    query: GET_TRACKS_BY_TAGS,
    fetchPolicy: "cache-and-network"
  })

  const {
    func: getTrackBytagsTwo,
    loading: getTracksByTagsLoadingTwo,
    data: trackByTagsDataTwo,
    error: trackByTagsDataErrorTwo
  } = useLazyQueryHook({
    query: GET_TRACKS_BY_TAGS,
    fetchPolicy: "cache-and-network"
  })

  useEffect(() => {
    // @ts-ignoreb
    let meStorageValueNew = getItem(STORAGE_KEYS.USER)

    if (meStorageValueNew !== "") {
      // @ts-ignore
      setToken(token)
      setToken(token)
    }
  }, [])

  let meStorageValueNew = getItem(STORAGE_KEYS.USER)
  const userTags = meStorageValueNew?.tags?.map((item, index) => {
    const tagList = { tagName: item.tagName, tagId: item.id }
    return tagList
  })

  // calling tags by tracks
  useEffect(() => {
    if (userTags[2]?.tagId !== "") {
      const variablesTwo = { tracksByTagsArgs: { tagIds: userTags[2]?.tagId } }
      getTrackBytagsTwo({ variables: variablesTwo })
    }

    if (userTags[1]?.tagId !== "") {
      const variablesOne = { tracksByTagsArgs: { tagIds: userTags[1]?.tagId } }
      getTrackBytagsOne({ variables: variablesOne })
    }
    if (userTags[0]?.tagId !== "") {
      const variables = { tracksByTagsArgs: { tagIds: userTags[0]?.tagId } }
      getTrackBytags({ variables })
    }
  }, [])
  const recommendClient = recommend(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_KEY
  )

  const indexName = process.env.REACT_APP_ALGOLIA_TRACK_INDEX_NAME

  const [itemLength, setItemlength] = useState(0)
  const [itemLengthOne, setOneItemlength] = useState(0)
  const [itemLengthTwo, setTwoItemlength] = useState(0)

  // let trackStorageValue = getItem(STORAGE_KEYS.SINGLE_TRACK)
  const getRowSlider = (props) => {
    if (props.items.length && itemLength == 0) {
      setItemlength(itemLength + 1)
    }
    return (
      <RowSlider
        items={props.items && props.items.length ? props.items.slice(0, 7) : []}
      />
    )
  }

  const getRowSliderSecond = (props) => {
    if (props.items.length && itemLengthOne == 0) {
      setOneItemlength(itemLengthOne + 1)
    }
    return (
      <RowSlider
        items={props.items && props.items.length ? props.items.slice(0, 7) : []}
      />
    )
  }
  const getRowSliderSecondThird = (props) => {
    if (props.items.length && itemLengthTwo == 0) {
      setTwoItemlength(itemLengthTwo + 1)
    }
    return (
      <RowSlider
        items={props.items && props.items.length ? props.items.slice(0, 7) : []}
      />
    )
  }

  return (
    <>
      <div className="trendingContainer">
        <div style={{ display: itemLength == 0 ? "none" : "block" }}>
          <DashSection
            moreLink={`/algoliaRecommendation/${userTags[0]?.tagName}`}
            state={{ tagId: userTags[0]?.tagId, tagName: userTags[0]?.tagName }}
          >
            <h4>{userTags[0]?.tagName}</h4>
            <div>
              <TrendingItems
                recommendClient={recommendClient}
                indexName={indexName}
                view={getRowSlider}
                facetName={"tags.tagName"}
                facetValue={userTags.length ? userTags[0].tagName : ""}
              />
            </div>
          </DashSection>
        </div>
        <div>
          {itemLength == 0 &&
            userTags[0]?.tagId !== "" &&
            userTags[0] &&
            trackByTagsData?.getTracksByTags?.tracks?.length ? (
            <div>
              <DashSection
                moreLink={`/recommendedlist/${userTags[0]?.tagName}`}
                state={{
                  tagId: userTags[0]?.tagId,
                  tagName: userTags[0]?.tagName
                }}
              >
                <h4>{userTags[0]?.tagName}</h4>
                <div>
                  <RowSlider items={trackByTagsData?.getTracksByTags?.tracks} />
                </div>
              </DashSection>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* second row */}
        <div style={{ display: itemLengthOne == 0 ? "none" : "block" }}>
          <DashSection
            moreLink={`/algoliaRecommendation/${userTags[1]?.tagName}`}
            state={{ tagId: userTags[1]?.tagId, tagName: userTags[1]?.tagName }}
          >
            <h4>{userTags[1]?.tagName}</h4>
            <div>
              <TrendingItems
                recommendClient={recommendClient}
                indexName={indexName}
                view={getRowSliderSecond}
                facetName={"tags.tagName"}
                facetValue={userTags.length ? userTags[1]?.tagName : ""}
              />
            </div>
          </DashSection>
        </div>
        <div>
          {itemLengthOne == 0 &&
            userTags[1] &&
            userTags[1]?.tagId !== "" &&
            trackByTagsDataOne?.getTracksByTags?.tracks?.length ? (
            <div>
              <DashSection
                moreLink={`/recommendedlist/${userTags[1]?.tagName}`}
                state={{
                  tagId: userTags[1]?.tagId,
                  tagName: userTags[1]?.tagName
                }}
              >
                <h4>{userTags[1]?.tagName}</h4>
                <div>
                  <RowSlider
                    items={trackByTagsDataOne?.getTracksByTags?.tracks}
                  />
                </div>
              </DashSection>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* third row */}
        <div style={{ display: itemLengthTwo == 0 ? "none" : "block" }}>
          <DashSection
            moreLink={`/algoliaRecommendation/${userTags[2]?.tagName}`}
            state={{ tagId: userTags[2]?.tagId, tagName: userTags[2]?.tagName }}
          >
            <h4>{userTags[2]?.tagName}</h4>
            <div>
              <TrendingItems
                recommendClient={recommendClient}
                indexName={indexName}
                view={getRowSliderSecondThird}
                facetName={"tags.tagName"}
                facetValue={userTags.length ? userTags[2]?.tagName : ""}
              />
            </div>
          </DashSection>
        </div>
        <div>
          {itemLengthTwo == 0 &&
            userTags[2] &&
            userTags[2]?.tagId !== "" &&
            trackByTagsDataTwo?.getTracksByTags?.tracks?.length ? (
            <div>
              <DashSection
                moreLink={`/recommendedlist/${userTags[2]?.tagName}`}
                state={{
                  tagId: userTags[2]?.tagId,
                  tagName: userTags[2]?.tagName
                }}
              >
                <h4>{userTags[2]?.tagName}</h4>
                <div>
                  <RowSlider
                    items={trackByTagsDataTwo?.getTracksByTags?.tracks}
                  />
                </div>
              </DashSection>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  )
}

export default RecommendedComponent
