import React, { Fragment } from "react"
import { Breadcrumb } from "antd"
import { Link } from "react-router-dom"

const Bradcrumb = ({ linkItem }) => {
  return (
    <Fragment>
      <Breadcrumb className="breadcrumbs" separator=">">
        {linkItem.map((item, index) => {
          if (item.linkName?.length > 0) {
            return (
              <Breadcrumb.Item key={index}>
                <Link
                  // style={{ color: "#828282" }}
                  className="bread-link"
                  to={{
                    pathname: item.linkUrl,
                    state: item.id ? item.id : ""
                  }}
                  disable={item.disable ? item.disable : ""}
                  onClick={(e) => (item.disable ? e.preventDefault() : "")}
                >
                  {" "}
                  {item.linkName}
                </Link>
              </Breadcrumb.Item>
            )
          }

          // 👇️ render nothing
          return null
        })}

        {/* <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="/favorite-tracks">
          Favorite Tracks
        </Breadcrumb.Item> */}
      </Breadcrumb>
    </Fragment>
  )
}

export default Bradcrumb
