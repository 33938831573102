import React from "react"
import "./loader.scss"

const horizontalLoader = () => {
  return (
    <div>
      <div id="circleG">
        <div id="circleG_1" className="circleG"></div>
        <div id="circleG_2" className="circleG"></div>
        <div id="circleG_3" className="circleG"></div>
      </div>
    </div>
  )
}

export default horizontalLoader
