import { createSlice } from "@reduxjs/toolkit"

const accessToken = localStorage.getItem("accessToken")
const refreshToken = localStorage.getItem("refreshToken")

const initalState = {
  accessToken: accessToken ? accessToken : undefined,
  refreshToken: refreshToken ? refreshToken : undefined,
  authenticated: accessToken ? true : false,
  user: undefined,
  loading: false,
  errors: undefined
}

const AuthSlice = createSlice({
  name: "auth",
  initialState: initalState,
  reducers: {
    //login

    loginRequest(state) {
      state.loading = true
    },

    loginSuccess(state, actions) {
      state.loading = false
      state.authenticated = true
      state.accessToken = actions.payload.accessToken
      state.refreshToken = actions.payload.refreshToken
      state.user = actions.payload.user
    },

    loginFail(state, actions) {
      state.loading = false
      state.errors = actions.payload
      state.authenticated = false
    },

    // end login

    //logout
    logoutSuccess(state) {
      state.loading = false
      state.authenticated = false
      state.accessToken = undefined
      state.refreshToken = undefined
      state.user = undefined
    },

    fetchUserProfileRequest(state) {
      state.loading = true
    },

    fetchUserProfileSuccess(state, actions) {
      state.loading = false
      state.user = actions.payload
    },

    fetchUserProfileFail(state, actions) {
      state.loading = false
      state.errors = actions.payload
    },

    resetAuth(state) {
      state.token = undefined
      state.authenticated = false
      state.user = undefined
      state.loading = false
      state.errors = undefined
    }
  }
})

//actions
export const {
  //login
  loginFail,
  loginRequest,
  loginSuccess,
  //end login

  //logout
  logoutSuccess,

  //user profile
  fetchUserProfileFail,
  fetchUserProfileRequest,
  fetchUserProfileSuccess,

  resetAuth
} = AuthSlice.actions

//reducer
export default AuthSlice.reducer
