import React, { useEffect } from "react"
import Dashsection from "../../components/DashSection"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { fetchTracks } from "../../services/track.service"
import Preloader from "../../components/UI/Preloader"
import ScrollPagination from "../../components/UI/Scrollpagination"

const NewReleasesPage = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const { tracks, loadingTracks, metaTracks } = state.track

  useEffect(() => {
    fetchLatestTracks()
  }, [])

  const fetchLatestTracks = (params = { page: 1 }) => {
    dispatch(fetchTracks(params))
  }

  return (
    <>
      <Dashsection title="New Releases">
        {loadingTracks ? <Preloader /> : ""}
        <>
          {tracks && tracks.length ? (
            <>
              <RowCardlist
                cardlist={
                  <>
                    {tracks.map((item) => {
                      {
                        /* const { track } = item; */
                      }
                      return (
                        <>
                          <Col
                            // md={12}
                            // lg={8}
                            // xl={6}
                            // xxl={4}
                            className="card-list__item"
                            key={item.id}
                          >
                            <Card
                              img={item.image || Cimg1}
                              title={item.name}
                              link={`/track/${item.slug}`}
                            />
                          </Col>
                        </>
                      )
                    })}
                  </>
                }
              />
              {/* infinite scrolling */}
              {metaTracks ? (
                <ScrollPagination
                  meta={metaTracks}
                  pagination={fetchLatestTracks}
                  endText={"No more tracks"}
                  loading={loadingTracks}
                />
              ) : (
                ""
              )}
              {/*end infinite scrolling */}
            </>
          ) : (
            <>
              {loadingTracks ? (
                ""
              ) : (
                <div>Sorry no latest releases found !!</div>
              )}
            </>
          )}
        </>
      </Dashsection>
    </>
  )
}

export default NewReleasesPage
