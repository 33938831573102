export const appMessagepoints = [
    {
        id: 1,
        text: "Download tracks"
    },
    {
        id: 2,
        text: "Play tracks on offline mode"
    },
    {
        id: 3,
        text: "Review Your Performance"
    },
]