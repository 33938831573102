import React, { useEffect } from "react"
import Dashsection from "../../components/DashSection"
import Card from "../../components/Cards/Card"
import RowCardlist from "../../components/Cards/RowCardList"
import Cimg1 from "../../assets/images/Thumbnail.png"
import { Col } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { fetchTracksByTagsRecommendation } from "../../services/track.service"
import Preloader from "../../components/UI/Preloader"
import ScrollPagination from "../../components/UI/Scrollpagination"

//graphql imports
import { useQueryHook } from "../../graphql/hooks"
import { GET_PREMIUM_TRACKS_BY_TAGS } from "../../graphql/queries/track"

const PremiumPage = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const { recommended_tracks, loadingRT, metaRT } = state.home

  // useEffect(() => {
  //   fetchRecommendedTracks()
  // }, [])

  // const fetchRecommendedTracks = (params = { page: 1 }) => {
  //   dispatch(fetchTracksByTagsRecommendation(params))
  // }

  const { loading, data, error } = useQueryHook({
    query: GET_PREMIUM_TRACKS_BY_TAGS,
    variables: { getTracksDto: { page: 1, limit: 12, platform: "WEB" } }
  })

  return (
    <>
      <Dashsection title="Premium Tracks">
        {loading ? <Preloader /> : ""}
        <>
          {data && data.getPremiumTracksByTags ? (
            <>
              <RowCardlist
                cardlist={
                  <>
                    {data.getPremiumTracksByTags.map((item) => {
                      {
                        /* const { track } = item; */
                      }
                      return (
                        <>
                          <Col
                            // md={12}
                            // lg={8}
                            // xl={6}
                            // xxl={4}
                            className="card-list__item"
                            key={item.id}
                          >
                            <Card
                              img={
                                item.storageKeys.azure &&
                                item.storageKeys.azure.image
                                  ? item.storageKeys.azure.image
                                  : Cimg1
                              }
                              title={item.title}
                              link={`/track/${item.slug}`}
                              premium={
                                item.trackType === "PREMIUM" ||
                                item.trackType === "premium"
                                  ? true
                                  : false
                              }
                              id={item.id}
                            />
                          </Col>
                        </>
                      )
                    })}
                  </>
                }
              />
              {/* infinite scrolling */}
              {/* {metaRT ? (
                <ScrollPagination
                  meta={metaRT}
                  pagination={fetchRecommendedTracks}
                  endText={"No more tracks"}
                  loading={loadingRT}
                />
              ) : (
                ""
              )} */}
              {/*end infinite scrolling */}
            </>
          ) : (
            <>{loading ? "" : <div>Sorry no premium tracks found !!</div>}</>
          )}
        </>
      </Dashsection>
    </>
  )
}

export default PremiumPage
