import {
  ClockCircleOutlined,
  HeartOutlined,
  UnorderedListOutlined
} from "@ant-design/icons"

export const menu = []

export const library = [
  {
    title: "Favorite Tracks",
    link: "/favorite-tracks",
    icon: HeartOutlined,
    disabledMessage:
      "<p class='p-disabled'>sign up or login to add tracks to <span class='ml-05'>favourite tracks !!</span></p>",
    activeIcon: "iconly-Heart icbo",
    inactiveIcon: "iconly-Heart icli",
    currentUrl: "favorite-tracks"
  },
  {
    title: "Recently Played",
    link: "/recently-played-detail",
    icon: ClockCircleOutlined,
    disabledMessage:
      "<p class='p-disabled'>sign up or login to see your <span class='ml-05'>played histories !!</span></p>",
    activeIcon: "iconly-Time-Circle icbo",
    inactiveIcon: "iconly-Time-Circle icli",
    currentUrl: "recently-played"
  },
  // {
  //   title: "Playlists",
  //   link: "/playlist",
  //   icon: UnorderedListOutlined,
  //   disabledMessage:
  //     "<p class='p-disabled'>sign up or login to see <span class='ml-05'>playlists !!</span></p>",
  //   activeIcon: "iconly-Paper icbo",
  //   inactiveIcon: "iconly-Paper icli",
  //   currentUrl: "playlist"
  // }
]

export const playlist = []
