import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  isPlaying: false,
  playingSong: undefined,
  volume: 1,
  activeSongId: undefined,
  activeSongIndex: 0
}

const playerSlice = createSlice({
  name: "player",
  initialState: initialState,
  reducers: {
    setSongToPlay(state, actions) {
      state.playingSong = actions.payload
      state.isPlaying = true
    },

    playPause(state, actions) {
      state.isPlaying = actions.payload
    },

    updateVolume(state, actions) {
      state.volume = actions.payload
    },

    clearPlayer: (state) => initialState,

    setPlayingSong(state, actions) {
      const { id, index } = actions?.payload
      if (id) {
        state.activeSongId = id
      }
      if (index === 0 || index) {
        state.activeSongIndex = index ?? 0
      }
    },

    addToQueue(state, actions) {
      const { track } = actions?.payload
      if (state?.playingSong?.length > 0) {
        state.playingSong = [...state.playingSong, track]
      } else {
        state.playingSong = [track]
        state.isPlaying = true
        state.activeSongId = track?.id
        state.activeSongIndex = 0
      }
    }
  }
})

export const {
  playPause,
  setSongToPlay,
  updateVolume,
  clearPlayer,
  setPlayingSong,
  addToQueue
} = playerSlice.actions

export default playerSlice.reducer
