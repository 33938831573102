import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import useravatar from "../../assets/images/DP.png"
import favicon from "../../assets/images/fav-icon-brand.png"
import Toptracks from "./toptracks"
import Following from "./following"
import { ArrowRightOutlined, PlusCircleOutlined } from "@ant-design/icons"
import { Row, Col, Tabs, Progress } from "antd"
// import { Swiper, SwiperSlide } from "swiper/react"
// import SwiperCore, { Navigation, Pagination } from "swiper"
import { useDispatch, useSelector } from "react-redux"
import Avatar from "../../assets/images/toy-face.png"
import {
  fetchProfileApi,
  goalHistoryApi,
  fetchWeeklyReportApi
} from "../../services/user.service"
import { setDailyGoalModal } from "../../store/slice/user.slice"
import DailyGoalModal from "./dailyGoalModal"
import { getTime } from "../../utils/helper"
import _ from "lodash"
import moment from "moment"

const { TabPane } = Tabs
const Profile = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const { userData, dailyGoal, weeklyReport } = state.user
  const [totalHealingPoints, setTotalHealingPoints] = useState()

  useEffect(() => {
    getProfile()
    getGoalHistory()
    getWeeklyReport()
  }, [])

  useEffect(() => {
    let point = 0
    if (weeklyReport) {
      weeklyReport?.forEach((item, itemIndex) => {
        point = point + item?.achieved_percent
      })
      setTotalHealingPoints(point / weeklyReport.length)
    }
  }, [weeklyReport])

  const getProfile = () => {
    dispatch(fetchProfileApi())
  }

  const getGoalHistory = () => {
    dispatch(goalHistoryApi())
  }

  const openDailySetModal = () => {
    dispatch(setDailyGoalModal(true))
  }

  const getWeeklyReport = () => {
    dispatch(fetchWeeklyReportApi())
  }

  const handleRefresh = () => {
    getProfile()
    getGoalHistory()
    getWeeklyReport()
  }

  return (
    <div className="profile-wrapper">
      <section className="dash-section">
        <Row gutter={[30, 30]} className="row--profile-info">
          <Col xs={24} lg={8}>
            <div className="card card-profile card-profile--info card-profile--dashboard">
              <div className="profile-img">
                <img
                  src={userData?.versioned_image_urls?.large ?? Avatar}
                  alt="user-avatar"
                />
              </div>
              <h3 className="card__header">Profile</h3>
              <h2 className="username">{userData?.full_name}</h2>
              {userData?.paid ? (
                <p className="usertype green">
                  <span className="dot"></span>Premium User
                </p>
              ) : (
                <Link
                  to="/subscription"
                  className="btn btn-outline btn-large upgrade-btn"
                >
                  Upgrade
                </Link>
              )}
              {/* <p className="user-handle">@username</p> */}
              <p className="daily-goal">
                Daily Goal Set:{" "}
                <span>
                  {userData?.settings?.daily_goal === null
                    ? "Not Set"
                    : getTime(userData?.settings?.daily_goal)}
                </span>
              </p>
              <p className="fav-track">
                <span>{userData?.favourite_tracks_count ?? 0}</span>Favorite
                Track{userData?.favourite_tracks_count > 1 ? "s" : ""},{" "}
                <span>{userData?.playlists_count ?? 0}</span>Playlist
                {userData?.playlists_count > 1 ? "s" : ""}
              </p>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="card card-profile">
              <h3 className="card__header">Interaction</h3>
              <div className="interaction-graph">
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Days" key="1">
                    {weeklyReport?.length > 0 ? (
                      weeklyReport?.map((item, itemIndex) => {
                        return (
                          <div className="progress-container">
                            <div class="days">
                              {moment(item?.date)?.format("dddd")?.slice(0, 3)}
                            </div>
                            <Progress
                              className="progress-graph"
                              orientation="vertical"
                              percent={_.round(item?.achieved_percent, 1)}
                              strokeColor={"#9797DE"}
                              showInfo={false}
                            />
                          </div>
                        )
                      })
                    ) : (
                      <div>No interaction data available.</div>
                    )}
                  </TabPane>
                  {/* <TabPane tab="Month" key="2">
                                        <Swiper
                                            spaceBetween={15}
                                            slidesPerView={6}
                                        >
                                            <SwiperSlide>
                                                <Progress percent={30} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={40} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={50} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={30} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={60} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={80} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={50} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={20} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={40} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={30} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={60} />
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <Progress percent={70} />
                                            </SwiperSlide>

                                        </Swiper>
                                    </TabPane> */}
                </Tabs>
              </div>
              <div className="healing-info">
                <div className="healing-graph">
                  <Progress
                    type="circle"
                    strokeWidth="8"
                    showInfo={false}
                    strokeColor="#9797de"
                    percent={totalHealingPoints}
                  />
                  <img src={favicon} alt="" />
                </div>
                <div className="healing-text">
                  <h4>Total Healing points</h4>
                  {/* <p className="healing-points">10 Points</p> */}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <div className="card card-profile">
              <h3 className="card__header">
                Daily Goal Completion
                {/* <Link to='#' onClick={() => { openDailySetModal() }}>Edit Goals</Link> */}
              </h3>
              <div className="goal-progress">
                <Progress
                  percent={_.round(dailyGoal?.achieved_percent, 1)}
                  strokeColor="#9797de"
                />
                <Link
                  className="link-primary"
                  to="#"
                  onClick={() => {
                    openDailySetModal()
                  }}
                >
                  {userData?.settings?.daily_goal ? "Edit Goal" : "Set Goal"}{" "}
                  <ArrowRightOutlined />
                </Link>
              </div>
              <h3 className="card__header">
                Following
                {userData?.tags?.length !== 0 ? (
                  <Link
                    to={{
                      pathname: "/choose-genre",
                      state: {
                        fromProfile: true
                      }
                    }}
                    // to='#'
                  >
                    Edit
                  </Link>
                ) : (
                  <></>
                )}
              </h3>
              <div className="add-tags">
                {userData?.tag_groups?.length > 0 ? (
                  <div className="following_tags_container">
                    {userData?.tag_groups?.map((tag, tagIndex) => {
                      return (
                        <div className="following_tags_container__item">
                          <img src={tag?.svg_image_url} alt="tag" />
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <Link className="link-primary" to="#">
                    <PlusCircleOutlined />
                  </Link>
                )}
              </div>
              <div className="tag-progress">
                {dailyGoal?.tag_groups?.map((item, itemIndex) => {
                  return (
                    <div className="tag-progress__item">
                      <h4>{item?.name}</h4>
                      <Progress
                        percent={_.round(item?.achieved_percent, 1)}
                        strokeColor="#9797de"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <Toptracks />
      {/* <Following
                listData={userData?.tag_groups}
            /> */}
      <DailyGoalModal
        handleRefresh={handleRefresh}
        dailyGoal={userData?.settings?.daily_goal}
      />
    </div>
  )
}

export default Profile
