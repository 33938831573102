import React, { useState } from "react"
import Sidebar from "../../components/Sidebar"
import Header from "../../components/Header"
import { Layout } from "antd"
import ScrollToTop from "../../components/UI/ScrollToTop"
import { useSelector } from "react-redux"

const { Content } = Layout

const GuestLayout = (props) => {
  const [sidebarStatus, setSidebarStatus] = useState(false)
  const state = useSelector((state) => state)

  const { player } = state

  const changeSidebarStatus = () => {
    setSidebarStatus(!sidebarStatus)
  }

  return (
    <Layout
      theme="light"
      className={`main-layout ${sidebarStatus ? "active" : ""}`}
      id="gv-main-layout"
    >
      <div
        className={`sidebar-active-bg ${sidebarStatus ? "active" : ""}`}
        onClick={() => {
          changeSidebarStatus()
        }}
      ></div>
      <Sidebar sidebarStatus={sidebarStatus} />
      <ScrollToTop>
        <Layout
          className={`site-layout ${
            player?.playingSong ? "site-layout-with-player" : ""
          }`}
          theme="light"
        >
          <Header sidebarStatus={changeSidebarStatus} />
          <Content>{props.children}</Content>
        </Layout>
      </ScrollToTop>
    </Layout>
  )
}

export default GuestLayout
