import React, { useEffect } from "react"

import Cardbanner from "../../components/Cards/Card-banner"
import Cardsonglist from "../../components/Cards/Cardsonglist"
import Songlistitem from "../../components/songlist"

import Bannerimg from "../../assets/images/favorite-tracks.png"
import Cimg1 from "../../assets/images/Thumbnail.png"
// import ScrollPagination from "../../components/UI/Scrollpagination"
import { useLazyQueryHook, useMutationHook } from "graphql/hooks"
import {
  GET_FAVOURITES_TRACKS,
  REMOVE_TRACK_TO_FAVOURITE
} from "graphql/queries/track"
import { getErrors, getTotalTracksTime } from "utils/helper"
import { message } from "antd"
import Bradcrumb from "components/Bradcrumb"
import ScrollPagination from "components/ScrollPagination"
import { useState } from "react"
import userMixPanelEvents from "functions/mixPanelEvents"
import Preloader from "components/UI/Preloader"

const Favoritetracks = () => {
  const [currentPage, seTcurrentPage] = useState(1)
  const [limit, setLimit] = useState(100)

  const {
    func: favoriteTrackFun,
    loading: favouriteLoading,
    data: favouriteData,
    error: favouriteError
  } = useLazyQueryHook({
    query: GET_FAVOURITES_TRACKS,
    fetchPolicy: "network-only"
  })

  useEffect(() => {
    favoriteTrackFun({
      variables: { options: { page: currentPage, limit: limit } }
    })
  }, [currentPage])

  const [favoriteSong, setFavoriteSong] = useState([])

  useEffect(() => {
    if (
      favouriteData &&
      favouriteData.getFavoriteTracks &&
      favouriteData.getFavoriteTracks.tracks &&
      favouriteData.getFavoriteTracks.tracks.length
    ) {
      if (currentPage === 1) {
        setFavoriteSong(favouriteData?.getFavoriteTracks?.tracks)
      } else {
        setFavoriteSong([
          ...favoriteSong,
          ...favouriteData?.getFavoriteTracks?.tracks
        ])
      }
    } else if (
      !favouriteLoading &&
      favouriteData &&
      !favouriteData.getFavoriteTracks.tracks.length > 0
    ) {
      setFavoriteSong([])
    }
  }, [favouriteData])
  useEffect(() => {
    if (!favouriteLoading) {
      if (favouriteError) {
        message.error(
          getErrors(favouriteError.graphQLErrors) || favouriteError.message
        )
      }
    }
  }, [favouriteError, favouriteLoading])
  // mix panel events
  // mix panel events
  useEffect(() => {
    userMixPanelEvents("screen_view", { screen: "search" })
  }, [])
  const linkItem = [
    {
      linkName: "Home",
      linkUrl: "/"
    },
    {
      linkName: "Favorite Tracks",
      linkUrl: "/favorite-tracks"
    }
  ]

  const {
    func: removeTrackFromFavoriteFunc,
    loading: removeTrackFromFavoriteLoading,
    data: removeTrackFromFavoriteData,
    error: removeTrackFromFavoriteError
  } = useMutationHook({
    query: REMOVE_TRACK_TO_FAVOURITE,
    refetchQueries: [
      {
        query: GET_FAVOURITES_TRACKS,
        variables: {
          options: {
            limit: 100,
            page: 1
          }
        }
      }
    ]
  })

  const handleRemoveFavTrack = (removeTrackId) => {
    // const variables = { trackId: trackIdInd, trackId: track.id }
    const variables = { trackId: removeTrackId }
    removeTrackFromFavoriteFunc({ variables }).then((res) => {
      if (res.data !== null) {
        message.success("Favorite track is successfully removed")
      }
      if (res?.errors) {
        message.error(res?.errors[0].message)
      }
    })
  }
  return (
    <>
      {favouriteLoading ? <Preloader /> : ""}
      <>
        <section className="dash-section">
          <div className="bradcrumb-container">
            <Bradcrumb linkItem={linkItem} />
          </div>
          <Cardbanner
            img={Bannerimg}
            title="Favorite Tracks"
            totalTracks={`${
              favouriteData?.getFavoriteTracks
                ? favouriteData?.getFavoriteTracks?.meta?.total
                : "0"
            } 
        `}
            titleSize="large"
            track={favoriteSong}
            // createdby={``}
            fromFav={true}
            fromPlaylist={true}
          />
        </section>
        <section className="dash-section dash-section--playlist">
          {favoriteSong ? (
            <>
              <Cardsonglist
                songlist={
                  <>
                    {favoriteSong.map((item, index) => (
                      <Songlistitem
                        index={index < 9 ? "0" + (index + 1) : index + 1}
                        title={item.title}
                        category={
                          item.category ? item.category.categoryName : "N/A"
                        }
                        duration={getTotalTracksTime(item.duration)}
                        thumbnail={item.storageKeys?.azure?.image || Cimg1}
                        track={item}
                        allTracks={favoriteSong}
                        indexValue={index}
                        page="fev"
                        trackIdInd={item.id}
                        pathname="favorite-tracks"
                        handleRemoveFunc={handleRemoveFavTrack}
                        loadingafterfetch={removeTrackFromFavoriteLoading}
                      />
                    ))}
                  </>
                }
              />
              {/* infinite scrolling */}
              {/* {metaFavouriteTracks ? (
                <ScrollPagination
                  meta={metaFavouriteTracks}
                  pagination={loadFavTracks}
                  endText={"No more favourite tracks"}
                  loading={favouriteLoading}
                />
              ) : (
                ""
              )} */}
              {/*end infinite scrolling */}
            </>
          ) : (
            <div>Sorry no favorite tracks found!</div>
          )}
          <ScrollPagination
            current_page={currentPage}
            // pagination={loadMoreRecentlyPlayedSongs}
            // HasMore={recentlyPlayedAllHasMoreData}
            loadingMore={favouriteLoading}
            setLimit={setLimit}
            seTcurrentPage={seTcurrentPage}
            limit={limit}
            data={favouriteData}
            total={favouriteData?.getFavoriteTracks?.meta?.total_items}
            total_pages={favouriteData?.getFavoriteTracks?.meta?.total_pages}
          />
        </section>
      </>
    </>
  )
}

export default Favoritetracks
