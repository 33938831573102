export const STORAGE_KEYS = {
  ACCESS_TOKEN: "__a_t__",
  REFRESH_TOKEN: "__r_t__",
  USER: "__u__",
  EMAIL: "__e__",
  PURCHASE_USER: "__p_u__",
  SINGLE_TRACK: "__s_t__",
  UNIQUE_DEVICE_ID_FOR_WEB: "__u_d_id__"
}

// get items from localstorage
export const getItem = (key) => {
  const value = localStorage.getItem(key)

  if (!value) return null

  try {
    return JSON.parse(value)
  } catch (err) {
    return value
  }
}

// set item in localstorage
export const setItem = (key, value) => {
  if (!value) return null

  if (typeof value === "string") {
    localStorage.setItem(key, value)
  } else {
    localStorage.setItem(key, JSON.stringify(value)) // convert arrays or objects into strings
  }
}

// remove item from localstorage
export const removeItem = (key) => {
  localStorage.removeItem(key)
  localStorage.clear()
}
