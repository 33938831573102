// import from react 
import Banner from 'components/offerComponents/Banner/Banner';
import MidComponent from 'components/offerComponents/MidComponent';
import React from 'react';
import FAQ from '../../components/offerComponents/Faq/Faq';
// import components


const Offfer = () => {
    return (
        <div className='offer-page-wrapper'>
            <div className='banner-section'>
                <Banner />
            </div>
            <div>
                <MidComponent />
            </div>
            <FAQ />
        </div>
    );
};

export default Offfer;